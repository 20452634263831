// @ts-nocheck
import {TableGroupFilter} from '../models/tableData';
import {KeyCreatorParams, ValueGetterParams} from '@ag-grid-enterprise/all-modules';

export const groupFilterList: Array<TableGroupFilter> = [
  {
    filterField: 'lot_name',
    filterDisplayName: 'Lot name',
    filters: {
      lot_name: 'display',
      'lot.landuse': 'display',
      'lot.usage': 'hide',
      'parcelName': 'hide',
      'parcel.typology': 'hide',
      entity_index: 'hide',
      parcel_area: 'display',
      lot_perimeter: 'display',
      lot_frontage_length: 'display',
      lot_setback_front: 'display',
      lot_setback_back: 'display',
      lot_setback_right: 'display',
      lot_setback_left: 'display',
      lot_buildable_area: 'display',
      lot_building_coverege_ratio: 'display',
      lot_building_coverege_area: 'display',
      lot_open_area: 'display',
      lot_floor_area_residential_gross: 'sum',
      lot_floor_area_residential_net: 'sum',
      lot_floor_area_residential_service: 'sum',
      unit_num: 'sum',
      unit_floor_area_avg: 'hide',
      lot_layout_residential_max: 'hide',
      floor_residential_num: 'sum',
      lot_floor_area_underground_gross: 'sum',
      lot_building_coverege_underground_ratio: 'display',
      lot_building_coverege_underground_area: 'display',
      lot_floor_underground_num: 'sum',
      parcel_residential_parking_factor: 'hide',
      lot_parking_slot_num: 'sum',
      parcel_far: 'hide',
      parcel_unit_density_index: 'hide',
      parcel_zone: 'hide',
      parcel_household_avg: 'hide',
      parcel_worker_per_sqkm_num: 'hide'
    }
  },
  {
    filterField: 'lot.landuse',
    filterDisplayName: 'Land Use',
    filters: {
      lot_name: 'hide',
      'lot.landuse': 'display',
      'lot.usage': 'hide',
      'parcelName': 'hide',
      'parcel.typology': 'hide',
      entity_index: 'hide',
      parcel_area: 'hide',
      lot_perimeter: 'hide',
      lot_frontage_length: 'hide',
      lot_setback_front: 'hide',
      lot_setback_back: 'hide',
      lot_setback_right: 'hide',
      lot_setback_left: 'hide',
      lot_buildable_area: 'hide',
      lot_building_coverege_ratio: 'hide',
      lot_building_coverege_area: 'hide',
      lot_open_area: 'hide',
      lot_floor_area_residential_gross: 'sum',
      lot_floor_area_residential_net: 'sum',
      lot_floor_area_residential_service: 'sum',
      unit_num: 'sum',
      unit_floor_area_avg: 'hide',
      lot_layout_residential_max: 'hide',
      floor_residential_num: 'sum',
      lot_floor_area_underground_gross: 'sum',
      lot_building_coverege_underground_ratio: 'hide',
      lot_building_coverege_underground_area: 'hide',
      lot_floor_underground_num: 'sum',
      parcel_residential_parking_factor: 'hide',
      lot_parking_slot_num: 'sum',
      parcel_far: 'hide',
      parcel_unit_density_index: 'hide',
      parcel_zone: 'hide',
      parcel_household_avg: 'hide',
      parcel_worker_per_sqkm_num: 'hide',
    }
  },
  {
    filterField: 'lot.usage',
    filterDisplayName: 'Usages',
    filters: {
      lot_name: 'hide',
      'lot.landuse': 'hide',
      'lot.usage': 'display',
      'parcelName': 'hide',
      'parcel.typology': 'hide',
      entity_index: 'hide',
      parcel_area: 'hide',
      lot_perimeter: 'hide',
      lot_frontage_length: 'hide',
      lot_setback_front: 'hide',
      lot_setback_back: 'hide',
      lot_setback_right: 'hide',
      lot_setback_left: 'hide',
      lot_buildable_area: 'hide',
      lot_building_coverege_ratio: 'hide',
      lot_building_coverege_area: 'hide',
      lot_open_area: 'hide',
      lot_floor_area_residential_gross: 'sum',
      lot_floor_area_residential_net: 'sum',
      lot_floor_area_residential_service: 'sum',
      unit_num: 'sum',
      unit_floor_area_avg: 'hide',
      lot_layout_residential_max: 'hide',
      floor_residential_num: 'sum',
      lot_floor_area_underground_gross: 'sum',
      lot_building_coverege_underground_ratio: 'hide',
      lot_building_coverege_underground_area: 'hide',
      lot_floor_underground_num: 'sum',
      parcel_residential_parking_factor: 'hide',
      lot_parking_slot_num: 'sum',
      parcel_far: 'hide',
      parcel_unit_density_index: 'hide',
      parcel_zone: 'hide',
      parcel_household_avg: 'hide',
      parcel_worker_per_sqkm_num: 'hide'
    }
  },
  {
    filterField: 'parcelName',
    filterDisplayName: 'Parcel + Lot',
    filters: {
      lot_name: 'display',
      'lot.landuse': 'hide',
      'lot.usage': 'hide',
      'parcelName': 'display',
      'parcel.typology': 'hide',
      entity_index: 'hide',
      parcel_area: 'hide',
      lot_perimeter: 'hide',
      lot_frontage_length: 'hide',
      lot_setback_front: 'hide',
      lot_setback_back: 'hide',
      lot_setback_right: 'hide',
      lot_setback_left: 'hide',
      lot_buildable_area: 'hide',
      lot_building_coverege_ratio: 'hide',
      lot_building_coverege_area: 'hide',
      lot_open_area: 'hide',
      lot_floor_area_residential_gross: 'sum',
      lot_floor_area_residential_net: 'sum',
      lot_floor_area_residential_service: 'sum',
      unit_num: 'sum',
      unit_floor_area_avg: 'hide',
      lot_layout_residential_max: 'hide',
      floor_residential_num: 'sum',
      lot_floor_area_underground_gross: 'sum',
      lot_building_coverege_underground_ratio: 'hide',
      lot_building_coverege_underground_area: 'hide',
      lot_floor_underground_num: 'sum',
      parcel_residential_parking_factor: 'hide',
      lot_parking_slot_num: 'sum',
      parcel_far: 'hide',
      parcel_unit_density_index: 'hide',
      parcel_zone: 'hide',
      parcel_household_avg: 'hide',
      parcel_worker_per_sqkm_num: 'hide'
    }
  }
];

export const columnDefs = [
  {
    field: 'lot_name',
    headerName: 'Lot name',
    pinned: 'left',
    hide: false,
    headerCheckboxSelectionFilteredOnly: true,
    editable: false,
    checkboxSelection: (e) => {
      const displayedColumns = e.columnApi.getAllDisplayedColumns();
      return displayedColumns[0] === e.column;
    }
  },
  { field: 'lot.landuse', headerName: 'land use', pinned: 'left', editable: false, hide: false},
  { field: 'lot.usage', headerName: 'usages', pinned: 'left', editable: (event) => event.data.isInstance, hide: false},
  { field: 'parcelName', headerName: 'Parcel name', pinned: 'left', editable: false, hide: false,
    keyCreator: (row: KeyCreatorParams) => row.data.parent ? row.data.parent.parcelName : '',
    valueGetter: (params: ValueGetterParams) => params.data && params.data.parent ? params.data.parent.parcelName : ''
  },
  { field: 'parcel.typology', headerName: 'typology', editable: false},
  { field: 'entity_index', headerName: 'entity_index', editable: false},
  { field: 'parcel_area', headerName: 'lot area', editable: false},
  { field: 'lot_perimeter', headerName: 'perimeter', editable: false},
  { field: 'lot_frontage_length', headerName: 'frontage', editable: false},
  { field: 'lot_setback_front', headerName: 'front setback', editable: false},
  { field: 'lot_setback_back', headerName: 'back setback', editable: false},
  { field: 'lot_setback_right', headerName: 'right setback', editable: false},
  { field: 'lot_setback_left', headerName: 'left setback', editable: false},
  { field: 'lot_buildable_area', headerName: 'Buildable Area (after setbacks)', editable: false},
  { field: 'lot_building_coverege_ratio', headerName: 'coverege', editable: false},
  { field: 'lot_building_coverege_area', headerName: 'coverege area', editable: false},
  { field: 'lot_open_area', headerName: 'open area', editable: false},
  { field: 'lot_floor_area_residential_gross', headerName: 'gross', editable: false},
  { field: 'lot_floor_area_residential_net', headerName: 'net', editable: false},
  { field: 'lot_floor_area_residential_service', headerName: 'service', editable: false},
  { field: 'unit_num', headerName: 'num of units', editable: false},
  { field: 'unit_floor_area_avg', headerName: 'avg unit area', editable: false},
  { field: 'lot_layout_residential_max', headerName: 'max layout', editable: false},
  { field: 'floor_residential_num', headerName: 'num of floor', editable: false},
  { field: 'lot_floor_area_underground_gross', headerName: 'gross area UG', editable: false},
  { field: 'lot_building_coverege_underground_ratio', headerName: 'coverege UG', editable: false},
  { field: 'lot_building_coverege_underground_area', headerName: 'coverage area UG', editable: false},
  { field: 'lot_floor_underground_num', headerName: 'num of floors UG', editable: false},
  { field: 'parcel_residential_parking_factor', headerName: 'parking factor', editable: false},
  { field: 'lot_parking_slot_num', headerName: 'num of parking slot UG', editable: false},
  { field: 'parcel_far', headerName: 'far', editable: false},
  { field: 'parcel_unit_density_index', headerName: 'units density per sqkm', editable: false},
  { field: 'parcel_zone', headerName: 'zone code', editable: false},
  { field: 'parcel_household_avg', headerName: 'Average household size', editable: false},
  { field: 'parcel_worker_per_sqkm_num', headerName: 'num workers per sqkm', minWidth: 200, flex: 1, editable: false},
];

export const columnDefsAllEntities = [
  { field: 'name', headerName: 'Name', editable: false, enableRowGroup: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: (e) => {
      const displayedColumns = e.columnApi.getAllDisplayedColumns();
      return displayedColumns[0] === e.column;
    }},
  { field: 'entityType', headerName: 'Entity Type', editable: false, enableRowGroup: true,},
  { field: 'geometryType', headerName: 'Geometry Type', editable: false, enableRowGroup: true}
];

export const columnDefsLotsPlanned = [
  { field: 'name', headerName: 'Name', pinned: 'left', editable: false, enableRowGroup: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: (e) => {
      const displayedColumns = e.columnApi.getAllDisplayedColumns();
      return displayedColumns[0] === e.column;
    }},
  { field: 'usages', headerName: 'Usages', editable: false, enableRowGroup: true},
  { field: 'area', headerName: 'Area (Dunam)', editable: false, enableRowGroup: true,},
  { field: 'mainArea', headerName: 'Main Area', editable: false, enableRowGroup: true,},
  { field: 'FAR', headerName: 'FAR', editable: false, enableRowGroup: true, field: 'number', valueFormatter: params => {
      if (params.data.far) {
        return params.data.far.toFixed(2);
      }
    }},
  { field: 'gfa', headerName: 'GFA (m2)', editable: false, enableRowGroup: true},
  { field: 'coverage', headerName: 'Coverage (%) ', editable: false, enableRowGroup: true},
  { field: 'numOfBuildings', headerName: 'Num of buildings', editable: false, enableRowGroup: true},
  { field: 'residentialUnits', headerName: 'Residential units', editable: false, enableRowGroup: true},
  { field: 'residentialGFA', headerName: 'Residential GFA (m2)', editable: false, enableRowGroup: true},
  { field: 'officeGFA', headerName: 'Office GFA (m2)', editable: false, enableRowGroup: true},
  { field: 'retailGFA', headerName: 'Retail GFA (m2)', editable: false, enableRowGroup: true},
  // optional
  { field: 'landUse', headerName: 'Land use', editable: false, enableRowGroup: true, hide: true},
  { field: 'avgCostOnGroundPerM2', headerName: 'AVG cost on ground per M2 ($)', editable: false, enableRowGroup: true, hide: true},
  { field: 'avgCostUndergroundPerM2', headerName: 'AVG cost underground per M2 ($)', editable: false, enableRowGroup: true, hide: true},
  { field: 'avgValuePerM2Residential', headerName: 'AVG value per M2 residential ($)', editable: false, enableRowGroup: true, hide: true},
  { field: 'publicGFA', headerName: 'public GFA (m2)', editable: false, enableRowGroup: true, hide: true},
  // { field: 'percentageOfResidentialGFA', headerName: 'Percentage of residential GFA (%)', editable: false, enableRowGroup: true, hide: true},
  // { field: 'percentageOfOfficeGFA', headerName: 'Percentage of office GFA (%)', editable: false, enableRowGroup: true, hide: true},
  // { field: 'percentageOfRetailGFA', headerName: 'Percentage of retail GFA (%)', editable: false, enableRowGroup: true, hide: true},
  // { field: 'percentageOfPublicGFA', headerName: 'Percentage of public GFA (%)', editable: false, enableRowGroup: true, hide: true},
  // { field: 'perimeter', headerName: 'Perimeter (m)', editable: false, enableRowGroup: true, hide: true},
  // { field: 'frontage', headerName: 'Frontage (m)', editable: false, enableRowGroup: true, hide: true},
  { field: 'maxBuildingHeight', headerName: 'Max building height (m)', editable: false, enableRowGroup: true, hide: true},
  { field: 'residentialMainArea', headerName: 'Residential main area (m2)', editable: false, enableRowGroup: true, hide: true},
  { field: 'residentialServiceArea', headerName: 'Residential service area (m2)', editable: false, enableRowGroup: true, hide: true}
];

export const columnDefsLotsGoals = [
  { field: 'name', headerName: 'Name', pinned: 'left', editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'usages', headerName: 'Usages', editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'area', headerName: 'Area', editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'FAR', headerName: 'FAR', editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'gfa', headerName: 'GFA', editable: false, enableRowGroup: true},
  { field: 'coverage', headerName: 'Coverage', editable: false, enableRowGroup: true},
  { field: 'numOfBuildings', headerName: 'Num of buildings', editable: false, enableRowGroup: true},
  { field: 'residentialUnits', headerName: 'Residential units', editable: false, enableRowGroup: true},
  { field: 'residentialGFA', headerName: 'Residential GFA', editable: false, enableRowGroup: true},
  { field: 'officeGFA', headerName: 'Office GFA', editable: false, enableRowGroup: true},
  { field: 'retailGFA', headerName: 'Retail GFA', editable: false, enableRowGroup: true},
  // optional
  { field: 'publicGFA', headerName: 'Public GFA', editable: false, enableRowGroup: true, hide: true},
  { field: 'percentageOfResidentialGFA', headerName: 'Percentage of residential GFA', editable: false, enableRowGroup: true, hide: true},
  { field: 'percentageOfOfficeGFA', headerName: 'Percentage of office GFA', editable: false, enableRowGroup: true, hide: true},
  { field: 'percentageOfRetailGFA', headerName: 'Percentage of retail GFA', editable: false, enableRowGroup: true, hide: true},
  { field: 'percentageOfPublicGFA', headerName: 'Percentage of public GFA', editable: false, enableRowGroup: true, hide: true},
  { field: 'perimeter', headerName: 'Perimeter', editable: false, enableRowGroup: true, hide: true},
  { field: 'frontage', headerName: 'Frontage', editable: false, enableRowGroup: true, hide: true},
  { field: 'residentialMainArea', headerName: 'Residential main area', editable: false, enableRowGroup: true, hide: true},
  { field: 'residentialServiceArea', headerName: 'Residential service area', editable: false, enableRowGroup: true, hide: true},
];

export const columnDefsBuildings = [
  { field: 'name', headerName: 'Name', pinned: 'left', editable: false, enableRowGroup: true, enableRowGroup: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: (e) => {
      const displayedColumns = e.columnApi.getAllDisplayedColumns();
      return displayedColumns[0] === e.column;
    }},
  { field: 'usages', headerName: 'Usages', editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'groundArea', headerName: 'Ground area (m2)', editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'mainArea', headerName: 'Main Area', editable: false, enableRowGroup: true,},
  { field: 'gfa', headerName: 'GFA (m2)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'height', headerName: 'Height (m)', editable: false, enableRowGroup: true},
  { field: 'numOfFloors', headerName: 'Num of floors', editable: false, enableRowGroup: true},
  { field: 'residentialUnits', headerName: 'Residential units', editable: false, enableRowGroup: true},
  { field: 'residentialGFA', headerName: 'Residential GFA (m2)', editable: false, enableRowGroup: true},
  { field: 'officeGFA', headerName: 'Office GFA (m2)', editable: false, enableRowGroup: true},
  { field: 'retailGFA', headerName: 'Retail GFA (m2)', editable: false, enableRowGroup: true},
  { field: 'residentialService' , headerName: 'Residential Service (m2)', editable: false, enableRowGroup: true},
  { field: 'officeService' , headerName: 'Office Service (m2)', editable: false, enableRowGroup: true},
  { field: 'retailService' , headerName: 'Retail Service (m2)', editable: false, enableRowGroup: true},
  // optional
  { field: 'hotelService' , headerName: 'Hotel Service (m2)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'publicService' , headerName: 'public Service (m2)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'parkingService' , headerName: 'parking Service (m2)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'parentId', headerName: 'Parental lot', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'residentialFloors', headerName: 'Residential\'s floors', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'officeFloors', headerName: 'Office\'s floors', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'retailFloors', headerName: 'Retail\'s floors', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'publicGFA', headerName: 'Public GFA', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'publicFloors', headerName: 'Public\'s floors', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'groundFloorHeight', headerName: 'Ground floor height', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'typicalFloorHeight', headerName: 'Typical floor height', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  { field: 'averageUnitSize', headerName: 'Average unit size', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'percentageOf5BedroomsUnits', headerName: 'Percentage of 5 bedrooms units (%)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'numOf5BedroomsUnits', headerName: 'Num of 5 bedrooms units', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'percentageOf4BedroomsUnits', headerName: 'Percentage of 4 bedrooms units (%)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'numOf4BedroomsUnits', headerName: 'Num of 4 bedrooms units', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'percentageOf3BedroomsUnits', headerName: 'Percentage of 3 bedrooms units (%)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'numOf3BedroomsUnits', headerName: 'Num of 3 bedrooms units', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'percentageOf2BedroomsUnits', headerName: 'Percentage of 2 bedrooms units (%)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'numOf2BedroomsUnits', headerName: 'Num of 2 bedrooms units', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'percentageOfStudioUnits', headerName: 'Percentage of studio units (%)', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
  // { field: 'numOfStudioUnits', headerName: 'Num of studio units', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true, hide: true},
];

export const columnDefsComments = [
  { field: 'name', headerName: 'Name', pinned: 'left', editable: false, enableRowGroup: true, enableRowGroup: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: (e) => {
      const displayedColumns = e.columnApi.getAllDisplayedColumns();
      return displayedColumns[0] === e.column;
    }},
  { field: 'createdBy', headerName: 'User Name', editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'date', headerName: 'Date Added', editable: false, enableRowGroup: true, enableRowGroup: true},
  { field: 'description', headerName: 'Description', editable: false, enableRowGroup: true, editable: false, enableRowGroup: true, enableRowGroup: true},
];

export const tableColumnState = 'tableColumnState'
export const tableGroupState = 'tableGroupState'
export const unGrouped = 'unGrouped'
