import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '../../services/state/state.service';
import {ENTITY_GEOMETRIC_TYPES, ENTITY_CATEGORIES, PANEL_TABS, ENTITY_ACCESS_TYPE} from '../../enums/enums';
import {
  BLUELINE_LANDUSE,
  SCOPELINE_INDEX_OLD,
  BLUELINE_MAVAT_CODE,
  DEFAULT_ENTITY_LANDUSE, ENTITIES_TYPES, TEMP_NAME,
  URBAN_S3_URL
} from '../../config';
import {Feature, Geometry} from 'geojson';
import * as TurfCenter from '@turf/center';
import {Subject, combineLatest, forkJoin, of} from 'rxjs';
import {ImportEntitiesActionTypes} from '../../shared/types/types';
import {NzMessageService} from 'ng-zorro-antd/message';
import {EntityProperties} from '../../models/entity-properties';
import Utils from '../../utils/utils';
import {EntitiesService} from '../../services/entities/entities.service';
import {PlansService} from '../../services/plans/plans.service';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {mavatUrbanDashboard} from '../../enums/mavat-urban-dashboard';
import { StoresManagerService } from 'src/app/services/storesManagerService/stores-manager.service';
import { ProjectStateStoreService } from 'src/app/services/projectStateStore/project-state-store.service';
import {switchMap, take, takeUntil,} from 'rxjs/operators';
import { AddUpdateDeleteEntitiesParams, EntityEntry } from 'src/app/models/entity-map';
import { IParentChildRelationship } from 'src/app/models/parent-child-relationship';
import { ProjectStoreService } from 'src/app/services/projectStore/project-store.service';
import { BuildingDataService } from 'src/app/services/building-pipeline/building-data.service';
import { LeafletService } from './panels/panel-map-main/leaflet-map/leaflet.service';
import { GeomanControlService } from './panels/panel-map-main/leaflet-map/services/geoman-control.service';
import { MenuDataStoreService } from 'src/app/services/menuDataStore/menu-data-store.service';

@Injectable({
  providedIn: 'root'
})
export class MainPageService implements OnDestroy {
  enterPressed$: Subject<void> = new Subject<void>();
  escPressed$: Subject<void> = new Subject<void>();
  // deletePressed$: Subject<void> = new Subject<void>();
  resizeMap$: Subject<void> = new Subject<void>();
  // dashboardTypeChange$: Subject<void> = new Subject<void>();
  isShiftKeyPressed: boolean;

  confirmModal?: NzModalRef;

  componentDestroyed$: Subject<boolean> = new Subject();

  private keyActions: { [key: string]: () => void } = {
    'e': () => {
      this.editEntity();
      this.leafletService.nextCloseMenu();
    },
    'd': () => {
      this.geomanControlService.handleEntityDuplication();
      this.leafletService.nextCloseMenu();
    },
    's': () => {
      this.geomanControlService.handleEntityScale();
      this.leafletService.nextCloseMenu();
    },
    'm': () => {
      this.geomanControlService.handleMoveEntity();
      this.leafletService.nextCloseMenu();
    },
    'r': () => {
      this.geomanControlService.handleRotateEntity();
      this.leafletService.nextCloseMenu();
    }
  };

  private _flyToCenterSource = new Subject<any>();
  flyToCenter$ = this._flyToCenterSource.asObservable();

  constructor(
    private menuDataStoreService: MenuDataStoreService,
    private stateService: StateService,
    private projectStoreService: ProjectStoreService,
    private storesManagerService: StoresManagerService,
    private projectStateStoreService: ProjectStateStoreService,
    private nzMessageService: NzMessageService,
    private entitiesService: EntitiesService,
    private buildingDataService: BuildingDataService,
    private plansService: PlansService,
    private geomanControlService: GeomanControlService,
    private leafletService: LeafletService,
    private modal: NzModalService) {
  }

  initializeService() {
    // this.menuDataStoreService.menuData$.pipe(
    //   takeUntil(this.componentDestroyed$)
    // ).subscribe(menuData => {
    //   this.keyActions = {
    //     'e': () => this.editEntity(),
    //     'd': () => this.geomanControlService.handleEntityDuplication(menuData),
    //     's': () => this.geomanControlService.handleEntityScale(),
    //     'm': () => this.geomanControlService.handleMoveEntity(),
    //     'r': () => this.geomanControlService.handleRotateEntity(),
    //   };
    // });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  importEntities(
    features: any[], 
    options: {
      description: string, toSave: boolean, isOverrideScopeline: boolean, isGenerateDesign?: boolean,
      action: ImportEntitiesActionTypes
    },
    menuData,
    projectName,
    currentPlanName,
    toDrag: boolean = false,
    importedFrom?) {

    const handleEntity = (ent: Feature<Geometry, any>) => {
      if (ent.id !== SCOPELINE_INDEX_OLD) {
        let shape = ent.properties && ent.properties.type || this.entitiesService.generateShapeFromGeometry(ent.geometry.type);
        if (shape === ENTITY_GEOMETRIC_TYPES.CIRCLE) {
          shape = {type: ENTITY_GEOMETRIC_TYPES.CIRCLE, radius: ent.properties.radius};
        }
        let params;
        switch (options.action) {
          case 'building':
            params = {
              entityCategory: ENTITY_CATEGORIES.BUILDING,
              landUse: 'building',
              entityType: ENTITIES_TYPES.building.building
            }
            ent.properties.entityType = ENTITIES_TYPES.building.building;
            break;
          case 'mavat':
            const style = {
              pattern: `${URBAN_S3_URL}/patterns/mavat_patterns/${mavatUrbanDashboard[ent.properties.landuseCode]?.pattern}` || ''
            };
            params = {
              // landUse: mavatCodeLandUse(ent.properties.landuseCode),
              accessType: mavatUrbanDashboard[ent.properties.landuseCode]?.accessType || ENTITY_ACCESS_TYPE.OTHER,
              landUse: mavatUrbanDashboard[ent.properties.landuseCode]?.urbanLandUse || ENTITIES_TYPES.default.default_polygon,
              entityCategory: ENTITY_CATEGORIES.PARCEL,
              entityType: mavatUrbanDashboard[ent.properties.landuseCode]?.urbanLandUse || 'other',
              landuseCode: ent.properties.landuseCode,
              style: style
            };
            // params.style.fillColor = mavatUrbanDashboard[ent.properties.landuseCode].pattern;
            params.isMavatLanduseCode = true;
            break;
          case 'blue_line':

            this.projectStateStoreService.isBlueLine().pipe(
              take(1)
            ).subscribe(isBlueLine => {
              if (isBlueLine) {
                this.nzMessageService.error('Blue line rejected - plan already has a blue line');
                return;
              } else {
                if (ent.geometry.type !== ENTITY_GEOMETRIC_TYPES.POLYGON) {
                  this.nzMessageService.error('Blue line rejected - must be of type Polygon');
                  return;
                }
                ent.properties.landUse = BLUELINE_LANDUSE;
                ent.properties.entityCategory = ENTITY_CATEGORIES.GUIDELINE_ELEMENTS;
                ent.properties.name = 'Blue Line';
              }
            })
            break
          case 'urban_mavat':
            params = {
              accessType: mavatUrbanDashboard[ent.properties.landuseCode].accessType,
              landUse: mavatUrbanDashboard[ent.properties.landuseCode].urbanLandUse || 'other',
              entityCategory: ENTITY_CATEGORIES.PARCEL,
              entityType: mavatUrbanDashboard[ent.properties.landuseCode].urbanLandUse || 'other',
              landuseCode: ent.properties.landuseCode || 'other',
              // description: mavatUrbanDashboard[ent.properties.landuseCode].mavatNameHebrew 
            };

            break;
          case 'default':
          default:
            params = {};
        }

        // importedEntities.push(this.stateService.createEntity(ent, {shape, params, isImport: true}));
        importedEntities.push(this.entitiesService.createEntity(ent, {shape, params, isImport: true}, menuData, projectName)); // !!!!

      } else if (ent.id === SCOPELINE_INDEX_OLD && options.isOverrideScopeline) {
        scopeline = this.entitiesService.createScopeline(ent);

      }
    };
    const mavatCodeLandUse = (landuseCode: string) => {
      // const parcelLandUses = this.stateService.landUses.parcel.landUse // !!!
      const parcelLandUses = menuData.parcel.landUse // !!!

      const mavat = Object.keys(parcelLandUses).find(landUse => parseInt(parcelLandUses[landUse].mavatCode) === parseInt(landuseCode));
      return mavat ? mavat : 'default';
    };

    if (!features || !features.length) {
      return;
    }

    let scopeline;
    let importedEntities: any[] = [];
    if (options.action === 'blue_line') {
      const featuresOfJson = features
      features = features.filter(feature => (!feature.properties.landuseCode && feature.properties.landUse === BLUELINE_LANDUSE) || (feature.properties.landuseCode && feature.properties.landuseCode === BLUELINE_MAVAT_CODE));
      if (features.length === 0 && importedFrom === 'geojson') {
        for (let i = 0; i < featuresOfJson.length; i++) {
          if ( featuresOfJson[i].properties.landUse && featuresOfJson[i].properties.landUse !== BLUELINE_LANDUSE) {
            featuresOfJson.splice(i, 1)
          }
        }
        features = featuresOfJson
      }
    } else {
      features = features.filter(feature => (!feature.properties.landuseCode && feature.properties.landUse !== BLUELINE_LANDUSE) || (feature.properties.landuseCode && feature.properties.landuseCode !== BLUELINE_MAVAT_CODE));
    }
    features.forEach(ent => {
      if (ent.type && ent.type === 'Feature') {
        if (options.isGenerateDesign) {
          ent.properties.isGenerateDesign = options.isGenerateDesign;
        }
        handleEntity(ent);
      } else if ((ent.type && ent.type === 'FeatureCollection') && (ent.features && Array.isArray(ent.features))) {
        ent.features.forEach((en: Feature<Geometry, any>) => handleEntity(en));
      } else {
        console.warn(`Skipping entity ${ent.id || ent.properties.name} - invalid GeoJSON`);
      }
    });
    if (importedEntities.length === 0) {
      return;
    }


    let entitiesNames = [];
    importedEntities.forEach(ent => {
      const entityDescription = options.action === 'mavat' || options.action === 'urban_mavat' ? options.description + mavatUrbanDashboard[ent.properties.landuseCode]?.mavatNameHebrew : options.description  || ent.description || ''
      ent.properties.description = entityDescription;
      if (ent.properties.name && ent.properties.name !== TEMP_NAME) {
        ent.properties.originalName = ent.properties.name;
      }
      ent.properties.name = this.entitiesService.generateDefaultEntityName(ent, {namesList: entitiesNames, isUseExistingName: true, importType: options.action});
      entitiesNames.push(ent.properties.name);
      Utils.truncateGeoJson(ent);
    });
    importedEntities = this.entitiesService.uniqueIdValidator(importedEntities).map(ent => this.entitiesService.calculateArea(ent));
    const blueline = importedEntities.find(ent => ent.properties.landUse === BLUELINE_LANDUSE);
    if (blueline) {
      // this.stateService.planBluelineId = blueline.id;
    }

    const entitiesUpdateObject: AddUpdateDeleteEntitiesParams = {
      entitiesToAdd: {},
      entitiesToUpdate: {},
    };
  
    this.projectStateStoreService.getCurrentPlanEntities().pipe(
      take(1)
    ).subscribe(currentEntities => {
      let entityEntry: EntityEntry;
      importedEntities.forEach(ent => {
        // calculate parent child relationship for every building entity
        if (ent.properties.entityType === 'building') {
          const parentChildRelationship: IParentChildRelationship = this.entitiesService.handlePolygonWithinPolygon(ent, Object.values(currentEntities));
          entityEntry = {
            parentId: parentChildRelationship.parentId,
            entity: ent
          };
  
          if (currentEntities[parentChildRelationship.entityId]) {
            entitiesUpdateObject.entitiesToUpdate[parentChildRelationship.entityId] = entityEntry;
          } else {
            entitiesUpdateObject.entitiesToAdd[parentChildRelationship.entityId] = entityEntry;
          }
        } else {
          entityEntry = {
            entity: ent
          }
          entitiesUpdateObject.entitiesToAdd[ent.id] = entityEntry;
        }
      });
      if (options.toSave) {
        this.storesManagerService.addUpdateDeleteEntities(entitiesUpdateObject, currentPlanName).pipe(
          take(1)
        ).subscribe(() => {
          this.projectStateStoreService.nextRecenterCoordinates(feature.geometry.coordinates);
          if (toDrag) {
            this.storesManagerService.setSelectedEntities([importedEntities[0]], false, false);
            const entityToDragId = importedEntities[0].id;
            this.entitiesService.nextDuplicatedEntityToDrag(entityToDragId);
          }
          
        })
      }
    });

    if (options.action === 'blue_line') {
      // this.dashboardTypeChange$.next();
    }
    // @ts-ignore
    const feature: Feature<Point> = TurfCenter.default(importedEntities[0]);

  }

  // initializeEntitiesTypeDerivatives(entities: Feature<Geometry, EntityProperties>[]) {
  //   let childrenIds = [];
  //   let parentIds = [];
  //   for (const entity of entities) {
  //     if (!entity.properties.entityType || (entity.properties.entityType !== ENTITIES_TYPES.parcel.building && !Utils.isLot(entity.properties.entityType))) {
  //       continue;
  //     }
  //     entity.properties.landUseParams = this.stateService.entityParamsValues(entity.properties.entityCategory, entity.properties.landUse);
  //     if (entity.properties.entityType === ENTITIES_TYPES.parcel.building) {
  //       const lotBuilding = this.entitiesService.buildingPolygonWithinLotByBuilding(entity);
  //       const lotId = lotBuilding ? lotBuilding.lot : undefined;
  //       if (lotId) {
  //         childrenIds.push(entity.id);
  //         parentIds.push(lotId);
  //         continue;
  //       }
  //     }
  //     if (Utils.isLot(entity.properties.entityType)) {
  //       const parcelId = this.entitiesService.lotWithinParcel(entity);
  //       if (parcelId) {
  //         childrenIds.push(entity.id);
  //         parentIds.push(parcelId);
  //       }
  //       const lotBuildings = this.entitiesService.buildingPolygonWithinLotByLot(entity);
  //       if (lotBuildings && lotBuildings.length > 0) {
  //         lotBuildings.forEach(feature => {
  //           childrenIds.push(feature.building);
  //           parentIds.push(feature.lot);
  //         });
  //       }
  //     }
  //   }
  //   if (childrenIds.length > 0) {
  //     this.plansService.modifyCurrentPlanConfig(childrenIds, 'parentId', parentIds);
  //   }
  // }

  // closeEditEntityPanel() {
  //   if (this.stateService.panelTab === PANEL_TABS.Edit_Layer) {
  //     this.stateService.panelTab = PANEL_TABS.Plan_Layer;
  //   }
  // }

  undo() {
    // const currentIndex = this.stateService.historyIndex;
    // const prevIndex = 0 <= currentIndex - 1 ? currentIndex - 1 : null;
    // if (prevIndex === null || prevIndex < 0) {
    //   this.stateService.historyIndex = 0;
    //   return;
    // }
    // this.stateService.prevHistoryStep(currentIndex);
  }

  redo() {
    // const currentIndex = this.stateService.historyIndex;
    // const nextIndex = currentIndex + 1 < this.stateService.stateHistory.length ? currentIndex + 1 : null;
    // if (!nextIndex) {
    //   return;
    // }
    // this.stateService.changeHistoryState(nextIndex);
  }

  
  handleShiftAndKey(key: string) {
    if (this.keyActions[key]) {
      this.keyActions[key]();
    }
  }

  editEntity(): void {
    this.projectStateStoreService.selectedEntities$.pipe(
      take(1)
    ).subscribe(selectedEntitiesArray => {
      if(!selectedEntitiesArray || selectedEntitiesArray.length === 0){
        this.nzMessageService.warning('Please select an entity to edit');
      }else{
        this.leafletService.nextEnableEntitesEditMode(selectedEntitiesArray.map(entity => entity.id.toString()))
      }
    });
  }


  openDeleteModal() {
    this.projectStateStoreService.selectedEntities$.pipe(
      take(1)
    ).subscribe(selectedEntitiesArray => {
      const buildingEntities = selectedEntitiesArray.filter(entity => entity.properties.entityType === 'building');
      let marketableLotsToRecalculate = [];
  
      if (buildingEntities.length > 0) {
        this.projectStateStoreService.currentPlan$.pipe(
          take(1)
        ).subscribe(currentPlan => {
          const buildingEntitiesWithParents = buildingEntities.filter(entity => currentPlan.planConfig[entity.id]?.parentId);
  
          if (buildingEntitiesWithParents.length > 0) {
            const parentIds = buildingEntitiesWithParents.map(entity => currentPlan.planConfig[entity.id]?.parentId).filter(parentId => parentId);
  
            this.projectStoreService.getEntitiesByIds(parentIds).pipe(
              take(1)
            ).subscribe(parentEntities => {
              marketableLotsToRecalculate = parentEntities
                // .filter(parentEntity => parentEntity.properties.entityType === "marketable lot" && !selectedEntitiesArray.includes(parentEntity));
                .filter(parentEntity => parentEntity.properties.entityCategory === ENTITY_CATEGORIES.PARCEL && !selectedEntitiesArray.includes(parentEntity));
            });
          }
        });
      }
  
      const entitiesNames = selectedEntitiesArray.reduce((prev, cur) => {
        prev.push(cur.properties.name);
        return prev;
      }, []);
  
  
      const text = entitiesNames.length === 1 ? entitiesNames[0] : entitiesNames.length > 1 ? entitiesNames.length + ' entities' : '';
  
      this.confirmModal = this.modal.confirm({
        nzTitle: `Are you sure you wish to delete ${text} ?`,
        nzOkText: 'Yes',
        nzCancelText: 'No',
        nzOnCancel: () => {},
        nzOnOk: () => {
          this.storesManagerService.deleteEntitiesFromProjectAndPlanConfig(selectedEntitiesArray).pipe(
            take(1),
            switchMap(() => forkJoin([
              this.projectStateStoreService.getCurrentPlanEntities().pipe(take(1)),
              this.projectStateStoreService.currentPlan$.pipe(take(1))
            ])),
            switchMap(([currentPlanEntities, currentPlan]) => {
              const recalculations$ = marketableLotsToRecalculate
                .map(lotEntity => {
                  const recalculatedEntity = this.buildingDataService.resetAndCalculateLot(lotEntity, currentPlan, currentPlanEntities);
                  return this.storesManagerService.updateEntity(recalculatedEntity).pipe(take(1));
                });
  
              return recalculations$.length ? forkJoin(recalculations$) : of([]);
            })
          ).subscribe();
        }
      });
    });
  }
  

  sendCenter(Center: any) {
    this._flyToCenterSource.next(Center);
  }

}
