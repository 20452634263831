import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import type {  Feature, FeatureCollection, Geometry  } from 'geojson';
import L from 'leaflet';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BehaviorSubject, EMPTY, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SCOPELINE_INDEX_OLD, PLANBOUNDARY_LANDUSE, DEFAULT_POI_IMG_URL } from 'src/app/config';
import { ENTITY_GEOMETRIC_TYPES, PANEL_TABS, ProjectRoles } from 'src/app/enums/enums';
import { EntityProperties } from 'src/app/models/entity-properties';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CrudService } from 'src/app/services/crud/crud.service';
import { EntitiesService } from 'src/app/services/entities/entities.service';
import { ImportEntitiesService } from 'src/app/services/importEntities/import-entities.service';
import { MenuDataStoreService } from 'src/app/services/menuDataStore/menu-data-store.service';
import { ProjectStateStoreService } from 'src/app/services/projectStateStore/project-state-store.service';
import { StoresManagerService } from 'src/app/services/storesManagerService/stores-manager.service';
import { ImportEntitiesActionTypes } from 'src/app/shared/types/types';
import Utils from 'src/app/utils/utils';
import { LeafletPMLayer } from '../leaflet-models/leaflet.models';
import { ToolbarTemplateModalComponent } from 'src/app/shared/components/toolbar-template-modal/toolbar-template-modal.component';
import { NavigationStart, Router } from '@angular/router';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { NzModalService } from 'ng-zorro-antd/modal';
import { OffsetModalComponent } from '../../offset-modal/offset-modal.component';
import { OffsetFeatureCollction } from 'src/app/models/extend-feature-collection';

@Injectable({
  providedIn: 'root'
})
export class GeomanControlService implements OnDestroy {

  isComment: boolean;
  isSelectionMode: boolean = false;
  isHasLayerInCoordinate: boolean;
  isEditingLayer: boolean = false;
  hasLayerInCoordinateTimer = 300;
  controlMenu;
  controlSelector;
  commentPoi;
  extraToolsMenu;

  selectedEntities: Feature<Geometry, EntityProperties>[]; 
  isShiftKeyPressed: boolean;
  componentDestroyed$: Subject<boolean> = new Subject();


  snapEnabled: boolean = true;
  snapGuidesEnabled: boolean = false;

  private leafletMap: L.Map;

  geomanMeasurementOptions = {
    measurement: true,
    showTooltip: true,
    showTooltipOnHover: true,
    displayFormat: 'metric',
    totalLength: true,
    segmentLength: true,
    area: true,
    radius: true,
    perimeter: true,
    height: false,
    width: false,
    coordinates: false
  }

  iconMarkerDefault: L.DivIcon = L.divIcon({
    className: 'marker-icon',
  });
  
  popoverElement: HTMLElement | null = null;
  componentRef: any;
  currentLang: string;

  private currentLangSubject = new BehaviorSubject<string>(null);

  // Public observable for components to subscribe
  currentLang$: Observable<string> = this.currentLangSubject.asObservable();

  updateLang(lang: string) {
    this.currentLangSubject.next(lang);
  }


  constructor(
    private translate : TranslateService,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private _ngZone: NgZone,
    private authService: AuthService,
    private nzMessageService: NzMessageService,
    private entitiesService: EntitiesService,
    private storesManagerService: StoresManagerService,
    private projectStateStoreService: ProjectStateStoreService,
    private menuDataStoreService: MenuDataStoreService,
    private importEntitiesService: ImportEntitiesService,
    private crudService: CrudService,
    private nzModalService: NzModalService
  ) { 
    this.updateLang(this.translate.defaultLang);

    this.projectStateStoreService.shiftKeyPressed$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(pressed => {
      this.isShiftKeyPressed = pressed;
    })
    this.projectStateStoreService.selectedEntities$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(entities => {
      this.selectedEntities = entities;
      if(entities.length <= 0) {
        this.isEditingLayer = false;
      }
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.removePopover();
      }
    });

    window.addEventListener('beforeunload', this.removePopover.bind(this));
    window.addEventListener('popstate', this.removePopover.bind(this));
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
    this.removePopover();
    window.removeEventListener('beforeunload', this.removePopover.bind(this));
    window.removeEventListener('popstate', this.removePopover.bind(this));
  }

  private removePopover(): void {
    if (this.popoverElement) {
      this.popoverElement.remove();
      this.popoverElement = null;
    }
  }

  isIncludeInSelectedEntities(id : string) : boolean {
    return this.selectedEntities.some(entity => entity.id == id);
  }

  initControls(map: L.Map, allParams, projectName, controls): void {

    map.pm.setGlobalOptions({
      // markerStyle: { icon: this.iconMarkerDefault },
      // @ts-ignore
      measurements: this.geomanMeasurementOptions
    });

    map.on('pm:create', (e) => {
      if (e.shape === "Text") {
        return
      }
      this._ngZone.run(() => this.createEntity(map, e, allParams, projectName, controls));
      // @ts-ignore
      map.pm.Draw._setGlobalDrawMode();
    })
    map.on('pm:drawend', (e) => {
      setTimeout(() => this.isSelectionMode = false, this.hasLayerInCoordinateTimer + 1)
    });


    
    // listen to global event to enable snapping if it was changed while editing a polygon
    map.on('pm:globaloptionschanged', () => {
      const currentOptions = map.pm.getGlobalOptions();
    
      if (currentOptions.snappable !== this.snapEnabled) {
        this.snapEnabled = currentOptions.snappable;
        this.leafletMap.eachLayer((layer : LeafletPMLayer) => {
          //@ts-ignore 
          if (this.isEditingLayer && layer.id && layer.pm) {
            if(this.isIncludeInSelectedEntities(layer.id)){
              layer.pm.enable({ snapping: true });
            }
          }
        });
      }
    });


    map.on('pm:buttonclick', e => this.toggleBar(map, e, controls));
    map.on('click', () => {
      this.detectIfClickLayer(map);

      this.resetDrag();

      // map.eachLayer((layer) => {
      //   // @ts-ignore
      //   if (layer.pm && layer.pm.options && layer.pm.options.draggable) {
      //     // @ts-ignore
      //     layer.pm.disableLayerDrag();
      //   }
      // });
    }
  );
    map.attributionControl.setPrefix(false);

    ['Line', 'Polygon', 'Rectangle', 'Circle', 'Marker'].forEach(shape => map.pm.Toolbar.changeActionsOfControl(shape, []));
    if (this.authService.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR])) {
      this.controlMenu = map.pm.Toolbar.createCustomControl({
        name: 'drawMenu',
        block: 'draw',
        title: 'Start drawing',
        className: 'drawMenuIcon',
        toggle: true,
        actions: [
          {
            text: '<div class="control-icon leaflet-pm-icon-polyline" style="width:20px;" title="Draw Line"></div>',
            onClick: () => {
              map.pm.enableDraw('Line');
              controls.drawPolyline = true;
              controls.drawMenu = false;
              map.pm.addControls(controls);
            },
          },
          {
            text: '<div class="control-icon leaflet-pm-icon-polygon" style="width:20px;" title="Draw Polygon"></div>',
            onClick: () => {
              map.pm.enableDraw('Polygon');
              controls.drawPolygon = true;
              controls.drawMenu = false;
              map.pm.addControls(controls);
            }
          },
          {
            text: '<div class="control-icon leaflet-pm-icon-rectangle" style="width:20px;" title="Draw Rectangle"></div>',
            onClick: () => {
              map.pm.enableDraw('Rectangle');
              controls.drawRectangle = true;
              controls.drawMenu = false;
              map.pm.addControls(controls);
            }
          },
          {
            text: '<div class="control-icon leaflet-pm-icon-circle" style="width:20px;" title="Draw Circle"></div>',
            onClick: () => {
              map.pm.enableDraw('Circle');
              controls.drawCircle = true;
              controls.drawMenu = false;
              map.pm.addControls(controls);
            }
          },
          {
            text: '<div class="control-icon leaflet-pm-icon-marker" style="width:20px;" title="Draw POI"></div>',
            onClick: () => {
              const imgDataUrl = DEFAULT_POI_IMG_URL;
              const customIcon = L.icon({
                iconUrl: imgDataUrl,
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [0, -15]
              });
              map.pm.enableDraw('Marker', {
                markerStyle: { icon: customIcon },
                freehandOptions: undefined,
                lassoDrawOptions: undefined,
                requireContainment: [],
                preventIntersection: []
              });
              // controls.drawMarker = true;
              // map.pm.addControls(controls);
            }
          }
        ]
      });

      // Modify Menu Button
      const modifyMenu = map.pm.Toolbar.createCustomControl({
        name: 'modifyMenu',
        block: 'draw',
        title: 'Modify Menu',
        className: 'modifyMenuIcon',
        toggle: true,
        actions: [
          {
            text: '<div class="control-icon rotateIcon" style="width:20px;" title="Rotate Entity"></div>',
            onClick: () => {
              this.handleRotateEntity();
            }
          },
          {
            text: '<div class="control-icon dragIcon" style="width:20px;" title="Drag Entity"></div>',
            onClick: () => {
              this.handleMoveEntity();
            }
          },
          {
            text: '<div class="control-icon duplicateIcon" style="width:20px;" title="Duplicate Entity"></div>',
            onClick: () => {
              this.handleEntityDuplication();
            }
          },
          {
            text: '<div class="control-icon scaleIcon" style="width:20px;" title="Scale Entity"></div>',
            onClick: () => {
              // @ts-ignore
              // map.pm.enableGlobalScaleMode()
              this.handleEntityScale();
            }
          }
        ]
      });
      
      // Annotate Menu Button
      const annotateMenu = map.pm.Toolbar.createCustomControl({
        name: 'annotateMenu',
        block: 'draw',
        title: 'Annotate Menu',
        className: 'annotateMenuIcon',
        toggle: true,
        actions: [
          {
            text: '<div class="control-icon commentIcon" style="width:20px;" title="Comment"></div>',
            onClick: () => {
              const userName = this.authService.getUsername();
              const userInitial = Utils.getInitials(userName);
              const backgrounColor = this.stringToColor(userName);
              const customIconSvg = this.generateSVG(backgrounColor, userInitial);
              const svgDataUrl = "data:image/svg+xml," + encodeURIComponent(customIconSvg);
              const customIcon = L.icon({
                iconUrl: svgDataUrl,
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                popupAnchor: [0, -15]
              });
              map.pm.enableDraw('Marker', {
                markerStyle: { icon: customIcon },
                freehandOptions: undefined,
                lassoDrawOptions: undefined,
                requireContainment: [],
                preventIntersection: []
              });
              this.isComment = true;
            }
          },
          {
            text: '<div class="control-icon textIcon" style="width:20px;" title="Text Label"></div>',
            onClick: () => {
              map.pm.enableDraw('Text', {
                textOptions: { focusAfterDraw: true, text: 'Enter text here!' },
                freehandOptions: undefined,
                lassoDrawOptions: undefined,
                requireContainment: [],
                preventIntersection: []
              });
              // controls.drawText = true;
              // map.pm.addControls(controls);
            }
          },
          // {
          //   text: '<div class="control-icon leaflet-pm-icon-marker" style="width:20px;" title="Draw POI"></div>',
          //   onClick: () => {
          //     map.pm.enableDraw('Marker');
          //     // controls.drawMarker = true;
          //     // map.pm.addControls(controls);
          //   }
          // }
        ]
      });

      // Extra Tools Menu Button
      this.extraToolsMenu = map.pm.Toolbar.createCustomControl({
        name: 'extraToolsMenu',
        block: 'draw',
        title: 'Extra Tools Menu',
        className: 'extraToolsMenuIcon',
        toggle: true,
        actions: [
          {
            text: '<div class="control-icon mergeIcon" style="width:20px;" title="Merge"></div>',
            onClick: () => {
              this.handleMergePolygon()
             },
            // disabled: true
          },
          {
            text: '<div class="control-icon skeletonIcon" style="width:20px;" title="Skeleton"></div>',
            onClick: () => { 
              this.handleSkeletonize()
            },
            // disabled: true
          },
          {
            text: '<div class="control-icon junctionIcon" style="width:20px;" title="Juction"></div>',
            onClick: () => { 
              this.handleNetworkIntersection();
            },
            // disabled: true
          },
          {
            text: '<div class="control-icon offsetIcon" style="width:20px; opacity: 0.7" title="Offset"></div>',
            onClick: () => { 
              this.handleOffset();
            },
            // disabled: true
          }
        ]
      });

      const buildingTemplateMenu = map.pm.Toolbar.createCustomControl({
        name: 'Toolber Info',
        block: 'custom',
        title: 'Toolber Info',
        className: 'blubTamplateIcon',
        toggle: true,
        onClick: this.togglePopover.bind(this)
      });


      map.pm.addControls({
        ...controls,
        drawMenu: true,
        modifyMenu: true,
        extraToolsMenu: true
      });

    }

    // if (this.authService.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR]) || Object.values(currentEntities).length > 0) {
    //   this.controlSelector = map.pm.Toolbar.createCustomControl({
    //     name: 'selector',
    //     block: 'draw',
    //     title: 'Select entities',
    //     toggle: true,
    //     className: 'selectMenuIcon',
    //     onClick: () => { // causes Maximum call stack size exceeded error
    //       this.startSelectionMode(map);
    //     },
    //     // afterClick: () => this.startSelectionMode(),
    //     //   // TODO: For some odd reason when we enableGlobalDragMode, this event fires TWICE.
    //     //   // Which closes them menu, preventing us from showing the "cancel" button.
    //     //   // map.pm.enableGlobalDragMode();
    //     // },
    //     actions: [],
    //   });
    //   map.pm.addControls(controls);
    // }

     // Second block with snapping options
    //  const snapControl = map.pm.Toolbar.createCustomControl({
    //   name: 'snapOption',
    //   block: 'options',
    //   title: 'Snap Option',
    //   toggle: true,
    //   className: 'snapOptionIcon',
    //   onClick: () => {
    //     this.snapEnabled = !this.snapEnabled;
    //     map.pm.setGlobalOptions({ snappable: this.snapEnabled });
    //   },
    //   actions: []
    // });

    // const snapGuidesControl = map.pm.Toolbar.createCustomControl({
    //   name: 'snapGuidesCustomOption',
    //   block: 'options',
    //   title: 'Snap Guides Option',
    //   toggle: false,
    //   className: 'snapGuidesOptionIcon',
    //   onClick: () => {
    //     this.snapGuidesEnabled = !this.snapGuidesEnabled;
    //     // @ts-ignore
    //     map.pm.setGlobalOptions({ showSnapGuides: this.snapGuidesEnabled});
    //     console.log(map.pm.getGlobalOptions())
    //   },
    //   actions: []
    // });

    // map.pm.addControls({
    //   snapOption: true,
    //   snapGuidesCustomOption: true
    // });

    map.on('pm:globalrotatemodetoggled', (e) => {

      map.eachLayer((layer) => {
        // @ts-ignore
        if (layer.id) {
            // @ts-ignore
            if ((!this.selectedEntitiesMap[layer.id] || this.currentPlan.isLock) ||(this.entitiesService.entityIsLock(layer.id) || !this.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR]))) {
              // @ts-ignore
              layer.pm.disableRotate()
          }
        }
      });
    });

    this.leafletMap = map;
  }

  get popoverContent(): string {
    return 'This feature is coming soon!';
  }

  togglePopover(): void {
    if (this.popoverElement) {
      this.appRef.detachView(this.componentRef.hostView); // Detach the view
      this.popoverElement.remove(); // Remove the popover from the DOM
      this.popoverElement = null; // Reset the reference
      this.componentRef = null; // Reset the component reference
    } else {
      // If popover doesn't exist, create it
      const buttonElement = document.querySelector('.blubTamplateIcon') as HTMLElement;
      const rect = buttonElement.getBoundingClientRect(); // Get button's position
  
      // Create a popover container
      this.popoverElement = document.createElement('div');
      this.popoverElement.classList.add('custom-popover');
      this.popoverElement.style.position = 'absolute';
      this.popoverElement.style.top = `${rect.bottom + window.scrollY - 26}px`; // Positioning below button
      this.popoverElement.style.left = `${rect.left + window.scrollX + 30}px`; // Aligning with button's left
      this.popoverElement.style.padding = '0';
      this.popoverElement.style.border = '1px solid #40A9FF';
      this.popoverElement.style.borderRadius = '8px';
      this.popoverElement.style.backgroundColor = '#fff';
      this.popoverElement.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
      this.popoverElement.style.zIndex = '1000'; // Ensure it's above other elements
      document.body.appendChild(this.popoverElement); // Append the popover container to the body
  
      // Dynamically create the ToolbarTemplateModalComponent inside the popover
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ToolbarTemplateModalComponent);
      this.componentRef = componentFactory.create(this.injector);
  
      // Attach the component's view to Angular's lifecycle
      this.appRef.attachView(this.componentRef.hostView);

      this.componentRef.instance.currentLanguage = this.currentLang;
  
      // Append the dynamically created component to the popover container
      this.popoverElement.appendChild(this.componentRef.location.nativeElement);
    }
  }





  handleMergePolygon() {
    forkJoin({
      menuData: this.menuDataStoreService.menuData$.pipe(take(1)),
      selectedEntities: this.projectStateStoreService.selectedEntities$.pipe(take(1)),
      currentPlan: this.projectStateStoreService.currentPlan$.pipe(take(1))
    }).pipe(
      switchMap(({ selectedEntities, menuData, currentPlan }) => {
        if (selectedEntities.length === 0) {
          this.nzMessageService.warning('No entities were selected');
          return EMPTY;
        }
        const invalidEntities = selectedEntities.filter(entity => 
          entity.geometry.type !== 'Polygon' && entity.geometry.type !== 'MultiPolygon'
        );
        if (invalidEntities.length > 0) {
          this.nzMessageService.warning('Entities must be polygon or multipolygon');
          return EMPTY;
        }
        const featureCollection: FeatureCollection = {
          type: 'FeatureCollection',
          features: selectedEntities
        };
        // logging service in the future
        if(!environment.production){
          console.log('Merge FeatureCollection:', featureCollection); 
        }
        return this.crudService.mergeGIS(featureCollection).pipe(
          map((response: any) => ({ response, menuData, currentPlan })),
          catchError(error => {
            this.nzMessageService.error('An error occurred during mergeGIS operation');
            return EMPTY;
          })
        );
      })
    ).subscribe(({ response, menuData, currentPlan }) => {
      if (response.return.error) {
        this.nzMessageService.error(response.return.error);
        return
      }
      const fcString = response.return;
      const featureCollection: FeatureCollection = JSON.parse(fcString);
      const features = featureCollection.features;
      const options = {
        description: '',
        toSave: true,
        isOverrideScopeline: false,
        action: 'default' as ImportEntitiesActionTypes
      };
      const projectName = currentPlan.projectName;
      const currentPlanName = currentPlan.planName;
      this.importEntitiesService.importEntities(features, options, menuData, projectName, currentPlanName);
    });
  }

  handleSkeletonize() {
    forkJoin({
      menuData: this.menuDataStoreService.menuData$.pipe(take(1)),
      selectedEntities: this.projectStateStoreService.selectedEntities$.pipe(take(1)),
      currentPlan: this.projectStateStoreService.currentPlan$.pipe(take(1))
    }).pipe(
      switchMap(({ selectedEntities, menuData, currentPlan }) => {
        if (selectedEntities.length === 0) {
          this.nzMessageService.warning('No entities were selected');
          return EMPTY;
        }
        const invalidEntities = selectedEntities.filter(entity => 
          entity.geometry.type !== 'Polygon' && entity.geometry.type !== 'MultiPolygon'
        );
        if (invalidEntities.length > 0) {
          this.nzMessageService.warning('Entities must be polygon or multipolygon');
          return EMPTY;
        }
        const featureCollection: FeatureCollection = {
          type: 'FeatureCollection',
          features: selectedEntities
        };
        // logging service in the future
        if(!environment.production){  
          console.log('Skeletonize FeatureCollection:', featureCollection); 
        }
        return this.crudService.skeletonize(featureCollection).pipe(
          map((response: any) => ({ response, menuData, currentPlan })),
          catchError(error => {
            this.nzMessageService.error('An error occurred during skeletonize operation');
            return EMPTY;
          })
        );
      })
    ).subscribe(({ response, menuData, currentPlan }) => {

      if (response.return.error) {
        this.nzMessageService.error(response.return.error);
        return;
      }
      // console.log(JSON.parse(response.return))

      let allFeatures = [];
      try {
        response.return.forEach((fcString: string) => {
          const featureCollection: FeatureCollection = JSON.parse(fcString);
          allFeatures = allFeatures.concat(featureCollection.features);
        });
      } catch (error) {
        console.error('JSON Parsing Error:', error.message);
        this.nzMessageService.error('Invalid JSON response received.');
        return;
      }
      // const fcString = response.return;
      // const featureCollection: FeatureCollection = JSON.parse(fcString);
      // const features = featureCollection.features;
      const features = allFeatures;
      const options = {
        description: '',
        toSave: true,
        isOverrideScopeline: false,
        action: 'road' as ImportEntitiesActionTypes
      };
      const projectName = currentPlan.projectName;
      const currentPlanName = currentPlan.planName;
      this.importEntitiesService.importEntities(features, options, menuData, projectName, currentPlanName);
    });
  }

  handleNetworkIntersection() {
    forkJoin({
      menuData: this.menuDataStoreService.menuData$.pipe(take(1)),
      selectedEntities: this.projectStateStoreService.selectedEntities$.pipe(take(1)),
      currentPlan: this.projectStateStoreService.currentPlan$.pipe(take(1))
    }).pipe(
      switchMap(({ selectedEntities, menuData, currentPlan }) => {
        if (selectedEntities.length === 0) {
          this.nzMessageService.warning('No entities were selected');
          return EMPTY;
        }
        const invalidEntities = selectedEntities.filter(entity => 
          entity.geometry.type !== 'LineString' && entity.geometry.type !== 'MultiLineString'
        );
        if (invalidEntities.length > 0) {
          this.nzMessageService.warning('Entities must be linestring or multilinestring');
          return EMPTY;
        }
        const featureCollection: FeatureCollection = {
          type: 'FeatureCollection',
          features: selectedEntities
        };
        // logging service in the future
        if(!environment.production){
          console.log('Network Intersection FeatureCollection:', featureCollection);
        }
        return this.crudService.networksIntersection(featureCollection).pipe(
          map((response: any) => ({ response, menuData, currentPlan })),
          catchError(error => {
            this.nzMessageService.error('An error occurred during network intersection operation');
            return EMPTY;
          })
        );
      })
    ).subscribe(({ response, menuData, currentPlan }) => {

      if (response.return.error) {
        this.nzMessageService.error(response.return.error);
        return;
      }
      // console.log(JSON.parse(response.return))

      // let allFeatures = [];
      // try {
      //   response.return.forEach((fcString: string) => {
      //     const featureCollection: FeatureCollection = JSON.parse(fcString);
      //     allFeatures = allFeatures.concat(featureCollection.features);
      //   });
      // } catch (error) {
      //   console.error('JSON Parsing Error:', error.message);
      //   this.nzMessageService.error('Invalid JSON response received.');
      //   return;
      // }
      const fcString = response.return;
      const featureCollection: FeatureCollection = JSON.parse(fcString);
      const features = featureCollection.features;
      // const features = allFeatures;
      const options = {
        description: '',
        toSave: true,
        isOverrideScopeline: false,
        action: 'junction' as ImportEntitiesActionTypes
      };
      const projectName = currentPlan.projectName;
      const currentPlanName = currentPlan.planName;
      this.importEntitiesService.importEntities(features, options, menuData, projectName, currentPlanName);
    });
  }

  handleOffset(): void {
    forkJoin({
      menuData: this.menuDataStoreService.menuData$.pipe(take(1)),
      selectedEntities: this.projectStateStoreService.selectedEntities$.pipe(take(1)),
      currentPlan: this.projectStateStoreService.currentPlan$.pipe(take(1))
    })
    .pipe(
      switchMap(({ selectedEntities, menuData, currentPlan }) => {
        // 1) Verify that entities are selected
        if (!selectedEntities || selectedEntities.length === 0) {
          this.nzMessageService.error('No entities were selected.');
          return EMPTY;
        }

        // 2) Verify geometry type
        const firstGeometryType = selectedEntities[0].geometry.type;
        const allSameType = selectedEntities.every(
          (entity) => entity.geometry.type === firstGeometryType
        );

        if (!allSameType) {
          this.nzMessageService.error('All selected features must share the same geometry type.');
          return EMPTY;
        }

        const isPolygon = (firstGeometryType === 'Polygon' || firstGeometryType === 'MultiPolygon');
        const isLine = (firstGeometryType === 'LineString' || firstGeometryType === 'MultiLineString');

        if (!isPolygon && !isLine) {
          this.nzMessageService.error(`Unsupported geometry type: ${firstGeometryType}`);
          return EMPTY;
        }

        // Convert MultiLineString -> 'LineString' label or
        // MultiPolygon -> 'Polygon' label, for simpler usage in the modal
        let modalGeometry: 'Polygon' | 'LineString' | 'Polyline' = 'LineString';
        if (isPolygon) modalGeometry = 'Polygon';

        // 3) Open the offset modal
        return this.openOffsetModalNz(modalGeometry).pipe(
          // The modal returns { distance, direction } or null if canceled
          switchMap((result) => {
            if (!result) {
              // User canceled; do nothing
              return EMPTY;
            }
            const { distance, direction } = result;

            // 4) Construct FeatureCollection with shift_params
            const featureCollection: OffsetFeatureCollction = {
              type: 'FeatureCollection',
              features: selectedEntities,
              shift_params: {} as any, // allow custom property
            } as any; // allow custom property

            if (isPolygon) {
              // direction: 'inner' => negative, 'outer' => positive
              const shiftValue = direction === 'inner' ? -Math.abs(distance) : Math.abs(distance);
              featureCollection.shift_params = { shift_m: shiftValue };
            } else {
              // direction for lines => 'left' => 0, 'right' => 1, 'both' => 2
              let sideCode = 0;
              if (direction === 'right') { sideCode = 1; }
              else if (direction === 'both') { sideCode = 2; }

              featureCollection.shift_params = {
                move: distance,
                side: sideCode
              };
            }

            // 5) Call the appropriate service method
            if (isLine) {
              return this.crudService.polyLineShift(featureCollection).pipe(
                map((response: any) => ({
                  response,
                  menuData,
                  currentPlan
                })),
                catchError(error => {
                  this.nzMessageService.error('An error occurred while offsetting polyline.');
                  return EMPTY;
                })
              );
            } else {
              return this.crudService.polygonShift(featureCollection).pipe(
                map((response: any) => ({
                  response,
                  menuData,
                  currentPlan
                })),
                catchError(error => {
                  this.nzMessageService.error('An error occurred while offsetting polygon.');
                  return EMPTY;
                })
              );
            }
          })
        );
      })
    )
    .subscribe(({ response, menuData, currentPlan }) => {
      if (!response || !response.return) {
        this.nzMessageService.error('Offset operation failed: Invalid response.');
        return;
      }

      console.log('Offset Response:', response);

      // The server typically returns a FeatureCollection or JSON string
      let features: Feature[] = [];
      try {
        const fc = (typeof response.return === 'string')
          ? JSON.parse(response.return)
          : response.return;
        features = fc.features || [];
      } catch (err) {
        this.nzMessageService.error('Offset operation failed: Invalid JSON in response.');
        return;
      }

      if (features[0]?.geometry === null) {
        this.nzMessageService.error('Offset operation failed: No geometry in response.'); 
        return;
      }

      const options = {
        description: '',
        toSave: true,
        isOverrideScopeline: false,
        action: 'default' as ImportEntitiesActionTypes
      };
      // 6) Use your import logic to bring in the offset geometry
      // Pass in 'default' or any relevant action you need
      this.importEntitiesService.importEntities(
        features,
        options,
        menuData,
        currentPlan.projectName,
        currentPlan.planName
      );

      this.extraToolsMenu.toggle(false);

      this.nzMessageService.success('Offset applied successfully.');
    });
  }

  private openOffsetModalNz(
    geometryType: 'Polygon' | 'LineString' | 'Polyline'
  ) {
    // The modal configuration
    const modal = this.nzModalService.create({
      nzTitle: 'Offset',
      nzContent: OffsetModalComponent,
      nzComponentParams: {
        geometryType  // pass in the geometryType so the component can set direction options
      },
      nzWidth: '340px',
      nzFooter: null, // We use a custom footer in the template
      nzClosable: false, // Force user to use the footer buttons
      // nzDirection: 'rtl'
    });

    // afterClose is an Observable that emits the value passed to modalRef.close(...)
    return modal.afterClose;
  }

  handleEntityDuplication() {
    forkJoin({
      menuData: this.menuDataStoreService.menuData$.pipe(take(1)),
      selectedEntities: this.projectStateStoreService.selectedEntities$.pipe(take(1)),
      currentEntities: this.projectStateStoreService.getCurrentPlanEntities().pipe(take(1)),
      currentPlan: this.projectStateStoreService.currentPlan$.pipe(take(1))
    }).subscribe(({ selectedEntities, menuData, currentEntities, currentPlan }) => {
      if (selectedEntities.length === 0) {
        this.nzMessageService.warning('Please select an entity to duplicate');
      } else {
        const selectedEntity = selectedEntities[0];
        this.entitiesService.duplicateEntity(selectedEntity, currentEntities, currentPlan, menuData);
      }
    });
  }
  

  handleEntityScale() {
    this.projectStateStoreService.selectedEntities$.pipe(take(1)).subscribe(selectedEntities => {
      if (selectedEntities.length === 0) {
        this.nzMessageService.warning('Please select an entity to scale');
      } else {
        const selectedEntity = selectedEntities[0];
        this.leafletMap.eachLayer(layer => {
          // @ts-ignore
          if (layer.id === selectedEntity.id) {
            // @ts-ignore
            layer.pm.enableScale();
          }
        });
      }
    });
  }

  handleMoveEntity(){
    this.projectStateStoreService.selectedEntities$.pipe(take(1)).subscribe(selectedEntities => {
      if (selectedEntities.length === 0) {
        this.nzMessageService.warning('Please select an entity to move');
      } else {
        const selectedEntity = selectedEntities[0];
        this.leafletMap.eachLayer(layer => {
          // @ts-ignore
          if (layer.id === selectedEntity.id) {
            // @ts-ignore
            layer.pm.enableLayerDrag();
          }
        });
      }
    });

  }

  handleRotateEntity(){
    // map.pm.enableGlobalRotateMode();
    this.projectStateStoreService.selectedEntities$.pipe(take(1)).subscribe(selectedEntities => {
      if (selectedEntities.length === 0) {
        this.nzMessageService.warning('Please select an entity to rotate');
      } else {
        const selectedEntity = selectedEntities[0];
        this.leafletMap.eachLayer(layer => {
          // @ts-ignore
          if (layer.id === selectedEntity.id) {
            // @ts-ignore
            layer.pm.enableRotate();
          }
        });
      }
    });
  }

  createEntity(map, layer, allParams, projectName, controls) {
    let icon = null;
    if(layer.marker._icon){
      icon = layer.marker._icon
    }
    layer.layer.remove();
    const shape = { type: layer.shape };   
    if (layer.layer.getRadius) {
      Object.assign(shape, { radius: layer.layer.getRadius() });
      shape.type = ENTITY_GEOMETRIC_TYPES.CIRCLE;
    }

    const options: {shape, params?: {}, isImport?: boolean, commentIcon?: string} = { shape };

    if (this.isComment) {
      Object.assign(options, {
        params: {
          entityType: 'comment',
          createdBy: this.authService.getUsername()
        },
        commentIcon: icon ? icon.src : null
      });
      this.isComment = false; // Reset the flag
    }

    if (this.isSelectionMode) {
      this.entitiesService.featuresWithin(layer.layer.toGeoJSON());
    } else {
      const entity = this.entitiesService.createEntity(layer.layer.toGeoJSON(), options, allParams, projectName);

      // check visibility state
      // const type = this.entitiesService.getEntityType(entity)
      this.entitiesService.checkEntityTypeVisibility(entity)
      
      if (entity.properties.type === ENTITY_GEOMETRIC_TYPES.POINT) {
        map.pm.disableGlobalEditMode();
      }
      
      this.entitiesService.generateDefaultEntityNameObservable(entity, {}).pipe(
        take(1),
        ).subscribe(name => {
        entity.properties.name = name;
  
        this.onClickEntity(map, entity, true, false); 
        this.storesManagerService.addNewEntityToProjectAndPlanConfig3(entity);
        this.resetDrawMenu(map, controls);
        this.storesManagerService.setPanelTab(PANEL_TABS.Edit_Layer, false);
      });
    }
  }

  resetDrag(){
    this.leafletMap.eachLayer((layer : LeafletPMLayer) => {
      if (layer.pm && layer.pm.options && layer.pm.options.draggable && layer.pm.layerDragEnabled()
      ) {
        layer.pm.disableLayerDrag();
      }
    });
  }

  onClickEntity( map: L.Map, entity: Feature<Geometry, EntityProperties>, isNew?: boolean, recordInHistory: boolean = true) {
    if (this.isEditingLayer) {
      return;
    }
    if (map.pm.globalDrawModeEnabled()) {
      return;
    }
    if (entity.id === SCOPELINE_INDEX_OLD) {
      return;
    }

    const toRecordInHistory = this.storesManagerService.managerActionFlag ? false : recordInHistory;  // a flag not to record history while updating entity

    const isEntitySelected = this.isIncludeInSelectedEntities(entity.id.toString());

    if (isEntitySelected) {
      // Unselect the entity
      this.projectStateStoreService.removeEntitiesFromSelectedEntities([entity]);
    } else {
      // Select the entity
      this.storesManagerService.setSelectedEntities(
        [entity],
        this.isShiftKeyPressed,
        toRecordInHistory
      );

      this.storesManagerService.setPanelTab(PANEL_TABS.Edit_Layer, false);
    }

    this.storesManagerService.managerActionFlag = false
    
    if (!isNew) {
      setTimeout(() => this.isHasLayerInCoordinate = false, this.hasLayerInCoordinateTimer + 100);
    }
  }


  toggleBar(map, e, controls) {
    if (!map.pm.globalDrawModeEnabled()) {
      return;
    }
    if (['drawPolyline', 'drawPolygon', 'drawRectangle', 'drawCircle', 'drawMenu', 'comment', 'drawText'].includes(e.btnName)) {
      setTimeout(() => this.resetDrawMenu(map, controls));
    }
  }

  resetDrawMenu(map: L.Map, controls) {
    controls.drawPolyline = false;
    controls.drawPolygon = false;
    controls.drawRectangle = false;
    controls.drawCircle = false;
    controls.drawMarker = false;
    controls.drawText = false;
    controls.drawMenu = true;
    map.pm.disableDraw();
    map.pm.addControls(controls);
    if (this.authService.isAuthorised([ProjectRoles.ADMIN, ProjectRoles.PRJ_EDITOR])) {
      this.controlMenu.toggle(false);
    }

    // this.controlSelector.toggle(false);
  }

  detectIfClickLayer(map: L.Map) {
    setTimeout(() => {
      if (!this.isHasLayerInCoordinate) {
        if (!map.pm.globalDrawModeEnabled() && !this.isSelectionMode) {
          this.storesManagerService.setSelectedEntities([], false, false)
        }
      }
    }, this.hasLayerInCoordinateTimer);
  }

  startSelectionMode(map: L.Map) {
    if (this.isSelectionMode) {
      return;
    }
    this.isSelectionMode = true;
    map.pm.enableDraw('Rectangle', {
      snappable: true,
      freehandOptions: undefined,
      lassoDrawOptions: undefined,
      requireContainment: [],
      preventIntersection: []
    });
  }


  stringToColor(name: string = 'Urban Dashboard'): string {
    // Hash function to convert the name into a number
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
        hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }

    // Base angle for the triadic color scheme
    const baseAngle = 120; // 120 degrees separation for triadic colors

    // Use the hash to determine the starting angle on the color wheel
    let hue = hash % 360; // Hue value between 0 and 359

    // Convert the hue to RGB using a simple algorithm
    const rgb = this.hueToRGB(hue);

    return this.rgbToHex(...rgb);
  }

  hueToRGB(hue: number): [number, number, number] {
    const chroma = 0.7; // Chroma fixed for vibrant colors
    const hPrime = hue / 60;
    const x = chroma * (1 - Math.abs((hPrime % 2) - 1));

    let r = 0, g = 0, b = 0;
    if(hPrime >= 0 && hPrime < 1) { r = chroma; g = x; }
    else if(hPrime >= 1 && hPrime < 2) { r = x; g = chroma; }
    else if(hPrime >= 2 && hPrime < 3) { g = chroma; b = x; }
    else if(hPrime >= 3 && hPrime < 4) { g = x; b = chroma; }
    else if(hPrime >= 4 && hPrime < 5) { r = x; b = chroma; }
    else if(hPrime >= 5 && hPrime <= 6) { r = chroma; b = x; }

    // Adjust lightness
    const lightnessAdjustment = 0.5 - (chroma / 2);
    r += lightnessAdjustment;
    g += lightnessAdjustment;
    b += lightnessAdjustment;

    return [r * 255, g * 255, b * 255];
  }

  rgbToHex(r: number, g: number, b: number): string {
    return "#" + [r, g, b].map(x => {
      const hex = Math.round(x).toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    }).join('');
  }

  generateSVG(color, text) {
    let svgTemplate = `<svg width="30" height="30" viewBox="0 0 42 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3192_697)">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M21 35.8888C30.3888 35.8888 38 28.2776 38 18.8888C38 9.49995 30.3888 1.88879 21 1.88879C11.6112 1.88879 4 9.49995 4 18.8888C4 18.8888 4 18.8888 4 18.8889V35.8889H21V35.8888Z" fill="white"/>
    </g>
    <circle cx="20.9998" cy="18.8889" r="15.1111" fill="{{color}}"/>
    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white">{{text}}</text>
    <defs>
    <filter id="filter0_d_3192_697" x="0.0769231" y="1.88879" width="41.8462" height="41.8463" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3.92308"/>
    <feGaussianBlur stdDeviation="1.96154"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3192_697"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3192_697" result="shape"/>
    </filter>
    </defs>
    </svg>`; // Your SVG string with placeholders
    svgTemplate = svgTemplate.replace("{{color}}", color);
    svgTemplate = svgTemplate.replace("{{text}}", text);
    return svgTemplate;
  }

}
