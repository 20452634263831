import {Injectable} from '@angular/core';
import type { Feature, Geometry } from 'geojson';
import {EntityProperties} from '../../models/entity-properties';
import {NzMessageService} from 'ng-zorro-antd/message';
import Utils from '../../utils/utils';
import {EntitiesService} from '../entities/entities.service';
import { ProjectStateStoreService } from '../projectStateStore/project-state-store.service';
import { BuildingDataService } from './building-data.service';
import { catchError, concatMap, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { PlanInterface } from 'src/app/models/plan-interface';
import { AddUpdateDeleteEntitiesParams, EntityMap } from 'src/app/models/entity-map';
import { from, of } from 'rxjs';
import { StoresManagerService } from '../storesManagerService/stores-manager.service';
import { MenuDataStoreService } from '../menuDataStore/menu-data-store.service';
import { NoLot } from 'src/app/models/parent-child-relationship';

@Injectable({
  providedIn: 'root'
})
export class BuildingPipelineService {

  constructor(
    private nzMessageService: NzMessageService,
    private projectStateStoreService: ProjectStateStoreService,
    private storesManagerService: StoresManagerService,
    private menuDataStoreService: MenuDataStoreService,
    private entitiesService: EntitiesService,
    private buildingDataService: BuildingDataService) {
  }


  calcBuilding2(landUseParams, feature: Feature<Geometry, EntityProperties>, movedToLotId?: string | NoLot) {
    this.projectStateStoreService.currentPlan$.pipe(
      take(1),
      switchMap((plan: PlanInterface) => {
        let lotId;
        if (movedToLotId === 'NO_LOT') {
          lotId = undefined;
        } else {
          lotId = movedToLotId ? movedToLotId : plan.planConfig[feature.id].parentId;
        }
        // const lotId = plan.planConfig[feature.id].parentId;
        return this.projectStateStoreService.getCurrentPlanEntities().pipe(
          take(1),
          switchMap((entities: EntityMap) => {

            const buildingEntity = Utils.clone(feature)
            buildingEntity.properties.landUseParams = landUseParams;


            let updatedPreviousLot: Feature<Geometry, EntityProperties> | null = null;
            const originalParentLotId = plan.planConfig[feature.id].parentId;

            if (lotId !== originalParentLotId && originalParentLotId) {
              const previousLotEntity = entities[originalParentLotId];
              updatedPreviousLot = this.buildingDataService.resetAndCalculateLot(previousLotEntity, plan, entities, feature.id);
            }

            const calcResult = this.buildingDataService.calculateBuildingAndLotNew(entities, plan.planConfig, buildingEntity, lotId ? entities[lotId]: undefined);
            const previousStoreysToDeleteIds = this.findChildrenById(plan.planConfig, feature.id);

            return this.menuDataStoreService.menuData$.pipe(
              take(1),
              mergeMap((menuData) => {
                const storeys = this.entitiesService.uniqueIdValidator(calcResult.result.floors.map(feature => this.entitiesService.createEntity(Utils.clone(feature), { shape: feature.geometry.type }, menuData, buildingEntity.properties.projectName)));
                storeys.forEach((entity) => {
                  entity.properties.name = this.entitiesService.generateDefaultEntityName(entity, {});
                });

                const entitiesToUpdate: { [id: string]: { entity: Feature<Geometry, EntityProperties>, parentId?: string } } = {};

                if (calcResult.result.lot) {
                  entitiesToUpdate[calcResult.result.lot.id] = {
                    entity: calcResult.result.lot
                  };
                }

                entitiesToUpdate[calcResult.result.building.id] = {
                  entity: calcResult.result.building,
                  parentId: lotId
                };

                if (updatedPreviousLot) {
                  entitiesToUpdate[updatedPreviousLot.id] = {
                    entity: updatedPreviousLot
                  };
                }

                const entitiesUpdateObject: AddUpdateDeleteEntitiesParams = {
                  entitiesToDelete: previousStoreysToDeleteIds,
                  entitiesToAdd: storeys.reduce((acc, entity) => { 
                    acc[entity.id] = { entity: entity, parentId: feature.id }; 
                    return acc; 
                  }, {} as { [id: string]: { entity: Feature<Geometry, EntityProperties>; parentId?: string; } }),
                  entitiesToUpdate: entitiesToUpdate
                };

                // if (updatedPreviousLot) {
                //   entitiesUpdateObject.entitiesToUpdate[updatedPreviousLot.id] = {
                //     entity: updatedPreviousLot,
                //   };
                // }

                return this.storesManagerService.addUpdateDeleteEntities(entitiesUpdateObject, plan.planName).pipe(
                  take(1),
                  catchError((error) => {
                    this.nzMessageService.error('Calculation error, parameters were not updated.');
                    return of(error);
                  })
                );
              })
            );
          })
        );
      })
    ).subscribe();
  }
  

  findChildrenById(planConfig, parentId: string | number): string[] {
    return Object.keys(planConfig).filter(id => planConfig[id].parentId === parentId);
  }



}
