import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-offset-modal',
  templateUrl: './offset-modal.component.html',
  styleUrls: ['./offset-modal.component.scss']
})
export class OffsetModalComponent implements OnInit {

  geometryType: 'Polygon' | 'LineString' | 'Polyline' = 'LineString';

  distance: number = 5;       // Default offset distance
  direction: string = 'left'; // Default direction

  directions: string[] = [];  // Will be populated based on geometryType

  directionsDict = {
    'inner': 'OFFSET.INNER',
    'outer': 'OFFSET.OUTER',
    'left': 'OFFSET.LEFT',
    'right': 'OFFSET.RIGHT',
    'both': 'OFFSET.BOTH'
  };

  constructor(private modalRef: NzModalRef) { }

  ngOnInit(): void {
     // Populate directions depending on geometry type
    // For polygons: inner or outer
    // For lines: left, right, or both
    if (this.geometryType === 'Polygon') {
      this.directions = ['inner', 'outer'];
      this.direction = 'outer';
    } else {
      // Treat 'LineString' and 'Polyline' the same
      this.directions = ['left', 'right', 'both'];
      this.direction = 'left';
    }
  }

  handleOk() {
    // You can add additional validation here if needed
    if (this.distance === null || this.distance === undefined) {
      // e.g. show an inline error or do nothing
      return;
    }
    this.modalRef.close({
      distance: this.distance,
      direction: this.direction
    });
  }

  handleCancel() {
    this.modalRef.close(null);  // Indicate user canceled
  }

  inputButtonClick(actionType: 'up' | 'down') {
    const value = this.distance;
    const nextValue = actionType === 'up' ? value + 1 : value - 1;
    this.distance = nextValue;
      
  }

}
