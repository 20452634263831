<div class="offset-modal-wrapper">
    <div class="offset-modal-content">
        <div class="offset-modal-input">
            <label class="offset-label">{{'OFFSET.DISTANCE' | translate}}</label>
            <nz-input-number
                class="offset-input" 
                [(ngModel)]="distance" 
                appNumberInputSuffix 
                [numberInputSuffix]="'m'"
                [isDisabled]="false"
                (arrowClick)="inputButtonClick($event)"
                [nzSize]="'small'"
            ></nz-input-number>
        </div>
        <div class="offset-modal-input">
            <label class="offset-label">{{'OFFSET.DIRECTION' | translate}}</label>
            <nz-select 
                class="offset-select"
                [nzSize]="'small'"
                [(ngModel)]="direction">
                <nz-option 
                *ngFor="let dir of directions" 
                [nzValue]="dir" 
                [nzLabel]="directionsDict[dir] | translate"
                >
                </nz-option>
            </nz-select>
        </div>

    </div>

    <div class="modal-footer">
        <button nz-button (click)="handleCancel()">Cancel</button>
        <button nz-button nzType="primary" (click)="handleOk()">OK</button>
    </div>
</div>


