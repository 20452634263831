import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GeomanControlService } from 'src/app/components/main-page/panels/panel-map-main/leaflet-map/services/geoman-control.service';

@Component({
  selector: 'app-toolbar-template-modal',
  templateUrl: './toolbar-template-modal.component.html',
  styleUrls: ['./toolbar-template-modal.component.scss']
})
export class ToolbarTemplateModalComponent implements OnInit,OnDestroy {

  constructor(
    private geomanControlService : GeomanControlService,
    private translate: TranslateService
  ) {
    this.translate.use('he');
  }

  
  isRtl$ = new BehaviorSubject<boolean>(false);
  componentDestroyed$: Subject<boolean> = new Subject();

  currentLanguage: string;

  cards = [
    {
      number: 1,
      title: 'ONBOARDING.DRAWAPOLYGONONTHEMAP',
      text: 'ONBOARDING.TEXTFORDRAWAPOLYGON',
      image: '../../../../assets/main-menu/OnbaordingLeftToolbar.png',
      alt: 'Polygon Drawing Tool',
      opacity: 0.3,
    },
    {
      number: 2,
      title: 'ONBOARDING.DEFINETHEPLANENTITIES',
      text: 'ONBOARDING.TEXTFORDEFINETHEPLAN',
      image: '../../../../assets/main-menu/OnbaordingMiddleToolbar.png',
      alt: 'Plan Definition Tool',
      opacity: 0.6,
    },
    {
      number: 3,
      title: 'ONBOARDING.SETALLTHEPARAMETERS',
      text: 'ONBOARDING.TEXTETALLTHEPARAMETERS',
      image: '../../../../assets/main-menu/OnbaordingRightToolbar.png',
      alt: 'Parameter Setting Tool',
      opacity: 1,
    },
  ];


  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  ngOnInit(): void {

    this.geomanControlService.currentLang$.pipe(takeUntil(this.componentDestroyed$)).subscribe((lang) => {
      this.translate.use(lang);
      this.updateRtlStatus(lang);
    });
  }

  dynamicHeight : number = 400;


  private updateRtlStatus(lang: string): void {
    const rtlLanguages = ['ar', 'he', 'fa', 'ur'];
    this.isRtl$.next(rtlLanguages.includes(lang));

    switch (rtlLanguages.includes(lang))
    {
      case true:
        this.dynamicHeight = 400;
        break;
      case false:
        this.dynamicHeight = 430;
        break;
    }
  }
}
