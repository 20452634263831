import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LeafletService {

  enableEntitiesEditModeSubject = new Subject<string[]>();
  enableEntitisEditMode$ = this.enableEntitiesEditModeSubject.asObservable()
  
  closeMenuSubject = new Subject<boolean>();
  closeMenu$ = this.closeMenuSubject.asObservable()

  constructor() { }

  nextCloseMenu() {
    this.closeMenuSubject.next(true)
  }

  nextEnableEntitesEditMode(entitesIdToEdit: string[]) {
    this.enableEntitiesEditModeSubject.next(entitesIdToEdit)
  }
}
