<div 
  [ngStyle]="{ height: dynamicHeight + 'px' }"
  class="container" 
  [ngClass]="{ 'rtl': isRtl$ | async, 'ltr': !(isRtl$ | async) }"
>
    <label class="main-title">{{ 'ONBOARDING.CREATEAWIDEVARIETYOFPLAN' | translate }}</label>
    <label class="sec-title" [innerHTML]="'ONBOARDING.USEOURTAILOTMADEDRAWINGTOOLS' | translate"></label>

  <div class="card-container">
    <div class="card" *ngFor="let card of cards">
      <div class="text-container">
        <div class="card-label">
          <div class="number-circle" [style.opacity]="card.opacity">
            {{ card.number }}
          </div>
          <label class="text">{{ card.title | translate }}</label>
        </div>
        <div class="card-text-div">
          <label class="card-text">{{ card.text | translate }}</label>
        </div>
      </div>
        <img style="scale: 0.7;" [src]="card.image" [alt]="card.alt" />
    </div>
  </div>
</div>