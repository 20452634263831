import {Injectable} from '@angular/core';
import * as TurfBooleanWithin from '@turf/boolean-within';
import * as BooleanIntersects from '@turf/boolean-intersects';
import * as TurfTruncate from '@turf/truncate';
import * as TurfPolygonToLine from '@turf/polygon-to-line';
import * as TurfDifference from '@turf/difference';
import * as TurfArea from '@turf/area';
import * as TurfLength from '@turf/length';
import booleanContains from '@turf/boolean-contains';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import lineSplit from '@turf/line-split';
import length from '@turf/length';
import difference from '@turf/difference';
import area from '@turf/area';
import transformTranslate from '@turf/transform-translate';
import booleanWithin from '@turf/boolean-within';
import {ENTITY_ACCESS_TYPE, ENTITY_CATEGORIES, ENTITY_GEOMETRIC_TYPES} from '../../enums/enums';
import {
  PLANBOUNDARY_LANDUSE,
  DEFAULT_COLOR,
  DEFAULT_ENTITY_CATEGORY,
  DEFAULT_ENTITY_LANDUSE,
  DEFAULT_MAP_INFO,
  ENTITIES_TYPES,
  ENTITY_PLAN_TYPE,
  GEOJSON_PRECISION,
  SCOPELINE_INDEX_OLD,
  SELECTED_COLOR,
  TEMP_NAME,
  URBAN_S3_URL
} from '../../config';
import {StateService} from '../state/state.service';
import {Feature, Geometry, LineString, MultiPolygon, Point, Polygon} from 'geojson';
import {EntityProperties} from '../../models/entity-properties';
import Utils from '../../utils/utils';
import { ImportEntitiesActionTypes } from 'src/app/shared/types/types';
import { StoresManagerService } from '../storesManagerService/stores-manager.service';
import { MAVAT_DATA } from 'src/app/utils/mavat';
import { ProjectStateStoreService } from '../projectStateStore/project-state-store.service';
import { concatMap, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { AddUpdateDeleteEntitiesParams, EntityMap } from 'src/app/models/entity-map';
import { IParentChildRelationship } from 'src/app/models/parent-child-relationship';
import { ProjectStoreService } from '../projectStore/project-store.service';
import { from, Observable, Subject } from 'rxjs';
import { NzMessageService } from 'ng-zorro-antd/message';
import { BuildingDataService } from '../building-pipeline/building-data.service';
import { PlanInterface } from 'src/app/models/plan-interface';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {

  private duplicatedEntitySubject = new Subject<string | number>();
  public duplicatedEntity$ = this.duplicatedEntitySubject.asObservable();

  constructor(
    private authService: AuthService,
    private stateService: StateService,
    private buildingDataService: BuildingDataService,
    private projectStateStoreService: ProjectStateStoreService,
    private projectStoreService: ProjectStoreService,
    private storesManagerService: StoresManagerService,
    private nzMessageService: NzMessageService) {
  }

  extractLngLat(geometry) {
    let lat = 32;
    let lng = 34;
    switch (geometry.type) {
      case ENTITY_GEOMETRIC_TYPES.POLYGON:
        lat = geometry.coordinates[0][0][1];
        lng = geometry.coordinates[0][0][0]
        break;
      case ENTITY_GEOMETRIC_TYPES.MULTI_POLYGON:
        lat = geometry.coordinates[0][0][0][1];
        lng = geometry.coordinates[0][0][0][0];
        break;
      case ENTITY_GEOMETRIC_TYPES.POINT:
        lat = geometry.coordinates[1];
        lng = geometry.coordinates[0];
        break;
      case ENTITY_GEOMETRIC_TYPES.LINE_STRING:
        lat = geometry.coordinates[0][1];
        lng = geometry.coordinates[0][0];
        break;
    }
    return {lat, lng};
  }


  featuresWithin(selection): void {
    this.projectStateStoreService
      .getCurrentPlanEntities()
      .pipe(
        take(1),
        map((entities: EntityMap) => {
          const features = Object.values(entities).filter(
            (entity) => entity.id !== SCOPELINE_INDEX_OLD
          );

          let withinFeatures = [];
          for (const feature of features) {
            if (
              feature.geometry.type === 'MultiLineString' ||
              feature.geometry.type === 'MultiPolygon'
            ) {
              continue;
            }
            if (
              TurfBooleanWithin.default(feature, selection) ||
              BooleanIntersects.default(feature, selection)
            ) {
              withinFeatures.push(feature.id);
            }
          }

          return withinFeatures;
        })
      )
      .subscribe((withinFeatures) => {
        this.storesManagerService.setSelectedEntities(withinFeatures, false, false);
      });
  }


  buildingPolygonWithinLotByLot(lot: Feature<Geometry, EntityProperties>, currentPlanEntities: Feature<Geometry, EntityProperties>[]) {
    let array = [];
    const buildings = currentPlanEntities.filter(entity => 
      entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON && 
      entity.properties.entityCategory === ENTITY_CATEGORIES.BUILDING && 
      entity.id !== lot.id && 
      entity.properties.entityType !== ENTITIES_TYPES.building.floor);
    for (const building of buildings) {
      const response = this.buildingPolygonWithinLot(building, lot);
      if (response) {
        array.push(response);
      }
    }
    if (array.length > 0) {
      return array;
    }
    return undefined;
  }


  buildingPolygonWithinLotByBuilding(building: Feature<Geometry, EntityProperties>, currentPlanEntities: Feature<Geometry, EntityProperties>[]) {
    const lots = currentPlanEntities.filter(entity => entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON && entity.properties.entityCategory === ENTITY_CATEGORIES.PARCEL && entity.id !== building.id);
    for (const lot of lots) {
      const response = this.buildingPolygonWithinLot(building, lot);
      if (response) {
        return response;
      }
    }
    return null;
  }

  buildingPolygonWithinLot(building: Feature<Geometry, EntityProperties>, lot: Feature<Geometry, EntityProperties>) {
    if (lot.geometry.type === ENTITY_GEOMETRIC_TYPES.MULTI_POLYGON) {
      return undefined;
    }
    const l = Utils.clone(lot);
    const b = Utils.clone(building);
    TurfTruncate.default(l, {mutate: true, precision: GEOJSON_PRECISION - 1});
    TurfTruncate.default(b, {mutate: true, precision: GEOJSON_PRECISION - 1});
    if (TurfBooleanWithin.default(b, l)) {
      return {lot: lot.id, building: building.id};
    }
    if (this.isPolygonWithinByArea(b, l)) {
      return {lot: lot.id, building: building.id};
    }
    return undefined;
  }


  lotWithinParcel(lot: Feature<Geometry, EntityProperties>, currentPlanEntities: Feature<Geometry, EntityProperties>[]) {
    if (lot.geometry.type === ENTITY_GEOMETRIC_TYPES.MULTI_POLYGON) {
      return null;
    }
    // const parcels = currentPlanEntities.filter(entity => entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON && (entity.properties.entityCategory === ENTITY_CATEGORIES.PARCEL && entity.properties.entityType === 'parcel'));
    const parcels = currentPlanEntities.filter(entity => entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON && entity.properties.entityCategory === ENTITY_CATEGORIES.PARCEL && entity.id !== lot.id);
    for (const parcel of parcels) {
      if (parcel.geometry.type === ENTITY_GEOMETRIC_TYPES.MULTI_POLYGON) {
        continue;
      }
      const l = TurfTruncate.default(Utils.clone(lot), {mutate: true, precision: GEOJSON_PRECISION - 1});
      const p = TurfTruncate.default(Utils.clone(parcel), {mutate: true, precision: GEOJSON_PRECISION - 1});
      if (TurfBooleanWithin.default(l, p)) {
        return parcel.id;
      }
      if (this.isPolygonWithinByArea(l, p)) {
        return parcel.id;
      }
    }
    return null
  }

  isPolygonWithinByArea(innerPolygon:Feature<Geometry, EntityProperties>, containerPolygon: Feature<Geometry, EntityProperties>): boolean {
    // @ts-ignore
    const dif = TurfDifference.default(innerPolygon, containerPolygon);
    if (dif) {
      // @ts-ignore
      const area = TurfArea.default(dif);
      if (area < 10) {
        return true;
      }
    }
    return false;
  }

  isLineWithinPolygon(line: Feature<Geometry, EntityProperties>, polygon: Feature<Geometry, EntityProperties>): boolean {

    if (line.geometry.type !== 'LineString') {
      throw new Error('isLineWithinPolygonWithTolerance expects a LineString geometry');
    }
    if (polygon.geometry.type !== 'Polygon' && polygon.geometry.type !== 'MultiPolygon') {
      throw new Error('isLineWithinPolygonWithTolerance expects a Polygon or MultiPolygon geometry');
    }

    line as Feature<LineString, EntityProperties>;
    polygon as Feature<Polygon | MultiPolygon, EntityProperties>

    return booleanContains(polygon, line);
  }

  isPointWithinPolygon(point: Feature<Geometry, EntityProperties>, polygon: Feature<Polygon | MultiPolygon, EntityProperties>): boolean {
    if (point.geometry.type !== 'Point') {
      throw new Error('isPointWithinPolygon expects a Point geometry');
    }
    if (polygon.geometry.type !== 'Polygon' && polygon.geometry.type !== 'MultiPolygon') {
      throw new Error('isPointWithinPolygon expects a Polygon or MultiPolygon geometry');
    }

    return booleanPointInPolygon(point.geometry.coordinates, polygon);
  }

  isPolygonWithinPolygonWithTolerance(
    innerPolygon: Feature<Geometry, EntityProperties>,
    outerPolygon: Feature<Geometry, EntityProperties>,
    tolerance: number = 10
  ): boolean {

    let innerPoly: Feature<Polygon | MultiPolygon, EntityProperties> | null = null;
    if (innerPolygon.geometry.type === 'Polygon' || innerPolygon.geometry.type === 'MultiPolygon') {
      innerPoly = innerPolygon as Feature<Polygon | MultiPolygon, EntityProperties>;
    } else {
      console.error('innerPolygon is not a Polygon or MultiPolygon');
      return false;
    }
  
    let outerPoly: Feature<Polygon | MultiPolygon, EntityProperties> | null = null;
    if (outerPolygon.geometry.type === 'Polygon' || outerPolygon.geometry.type === 'MultiPolygon') {
      outerPoly = outerPolygon as Feature<Polygon | MultiPolygon, EntityProperties>;
    } else {
      console.error('outerPolygon is not a Polygon or MultiPolygon');
      return false;
    }

    const isWithin = booleanWithin(innerPoly, outerPoly);
    if (isWithin) {
      return true;
    }
  
    //@ts-ignore
    const diff = difference(innerPoly, outerPoly);
  
    if (!diff) {
      return true;
    }
  
    const diffArea = area(diff);
  
    return diffArea <= tolerance;
  }

  // isLineWithinPolygonWithTolerance(
  //   line: Feature<Geometry, EntityProperties>,
  //   polygon: Feature<Geometry, EntityProperties>,
  //   tolerance: number = 10
  // ): boolean {

  //   if (line.geometry.type !== 'LineString') {
  //     throw new Error('isLineWithinPolygonWithTolerance expects a LineString geometry');
  //   }
  //   if (polygon.geometry.type !== 'Polygon' && polygon.geometry.type !== 'MultiPolygon') {
  //     throw new Error('isLineWithinPolygonWithTolerance expects a Polygon or MultiPolygon geometry');
  //   }

  //   line as Feature<LineString, EntityProperties>;
  //   polygon as Feature<Polygon | MultiPolygon, EntityProperties>

  //   // Split the line by the polygon boundaries
  //   const split = lineSplit(line, polygon);
  
  //   // Initialize the total length of the line outside the polygon
  //   let outsideLength = 0;
  
  //   // Iterate over each segment of the split line
  //   for (const segment of split.features) {
  //     // Check if the segment is within the polygon
  //     const isWithin = booleanWithin(segment, polygon);
  //     if (!isWithin) {
  //       // Segment is outside the polygon, accumulate its length
  //       outsideLength += length(segment, { units: 'meters' });
  //     }
  //   }
  
  //   // If the total length outside is less than or equal to tolerance, consider the line as within
  //   return outsideLength <= tolerance;
  // }

  getStrokeColor(entity: Feature<Geometry, EntityProperties>, isSelected: boolean, menuData: any) {
    if (entity.id === SCOPELINE_INDEX_OLD ) {
      return '#C9ADBE';
    }
    if (isSelected) {
      return SELECTED_COLOR;
    }
    if (entity.properties.style && entity.properties.style.strokeColor) {
      return entity.properties.style.strokeColor;
    }
    const allParams = Utils.clone(menuData)

    if (entity.properties.type === ENTITY_GEOMETRIC_TYPES.LINE_STRING) {
      return (entity.properties.style && entity.properties.style.strokeColor) || allParams[entity.properties.entityCategory].landUse[entity.properties.landUse] && allParams[entity.properties.entityCategory].landUse[entity.properties.landUse].color || DEFAULT_COLOR;
    }

    return this.getEntityParam(entity.properties, 'strokeColor', allParams) || DEFAULT_COLOR;
  }


  getPatternUrl(entityCategory: ENTITY_CATEGORIES, entityType: string, polygonType: ENTITY_GEOMETRIC_TYPES, menuData, createdBy?: string) {
    const allParams =Utils.clone(menuData)

    let pattern;

    if (entityCategory === ENTITY_CATEGORIES.BUILDING && ![ENTITIES_TYPES.building.building, ENTITIES_TYPES.building.floor].includes(entityType) ) {
      pattern = allParams[entityCategory]?.usage[entityType]?.pattern;
    } else {
      pattern = allParams[entityCategory]?.landUse[entityType]?.pattern;
    }

    if (pattern) {
      // https://cloud.google.com/storage/docs/configuring-cors#gsutil_1
      return `${URBAN_S3_URL}/patterns/${pattern}`;
    }
    if (polygonType === ENTITY_GEOMETRIC_TYPES.POLYGON) {
      return null;
    } else {
      const d = allParams.poi?.landUse?.landmark?.pattern || '1658130935265-POI test.png';
      return `${URBAN_S3_URL}/patterns/${d}`;
    }
  }

  getIconUrl(feature: Feature<Geometry, EntityProperties>, menuData) {
    if(feature.properties.entityType === 'comment') {
      return feature.properties.style.icon;
    }
    if (feature.properties.style && feature.properties.style.icon) {
      return `${URBAN_S3_URL}/poi-icons/${feature.properties.style.icon}`;
    }
    // @ts-ignore
    return this.getPatternUrl(feature.properties.entityCategory, feature.properties.entityType, feature.properties.type, menuData);
  }

  // switchIconUrl(entity: Feature<Geometry, EntityProperties>, menuData) {
  //   return this.switchPatternUrl(entity, entity.properties.entityType, entity.properties.type, menuData,);
  // }


  // switchPatternUrl(entity: Feature<Geometry, EntityProperties>, entityType: string, polygonType: ENTITY_GEOMETRIC_TYPES, menuData) {
  //   const allParams =Utils.clone(menuData)
  //   const entityCategory = entity.properties.entityCategory
  //   let pattern = allParams[entityCategory]?.landUse[entityType]?.pattern;
    
  //   if (pattern) {
  //     if(pattern.includes('active')) {
  //       return `${URBAN_S3_URL}/patterns/${pattern}`;
  //     } else {
  //       let p = pattern.split('.')[0];
  //       let v = `${URBAN_S3_URL}/patterns/Selected POI/${p}_selected.png`;
  //       return v;
  //     }
  //   } else {
  //     const d = allParams.poi?.landUse?.landmark?.pattern || '1658130935265-POI test.png';
  //     return `${URBAN_S3_URL}/patterns/${d}`;
  //   }
  // }

  setEntityType(featureToSet: Feature<Geometry, EntityProperties>, entityCategory, entityType: string, menuData): Feature<Geometry, EntityProperties>{
    const feature = Utils.clone(featureToSet);
    feature.properties.entityCategory = entityCategory;
    feature.properties.entityType = entityType;
    feature.properties.landUse = entityType;
    feature.properties.accessType = menuData[entityCategory].landUse[entityType].subCategory || undefined;
    // feature.properties.landUseParams = this.stateService.entityParamsValues(feature.properties.entityCategory, feature.properties.landUse);
    feature.properties.landUseParams = this.entityParamsValues(feature.properties.entityCategory, feature.properties.landUse, menuData);
    delete feature.properties.style;
    return feature;
  }


  duplicateEntity(entity: Feature<Geometry, EntityProperties>, currentEntities: EntityMap, currentPlan: PlanInterface, menuData) {
    const dupEntity = Utils.clone(entity);
    dupEntity.properties.name = `copy-${entity.properties.name}`;

    this.shiftEntityPosition(dupEntity, 0.20);

    const duplicated = this.createEntity(dupEntity, {shape: dupEntity.geometry.type}, menuData, entity.properties.projectName);
    if (duplicated.properties.entityCategory === ENTITY_CATEGORIES.PARCEL && duplicated.properties.accessType === ENTITY_ACCESS_TYPE.PRIVATE) {
      duplicated.properties.landUseParams = this.buildingDataService.initializeLandUseParams(duplicated.properties.landUseParams)
      this.buildingDataService.reCalculateLot(duplicated)
    }

    const parentChildRelationship = this.handlePolygonWithinPolygon(
      duplicated,
      Object.values(currentEntities)
    );
  
    const entitiesToUpdateObject: AddUpdateDeleteEntitiesParams = {
      entitiesToAdd: {},
      entitiesToUpdate: {}
    };
  
    entitiesToUpdateObject.entitiesToAdd[duplicated.id] = {
      entity: duplicated,
      parentId: parentChildRelationship?.parentId,
    };
  
    this.storesManagerService.addUpdateDeleteEntities(entitiesToUpdateObject, currentPlan.planName).pipe(
      take(1)
    ).subscribe( () => {
      this.storesManagerService.setSelectedEntities([duplicated], false, false);
      this.nextDuplicatedEntityToDrag(duplicated.id);
    });
  }

  nextDuplicatedEntityToDrag(entityId): void {
    this.duplicatedEntitySubject.next(entityId)
  }

  shiftEntityPosition(entity: Feature<Geometry, EntityProperties>, percentage: number = 0.01 ): void {
    let shiftDistance: number; // in meters
  
    switch (entity.geometry.type) {
      case 'Point':
        // Shift by a small distance, e.g., 10 meters
        shiftDistance = 10;
        break;
  
      case 'LineString':
        // Calculate length in meters
        //@ts-ignore
        shiftDistance = this.calculateShiftDistanceForLine(entity, percentage);
        break;
  
      case 'Polygon':
      case 'MultiPolygon':
        // Calculate shift distance based on the polygon's approximate size
        //@ts-ignore
        shiftDistance = this.calculateShiftDistanceForPolygon(entity, percentage);
        break;
  
      default:
        throw new Error(`Unsupported geometry type: ${entity.geometry.type}`);
    }
  
    // Use a consistent bearing (e.g., 45 degrees) or randomize if preferred
    const bearing = 45; // degrees
  
    // Shift the entity using turf.transformTranslate
    //@ts-ignore
    const shiftedEntity = transformTranslate(entity, shiftDistance, bearing, { units: 'meters' }) as Feature<Geometry, EntityProperties>;
  
    // Update the entity's geometry
    entity.geometry = shiftedEntity.geometry;
  }

  calculateShiftDistanceForLine(
    entity: Feature<LineString, EntityProperties>,
    percentage: number
  ): number {
    // Calculate the length of the line in meters
    const lineLength = length(entity, { units: 'meters' });
    // Shift distance is a percentage of the length
    return lineLength * percentage;
  }

  calculateShiftDistanceForPolygon(
    entity: Feature<Polygon | MultiPolygon, EntityProperties>,
    percentage: number
  ): number {
    // Calculate the area of the polygon in square meters
    const polyArea = area(entity);
    // Approximate the size using the square root of the area
    const approximateSize = Math.sqrt(polyArea);
    // Shift distance is a percentage of the approximate size
    return approximateSize * percentage;
  }

  lockEntity(entity: Feature<Geometry, EntityProperties>) {
    if (entity.properties.isLock) {
      return;
    }
    const entityToLock = Utils.clone(entity);
    entityToLock.properties.isLock = true;
    this.storesManagerService.updateEntity(entityToLock).pipe(
      take(1)
    ).subscribe()
  }

  unlockEntity(entity: Feature<Geometry, EntityProperties>) {
    if (!entity.properties.isLock) {
      return
    }
    const entityToUnlock = Utils.clone(entity);
    entityToUnlock.properties.isLock = false;
    this.storesManagerService.updateEntity(entityToUnlock).pipe(
      take(1)
    ).subscribe()
  }

  entityIsLock(entityId): boolean {
    let isLock: boolean;
    this.projectStoreService.findEntityById(entityId).pipe(
      tap(entity => isLock = entity.properties.isLock),
      take(1)
    ).subscribe()
    return isLock;
  }

  generateDefaultEntityName(entity: Feature<Geometry, EntityProperties>, params: {isUseExistingName?: boolean, namesList?: string[], importType?: ImportEntitiesActionTypes}) {
    if ((params.hasOwnProperty('isUseExistingName') && !params.isUseExistingName) && (entity.properties.name && entity.properties.name !== TEMP_NAME)) {
      return entity.properties.name;
    }

    const generateUniqueName = () => {
      let name = baseName;
      let isExists: boolean = true;
      let i = 1;
      if (!allEntitiesNames.find(n => n === name) || params.isUseExistingName) {
        return name;
      }
      name += ' ';
      do {
        if (!allEntitiesNames.find(n => n === name + i)) {
          isExists = false;
          name += i;
        }
        i++;
      } while (isExists);
      return name;
    }
    const generateDuplicateName = (array, name) => {
      const index = array.indexOf(name);
      if (-1 === index) {
        return name;
      }
      array.splice(index, 1);
      const regExp = /\(([^)]+)\)/;
      const filteredNumArray = array.reduce((prev, curr) => {
        const splitted = curr.split(' ');
        const lastElement = splitted.pop();
        if (splitted.join(' ') !== name) {
          return prev;
        }
        const matches = regExp.exec(lastElement);
        const num = parseInt(matches[0].replace('(', '').replace(')', ''));
        if (!isNaN(num)) {
          prev.push(num);
        }
        return prev;
      }, []);
      const max = 0 < filteredNumArray.length ? Math.max(...filteredNumArray) : 0;
      return `${name} (${ max + 1})`;
    }

    let allEntitiesNames = this.stateService.entities.features.map(entity => entity.properties.name);
    let baseName;
    if (entity.properties.hasOwnProperty('heightProperties')) {
      return entity.id.toString();
    }
    baseName = entity.properties.type;
    if (params.isUseExistingName) {
      if (entity.properties.name !== TEMP_NAME) {
        baseName = entity.properties.name;
        if (params.importType === 'urban_mavat') {
          baseName = Utils.capitalizeWords(entity.properties.entityType + ' ' + baseName);
        }
        baseName = generateUniqueName();
        return generateDuplicateName(allEntitiesNames, baseName);
      }
      baseName = Utils.capitalizeWords(entity.properties.entityType || entity.properties.landUse);
      baseName = baseName.replaceAll('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
      baseName = generateUniqueName();
      const names = params.hasOwnProperty('namesList') ? allEntitiesNames.concat(params.namesList) : allEntitiesNames;
      const l = generateDuplicateName(names, baseName);
      return l;
    }
    if (baseName === ENTITY_GEOMETRIC_TYPES.LINE_STRING) {
      baseName = ENTITY_GEOMETRIC_TYPES.POLYLINE;
    }
    if (entity.properties.type === ENTITY_GEOMETRIC_TYPES.CIRCLE) {
      baseName = 'Influence';
    }
    if (entity.properties.type === ENTITY_GEOMETRIC_TYPES.POINT) {
      baseName = 'POI';
    }
    return generateUniqueName();
  }


  generateDefaultEntityNameObservable(entity: Feature<Geometry, EntityProperties>, params: {isUseExistingName?: boolean, namesList?: string[], importType?: ImportEntitiesActionTypes}): Observable<string> {
    return this.projectStateStoreService.getCurrentPlanEntities().pipe(
      map((entities: EntityMap) => {
        let allEntitiesNames = Object.values(entities).map(entity => entity.properties.name);
  
        const generateUniqueName = (): string => {
          let name = baseName;
          let isExists: boolean = true;
          let i = 1;
          if (!allEntitiesNames.find(n => n === name) || params.isUseExistingName) {
            return name;
          }
          name += ' ';
          do {
            if (!allEntitiesNames.find(n => n === name + i)) {
              isExists = false;
              name += i;
            }
            i++;
          } while (isExists);
          return name;
        }
  
        const generateDuplicateName = (array: string[], name: string): string => {
          const index = array.indexOf(name);
          if (-1 === index) {
            return name;
          }
          array.splice(index, 1);
          const regExp = /\(([^)]+)\)/;
          const filteredNumArray = array.reduce((prev, curr) => {
            const splitted = curr.split(' ');
            const lastElement = splitted.pop();
            if (splitted.join(' ') !== name) {
              return prev;
            }
            const matches = regExp.exec(lastElement);
            const num = parseInt(matches[0].replace('(', '').replace(')', ''));
            if (!isNaN(num)) {
              prev.push(num);
            }
            return prev;
          }, []);
          const max = 0 < filteredNumArray.length ? Math.max(...filteredNumArray) : 0;
          return `${name} (${ max + 1})`;
        }
  
        let baseName: string;
        if (entity.properties.hasOwnProperty('heightProperties')) {
          return entity.id.toString();
        }
        baseName = entity.properties.type;
  
        if (params.isUseExistingName) {
          if (entity.properties.name !== TEMP_NAME) {
            baseName = entity.properties.name;
            if (params.importType === 'urban_mavat') {
              baseName = Utils.capitalizeWords(entity.properties.entityType + ' ' + baseName);
            }
            baseName = generateUniqueName();
            return generateDuplicateName(allEntitiesNames, baseName);
          }
          baseName = Utils.capitalizeWords(entity.properties.entityType || entity.properties.landUse);
          baseName = baseName.replaceAll('_', ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');
          baseName = generateUniqueName();
          const names = params.hasOwnProperty('namesList') ? allEntitiesNames.concat(params.namesList) : allEntitiesNames;
          const l = generateDuplicateName(names, baseName);
          return l;
        }
  
        if (baseName === ENTITY_GEOMETRIC_TYPES.LINE_STRING) {
          baseName = ENTITY_GEOMETRIC_TYPES.POLYLINE;
        }
        if (entity.properties.type === ENTITY_GEOMETRIC_TYPES.CIRCLE) {
          baseName = 'Influence';
        }
        if (entity.properties.entityType !== 'comment' && entity.properties.type === ENTITY_GEOMETRIC_TYPES.POINT) {
          baseName = 'POI';
        }
        if (entity.properties.entityType === 'comment' && entity.properties.type === ENTITY_GEOMETRIC_TYPES.POINT) {
          baseName = 'Comment';
        }
        
        return generateUniqueName();
      })
    );
  }
  

  async fetchShadows(projectName, planName) {
    // try {
    //   const response: any = await this.crudService.fetchShadows(projectName, planName);
    //   const multiPolygon = TurfHelpers.multiPolygon(JSON.parse(response.result).coordinates, {name: 'shadow'});
    //   multiPolygon.id = SHADOWS_INDEX;
    //   const features = this.stateService.viewOnlyFeatures$.getValue().features.filter(feature => feature.id !== SHADOWS_INDEX);
    //   features.push(multiPolygon);
    //   const featureCollection: FeatureCollection = {type: 'FeatureCollection', features};
    //   this.stateService.viewOnlyFeatures$.next(featureCollection);
    // } catch (e) {
    // }
  }


  //////////////////

  createScopeline(entity: any): Feature<LineString, EntityProperties> {
    const properties: EntityProperties = {
      date: Date.now(),
      description: '',
      entityCategory: null,
      accessType: ENTITY_ACCESS_TYPE.OTHER,
      entityPlanType: entity.properties.entityPlanType || ENTITY_PLAN_TYPE[0],
      entityType: null,
      entityVersion: 'v0',
      heightProperties: {},
      isArchive: false,
      isLock: false,
      isHide: false,
      landUse: 'mix-use',
      mapInfo: {
        mapCenter: entity.properties.mapCenter || DEFAULT_MAP_INFO.coordinates,
        zoom: 17,
        projectAddress: entity.properties.projectAddress,
        projectName: entity.properties.projectName,
        projectType: entity.properties.projectType
      },
      name: 'Scopeline',
      plansOrder: ['plan1'],
      projectName: '',
      type: ENTITY_GEOMETRIC_TYPES.LINE_STRING,
      style: {
        fillColor: '#C9ADBE'
      }
    };
    entity.properties = properties;
    entity.id = SCOPELINE_INDEX_OLD;
    if (entity.geometry && entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON) {
      const feature = TurfPolygonToLine.polygonToLine(entity);
      entity.geometry = feature.geometry;
    }
    return entity;
  }

  createEntity(entity: Feature<Geometry, EntityProperties>, options: { shape: any, params?: any, isImport?: boolean,  commentIcon? : string }, menuData, projectName, ) {
  
    const nextEntityIndex = this.generateId();
    let entityCategory, entityType, landUse;
  
    if (options.params && options.params.entityCategory) {
      entityCategory = options.params.entityCategory;
      landUse = options.params.landUse || DEFAULT_ENTITY_LANDUSE;
    } else {
      const entityTypeMapping = {
        [ENTITY_GEOMETRIC_TYPES.POINT]: { category: ENTITY_CATEGORIES.DEFAULT, landUse: ENTITIES_TYPES.default.default_point },
        [ENTITY_GEOMETRIC_TYPES.LINE_STRING]: { category: ENTITY_CATEGORIES.DEFAULT, landUse: ENTITIES_TYPES.default.default_polyline },
      };
      const mapping = entityTypeMapping[entity.geometry.type] || { category: DEFAULT_ENTITY_CATEGORY, landUse: DEFAULT_ENTITY_LANDUSE };
      entityCategory = mapping.category;
      landUse = mapping.landUse;
    }
  
    if (entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POINT && !entity.properties.radius) {
      entityType = ENTITIES_TYPES.default.default_point;
    }
  
    if (entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON) {
      entityType = ENTITIES_TYPES.default.default_polygon;
    }

    if (entity.geometry.type === ENTITY_GEOMETRIC_TYPES.LINE_STRING) {
      entityType = ENTITIES_TYPES.default.default_polyline;
    }

    entity.id = nextEntityIndex.toString();
    const optionslandUseParams = options?.params?.landUseParams
  
    const properties = {
      name: entity.properties?.name || TEMP_NAME,
      description: entity.properties?.description || '',
      entityCategory: entity.properties?.entityCategory || entityCategory,
      entityType: entity.properties?.entityType || entityType || null,
      accessType: entity.properties?.accessType || 'other',
      entityVersion: 'v0',
      type: entity.geometry.type,
      landUse: entity.properties?.landUse || landUse,
      landUseParams: optionslandUseParams || entity.properties.landUseParams || {},
      projectName: projectName,
      isHide: entity.properties.isHide || false,
      isLock: entity.properties.isLock || false,
      entityPlanType: entity.properties.entityPlanType,
      style: options.params?.style || entity.properties?.style || {icon : options.commentIcon? options.commentIcon : null},
      date: Date.now(),
      ...options.params,
    };
  
    if (entity.properties?.heightProperties) {
      properties.heightProperties = entity.properties.heightProperties;
    }
  
    if (options.isImport && entity.properties) {
      entity.properties = { ...entity.properties, ...properties };
    } else {
      entity.properties = properties;
    }
  
    if (options.shape.type === ENTITY_GEOMETRIC_TYPES.CIRCLE) {
      if (!options.isImport) {

        entity.properties.entityCategory = ENTITY_CATEGORIES.DEFAULT;
        entity.properties.landUse = ENTITIES_TYPES.default.default_polygon;
      }
      entity.properties.radius = options.shape.radius;
      entity.properties.type = ENTITY_GEOMETRIC_TYPES.CIRCLE;
    }
  
    if (!entity.properties.landUseParams || Object.keys(entity.properties.landUseParams).length === 0) {
      const landUseParams = this.entityParamsValues(entity.properties.entityCategory, entity.properties.landUse, menuData);
      if (landUseParams) {
        entity.properties.landUseParams = landUseParams;
      }
    }
  
    if (properties.type === ENTITY_GEOMETRIC_TYPES.POLYGON || properties.type === ENTITY_GEOMETRIC_TYPES.MULTI_POLYGON) {
      // @ts-ignore
      entity.geometry.coordinates = entity.geometry.coordinates.map(co => co.map(coo => [coo[0], coo[1]]));   //this removes z 
      entity = this.calculateArea(entity);
    }

    if (properties.type === ENTITY_GEOMETRIC_TYPES.LINE_STRING || properties.type === ENTITY_GEOMETRIC_TYPES.MULTI_LINE_STRING) {
      entity = this.calculateLength(entity)
    }
  
    Utils.truncateGeoJson(entity);
    return entity;
  }

  entityParamsValues(entityCategory, landUse, menuData) {
    const defaultParams = this.filterEntityParams(entityCategory, landUse, menuData);
  
    return Object.keys(defaultParams).reduce((obj, key) => {
      const params = defaultParams[key];
  
      if (Array.isArray(params)) {
        params.forEach(param => {
          if (!param || !param.code) return;
  
          const value = typeof param.value === 'number'
            ? param.value
            : (param.defaultValue || param.min || 0);
  
          Object.assign(obj, { [param.code]: value });
        });
      }
  
      return obj;
    }, {});
  }


  filterEntityParams(entityCategory, landUse, menuData) {
    if (!menuData || !menuData[entityCategory]) {
      return {};
    }
  
    const keys = Object.keys(menuData[entityCategory]).filter(x => x !== 'landUse');
  
    return keys.reduce((menu, menuKey) => {
      const items = menuData[entityCategory][menuKey];
  
      if (Array.isArray(items)) {
        const arr = items.filter((m) => {
          if (!m || !m.landUses) return false;
  
          return m.landUses.indexOf(landUse) > -1 || m.landUses.indexOf('all_types') > -1;
        });
  
        if (arr.length > 0) {
          menu[menuKey] = arr;
        }
      }
      return menu;
    }, {});
  }



  calculateArea(feature: Feature<Geometry, any | EntityProperties>) {
    if (typeof feature !== 'object' || !feature.geometry || !feature.properties) {
      throw new Error('Invalid feature: expected an object with geometry and properties');
    }
  
    if (
      feature.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON ||
      feature.geometry.type === ENTITY_GEOMETRIC_TYPES.MULTI_POLYGON
    ) {
      //@ts-ignore
      const areaInSqMeters = parseFloat(TurfArea.default(feature).toFixed(5));
      let areaValue: number;
      let unit: 'sqm' | 'dunam';
  
      if (feature.properties.entityCategory === ENTITY_CATEGORIES.BUILDING) {
        areaValue = areaInSqMeters;
        unit = 'sqm';
      } else {
        areaValue = areaInSqMeters / 1000;
        unit = 'dunam';
      }
  
      feature.properties.area = { unit, value: areaValue };
    }
  
    return feature;
  }
  

  calculateLength(feature: Feature<Geometry, any | EntityProperties>) {
    if (typeof feature !== 'object' || !feature.geometry || !feature.properties) {
      throw new Error('Invalid feature: expected an object with geometry and properties');
    }
    if (
      feature.geometry.type === ENTITY_GEOMETRIC_TYPES.LINE_STRING ||
      feature.geometry.type === ENTITY_GEOMETRIC_TYPES.MULTI_LINE_STRING
    ) {
      const lengthInMeters = parseFloat(TurfLength.default(feature, { units: 'meters' }).toFixed(5));
  
      feature.properties.length = lengthInMeters;
    }
  
    return feature;
  }
  

  generateId(): number {
    const nextEntityIndex = Date.now();
    // FRN  use uuid (need to check if changing the id would not be a problem) or get all entities 

    // const allPlansEntities = this.getAllProjectEntitesSubjectValue();
    // for (const feature of allPlansEntities) {
    //   if (feature.id === nextEntityIndex.toString()) {
    //     return this.generateId;
    //   }
    // }
    return nextEntityIndex;
  }

  
  uniqueIdValidator(features: Feature[], newId?: number): any[] {
    for (let i = 0; i < features.length; i++) {
      for (let j = 0; j < features.length; j++) {
        if (i === j) {
          continue;
        }
        if (features[i].id === features[j].id) {
          let generatedId = newId || this.generateId();
          do {
            generatedId++;
          } while (generatedId.toString() === features[j].id);
          features[i].id = generatedId.toString();
          return this.uniqueIdValidator(features, generatedId + 1);
        }
      }
    }
    return features;
  }

  getEntityParam(layer: EntityProperties, param: string, allParams: any) {
    try {
      if (param === 'color') {
        const mvt = MAVAT_DATA.find(mvt => mvt.landUse === layer.landUse);
        if (mvt && mvt.fillColor) {
          return mvt.fillColor;
        }
      }
      // return allParams[layer.entityCategory].landUse[layer.landUse][param];
      return allParams[layer.entityCategory].landUse[layer.entityType][param];
    } catch (e) {
      return 0;
    }
  }


  turnEntityInto(entityCategory: ENTITY_CATEGORIES, entType: string, entities: Feature<Geometry, EntityProperties>[], menuData: any): Observable<Feature<Geometry, EntityProperties>[]> {
    const entityTypeReal = this.findKeyByDisplayName(menuData[entityCategory].landUse, entType);
    const entityType = entType === 'polygon' ? 'default' : entityTypeReal;
  
    this.checkEntityTypeVisibility(undefined, {category: entityCategory, type:entityType});
  
    return this.projectStateStoreService.getCurrentPlanEntities().pipe(
      take(1),
      map(currentEntitiesMap => Object.values(currentEntitiesMap)),
      switchMap(currentEntities => {
        return from(entities).pipe(
          concatMap(entity => {
            const entityToChange = Utils.clone(entity);
            entityToChange.properties.landUseParams = this.entityParamsValues(entityCategory, entityToChange.properties.entityType, menuData);
            const entityTransformed = this.setEntityType(entityToChange, entityCategory, entityType, menuData);
            const entityTransformedAndCalculated = this.calculateArea(entityTransformed);
  
            // Get parent-child relationship from handlePolygonWithinPolygon
            const parentChildRelationship = this.handlePolygonWithinPolygon(entityTransformedAndCalculated, currentEntities);

            if (entityCategory === ENTITY_CATEGORIES.BUILDING && !parentChildRelationship.parentId) {
              this.nzMessageService.warning(`Building must be on a private parcel`)
            }
  
            return this.storesManagerService.updateEntity(entityTransformedAndCalculated, parentChildRelationship).pipe(
              map(() => entityTransformedAndCalculated)
            );}
          ),
          toArray(),

        );
      })
    );
  }

  // checkEntityTypeVisibility(entType: string): void {
  //   this.projectStateStoreService.currentPlan$.pipe(
  //     take(1)
  //   ).subscribe(plan => {
  //     if (plan.visibilityState) {
  //       const isVisible = plan.visibilityState[entType];
  //       if (isVisible === false) {
  //         this.nzMessageService.warning(`Warning: Visibility for ${entType} type is off.`);
  //       }
  //     }
  //   });
  // }

  checkEntityTypeVisibility(entity?: Feature<Geometry, EntityProperties>, entityParameters?: {category: ENTITY_CATEGORIES, type: string}): void {
    const category = entity?.properties?.entityCategory || entityParameters?.category;
    const entType = entity?.properties?.entityType || entityParameters?.type;
    this.projectStateStoreService.currentPlan$.pipe(
      take(1)
    ).subscribe(plan => {
      if (plan.visibilityState) {
        const isVisible = plan.visibilityState[category]?.types[entType];
        if (isVisible === false) {
          this.nzMessageService.warning(`Warning: Visibility for ${entType} type is off.`);
        }
      }
    });
  }

  // getEntityType(entity: Feature<Geometry, EntityProperties>): string | null {
  //   if (entity.properties.landUse === BLUELINE_LANDUSE) {
  //     return 'blueLine';
  //   } else if (entity.properties.entityType === 'default' || entity.properties.landUse === 'default') {
  //     return 'polygons';
  //   } else if (entity.properties.entityCategory === 'parcel' && entity.properties.entityType !== 'building') {
  //     return 'lots';
  //   } else if (entity.properties.entityType === 'building') {
  //     return 'buildings';
  //   } else if (entity.properties.entityCategory === 'poi' && entity.properties.entityType !== 'comment') {
  //     return 'poi';
  //   } else if (entity.properties.entityCategory === 'poi' && entity.properties.entityType === 'comment') {
  //     return 'comments';
  //   }
  //   return null;
  // }


  handlePolygonWithinPolygon(entity: Feature<Geometry, EntityProperties>, currentPlanEntities: Feature<Geometry, EntityProperties>[]): IParentChildRelationship {
    if (entity.geometry.type !== ENTITY_GEOMETRIC_TYPES.POLYGON || !entity.properties.entityType) {
      return null;
    }
    let parentId;
    let childrenId;
    let isLot = entity.properties.entityCategory === ENTITY_CATEGORIES.PARCEL
  
    if (entity.properties.entityType === 'building') {
      const lotBuilding = this.buildingPolygonWithinLotByBuilding(entity, currentPlanEntities);
      parentId = lotBuilding ? lotBuilding.lot : undefined;
    }
    if (isLot) {
      parentId = this.lotWithinParcel(entity, currentPlanEntities);
      childrenId = this.buildingPolygonWithinLotByLot(entity, currentPlanEntities);
    }
  
    return {
      parentId,
      childrenId,
      entityId: entity.id.toString(),
    };
  }

  generateShapeFromGeometry(type: string) {
    if (type === ENTITY_GEOMETRIC_TYPES.LINE_STRING || type === 'MultiLineString') {
      return ENTITY_GEOMETRIC_TYPES.LINE_STRING;
    }
    if (type === ENTITY_GEOMETRIC_TYPES.POLYGON || type === 'MultiPolygon') {
      return ENTITY_GEOMETRIC_TYPES.POLYGON;
    }
    if (type === ENTITY_GEOMETRIC_TYPES.POINT || type === 'MultiPoint') {
      return ENTITY_GEOMETRIC_TYPES.POINT;
    }
    return null;
  }


  findKeyByDisplayName(landUse, displayName) {
    for (const key in landUse) {
        if (landUse[key].displayName === displayName) {
            return key;  // Return the key whose displayName matches the input
        }
    }
    return displayName;  // Return null if no matching displayName is found
}
  
  

}
