import { Injectable } from '@angular/core';
import { ImportEntitiesActionTypes } from 'src/app/shared/types/types';
import { StoresManagerService } from '../storesManagerService/stores-manager.service';
import { PLANBOUNDARY_LANDUSE, BLUELINE_MAVAT_CODE, ENTITIES_TYPES, SCOPELINE_INDEX_OLD, TEMP_NAME, URBAN_S3_URL } from 'src/app/config';
import { ENTITY_ACCESS_TYPE, ENTITY_CATEGORIES, ENTITY_GEOMETRIC_TYPES } from 'src/app/enums/enums';
import type {  Feature, Geometry  } from 'geojson';
import { mavatUrbanDashboard } from 'src/app/enums/mavat-urban-dashboard';
import { take } from 'rxjs/operators';
import Utils from 'src/app/utils/utils';
import { AddUpdateDeleteEntitiesParams, EntityEntry } from 'src/app/models/entity-map';
import { IParentChildRelationship } from 'src/app/models/parent-child-relationship';
import { ProjectStateStoreService } from '../projectStateStore/project-state-store.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EntitiesService } from '../entities/entities.service';
import * as TurfCenter from '@turf/center';
import { landUseParams } from 'src/app/land-use-params';

@Injectable({
  providedIn: 'root'
})
export class ImportEntitiesService {

  constructor(
    private storesManagerService: StoresManagerService,
    private projectStateStoreService: ProjectStateStoreService,
    private nzMessageService: NzMessageService,
    private entitiesService: EntitiesService
  ) { }

  importEntities(
    features: any[], 
    options: {
      description: string, toSave: boolean, isOverrideScopeline: boolean, isGenerateDesign?: boolean,
      action: ImportEntitiesActionTypes
    },
    menuData,
    projectName,
    currentPlanName,
    toDrag: boolean = false,
    importedFrom?) {

    const handleEntity = (ent: Feature<Geometry, any>) => {
      if (ent.id !== SCOPELINE_INDEX_OLD) {
        // let shape = ent.properties && ent.properties.type || this.entitiesService.generateShapeFromGeometry(ent.geometry.type);
        let shape = ent.properties && ent.properties.type || ent.geometry.type;
        if (shape === ENTITY_GEOMETRIC_TYPES.CIRCLE) {
          shape = {type: ENTITY_GEOMETRIC_TYPES.CIRCLE, radius: ent.properties.radius};
        }
        let params;
        switch (options.action) {
          case 'building':
            params = {
              entityCategory: ENTITY_CATEGORIES.BUILDING,
              landUse: 'building',
              entityType: ENTITIES_TYPES.building.building
            }
            ent.properties.entityType = ENTITIES_TYPES.building.building;
            break;
          case 'road':
            params = {
              entityCategory: ENTITY_CATEGORIES.NETWORK,
              landUse: ENTITIES_TYPES.network.road,
              entityType: ENTITIES_TYPES.network.road,
              ...(ent.properties?.width && {
                landUseParams: { 'road.width': Utils.round(ent.properties.width, 2) }
              }) 
            }
            ent.properties.entityType = ENTITIES_TYPES.network.road;
            break;
          case 'junction':
            params = {
              entityCategory: ENTITY_CATEGORIES.JUNCTION,
              landUse: ent.properties?.junction_type || ENTITIES_TYPES.junction.junction_roads,
              entityType: ent.properties?.junction_type || ENTITIES_TYPES.junction.junction_roads,
            }
            ent.properties.entityType = ent.properties?.junction_type || ENTITIES_TYPES.junction.junction_roads;
            break;
          case 'mavat':
            // const style = {
            //   pattern: `${URBAN_S3_URL}/patterns/mavat_patterns/${mavatUrbanDashboard[ent.properties.landuseCode]?.pattern}` || ''
            // };
            const patternVal = mavatUrbanDashboard[ent.properties.landuseCode]?.pattern;
            const style = {
              pattern: patternVal ? `${URBAN_S3_URL}/patterns/mavat_patterns/${patternVal}` : ''
            };

            params = {
              // landUse: mavatCodeLandUse(ent.properties.landuseCode),
              accessType: mavatUrbanDashboard[ent.properties.landuseCode]?.accessType || ENTITY_ACCESS_TYPE.OTHER,
              landUse: mavatUrbanDashboard[ent.properties.landuseCode]?.urbanLandUse || this.getEntityTypeFromGeometry(ent),
              entityCategory: mavatUrbanDashboard[ent.properties.landuseCode]?.entityCategory || ENTITY_CATEGORIES.DEFAULT,
              entityType: mavatUrbanDashboard[ent.properties.landuseCode]?.urbanLandUse || this.getEntityTypeFromGeometry(ent),
              landuseCode: ent.properties.landuseCode,
              style: style
            };
            // params.style.fillColor = mavatUrbanDashboard[ent.properties.landuseCode].pattern;
            params.isMavatLanduseCode = true;
            break;
          case 'plan_boundary':

            this.projectStateStoreService.isBlueLine().pipe(
              take(1)
            ).subscribe(isBlueLine => {
              if (isBlueLine) {
                this.nzMessageService.error('Blue line rejected - plan already has a blue line');
                return;
              } else {
                if (ent.geometry.type !== ENTITY_GEOMETRIC_TYPES.POLYGON) {
                  this.nzMessageService.error('Blue line rejected - must be of type Polygon');
                  return;
                }
                ent.properties.landUse = PLANBOUNDARY_LANDUSE;
                ent.properties.entityCategory = ENTITY_CATEGORIES.GUIDELINE_ELEMENTS;
                ent.properties.name = 'Plan Boundary';
              }
            })
            break
          case 'urban_mavat':
            params = {
              accessType: mavatUrbanDashboard[ent.properties.landuseCode].accessType,
              landUse: mavatUrbanDashboard[ent.properties.landuseCode].urbanLandUse || this.getEntityTypeFromGeometry(ent),
              entityCategory: mavatUrbanDashboard[ent.properties.landuseCode]?.entityCategory || ENTITY_CATEGORIES.DEFAULT,
              entityType: mavatUrbanDashboard[ent.properties.landuseCode].urbanLandUse || this.getEntityTypeFromGeometry(ent),
              landuseCode: ent.properties.landuseCode || '',
              // description: mavatUrbanDashboard[ent.properties.landuseCode].mavatNameHebrew 
            };

            break;
          case 'default':
          default:
            params = {};
        }

        // importedEntities.push(this.stateService.createEntity(ent, {shape, params, isImport: true}));
        importedEntities.push(this.entitiesService.createEntity(ent, {shape, params, isImport: true}, menuData, projectName)); // !!!!

      } else if (ent.id === SCOPELINE_INDEX_OLD && options.isOverrideScopeline) {
        scopeline = this.entitiesService.createScopeline(ent);

      }
    };
    const mavatCodeLandUse = (landuseCode: string) => {
      // const parcelLandUses = this.stateService.landUses.parcel.landUse // !!!
      const parcelLandUses = menuData.parcel.landUse // !!!

      const mavat = Object.keys(parcelLandUses).find(landUse => parseInt(parcelLandUses[landUse].mavatCode) === parseInt(landuseCode));
      return mavat ? mavat : 'default';
    };

    if (!features || !features.length) {
      return;
    }

    let scopeline;
    let importedEntities: any[] = [];
    if (options.action === 'plan_boundary') {
      const featuresOfJson = features
      features = features.filter(feature => (!feature.properties.landuseCode && feature.properties.landUse === PLANBOUNDARY_LANDUSE) || (feature.properties.landuseCode && feature.properties.landuseCode === BLUELINE_MAVAT_CODE));
      if (features.length === 0 && importedFrom === 'geojson') {
        for (let i = 0; i < featuresOfJson.length; i++) {
          if ( featuresOfJson[i].properties.landUse && featuresOfJson[i].properties.landUse !== PLANBOUNDARY_LANDUSE) {
            featuresOfJson.splice(i, 1)
          }
        }
        features = featuresOfJson
      }
    }
    // removes blue line from the imported entities
    // } else {
    //   features = features.filter(feature => (!feature.properties.landuseCode && feature.properties.landUse !== PLANBOUNDARY_LANDUSE) || (feature.properties.landuseCode && feature.properties.landuseCode !== BLUELINE_MAVAT_CODE));
    // }
    features.forEach(ent => {
      if (ent.type && ent.type === 'Feature') {
        if (options.isGenerateDesign) {
          ent.properties.isGenerateDesign = options.isGenerateDesign;
        }
        handleEntity(ent);
      } else if ((ent.type && ent.type === 'FeatureCollection') && (ent.features && Array.isArray(ent.features))) {
        ent.features.forEach((en: Feature<Geometry, any>) => handleEntity(en));
      } else {
        console.warn(`Skipping entity ${ent.id || ent.properties.name} - invalid GeoJSON`);
      }
    });
    if (importedEntities.length === 0) {
      return;
    }


    let entitiesNames = [];
    importedEntities.forEach(ent => {
      const entityDescription = options.action === 'mavat' || options.action === 'urban_mavat' ? options.description + mavatUrbanDashboard[ent.properties.landuseCode]?.mavatNameHebrew : options.description  || ent.description || ''
      ent.properties.description = entityDescription;
      if (ent.properties.name && ent.properties.name !== TEMP_NAME) {
        ent.properties.originalName = ent.properties.name;
      }
      ent.properties.name = this.entitiesService.generateDefaultEntityName(ent, {namesList: entitiesNames, isUseExistingName: true, importType: options.action});
      entitiesNames.push(ent.properties.name);
      Utils.truncateGeoJson(ent);
    });
    importedEntities = this.entitiesService.uniqueIdValidator(importedEntities).map(ent => this.entitiesService.calculateArea(ent));
    const blueline = importedEntities.find(ent => ent.properties.landUse === PLANBOUNDARY_LANDUSE);
    if (blueline) {
      // this.stateService.planBluelineId = blueline.id;
    }

    const entitiesUpdateObject: AddUpdateDeleteEntitiesParams = {
      entitiesToAdd: {},
      entitiesToUpdate: {},
    };
  
    this.projectStateStoreService.getCurrentPlanEntities().pipe(
      take(1)
    ).subscribe(currentEntities => {
      const allEntitiesArray = Object.values(currentEntities).concat(importedEntities);
      let entityEntry: EntityEntry;
      importedEntities.forEach(ent => {
        // calculate parent child relationship for every building entity
        if (ent.properties.entityType === 'building') {
          const parentChildRelationship: IParentChildRelationship = this.entitiesService.handlePolygonWithinPolygon(ent, allEntitiesArray);
          entityEntry = {
            parentId: parentChildRelationship.parentId,
            entity: ent
          };
  
          if (currentEntities[parentChildRelationship.entityId]) {
            entitiesUpdateObject.entitiesToUpdate[parentChildRelationship.entityId] = entityEntry;
          } else {
            entitiesUpdateObject.entitiesToAdd[parentChildRelationship.entityId] = entityEntry;
          }
        } else {
          entityEntry = {
            entity: ent
          }
          entitiesUpdateObject.entitiesToAdd[ent.id] = entityEntry;
        }
      });
      if (options.toSave) {
        this.storesManagerService.addUpdateDeleteEntities(entitiesUpdateObject, currentPlanName).pipe(
          take(1)
        ).subscribe(() => {
          this.projectStateStoreService.nextRecenterCoordinates(feature.geometry.coordinates);
          if (toDrag) {
            this.storesManagerService.setSelectedEntities([importedEntities[0]], false, false);
            const entityToDragId = importedEntities[0].id;
            this.entitiesService.nextDuplicatedEntityToDrag(entityToDragId);
          }
          
        })
      }
    });

    if (options.action === 'plan_boundary') {
      // this.dashboardTypeChange$.next();
    }
    // @ts-ignore
    const feature: Feature<Point> = TurfCenter.default(importedEntities[0]);

  }

  getEntityTypeFromGeometry(entity: Feature<Geometry, any>) {
    switch (entity.geometry.type) {
      case ENTITY_GEOMETRIC_TYPES.POLYGON:
        return ENTITIES_TYPES.default.default_polygon;
      case ENTITY_GEOMETRIC_TYPES.LINE_STRING:
        return ENTITIES_TYPES.default.default_polyline;
      case ENTITY_GEOMETRIC_TYPES.POINT:
        return ENTITIES_TYPES.default.default_point;
      default:
        return ENTITIES_TYPES.default.default_polygon;
    }
  }
}
