import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss']
})
export class PopoverComponent implements OnInit,OnDestroy  {

  textDirection: 'ltr' | 'rtl' = 'ltr';
  textAlignment: 'left' | 'right' = 'left';
   componentDestroyed$: Subject<boolean> = new Subject();


  @Input() popoverPlacement: string = 'bottom';
  @Input() popoverContent: string;
  @Input() popoverTrigger: 'click' | 'focus' | 'hover' | null = 'hover';



  constructor(private translate : TranslateService) { }

 ngOnInit() {
    this.updateTextProperties(this.translate.currentLang || this.translate.getDefaultLang());

    this.translate.onLangChange.pipe(takeUntil(this.componentDestroyed$)).subscribe((event) => {
      this.updateTextProperties(event.lang);
    });
  }



  private updateTextProperties(lang: string): void {
    const isRtl = this.isRtlLanguage(lang);
    this.textDirection = isRtl ? 'rtl' : 'ltr';
    this.textAlignment = isRtl ? 'right' : 'left';
  }

  private isRtlLanguage(lang: string): boolean {
    const rtlLanguages = ['ar', 'he', 'fa', 'ur'];
    return rtlLanguages.includes(lang);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

}
