import { Injectable } from '@angular/core';
import type {  Feature, Geometry  } from 'geojson';
import { ENTITIES_TYPES } from 'src/app/config';
import { ENTITY_CATEGORIES } from 'src/app/enums/enums';
import { EntityMap } from 'src/app/models/entity-map';
import { EntityProperties } from 'src/app/models/entity-properties';
import { IStorey, StoreysList } from 'src/app/models/floor-range';
import { PlanConfig, PlanInterface } from 'src/app/models/plan-interface';
import Utils from 'src/app/utils/utils';

// interface BuildingGFACalculationResult {
//   residential: { GFA: number, floors: number },
//   office: { GFA: number, floors: number },
//   retail: { GFA: number, floors: number },
//   public: { GFA: number, floors: number },
//   parking: { GFA: number, floors: number },
//   hotel: { GFA: number, floors: number },
//   senior_housing: { GFA: number, floors: number },
//   other: { GFA: number, floors: number },
//   total_GFA: number
// }

// interface BuildingAreaCalculationResult {
//   residential: { service_area: number, net_area: number },
//   retail: { service_area: number, net_area: number },
//   office: { service_area: number, net_area: number },
//   public: { service_area: number, net_area: number },
//   hotel: { service_area: number, net_area: number },
//   parking: { service_area: number, net_area: number },
//   senior_housing: { service_area: number, net_area: number },
//   other: { service_area: number, net_area: number },
//   storeyGrossArea: number,
//   typical_storey_net_area: number,
//   net_area_total: number,
//   service_area_total: number
// }

interface CalculatedResidentialUnitsResult {
  num_of_units: number,
  num_of_unit_in_floor: number,
  unit_area_avg_calculated: number
}

interface BuildingAreaCalculationResultNew {
  residential: { gross: number, net: number, floors: number, service: number },
  office: { gross: number, net: number, floors: number, service: number },
  retail: { gross: number, net: number, floors: number, service: number },
  public: { gross: number, net: number, floors: number, service: number },
  hotel: { gross: number, net: number, floors: number, service: number },
  parking: { gross: number, net: number, floors: number, service: number },
  senior_housing: { gross: number, net: number, floors: number, service: number },
  other: { gross: number, net: number, floors: number, service: number },
  storeyGrossArea: number,
  typical_storey_net_area: number,
  net_area_total: number,
  service_area_total: number,
  total_units: number,
  unit_counts: { [key: string]: number },
  unit_area_avg: number,
  unit_size_counts: { [key: number]: number }
}

@Injectable({
  providedIn: 'root'
})
export class BuildingDataService {


  defaultLandUseParams = {
    'building.storey.num' : 10,
    'building.storey.ground.height' : 6,
    'building.storey.typical.height' : 4,
    'building.usage_per_storey': [{"start": 0, "end": 0,"usage": "retail"}, {"start": 1, "end": 9,"usage": "residential"}],
    'building.unit.floor_area.avg' : 100,
    'parcel.parking_ratio.residential' : 1,
    'parent_lot.area' : 1500,
    'building.balcony.floor_area.avg' : 12
  }


  _buildingUsageConfig = {
    "residential" : "residential",
    "retail" : "retail",
    "office" : "office",
    "public" : "public",
    "hotel" : "hotel",
    "parking" : "parking", 
    "other" : "building"
  }

  defaultLotProperties: { [key: string]: any } = {
    'lot.parking_standart.residential.per_unit': 1,
    'lot.parking_standart.office.per_area': 40,
    'lot.parking_standart.retail.per_area': 25,
    'lot.parking_standart.public.per_area': 40,
    'lot.parking_standart.hotel.per_area': 200,
    'lot.parking_standart.underground.area.gross.per_spot': 40,
    'lot.impervious_surface.ratio': 15,
    'lot.storage.floor_area.per_unit': 6,
    'lot.value.residential.per_area': 0,
    'lot.value.office.per_area': 0,
    'lot.value.retail.per_area': 0,
    'lot.value.hotel.per_area': 0,
    'lot.value.senior_housing.per_area': 0,
    'lot.balcony.floor_area.per_unit': 12,
    'lot.balcony.revenue_ratio': 50,
    'lot.vat': 18,
    'lot.revenue.user_defined' : 0,
    'lot.cost.residential.per_area': 0,
    'lot.cost.underground.per_area': 0,
    'lot.cost.reduction.office_vs_residential': -1000,
    'lot.cost.reduction.retail_vs_residential': -1000,
    'lot.cost.reduction.public_vs_residential': -1000,
    'lot.cost.reduction.balcony_vs_residential': 45,
    'lot.cost.above_ground_parking.per_area' : 3500,
    'lot.cost.demolition_per_m2': 500,
    'lot.cost.user_defined' : 0,
    'lot.cost.local_fees.per_area': 350,
    'lot.cost.electric.per_unit': 3000,
    'lot.cost.electric.commercial.per_area': 60,
    'lot.cost.purchase_tax': 6,
    'lot.cost.managment_oversight': 5,
    'lot.cost.legal': 1.5,
    'lot.cost.planning': 4,
    'lot.cost.marketing': 2.5,
    'lot.cost.contingency': 5,
    'lot.cost.funding_fees': 6,
    'lot.cost.duration.month': 42,
    'lot.cost.moving.per_unit': 5000,
    'lot.cost.legal.per_unit': 20000,
    'lot.cost.supervision': 10000,
    'lot.cost.management_fee': 450,
    'lot.cost.user_input': 0,
    'lot.current.unit.num': 0,
    'lot.current.retail.floor_area': 0,
    'lot.current.office.floor_area': 0,
    'lot.current.public.floor_area': 0,
    'lot.current.unit.floor_area.avg': 0,
    'lot.current.unit.floor_area.additional_area': 12,
    'lot.current.unit.rent.per_month': 3800,
    'lot.betterment.mamad.area_per_unit': 12,
    'lot.betterment.developer_profit_ratio': 15,
    'lot.betterment.new_to_existing_value_ratio.residential': 80,
    'lot.betterment.new_to_existing_value_ratio.retail_office': 80,
    'lot.household_size': 3.2,
    'lot.annual_percentage': 2,
    'lot.conversion_ratio': 2.7,
    'lot.annual_child_ratio': 2,
    'lot.valuation.retail':15, 
    'lot.valuation.office':100, 
    'lot.valuation.senior_housing': 15,
    'lot.valuation.hotel': 15,
    'lot.affordable_housing.minimum_lease_period': 20,
    'lot.revenue.underground_retail.ratio': 70,
    'lot.floor_area.retail.underground.gross': 0,
    'lot.parking_standart.above_ground.spots': 0,
    'lot.betterment.ratio': 50
  };



  constructor() { }


  calculateBuildingAndLotNew(currentPlanEntities: EntityMap, planConfig: PlanConfig, buildingEntity: Feature<Geometry, EntityProperties>, lotEntity: Feature<Geometry, EntityProperties>) {
    const storeysList = this.createStoreysList(buildingEntity);
    const storeysFeatureList = this.create3DStoreyFeatures(buildingEntity, storeysList);
    const serviceAreaMethod = buildingEntity.properties.landUseParams['building.service.area.method'];
    const serviceAreaRatio = (serviceAreaMethod === 'Auto' ? this.calculateServiceAreaRatio(buildingEntity) : buildingEntity.properties.landUseParams['building.service.area.ratio']) || 0;
    const calculateGFAandNetFloorArea = this.calculateGFAandNetFloorAreaNew(buildingEntity, storeysList, serviceAreaRatio)

    const buildingHeight = this.getBuildingHeightFromStoreyList(storeysList);

    // const calculatedGFA = this.calculateGFAperUsage(buildingEntity, storeysList);
    // const isMixUse = this.isMixUse(buildingEntity);
    // const serviceAreaRatio = this.calculateServiceAreaRatio(buildingEntity);
    // const calculatedNetFloorArea = this.calculateNetFloorArea(buildingEntity, serviceAreaRatio, calculatedGFA);
    // const calculatedResidentialUnits = this.calculateResidentialUnits(buildingEntity, serviceAreaRatio, storeysList);
    const calculatedBalconies = this.calculateBalconiesNew(buildingEntity, calculateGFAandNetFloorArea.total_units);

    const updatedBuildingEntity = Utils.clone(buildingEntity);


    // updatedBuildingEntity.properties.landUseParams['building.storey.typical.floor_area'] = calculateGFAandNetFloorArea.storeyGrossArea;
    updatedBuildingEntity.properties.landUseParams['building.height'] = buildingHeight;
    // units
    updatedBuildingEntity.properties.landUseParams['building.unit.num'] = calculateGFAandNetFloorArea.total_units;
    updatedBuildingEntity.properties.landUseParams['building.unit.count'] = calculateGFAandNetFloorArea.unit_counts;
    updatedBuildingEntity.properties.landUseParams['building.unit.size_count'] = calculateGFAandNetFloorArea.unit_size_counts;
    // updatedBuildingEntity.properties.landUseParams['building.unit.floor_area.avg'] = calculateGFAandNetFloorArea.unit_area_avg;
    updatedBuildingEntity.properties.landUseParams['building.unit.floor_area.avg_calculated'] = calculateGFAandNetFloorArea.unit_area_avg;

    // Gross Floor Area
    updatedBuildingEntity.properties.landUseParams['building.floor_area.residential.gross'] = Utils.round(calculateGFAandNetFloorArea.residential.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.office.gross'] = Utils.round(calculateGFAandNetFloorArea.office.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.retail.gross'] = Utils.round(calculateGFAandNetFloorArea.retail.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.public.gross'] = Utils.round(calculateGFAandNetFloorArea.public.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.hotel.gross'] = Utils.round(calculateGFAandNetFloorArea.hotel.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.parking.gross'] = Utils.round(calculateGFAandNetFloorArea.parking.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.senior_housing.gross'] = Utils.round(calculateGFAandNetFloorArea.senior_housing.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.other.gross'] = Utils.round(calculateGFAandNetFloorArea.other.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.gross'] = Utils.round(calculateGFAandNetFloorArea.storeyGrossArea, 2);
    // Gross Floor Area - Service)
    updatedBuildingEntity.properties.landUseParams['building.service.area.ratio'] = serviceAreaRatio;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.residential.service'] = Utils.round(calculateGFAandNetFloorArea.residential.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.office.service'] = Utils.round(calculateGFAandNetFloorArea.office.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.public.service'] = Utils.round(calculateGFAandNetFloorArea.public.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.retail.service'] = Utils.round(calculateGFAandNetFloorArea.retail.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.hotel.service'] = Utils.round(calculateGFAandNetFloorArea.hotel.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.parking.service'] = Utils.round(calculateGFAandNetFloorArea.parking.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.senior_housing.service'] = Utils.round(calculateGFAandNetFloorArea.senior_housing.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.other.service'] = Utils.round(calculateGFAandNetFloorArea.other.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.service'] = Utils.round(calculateGFAandNetFloorArea.service_area_total, 2);
    // Gross Floor Area - Net 
    updatedBuildingEntity.properties.landUseParams['building.floor_area.residential.net'] = Utils.round(calculateGFAandNetFloorArea.residential.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.office.net'] = Utils.round(calculateGFAandNetFloorArea.office.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.public.net'] = Utils.round(calculateGFAandNetFloorArea.public.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.retail.net'] = Utils.round(calculateGFAandNetFloorArea.retail.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.hotel.net'] = Utils.round(calculateGFAandNetFloorArea.hotel.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.parking.net'] = Utils.round( calculateGFAandNetFloorArea.parking.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.senior_housing.net'] = Utils.round( calculateGFAandNetFloorArea.senior_housing.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.other.net'] = Utils.round(calculateGFAandNetFloorArea.other.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.net'] = Utils.round(calculateGFAandNetFloorArea.net_area_total, 2)
    //Number of Stories
    updatedBuildingEntity.properties.landUseParams['building.storey.residential.num'] = calculateGFAandNetFloorArea.residential.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.office.num'] = calculateGFAandNetFloorArea.office.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.public.num'] = calculateGFAandNetFloorArea.public.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.retail.num'] = calculateGFAandNetFloorArea.retail.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.hotel.num'] = calculateGFAandNetFloorArea.hotel.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.parking.num'] = calculateGFAandNetFloorArea.parking.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.senior_housing.num'] = calculateGFAandNetFloorArea.senior_housing.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.other.num'] = calculateGFAandNetFloorArea.other.floors || 0;
    // updatedBuildingEntity.properties.landUseParams['building.storey.num'] = 
    // Balconies
    updatedBuildingEntity.properties.landUseParams['building.balcony.floor_area.avg'] = buildingEntity.properties.landUseParams['building.balcony.floor_area.avg'] || this.defaultLandUseParams['building.balcony.floor_area.avg']
    updatedBuildingEntity.properties.landUseParams['building.balcony.num'] = calculatedBalconies.balconies_num
    updatedBuildingEntity.properties.landUseParams['building.balcony.floor_area.gross'] = calculatedBalconies.balconies_floor_area_total

    // return updatedBuildingEntity
    let calculatedUpdatedLotEntity;
    if (lotEntity) {
    // update lot 
      const updatedLotEntity = Utils.clone(lotEntity);

      updatedLotEntity.properties.landUseParams['lot.area'] = lotEntity.properties.area.value * 1000;
      updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] = Utils.round(calculateGFAandNetFloorArea.storeyGrossArea, 2);
      updatedLotEntity.properties.landUseParams['lot.floor_area.residential.gross'] = Utils.round(calculateGFAandNetFloorArea.residential.gross, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.retail.gross'] = Utils.round(calculateGFAandNetFloorArea.retail.gross, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.public.gross'] = Utils.round(calculateGFAandNetFloorArea.public.gross, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.office.gross'] = Utils.round(calculateGFAandNetFloorArea.office.gross, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.gross'] = Utils.round(calculateGFAandNetFloorArea.hotel.gross, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.parking.gross'] = Utils.round(calculateGFAandNetFloorArea.parking.gross, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.gross'] = Utils.round(calculateGFAandNetFloorArea.senior_housing.gross, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.other.gross'] = Utils.round(calculateGFAandNetFloorArea.other.gross, 2) || 0;

      updatedLotEntity.properties.landUseParams['lot.floor_area.net'] = Utils.round(calculateGFAandNetFloorArea.net_area_total, 2);
      updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] = Utils.round(calculateGFAandNetFloorArea.residential.net, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.retail.net'] = Utils.round(calculateGFAandNetFloorArea.retail.net, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.public.net'] = Utils.round(calculateGFAandNetFloorArea.public.net, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.office.net'] = Utils.round(calculateGFAandNetFloorArea.office.net, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.net'] = Utils.round(calculateGFAandNetFloorArea.hotel.net, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.parking.net'] = Utils.round(calculateGFAandNetFloorArea.parking.net, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.net'] = Utils.round(calculateGFAandNetFloorArea.senior_housing.net, 2) || 0;

      updatedLotEntity.properties.landUseParams['lot.floor_area.service'] = Utils.round(calculateGFAandNetFloorArea.service_area_total, 2);
      updatedLotEntity.properties.landUseParams['lot.floor_area.residential.service'] = Utils.round(calculateGFAandNetFloorArea.residential.service, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.public.service'] = Utils.round(calculateGFAandNetFloorArea.public.service, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.office.service'] = Utils.round(calculateGFAandNetFloorArea.office.service, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.retail.service'] = Utils.round(calculateGFAandNetFloorArea.retail.service, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.service'] = Utils.round(calculateGFAandNetFloorArea.hotel.service, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.parking.service'] = Utils.round(calculateGFAandNetFloorArea.parking.service, 2) || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.service'] = Utils.round(calculateGFAandNetFloorArea.senior_housing.service, 2) || 0;

      updatedLotEntity.properties.landUseParams['lot.unit.num'] = calculateGFAandNetFloorArea.total_units;
      updatedLotEntity.properties.landUseParams['lot.building.height'] = buildingHeight;
      // updatedLotEntity.properties.landUseParams['lot.built_area.ground'] = calculateGFAandNetFloorArea.storeyGrossArea;
      updatedLotEntity.properties.landUseParams['lot.built_area.ground'] = buildingEntity.properties.area.value;
      updatedLotEntity.properties.landUseParams['lot.building.storey.num.max'] = buildingEntity.properties.landUseParams['building.storey.num'];

      calculatedUpdatedLotEntity = this.accumBuildingsAndCalcLot(updatedLotEntity, currentPlanEntities, planConfig, updatedBuildingEntity.id);
    }
    // const lotOtherChildren: string[] = this.findOtherChildrenById(planConfig, updatedLotEntity.id, updatedBuildingEntity.id);

    
    // if (lotOtherChildren.length > 0) {
    //   lotOtherChildren.forEach(id => {
    //     updatedLotEntity.properties.landUseParams['lot.built_area.ground'] += currentPlanEntities[id].properties.area.value;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.gross'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.residential.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.gross'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.retail.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.retail.gross'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.public.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.public.gross'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.office.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.office.gross'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.hotel.gross'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.parking.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.parking.gross'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.senior_housing.gross'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.other.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.other.gross'] || 0;

    //     // updatedLotEntity.properties.landUseParams['lot.floor_area.underground.gross'] += currentPlanEntities[id]['properties']['landUseParams']['building.floor_area.underground.gross'] || 0;

    //     updatedLotEntity.properties.landUseParams['lot.floor_area.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.net'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.net'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.retail.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.retail.net'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.public.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.public.net'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.office.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.office.net'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.hotel.net'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.parking.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.parking.net'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.senior_housing.net'] || 0;

    //     updatedLotEntity.properties.landUseParams['lot.floor_area.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.service'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.residential.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.service'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.retail.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.retail.service'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.public.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.public.service'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.office.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.office.service'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.hotel.service'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.parking.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.parking.service'] || 0;
    //     updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.senior_housing.service'] || 0;

    //     updatedLotEntity.properties.landUseParams['lot.unit.num'] += currentPlanEntities[id].properties.landUseParams['building.unit.num'] || 0;

    //     updatedLotEntity.properties.landUseParams['lot.building.height.max'] = Math.max(updatedLotEntity.properties.landUseParams['lot.building.height'] || 0, currentPlanEntities[id].properties.landUseParams['building.height'] || 0);
    //     updatedLotEntity.properties.landUseParams['lot.building.storey.num.max'] = Math.max(updatedLotEntity.properties.landUseParams['lot.building.storey.num.max'] || 0, currentPlanEntities[id].properties.landUseParams['building.storey.num'] || 0)
    //     // updatedLotEntity.properties.landUseParams['built_area.ground'] = calculatedNetFloorArea.typical_storey_net_area
    //   })
    // }
    // const calculatedFAR = updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] / updatedLotEntity.properties.landUseParams['lot.area'];
    // const coverageAreaRatioCalculated = updatedLotEntity.properties.landUseParams['lot.built_area.ground'] / updatedLotEntity.properties.landUseParams['lot.area'] * 100;
    // updatedLotEntity.properties.landUseParams['lot.far'] = calculatedFAR;
    // updatedLotEntity.properties.landUseParams['lot.far.calc'] = calculatedFAR;
    // updatedLotEntity.properties.landUseParams['lot.building_coverage.ratio'] = coverageAreaRatioCalculated;
    // updatedLotEntity.properties.landUseParams['lot.unit.floor_area.avg'] = updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] / updatedLotEntity.properties.landUseParams['lot.unit.num']

    // this.updateEntityProperties(updatedLotEntity, this.defaultLotProperties);

    // this.lotCalculateParking(updatedLotEntity);
    // this.calculateAffordableHousingRevenue(updatedLotEntity);
    // this.lotCalculateRevenue(updatedLotEntity);
    // this.lotCalculateDirectCosts(updatedLotEntity);
    // this.lotCalculateInDirectCosts(updatedLotEntity);
    // this.lotCalculateTenantsCosts(updatedLotEntity);
    // this.lotCalculateFinancingCost(updatedLotEntity);
    // this.lotCalculateBettermentLevy(updatedLotEntity);
    // this.lotCalculatePurchaseTax(updatedLotEntity);
    // this.lotCalculateProfit(updatedLotEntity);
    // this.lotCalculateProgram(updatedLotEntity);


    return {
      result: {
        lot: calculatedUpdatedLotEntity, 
        building: updatedBuildingEntity,
        floors: storeysFeatureList
      }
    };

  }

  reCalculateLot(lotEntity) {

    const updatedLotEntity = Utils.clone(lotEntity);

    this.updateEntityProperties(updatedLotEntity, this.defaultLotProperties);

    this.lotCalculateParking(updatedLotEntity);
    this.calculateAffordableHousingRevenue(updatedLotEntity);
    this.lotCalculateRevenue(updatedLotEntity);
    this.lotCalculateDirectCosts(updatedLotEntity);
    this.lotCalculateInDirectCosts(updatedLotEntity);
    this.lotCalculateTenantsCosts(updatedLotEntity);
    this.lotCalculateFinancingCost(updatedLotEntity);
    this.lotCalculateBettermentLevy(updatedLotEntity);
    this.lotCalculatePurchaseTax(updatedLotEntity);
    this.lotCalculateProfit(updatedLotEntity);
    this.lotCalculateProgram(updatedLotEntity);

    return updatedLotEntity;
  }

  resetAndCalculateLot(lotEntity: Feature<Geometry, EntityProperties> , currentPlan: PlanInterface, currentPlanEntities: EntityMap, previousBuildingId?: string | number): Feature<Geometry, EntityProperties> {

    // return this.accumBuildingsAndCalcLot(lotEntity, currentPlanEntities, currentPlan.planConfig, previousBuildingId);
    const updatedLotEntity = Utils.clone(lotEntity)
    let lotChildrenIds: string[] = this.findOtherChildrenById(currentPlan.planConfig, lotEntity.id);

    // If a previousBuildingId was provided, remove it from the children list
    if (previousBuildingId) {
      lotChildrenIds = lotChildrenIds.filter(id => id !== previousBuildingId);
    }

    if (lotChildrenIds.length > 0) {
      // updatedLotEntity.properties.landUseParams = {};
      updatedLotEntity.properties.landUseParams = this.initializeLandUseParams(updatedLotEntity.properties.landUseParams);
      updatedLotEntity.properties.landUseParams['lot.area'] = lotEntity.properties.area.value * 1000;

      lotChildrenIds.forEach(id => {
        updatedLotEntity.properties.landUseParams['lot.built_area.ground'] += currentPlanEntities[id].properties.area.value;
        updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.retail.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.retail.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.public.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.public.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.office.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.office.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.hotel.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.parking.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.parking.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.senior_housing.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.other.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.other.gross'] || 0;

        updatedLotEntity.properties.landUseParams['lot.floor_area.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.retail.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.retail.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.public.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.public.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.office.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.office.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.hotel.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.parking.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.parking.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.senior_housing.net'] || 0;

        updatedLotEntity.properties.landUseParams['lot.floor_area.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.retail.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.retail.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.public.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.public.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.office.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.office.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.hotel.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.parking.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.parking.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.senior_housing.service'] || 0;
        // updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.net'] || 0;
        // updatedLotEntity.properties.landUseParams['lot.floor_area.residential.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.service'] || 0;

        updatedLotEntity.properties.landUseParams['lot.unit.num'] += currentPlanEntities[id].properties.landUseParams['building.unit.num'] || 0;

        updatedLotEntity.properties.landUseParams['lot.building.height.max'] = Math.max(updatedLotEntity.properties.landUseParams['lot.building.height'] || 0, currentPlanEntities[id].properties.landUseParams['building.height'] || 0);
        updatedLotEntity.properties.landUseParams['lot.building.storey.num.max'] = Math.max(updatedLotEntity.properties.landUseParams['lot.building.storey.num.max'] || 0, currentPlanEntities[id].properties.landUseParams['building.storey.num'] || 0)
      })

      const calculatedFAR = updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] / updatedLotEntity.properties.landUseParams['lot.area'];
      const coverageAreaRatioCalculated = updatedLotEntity.properties.landUseParams['lot.built_area.ground'] / updatedLotEntity.properties.landUseParams['lot.area'] * 100;
      updatedLotEntity.properties.landUseParams['lot.far'] = calculatedFAR;
      updatedLotEntity.properties.landUseParams['lot.far.calc'] = calculatedFAR;
      updatedLotEntity.properties.landUseParams['lot.building_coverage.ratio'] = coverageAreaRatioCalculated;
      updatedLotEntity.properties.landUseParams['lot.unit.floor_area.avg'] = updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] / updatedLotEntity.properties.landUseParams['lot.unit.num']

      this.updateEntityProperties(updatedLotEntity, this.defaultLotProperties);

      this.lotCalculateParking(updatedLotEntity);
      this.calculateAffordableHousingRevenue(updatedLotEntity);
      this.lotCalculateRevenue(updatedLotEntity);
      this.lotCalculateDirectCosts(updatedLotEntity);
      this.lotCalculateInDirectCosts(updatedLotEntity);
      this.lotCalculateTenantsCosts(updatedLotEntity);
      this.lotCalculateFinancingCost(updatedLotEntity);
      this.lotCalculateBettermentLevy(updatedLotEntity);
      this.lotCalculatePurchaseTax(updatedLotEntity);
      this.lotCalculateProfit(updatedLotEntity);
      this.lotCalculateProgram(updatedLotEntity);
    } else {
      updatedLotEntity.properties.landUseParams = this.initializeLandUseParams(updatedLotEntity.properties.landUseParams);
      updatedLotEntity.properties.landUseParams['lot.area'] = lotEntity.properties.area.value * 1000;
    }
    

    return updatedLotEntity;
  }

  private accumBuildingsAndCalcLot(
    lotEntity: Feature<Geometry, EntityProperties>,
    currentPlanEntities: EntityMap,
    planConfig: any,
    excludeBuildingId?: string | number
  ): Feature<Geometry, EntityProperties> {
    // 1. Clone the lot so we don't mutate the original
    const updatedLotEntity = Utils.clone(lotEntity);
  
    // 2. Re-initialize / reset the lot.landUseParams if needed
    // updatedLotEntity.properties.landUseParams = this.initializeLandUseParams(
    //   updatedLotEntity.properties.landUseParams
    // );
  
    // 3. Find all children (buildings) of this lot except the one to exclude (if any)
    let lotChildrenIds: string[] = this.findOtherChildrenById(planConfig, updatedLotEntity.id, excludeBuildingId);
  
    // 4. Sum each child building's areas/floors/units into the lot
    lotChildrenIds.forEach((childId) => {
      const child = currentPlanEntities[childId];
      if (!child?.properties?.landUseParams) return;
  
      updatedLotEntity.properties.landUseParams['lot.built_area.ground'] += child.properties.area.value;
      updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] += child.properties.landUseParams['building.floor_area.gross'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.residential.gross'] += child.properties.landUseParams['building.floor_area.residential.gross'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.retail.gross'] += child.properties.landUseParams['building.floor_area.retail.gross'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.public.gross'] += child.properties.landUseParams['building.floor_area.public.gross'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.office.gross'] += child.properties.landUseParams['building.floor_area.office.gross'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.gross'] += child.properties.landUseParams['building.floor_area.hotel.gross'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.parking.gross'] += child.properties.landUseParams['building.floor_area.parking.gross'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.gross'] += child.properties.landUseParams['building.floor_area.senior_housing.gross'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.other.gross'] += child.properties.landUseParams['building.floor_area.other.gross'] || 0;
      
      updatedLotEntity.properties.landUseParams['lot.floor_area.net'] += child.properties.landUseParams['building.floor_area.net'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] += child.properties.landUseParams['building.floor_area.residential.net'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.retail.net'] += child.properties.landUseParams['building.floor_area.retail.net'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.public.net'] += child.properties.landUseParams['building.floor_area.public.net'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.office.net'] += child.properties.landUseParams['building.floor_area.office.net'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.net'] += child.properties.landUseParams['building.floor_area.hotel.net'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.parking.net'] += child.properties.landUseParams['building.floor_area.parking.net'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.net'] += child.properties.landUseParams['building.floor_area.senior_housing.net'] || 0;
  
      updatedLotEntity.properties.landUseParams['lot.floor_area.service'] += child.properties.landUseParams['building.floor_area.service'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.residential.service'] += child.properties.landUseParams['building.floor_area.residential.service'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.retail.service'] += child.properties.landUseParams['building.floor_area.retail.service'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.public.service'] += child.properties.landUseParams['building.floor_area.public.service'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.office.service'] += child.properties.landUseParams['building.floor_area.office.service'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.service'] += child.properties.landUseParams['building.floor_area.hotel.service'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.parking.service'] += child.properties.landUseParams['building.floor_area.parking.service'] || 0;
      updatedLotEntity.properties.landUseParams['lot.floor_area.senior_housing.service'] += child.properties.landUseParams['building.floor_area.senior_housing.service'] || 0;
  
      updatedLotEntity.properties.landUseParams['lot.unit.num'] += child.properties.landUseParams['building.unit.num'] || 0;
      
      updatedLotEntity.properties.landUseParams['lot.building.height.max'] = Math.max(
        updatedLotEntity.properties.landUseParams['lot.building.height.max'] || 0, 
        child.properties.landUseParams['building.height'] || 0
      );
      updatedLotEntity.properties.landUseParams['lot.building.storey.num.max'] = Math.max(
        updatedLotEntity.properties.landUseParams['lot.building.storey.num.max'] || 0, 
        child.properties.landUseParams['building.storey.num'] || 0
      );
    });
  
    // 5. Compute FAR, coverage, etc.
    updatedLotEntity.properties.landUseParams['lot.far'] = 
      updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] / 
      updatedLotEntity.properties.landUseParams['lot.area'];
  
    updatedLotEntity.properties.landUseParams['lot.far.calc'] = 
      updatedLotEntity.properties.landUseParams['lot.far'];
  
    updatedLotEntity.properties.landUseParams['lot.building_coverage.ratio'] = 
      (updatedLotEntity.properties.landUseParams['lot.built_area.ground'] /
        updatedLotEntity.properties.landUseParams['lot.area']) * 100;
  
    const lotUnits = updatedLotEntity.properties.landUseParams['lot.unit.num'];
    const resNet = updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'];
    updatedLotEntity.properties.landUseParams['lot.unit.floor_area.avg'] = lotUnits > 0 
      ? resNet / lotUnits 
      : 0;
  
    // 6. Now run the standard lot calculations
    this.updateEntityProperties(updatedLotEntity, this.defaultLotProperties);
    this.lotCalculateParking(updatedLotEntity);
    this.calculateAffordableHousingRevenue(updatedLotEntity);
    this.lotCalculateRevenue(updatedLotEntity);
    this.lotCalculateDirectCosts(updatedLotEntity);
    this.lotCalculateInDirectCosts(updatedLotEntity);
    this.lotCalculateTenantsCosts(updatedLotEntity);
    this.lotCalculateFinancingCost(updatedLotEntity);
    this.lotCalculateBettermentLevy(updatedLotEntity);
    this.lotCalculatePurchaseTax(updatedLotEntity);
    this.lotCalculateProfit(updatedLotEntity);
    this.lotCalculateProgram(updatedLotEntity);

  
    // 7. Return the updated lot
    return updatedLotEntity;
  }
  

  initializeLandUseParams(params) {
    const defaultParams = {
      'lot.built_area.ground': 0,
      'lot.floor_area.gross': 0,
      'lot.floor_area.residential.gross': 0,
      'lot.floor_area.retail.gross': 0,
      'lot.floor_area.public.gross': 0,
      'lot.floor_area.office.gross': 0,
      'lot.floor_area.hotel.gross': 0,
      'lot.floor_area.parking.gross': 0,
      'lot.floor_area.senior_housing.gross': 0,
      'lot.floor_area.other.gross': 0,
      'lot.floor_area.net': 0,
      'lot.floor_area.service': 0,
      'lot.floor_area.residential.net': 0,
      'lot.floor_area.residential.service': 0,
      'lot.unit.num': 0,
      'lot.building.height.max': 0,

      "lot.area": 0,
      "lot.building.storey.num.max": 0,
      "lot.far": 0,
      "lot.far.calc": 0,
      "lot.building_coverage.ratio": 0,
      "lot.current.unit.rent.per_month": 0,
      "lot.parking.num.parking_floor": 0,
      "lot.parking.num": 0,
      "lot.floor_area.underground.gross": 0,
      "lot.storey.underground.num": 0,
      "lot.cost.underground": 0,
      "lot.parking.num.residential": 0,
      "lot.parking.num.office": 0,
      "lot.parking.num.retail": 0,
      "lot.parking.num.public": 0,
      "lot.parking.num.hotel": 0,
      "lot.parking.num.senior_housing": 0,
      "lot.revenue": 0,
      "lot.revenue.residential": 0,
      "lot.revenue.office": 0,
      "lot.revenue.retail": 0,
      "lot.revenue.hotel": 0,
      "lot.revenue.senior_housing": 0,
      "lot.cost.direct": 0,
      "lot.cost.betterment_levy.25%": 0,
      "lot.cost.betterment_levy.50%": 0,
      "lot.cost.betterment_levy.75%": 0,
      "lot.cost.betterment_levy.user": 0,
      "lot.cost.direct.residential": 0,
      "lot.cost.indirect": 0,
      "lot.cost.current_residents": 0,
      "lot.cost.financing": 0,
      "lot.cost.total": 0,
      "lot.cost.purchase_tax.0betterment.result": 0,
      "lot.cost.0betterment.total": 0,
      "lot.cost.purchase_tax.25betterment.result": 0,
      "lot.cost.25betterment.total": 0,
      "lot.cost.purchase_tax.50betterment.result": 0,
      "lot.cost.50betterment.total": 0,
      "lot.cost.purchase_tax.75betterment.result": 0,
      "lot.cost.75betterment.total": 0,
      "lot.cost.purchase_tax.userBetterment.result": 0,
      "lot.cost.userBetterment.total": 0,
      "lot.profit.betterment_0%": 0,
      "lot.profit_on_cost_ratio.betterment_0%": 0,
      "lot.profit.betterment_25%": 0,
      "lot.profit_on_cost_ratio.betterment_25%": 0,
      "lot.profit.betterment_50%": 0,
      "lot.profit_on_cost_ratio.betterment_50%": 0,
      "lot.profit.betterment_75%": 0,
      "lot.profit_on_cost_ratio.betterment_75%": 0,
      "lot.profit.bettermentUser": 0,
      "lot.profit_on_cost_ratio.bettermentUser%": 0,
      "lot.public_needs.population": 0,
      "lot.public_needs.anual_children": 0,
      "lot.public_needs.public_area_needed": 0,
      "lot.public_needs.green_area_needed": 0,
      "lot.public_needs.daycare.classes": 0,
      "lot.public_needs.daycare.area": 0,
      "lot.public_needs.kindergarten.classes": 0,
      "lot.public_needs.kindergarten.area": 0,
      "lot.public_needs.elementary.classes": 0,
      "lot.public_needs.elementary.area": 0,
      "lot.floor_area.retail.net": 0,
      "lot.floor_area.public.net": 0,
      "lot.floor_area.office.net": 0,
      "lot.floor_area.hotel.net": 0,
      "lot.floor_area.parking.net": 0,
      "lot.floor_area.senior_housing.net": 0,
      "lot.floor_area.public.service": 0,
      "lot.floor_area.office.service": 0,
      "lot.floor_area.retail.service": 0,
      "lot.floor_area.hotel.service": 0,
      "lot.floor_area.parking.service": 0,
      "lot.floor_area.senior_housing.service": 0,
      "lot.building.height": 0,
      "lot.unit.floor_area.avg": 0,
      "lot.affordable_housing.rental_revenue_ratio": 0,
      "lot.affordable_housing.rental_units_num": 0,
      "lot.land_value": 0,
      "lot.betterment.ratio": 50
    };
  
    return Object.assign({}, params, defaultParams);
  }

  findOtherChildrenById(planConfig, parentId: string | number, excludeBuildingId?: string | number): string[] {
    return Object.keys(planConfig).filter(id => planConfig[id].parentId === parentId && id !== excludeBuildingId);
  }

  updateEntityProperties(entity: Feature,  defaultProperties: { [key: string]: any }): Feature {
    // Iterating over the default properties
    for (const key in defaultProperties) {
      // If the property doesn't exist or it is null or undefined, update it
      if (
        !entity.properties.landUseParams.hasOwnProperty(key) ||
        entity.properties.landUseParams[key] === null ||
        entity.properties.landUseParams[key] === undefined
      ) {
        entity.properties.landUseParams[key] = defaultProperties[key];
      }
    }

    return entity;
  }

  // new method !!!
  createStoreysList(building: Feature<Geometry, EntityProperties>): StoreysList {
    let storeysList: StoreysList = [];
    let landUseParams = building.properties.landUseParams;

    let storey_num = 0;
    while (storey_num < landUseParams['building.storey.num']) {
        let storey_dict: IStorey = {
            storey_number: storey_num,
            base_height: 0,
            top_height: 0,
            landUse: 'residential',
            floor_area: building.properties.area.value
        };

        let storeyInfo = landUseParams['building.usage_per_storey'].find((range: any) =>
            storey_num >= range.start && storey_num <= range.end
        );

        // Calculate storey top and base height
        if (storey_num === 0) {
            const baseHeight = landUseParams['building.storey.ground.base_height'] ?? 0;
            const topFirstHeight = baseHeight + (storeyInfo?.height ?? landUseParams['building.storey.ground.height']);
            storey_dict.base_height = baseHeight;
            storey_dict.top_height = topFirstHeight;
        } else {
            let previousStorey = storeysList[storeysList.length - 1];
            storey_dict.base_height = previousStorey.top_height;
            storey_dict.top_height = previousStorey.top_height + (storeyInfo?.height ?? landUseParams['building.storey.typical.height']);
        }

        // Round heights to 1 decimal place
        storey_dict.base_height = parseFloat(storey_dict.base_height.toFixed(1));
        storey_dict.top_height = parseFloat(storey_dict.top_height.toFixed(1));

        // Define land use type
        storey_dict.landUse = storeyInfo?.primaryUsage ?? storeyInfo?.usage?.toLowerCase() ?? 'residential';

        // Add area
        storey_dict.floor_area = storeyInfo?.area ?? building.properties.area.value;

        // Add mainUsageList and serviceUsageList if they exist
        if (storeyInfo?.mainUsageList) {
            storey_dict.mainUsageList = storeyInfo.mainUsageList;
        }
        if (storeyInfo?.serviceUsageList) {
            storey_dict.serviceUsageList = storeyInfo.serviceUsageList;
        }

        // Add geometry if it exists
        if (storeyInfo?.geometry) {
          storey_dict.geometry = storeyInfo.geometry;
        }

        storeysList.push(storey_dict);
        storey_num++;
    }

    return storeysList;
  }


  create3DStoreyFeatures(building: Feature<Geometry, EntityProperties>, storeysList: StoreysList): Feature<Geometry, EntityProperties>[] {
    let storeyFeaturesList = [];

    for (let storey_dict of storeysList) {
        let storeyGeometry = storey_dict.geometry ? storey_dict.geometry : building.geometry;

        let storeyFeature = {
            type: 'Feature',
            geometry: storeyGeometry,
            properties: {
                id: Date.now(),
                storey_number: storey_dict.storey_number,
                heightProperties: {
                    height: storey_dict.top_height,
                    base_height: storey_dict.base_height
                },
                landUse: storey_dict.landUse,
                floor_area: storey_dict.floor_area,
                entityCategory: ENTITY_CATEGORIES.BUILDING,
                entityType: ENTITIES_TYPES.building.floor,
                // isHide: building.properties.isHide,
                // name: building.properties.name,
                // description: building.properties.description,
                // entityVersion: building.properties.entityVersion,
                // landUseParams: building.properties.landUseParams,
                // projectName: building.properties.projectName,
                // isLock: building.properties.isLock,
                // date: building.properties.date,
                // area: building.properties.area,
            }
        };

        storeyFeaturesList.push(storeyFeature);
    }

    return storeyFeaturesList;
  }


  calculateGFAandNetFloorAreaNew(building: Feature<Geometry, EntityProperties>, storeysList: StoreysList, serviceAreaRatio: number): BuildingAreaCalculationResultNew {
    let usageAreas: { [key: string]: { gross: number, net: number, floors: number, service: number } } = {};
    let totalUnits = 0;
    let unitCounts: { [key: string]: number } = {};
    let unitAreaSum = 0;
    let totalGrossArea = 0;
    let unitSizeCounts: { [key: number]: number } = {};
  
    for (let storey of storeysList) {
      const mainUsages = storey.mainUsageList || [];
      const serviceUsages = storey.serviceUsageList || [];
      const primaryUsage = storey.landUse.toLowerCase();
  
      if (!usageAreas[primaryUsage]) {
        usageAreas[primaryUsage] = { gross: 0, net: 0, floors: 0, service: 0 };
      }
  
      let netArea = 0;
      let serviceArea = 0;
  
      totalGrossArea += storey.floor_area;
  
      if (mainUsages.length > 0) {
        for (let usage of mainUsages) {
          const usageType = usage.usageType.toLowerCase();
          if (!usageAreas[usageType]) {
            usageAreas[usageType] = { gross: 0, net: 0, floors: 0, service: 0 };
          }
          usageAreas[usageType].net += usage.area;
          netArea += usage.area;
  
          // Count units
          if (usage.unitsList && usage.unitsList.length > 0) {
            for (let unit of usage.unitsList) {
              const unitType = unit.unit_type.toLowerCase();
              if (!unitCounts[unitType]) {
                unitCounts[unitType] = 0;
              }
              unitCounts[unitType] += unit.count || 1;
              totalUnits += unit.count || 1;
              unitAreaSum += unit.unit_size;
  
              // Count units by size
              const unitSize = unit.unit_size;
              if (!unitSizeCounts[unitSize]) {
                unitSizeCounts[unitSize] = 0;
              }
              unitSizeCounts[unitSize] += unit.count || 1;
            }
          }
        }
      } else {
        // netArea = storey.floor_area * (1 - serviceAreaRatio / 100);
        netArea = storey.floor_area * (100 / (100 + serviceAreaRatio));
        usageAreas[primaryUsage].net += netArea;
      }
  
      if (serviceUsages.length > 0) {
        for (let usage of serviceUsages) {
          const serviceForUsage = usage.serviceForUsage.toLowerCase();
          if (!usageAreas[serviceForUsage]) {
            usageAreas[serviceForUsage] = { gross: 0, net: 0, floors: 0, service: 0 };
          }
          usageAreas[serviceForUsage].service += usage.area;
          serviceArea += usage.area;
        }
      } else {
        // serviceArea = storey.floor_area * serviceAreaRatio / 100;
        serviceArea = storey.floor_area - netArea;
        usageAreas[primaryUsage].service += serviceArea;
      }
  
      // Ensure each floor is counted only once for its primary usage
      usageAreas[primaryUsage].floors += 1;
  
      // Count default units if no units are specified
      if (primaryUsage === 'residential' && mainUsages.length === 0) {
        let netFloorArea = netArea;
        let avgUnitArea = building.properties.landUseParams['building.unit.floor_area.avg'];
        let numUnitsInFloor = Math.round(netFloorArea / avgUnitArea);
        const calculatedAvgUnitArea = Math.round(netFloorArea / numUnitsInFloor);
        unitCounts['apartment'] = (unitCounts['apartment'] || 0) + numUnitsInFloor;
        totalUnits += numUnitsInFloor;
        unitAreaSum += netFloorArea;
  
        // Count default units by size
        if (!unitSizeCounts[calculatedAvgUnitArea]) {
          unitSizeCounts[calculatedAvgUnitArea] = 0;
        }
        unitSizeCounts[calculatedAvgUnitArea] += numUnitsInFloor;
      }
    }
  
    // After processing all storeys, set gross = net + service for each usage type
    for (let usageType in usageAreas) {
      usageAreas[usageType].gross = usageAreas[usageType].net + usageAreas[usageType].service;
    }
  
    const result: BuildingAreaCalculationResultNew = {
      residential: this.extractAreaData(usageAreas['residential']),
      office: this.extractAreaData(usageAreas['office']),
      retail: this.extractAreaData(usageAreas['retail']),
      public: this.extractAreaData(usageAreas['public']),
      hotel: this.extractAreaData(usageAreas['hotel']),
      parking: this.extractAreaData(usageAreas['parking']),
      senior_housing: this.extractAreaData(usageAreas['senior_housing']),
      other: this.extractAreaData(usageAreas['other']),
      storeyGrossArea: totalGrossArea,
      // typical_storey_net_area: (totalGrossArea / storeysList.length) * (1 - serviceAreaRatio / 100),
      typical_storey_net_area: (totalGrossArea / storeysList.length) * ( 100 / (100 + serviceAreaRatio )),
      net_area_total: 0,
      service_area_total: 0,
      total_units: totalUnits,
      unit_counts: unitCounts,
      unit_area_avg: totalUnits > 0 ? unitAreaSum / totalUnits : 0,
      unit_size_counts: unitSizeCounts
    };
  
    result.net_area_total = Object.values(usageAreas).reduce((sum, area) => sum + (area?.net || 0), 0);
    result.service_area_total = Object.values(usageAreas).reduce((sum, area) => sum + (area?.service || 0), 0);
  
    return result;
  }
  
  

  extractAreaData(areaData: { gross: number, net: number, floors: number, service: number } | undefined) {
    if (!areaData) {
        return { gross: 0, net: 0, floors: 0, service: 0 };
    }
    return {
        gross: areaData.gross,
        net: areaData.net,
        floors: areaData.floors,
        service: areaData.service
    };
  }


  calculateBuildingHeight(building: Feature<Geometry, EntityProperties>): number {
    let landUseParams = building.properties.landUseParams;
    let max_height = (landUseParams['building.storey.num'] - 1) * landUseParams['building.storey.typical.height'] + landUseParams['building.storey.ground.height'];
    return max_height;
  }

  getBuildingHeightFromStoreyList(storeysList: StoreysList) {
    if(storeysList[storeysList.length - 1]){
      return storeysList[storeysList.length - 1].top_height || 0;
    }
    return 0;
    
  }


  isMixUse(building: Feature<Geometry, EntityProperties>): boolean {
    // Initialize floor counts
    let residential_num_of_floors = 0;
    let retail_num_of_floors = 0;
    let office_num_of_floors = 0;
    let public_num_of_floors = 0;
    let hotel_num_of_floors = 0;
    let parking_num_of_floors = 0;
    let senior_housing_num_of_floors = 0;
    let other_num_of_floors = 0;

    // Calculate floor counts from usage_per_storey data
    const usage_per_storey = building.properties.landUseParams['building.usage_per_storey'];

    for (let usage of usage_per_storey) {
        const floors = usage.end - usage.start + 1;
        const usageType = usage.primaryUsage?.toLowerCase() || usage.usage?.toLowerCase();
        switch (usageType) {
            case 'residential':
                residential_num_of_floors += floors;
                break;
            case 'retail':
                retail_num_of_floors += floors;
                break;
            case 'office':
                office_num_of_floors += floors;
                break;
            case 'public':
                public_num_of_floors += floors;
                break;
            case 'hotel':
                hotel_num_of_floors += floors;
                break;
            case 'parking':
                parking_num_of_floors += floors;
                break;
            case 'senior_housing':
                senior_housing_num_of_floors += floors;
                break;
            default:
                other_num_of_floors += floors;
                break;
        }
    }

    // Determine ground floor usage
    const ground_floor_usage = usage_per_storey.find(i => i.start === 0)?.primaryUsage?.toLowerCase() || 
                               usage_per_storey.find(i => i.start === 0)?.usage?.toLowerCase();

    // Count number of usages in building
    const usageCounts = {
        residential: residential_num_of_floors,
        retail: retail_num_of_floors,
        office: office_num_of_floors,
        public: public_num_of_floors,
        hotel: hotel_num_of_floors,
        parking: parking_num_of_floors,
        senior_housing: senior_housing_num_of_floors,
        other: other_num_of_floors,
    };

    const num_of_usages_in_building = Object.keys(usageCounts)
        .filter(usage => usageCounts[usage] > 0 && 
            (usageCounts[usage] > 1 || ground_floor_usage !== usage))
        .length;

    // Determine if building is mixed use
    return num_of_usages_in_building > 1;
}


  calculateServiceAreaRatio(building: Feature<Geometry, EntityProperties>): number {
    const buildingStoreyNum = building.properties.landUseParams['building.storey.num'];
    const isMixUse = this.isMixUse(building);
    
    switch (true) {
      case (buildingStoreyNum <= 2):
        return 0;
  
      case (buildingStoreyNum >= 3 && buildingStoreyNum <= 5):
        return isMixUse ? 25 : 15;
      
      case (buildingStoreyNum >= 6 && buildingStoreyNum <= 9):
        return isMixUse ? 30 : 20;
      
      case (buildingStoreyNum >= 10 && buildingStoreyNum <= 20):
        return isMixUse ? 40 : 25;
  
      case (buildingStoreyNum >= 21):
        return isMixUse ? 45 : 30;
  
      default:
        return 0;
    }
  }


  calculateResidentialUnits(building: Feature<Geometry, EntityProperties>, serviceAreaRatio: number, storeysList): CalculatedResidentialUnitsResult {
    let num_of_units = 0;
    let num_of_unit_in_floor = 0;
    let unit_area_avg_calculated = 0;
    

    storeysList.forEach(storey => {
      if (storey.landUse === this._buildingUsageConfig.residential) {
        let net_floor_area = storey.floor_area / ((100 + serviceAreaRatio) / 100);
        num_of_unit_in_floor = Math.round(net_floor_area / building.properties.landUseParams['building.unit.floor_area.avg']);
        unit_area_avg_calculated = Math.round(net_floor_area / num_of_unit_in_floor);
        num_of_units += num_of_unit_in_floor;
      }
    });

    return {
      num_of_units,
      num_of_unit_in_floor,
      unit_area_avg_calculated
    };
  }

  calculateBalconies(building: Feature<Geometry, EntityProperties>, residentialUnits: CalculatedResidentialUnitsResult): {balconies_num: number, balconies_floor_area_total: number} {
    
    let num_of_units = residentialUnits.num_of_units;
    
    let balcony_avg_floor_area = building.properties.landUseParams['building.balcony.floor_area.avg'] || this.defaultLandUseParams['building.balcony.floor_area.avg'];
    let balconies_num = num_of_units;
    let balconies_floor_area_total = balconies_num * balcony_avg_floor_area;

    return {balconies_num, balconies_floor_area_total};
  }

  calculateBalconiesNew(building: Feature<Geometry, EntityProperties>, residentialUnits: number = 0): {balconies_num: number, balconies_floor_area_total: number} {
    
    let num_of_units = residentialUnits;
    
    let balcony_avg_floor_area = building.properties.landUseParams['building.balcony.floor_area.avg'] || this.defaultLandUseParams['building.balcony.floor_area.avg'];
    let balconies_num = num_of_units;
    let balconies_floor_area_total = balconies_num * balcony_avg_floor_area;

    return {balconies_num, balconies_floor_area_total};
  }

  lotCalculateParking(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const marketableLotParams = marketableLotEntity.properties.landUseParams;

    const parkingStandardResidential = marketableLotParams['lot.parking_standart.residential.per_unit'];
    const parkingStandardOffice = marketableLotParams['lot.parking_standart.office.per_area'];
    const parkingStandardRetail = marketableLotParams['lot.parking_standart.retail.per_area'];
    const parkingStandardPublic = marketableLotParams['lot.parking_standart.public.per_area'];
    const parkingStandardHotel = marketableLotParams['lot.parking_standart.hotel.per_area'];
    const parkingStandardSenior_Housing = marketableLotParams["lot.parking_standart.senior_housing.per_area"];
    const parkingSpotGrossAreaUg = marketableLotParams['lot.parking_standart.underground.area.gross.per_spot'];
    const lotParkingGFA = marketableLotParams['lot.floor_area.parking.gross'];
    const lotNumOfUnits = marketableLotParams['lot.unit.num'];
    const lotRetailGFA = marketableLotParams['lot.floor_area.retail.gross'];
    const lotOfficeGFA = marketableLotParams['lot.floor_area.office.gross'];
    const lotPublicGFA = marketableLotParams['lot.floor_area.public.gross'];
    const lotHotelGFA = marketableLotParams['lot.floor_area.hotel.gross'];
    const lotSeniorHousingGFA = marketableLotParams['lot.floor_area.senior_housing.gross'];

    const lotArea = marketableLotParams['lot.area'];
    const lotImperviousSurfaceRatio = marketableLotParams['lot.impervious_surface.ratio'];
    const lotStorageFGAPerUnit = marketableLotParams['lot.storage.floor_area.per_unit'];
    const lotCostUndergroundPerArea = marketableLotParams['lot.cost.underground.per_area'];
    const vat = marketableLotParams['lot.vat'];

    const lotRetailUndergroundGross = marketableLotParams['lot.floor_area.retail.underground.gross'];
    const lotAboveGroundParkingSpots = marketableLotParams['lot.parking_standart.above_ground.spots'];

    const numOfParkingSpotsResidential = Math.ceil(parkingStandardResidential * lotNumOfUnits);

    const numOfParkingSpotsOffice = Math.ceil(lotOfficeGFA / parkingStandardOffice);
    const numOfParkingSpotsRetail = Math.ceil(lotRetailGFA / parkingStandardRetail);
    const numOfParkingSpotsPublic = Math.ceil(lotPublicGFA / parkingStandardPublic);
    const numOfParkingSpotsHotel = Math.ceil(lotHotelGFA / parkingStandardHotel);
    const numOfParkingSpotsSeniorHousing = Math.ceil(lotSeniorHousingGFA / parkingStandardSenior_Housing);

    const numOfParkingSpotsInLot = numOfParkingSpotsResidential + numOfParkingSpotsOffice + numOfParkingSpotsRetail + numOfParkingSpotsPublic + numOfParkingSpotsHotel + numOfParkingSpotsSeniorHousing - lotAboveGroundParkingSpots;
    const GFA_NeededForParking = numOfParkingSpotsInLot * parkingSpotGrossAreaUg;
    const GFA_NeededForStorage = lotNumOfUnits * lotStorageFGAPerUnit;
    const GFA_NeededUndergroundTotal = GFA_NeededForParking + GFA_NeededForStorage + lotRetailUndergroundGross;
    const availableAreaUnderground = lotArea * (100 - lotImperviousSurfaceRatio) / 100;
    const numOfUndergroundStories = Math.ceil(GFA_NeededUndergroundTotal / availableAreaUnderground);
    const lotCostUndergroundNoVat = ((numOfParkingSpotsResidential + numOfParkingSpotsOffice + numOfParkingSpotsRetail + numOfParkingSpotsHotel + numOfParkingSpotsSeniorHousing) * parkingSpotGrossAreaUg + GFA_NeededForStorage) * lotCostUndergroundPerArea;
    const lotCostUndergroundWithVat = numOfParkingSpotsPublic * parkingSpotGrossAreaUg * lotCostUndergroundPerArea * (100 + vat) / 100;
    const lotCostUnderground = lotCostUndergroundNoVat + lotCostUndergroundWithVat;
    marketableLotEntity.properties.landUseParams['lot.parking.num.parking_floor'] = Math.floor(lotParkingGFA / parkingSpotGrossAreaUg);

    marketableLotEntity.properties.landUseParams['lot.parking.num'] = Math.floor(numOfParkingSpotsInLot + marketableLotEntity.properties.landUseParams['lot.parking.num.parking_floor']);
    marketableLotEntity.properties.landUseParams['lot.floor_area.underground.gross'] = GFA_NeededUndergroundTotal;
    marketableLotEntity.properties.landUseParams['lot.storey.underground.num'] = numOfUndergroundStories;
    marketableLotEntity.properties.landUseParams['lot.cost.underground'] = lotCostUnderground;

    marketableLotEntity.properties.landUseParams['lot.parking.num.residential'] = numOfParkingSpotsResidential;
    marketableLotEntity.properties.landUseParams['lot.parking.num.office'] = numOfParkingSpotsOffice;
    marketableLotEntity.properties.landUseParams['lot.parking.num.retail'] = numOfParkingSpotsRetail;
    marketableLotEntity.properties.landUseParams['lot.parking.num.public'] = numOfParkingSpotsPublic;
    marketableLotEntity.properties.landUseParams['lot.parking.num.hotel'] = numOfParkingSpotsHotel;
    marketableLotEntity.properties.landUseParams['lot.parking.num.senior_housing'] = numOfParkingSpotsSeniorHousing;

    marketableLotEntity.properties.landUseParams['lot.floor_area.underground.parking'] = GFA_NeededForParking; //for piechart
    marketableLotEntity.properties.landUseParams['lot.floor_area.underground.storage'] = GFA_NeededForStorage; //for piechart


    return marketableLotEntity;
  }


  public calculateAffordableHousingRevenue(
    marketableLotEntity: Feature<Geometry, EntityProperties>
  ): Feature<Geometry, EntityProperties> {
    const marketableLotParams = marketableLotEntity.properties.landUseParams;

    // 1. Extract parameters from landUseParams
    const unitAvgArea = marketableLotParams["lot.unit.floor_area.avg"] || 0;
    const lotValueResidential = marketableLotParams["lot.value.residential.per_area"] || 0;
    const newToExistingValueRatio  = marketableLotParams["lot.betterment.new_to_existing_value_ratio.residential"] || 0;
    const residentialYield  = marketableLotParams["lot.cost.residential_yield"] || 0;
    const unitNum = marketableLotParams["lot.unit.num"] || 0;
    const affordableHousingRatio  = marketableLotParams["lot.affordable_housing.ratio"] || 0;
    const affordableHousingDiscount  = marketableLotParams["lot.affordable_housing.discount"] || 0;
    const affordableHousingDiscountRate = marketableLotParams["lot.affordable_housing.discount_rate"] || 0;
    const occupancyRateFreeMarketApts = marketableLotParams["lot.affordable_housing.occupancy_rate_free_market_apts"] || 0;
    const maintenanceManagementCosts  = marketableLotParams["lot.affordable_housing.maintenance_management_costs"] || 0;
    const revenueFromMaintenanceFees  = marketableLotParams["lot.affordable_housing.revenue_from_maintenance_fees"] || 0;
    const annualRenovationFund  = marketableLotParams["lot.affordable_housing.annual_renovation_fund"] || 0;
    const annualCapitalizationRate  = marketableLotParams["lot.affordable_housing.annual_capitalization_rate"] || 0;
    const minimumLeasePeriod = marketableLotParams["lot.affordable_housing.minimum_lease_period"] || 0;
    const annualApartmentValueAppreciation  = marketableLotParams["lot.affordable_housing.annual_apartment_value_appreciation"] || 0;
    const isPerpetualLease = marketableLotParams["lot.affordable_housing.perpetual_lease_period"] || false;

    // 2. Core calculations
    //    a) New/Old unit values & rents
    const newUnitValue = unitAvgArea * lotValueResidential;
    const oldUnitValue = newUnitValue * (newToExistingValueRatio / 100);
    const rentPriceNewUnit = (newUnitValue * (residentialYield / 100)) / 12;
    const rentPriceOldUnit = (oldUnitValue * (residentialYield / 100)) / 12;

    //    b) Number of rental units, discount units
    const rentalUnitsNum = Math.round(unitNum * (affordableHousingRatio / 100));
    const discountUnitsNum = Math.round(rentalUnitsNum * (affordableHousingDiscount / 100));

    //    c) Yearly incomes
    const YearlyIncomeDiscountUnits =
      rentPriceNewUnit * ((100 - affordableHousingDiscountRate) / 100) * 12;
    const YearlyIncomeFreeMarketUnits =
      rentPriceNewUnit * (occupancyRateFreeMarketApts / 100) * 12;

    //    d) Housekeeping subsidy
    const houseKeepingSubsidy =
      ((maintenanceManagementCosts - revenueFromMaintenanceFees) * 12) * -1;

    //    e) Total affordable housing revenue
    const totalAffordableHousingRevenue =
      (YearlyIncomeDiscountUnits * discountUnitsNum)
      + (YearlyIncomeFreeMarketUnits * (rentalUnitsNum - discountUnitsNum))
      + (houseKeepingSubsidy * discountUnitsNum)
      - annualRenovationFund;

    //    f) Perpetual lease revenue
    const perpetualLeaseAffordableHousingRevenue =
      (annualCapitalizationRate !== 0)
        ? totalAffordableHousingRevenue / (annualCapitalizationRate / 100)
        : 0;

    // 3. Present value calculation
    let presentValue = 0;
    if (annualCapitalizationRate !== 0) {
      // Use Math.pow for exponentiation, note that '^' in TS is bitwise XOR
      const rate = annualCapitalizationRate / 100;
      presentValue = this.calculatePresentValue(rate, minimumLeasePeriod, totalAffordableHousingRevenue);
      // const discountFactor = (1 - Math.pow(1 + rate, -minimumLeasePeriod)) / rate;
      // presentValue = -totalAffordableHousingRevenue * discountFactor * 10000;
      // presentValue = this.presentValue(totalAffordableHousingRevenue, minimumLeasePeriod, rate)
    } else {
      // If capitalization rate = 0, simple multiplication
      presentValue = totalAffordableHousingRevenue * minimumLeasePeriod;
    }

    // 4. Future Value & discounted future revenue
    const futureValue = newUnitValue * Math.pow(
      1 + (annualApartmentValueAppreciation / 100),
      minimumLeasePeriod
    );
    const affordableHousingFutureRevenueAfterSell = futureValue * rentalUnitsNum;

    // Here we interpret that the discounting should be
    //  (affordableHousingFutureRevenueAfterSell) / (1 + (annualCapitalizationRate/100))^(minimumLeasePeriod)
    // Adjust if needed to match your exact formula
    let affordable_housing_future_revenue = 0;
    if (annualCapitalizationRate !== 0) {
      const discount = Math.pow(1 + annualCapitalizationRate / 100, minimumLeasePeriod);
      affordable_housing_future_revenue = affordableHousingFutureRevenueAfterSell / discount;
    } else {
      // If cap rate is zero, no discounting
      affordable_housing_future_revenue = affordableHousingFutureRevenueAfterSell;
    }

    const totalRevenueSellAndRent = presentValue + affordable_housing_future_revenue;

    // 5. Ratios
    const combinedUnitValue = newUnitValue * rentalUnitsNum;
    const rentalRevenueRatio =
      (combinedUnitValue !== 0)
        ? (totalRevenueSellAndRent / combinedUnitValue) * 100
        : 0;

    const perpetualRentalRatio =
      (combinedUnitValue !== 0)
        ? (perpetualLeaseAffordableHousingRevenue / combinedUnitValue) * 100
        : 0;

    // 6. Decide which ratio to store (based on perpetual lease)
    if (!isPerpetualLease) {
      marketableLotParams["lot.affordable_housing.rental_revenue_ratio"] = rentalRevenueRatio;
    } else {
      marketableLotParams["lot.affordable_housing.rental_revenue_ratio"] = perpetualRentalRatio;
    }

    // 7. Update monthly rent for old units & store number of rental units
    marketableLotParams["lot.current.unit.rent.per_month"] = rentPriceOldUnit;
    marketableLotParams["lot.affordable_housing.rental_units_num"] = rentalUnitsNum;

    return marketableLotEntity;
  }


  lotCalculateRevenue(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const valuePerM2Residential = lotDictParams['lot.value.residential.per_area'];
    const valuePerM2Office = lotDictParams['lot.value.office.per_area'];
    const valuePerM2Retail = lotDictParams['lot.value.retail.per_area'];
    const valuePerM2Hotel = lotDictParams['lot.value.hotel.per_area'];
    const valuePerM2SeniorHousing = lotDictParams['lot.value.senior_housing.per_area'];
    const balconiesArea = lotDictParams['lot.balcony.floor_area.per_unit'];
    const revenueFromBalconies = lotDictParams['lot.balcony.revenue_ratio'];
    const vat = lotDictParams['lot.vat'];
    const residentialNetArea = lotDictParams['lot.floor_area.residential.net'];
    const officeGFA = lotDictParams['lot.floor_area.office.gross'];
    const retailGFA = lotDictParams['lot.floor_area.retail.gross'];
    const hotelGFA = lotDictParams['lot.floor_area.hotel.gross'];
    const lotNumOfUnits = lotDictParams['lot.unit.num'];
    const numOfExistingUnits = lotDictParams['lot.current.unit.num'];
    const avgExistingUnitSize = lotDictParams['lot.current.unit.floor_area.avg'];
    const additionalAreaForExistingUnit = lotDictParams['lot.current.unit.floor_area.additional_area'];
    const additionalRevenueUserDefined = lotDictParams['lot.revenue.user_defined'];
    const currnetOfficeFloorArea = lotDictParams['lot.current.office.floor_area'];
    const currnetRetailFloorArea = lotDictParams['lot.current.retail.floor_area'];
    const currnetRetailFloorAreaAdditionalArea = lotDictParams['lot.current.retail.floor_area.additional_area'];
    const rentalRevenueRatio = lotDictParams['lot.affordable_housing.rental_revenue_ratio'];
    const affordableHousingRatio  = lotDictParams["lot.affordable_housing.ratio"];
    const valuationRetail  = lotDictParams["lot.valuation.retail"];
    const valuationOffice  = lotDictParams["lot.valuation.office"];
    const valuationSeniorHousing  = lotDictParams["lot.valuation.senior_housing"];
    const valuationHotel  = lotDictParams["lot.valuation.hotel"];
    const retailNet  = lotDictParams["lot.floor_area.retail.net"];
    const officeNet  = lotDictParams["lot.floor_area.office.net"];
    const hotelNet  = lotDictParams["lot.floor_area.hotel.net"];
    const seniorHousingNet  = lotDictParams["lot.floor_area.senior_housing.net"];
    const numOfParkingSpotsOffice = lotDictParams["lot.parking.num.office"];
    const parkingForSale = lotDictParams["lot.parking_standart.for_sale"];
    const valueParkingSpot = lotDictParams["lot.value.parking.per_spot"];
    const revenueUserDefine1 = lotDictParams["lot.revenue.user_defined_1"]?? 0;
    const revenueUserDefine2 = lotDictParams["lot.revenue.user_defined_2"]?? 0;

    const lotRetailUndergroundGross = lotDictParams['lot.floor_area.retail.underground.gross'];
    const revenueUndergroundRetailRatio = lotDictParams['lot.revenue.underground_retail.ratio'];

    const agreementType = lotDictParams['lot.agreement_type'];

    let lotCombinationRatio;
    let landCost;
    let bettermentRatio;
    if (agreementType === 'pinuy_binuy') {
      lotCombinationRatio = 0;
      landCost = 0;
      bettermentRatio = lotDictParams['lot.betterment.ratio'] || this.defaultLotProperties['lot.betterment.ratio'];
    } else if (agreementType === 'land_purchase') {
      lotCombinationRatio = 0;
      landCost = lotDictParams['lot.cost.land_cost'];
      bettermentRatio =  0;
    } else if (agreementType === 'combination') {
      lotCombinationRatio = lotDictParams['lot.combination_ratio'];
      landCost = 0;
      bettermentRatio = 0;
    }


    const residentialAreaForSell = residentialNetArea - (numOfExistingUnits * (avgExistingUnitSize + additionalAreaForExistingUnit));
    const balconiesAreaForSell = (lotNumOfUnits - numOfExistingUnits) * balconiesArea;
    const totalResAreaForRevenue = (balconiesAreaForSell * revenueFromBalconies / 100) + residentialAreaForSell;
    const revenueFromResidentialIncludeVAT = totalResAreaForRevenue * valuePerM2Residential
    const revenueFromResidentialNoVAT = revenueFromResidentialIncludeVAT / ((100 + vat) / 100);
    const revenueFromResidentialSellUnitsNoVAT = revenueFromResidentialNoVAT * (100-affordableHousingRatio)/100;

    const revenueFromResidentialRentalIncludeVAT = (affordableHousingRatio === 0) ? 0 : revenueFromResidentialIncludeVAT * (affordableHousingRatio / 100) * (rentalRevenueRatio / 100);

    const officeRevenue = ( officeNet * ( 100 + valuationOffice ) / 100 - currnetOfficeFloorArea ) * valuePerM2Office;
    const retailRevenue = (( retailNet * ( 100 + valuationRetail ) / 100 - ( currnetRetailFloorArea * ( 100 + currnetRetailFloorAreaAdditionalArea ) / 100)) * valuePerM2Retail) + (lotRetailUndergroundGross * (revenueUndergroundRetailRatio / 100) * valuePerM2Retail); //
    const hotelRevenue = hotelNet * ( 100 + valuationHotel ) / 100 * valuePerM2Hotel;
    const seniorHousingRevenue = seniorHousingNet * ( 100 + valuationSeniorHousing ) / 100 * valuePerM2SeniorHousing;
    const parkingForSaleRevenue = ( numOfParkingSpotsOffice + parkingForSale ) * valueParkingSpot
    const lotTotalRevenue = revenueFromResidentialSellUnitsNoVAT + revenueFromResidentialRentalIncludeVAT + officeRevenue + retailRevenue + hotelRevenue + seniorHousingRevenue + parkingForSaleRevenue + revenueUserDefine1 + revenueUserDefine2;
    const lotTotalRevenueCombination = lotTotalRevenue * (100 - lotCombinationRatio) / 100;
    const lotTotalResidentialRevenue = revenueFromResidentialSellUnitsNoVAT + revenueFromResidentialRentalIncludeVAT;

    const balconiesAreaForRevenue = (lotNumOfUnits - numOfExistingUnits) * balconiesArea * revenueFromBalconies / 100;
    const existingUnitsArea = numOfExistingUnits * (avgExistingUnitSize + additionalAreaForExistingUnit);
    const residentialAreaForRevenueCalc = residentialNetArea + balconiesAreaForRevenue - existingUnitsArea;
    // const revenueFromResidentialIncludeVAT = residentialAreaForRevenueCalc * valuePerM2Residential;
    // const revenueFromResidentialNoVAT = revenueFromResidentialIncludeVAT / ((100 + vat) / 100);

    // const officeRevenue = (officeGFA - currnetOfficeFloorArea) * valuePerM2Office;
    // const retailRevenue = (retailGFA - (currnetRetailFloorArea * (100 + currnetRetailFloorAreaAdditionalArea) / 100)) * valuePerM2Retail; // check if correct
    // const hotelRevenue = hotelGFA * valuePerM2Hotel;
    // const lotTotalRevenue = revenueFromResidentialNoVAT + officeRevenue + retailRevenue + hotelRevenue + additionalRevenueUserDefined;

    marketableLotEntity.properties.landUseParams['lot.revenue'] = lotTotalRevenue;
    marketableLotEntity.properties.landUseParams['lot.revenue.combination'] = lotTotalRevenueCombination;
    marketableLotEntity.properties.landUseParams['lot.revenue.residential'] = lotTotalResidentialRevenue;
    marketableLotEntity.properties.landUseParams['lot.revenue.office'] = officeRevenue;
    marketableLotEntity.properties.landUseParams['lot.revenue.retail'] = retailRevenue;
    marketableLotEntity.properties.landUseParams['lot.revenue.hotel'] = hotelRevenue;
    marketableLotEntity.properties.landUseParams['lot.revenue.senior_housing'] = seniorHousingRevenue;
    marketableLotEntity.properties.landUseParams['lot.betterment.ratio'] = bettermentRatio;
    marketableLotEntity.properties.landUseParams['lot.cost.land_cost'] = landCost;
    marketableLotEntity.properties.landUseParams['lot.combination_ratio'] = lotCombinationRatio;

    return marketableLotEntity;
  }

  lotCalculateDirectCosts(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const costPerM2Residential = lotDictParams['lot.cost.residential.per_area'];
    const costPerM2OfficeReduction = lotDictParams['lot.cost.reduction.office_vs_residential'];
    const costPerM2RetailReduction = lotDictParams['lot.cost.reduction.retail_vs_residential'];
    const costPerM2PublicReduction = lotDictParams['lot.cost.reduction.public_vs_residential'];
    const costPerM2BalconyReduction = lotDictParams['lot.cost.reduction.balcony_vs_residential'];
    const costPerM2AboveGroundParking = lotDictParams['lot.cost.above_ground_parking.per_area'];
    const lotResidentialGFA = lotDictParams['lot.floor_area.residential.gross'];
    const lotRetailGFA = lotDictParams['lot.floor_area.retail.gross'];
    const lotOfficeGFA = lotDictParams['lot.floor_area.office.gross'];
    const lotPublicGFA = lotDictParams['lot.floor_area.public.gross'];
    const lotHotelGFA = lotDictParams['lot.floor_area.hotel.gross'];
    const lotSeniorHousingGFA = lotDictParams['lot.floor_area.senior_housing.gross'];
    const lotParkingGFA = lotDictParams['lot.floor_area.parking.gross'];
    const lotNumOfUnits = lotDictParams['lot.unit.num'];
    const balconiesArea = lotDictParams['lot.balcony.floor_area.per_unit'];
    const vat = lotDictParams['lot.vat'];
    const lotCostUnderground = lotDictParams['lot.cost.underground'];
    const lotCostDemolitionPerM2 = lotDictParams['lot.cost.demolition_per_m2'];
    const lotCurrentUnitNum = lotDictParams['lot.current.unit.num'];
    const existingUnitAvgArea = lotDictParams['lot.current.unit.floor_area.avg'];
    const lotCurrentRetailArea = lotDictParams['lot.current.retail.floor_area'];
    const lotCurrentOfficeArea = lotDictParams['lot.current.office.floor_area'];
    const lotCurrentPublicArea = lotDictParams['lot.current.public.floor_area'];
    // const lotCostUserDefined = lotDictParams['lot.cost.user_defined'];
    const lotCostUndergroundPerArea = lotDictParams['lot.cost.underground.per_area'];
    const publicParkingSpots = lotDictParams['lot.parking.num.public']
    const parkingSpotGrossAreaUg = lotDictParams['lot.parking_standart.underground.area.gross.per_spot'];
    const lotArea = lotDictParams['lot.area'] * 100
    const coverageAreaRatio = lotDictParams['lot.building_coverage.ratio'];
    const costLandscapeDevelopment = lotDictParams["lot.cost.landscape_development"]

    const lotRetailUndergroundGross = lotDictParams['lot.floor_area.retail.underground.gross'];

    const costResidential = costPerM2Residential * lotResidentialGFA;
    const costBalconies = lotNumOfUnits * balconiesArea * costPerM2Residential * (costPerM2BalconyReduction / 100);
    const costOffice = (costPerM2Residential + costPerM2OfficeReduction) * lotOfficeGFA;
    const costRetail = (costPerM2Residential + costPerM2RetailReduction) * ( lotRetailGFA + lotRetailUndergroundGross );
    const costHotel = (costPerM2Residential * lotHotelGFA);
    const costSeniorHousing = (costPerM2Residential * lotSeniorHousingGFA);
    const costAboveGroundParking = costPerM2AboveGroundParking * lotParkingGFA;
    const costPublic = (costPerM2Residential + costPerM2PublicReduction) * lotPublicGFA * ((100 + vat) / 100);
    const costVatPublicParking = publicParkingSpots * parkingSpotGrossAreaUg * lotCostUndergroundPerArea * vat / 100;
    const costLandDevelopment = (lotArea / 100) * (100 - coverageAreaRatio ) / 100 * costLandscapeDevelopment;
    const costDemolition = (lotCurrentUnitNum * existingUnitAvgArea * 1.15 + lotCurrentRetailArea + lotCurrentOfficeArea + lotCurrentPublicArea) * lotCostDemolitionPerM2;
    const directCostTotal = costResidential + costBalconies + costOffice + costRetail + costPublic + costHotel + costAboveGroundParking + lotCostUnderground + costDemolition + costSeniorHousing + costVatPublicParking + costLandDevelopment;

    marketableLotEntity.properties.landUseParams['lot.cost.direct'] = Math.floor(directCostTotal);
    marketableLotEntity.properties.landUseParams['lot.cost.direct.residential'] = Math.floor( costResidential + costBalconies );

    return marketableLotEntity;
  }

  lotCalculateInDirectCosts(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const localFeesPerM2 = lotDictParams['lot.cost.local_fees.per_area'];
    const electricResidential = lotDictParams['lot.cost.electric.per_unit'];
    const electricCommercial = lotDictParams['lot.cost.electric.commercial.per_area'];
    const managmentOversight = lotDictParams['lot.cost.managment_oversight'];
    const legal = lotDictParams['lot.cost.legal'];
    const marketing = lotDictParams['lot.cost.marketing'];
    const planning = lotDictParams['lot.cost.planning'];
    const contingency = lotDictParams['lot.cost.contingency'];
    const lotAboveGroundGFA = lotDictParams['lot.floor_area.gross'];
    const lotUndergroundGFA = lotDictParams['lot.floor_area.underground.gross'];
    const existingUnitNum = lotDictParams['lot.current.unit.num'];
    const existingUnitAvgArea = lotDictParams['lot.current.unit.floor_area.avg'];
    const currnetOfficeFloorArea = lotDictParams['lot.current.office.floor_area'];
    const currnetRetailFloorArea = lotDictParams['lot.current.retail.floor_area'];
    const lotNumOfUnits = lotDictParams['lot.unit.num'];
    const lotRetailGFA = lotDictParams['lot.floor_area.retail.gross'];
    const lotOfficeGFA = lotDictParams['lot.floor_area.office.gross'];
    const lotHotelGFA = lotDictParams['lot.floor_area.hotel.gross'];
    const lotPublicGFA = lotDictParams['lot.floor_area.public.gross'];
    const totalDirectCosts = lotDictParams['lot.cost.direct'];
    const lotTotalRevenue = lotDictParams['lot.revenue'];
    const affordableHousingRatio = lotDictParams["lot.affordable_housing.ratio"];
    const costResidential = lotDictParams['lot.cost.direct.residential'];
    const vat = lotDictParams['lot.vat'];
    // const costUserDefined = lotDictParams['lot.cost.user_defined'];
    const costUserDefined1 = lotDictParams["lot.cost.user_input_1"];   
    const costUserDefined2 = lotDictParams["lot.cost.user_input_2"]; 

    const localFeesCost = Math.floor((lotAboveGroundGFA + lotUndergroundGFA - (existingUnitNum * existingUnitAvgArea + currnetOfficeFloorArea + currnetRetailFloorArea)) * localFeesPerM2);
    const electricityConnectionCost = Math.floor((lotNumOfUnits * electricResidential) + ((lotRetailGFA + lotOfficeGFA+ lotPublicGFA + lotHotelGFA) * electricCommercial));
    const marketingCost = Math.floor(lotTotalRevenue * (marketing) / 100);
    const planningCost = Math.floor(totalDirectCosts * (planning / 100));
    const managmentOversightCost = Math.floor(totalDirectCosts * (managmentOversight / 100));
    const legalCost = Math.floor(lotTotalRevenue * legal / 100);
    const contingencyCost = Math.floor(totalDirectCosts * contingency / 100);
    const costVatAffordableHousing = affordableHousingRatio / 100 * (costResidential + ( lotNumOfUnits * electricResidential ) + marketingCost + planningCost + managmentOversightCost + legalCost + contingencyCost) * vat / 100
    const totalIndirectCosts = localFeesCost + electricityConnectionCost + managmentOversightCost + legalCost + marketingCost + planningCost + contingencyCost + costVatAffordableHousing + costUserDefined1 + costUserDefined2;

    marketableLotEntity.properties.landUseParams['lot.cost.indirect'] = Math.floor(totalIndirectCosts);

    return marketableLotEntity;
  }

  lotCalculateTenantsCosts(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const rentPerMonth = lotDictParams['lot.current.unit.rent.per_month'];
    const durationMonths = lotDictParams['lot.cost.duration.month'];
    const movingPerUnit = lotDictParams['lot.cost.moving.per_unit'];
    const legalPerUnit = lotDictParams['lot.cost.legal.per_unit'];
    let supervisionCost = lotDictParams['lot.cost.supervision'];
    const managementFee = lotDictParams['lot.cost.management_fee'];
    const existingUnitNum = lotDictParams['lot.current.unit.num'];
    const lotCurrentRetailArea = lotDictParams['lot.current.retail.floor_area'];
    const lotCurrentOfficeArea = lotDictParams['lot.current.office.floor_area'];
    const lotValueRetail = lotDictParams['lot.value.retail.per_area'];
    const lotValueOffice = lotDictParams['lot.value.office.per_area'];
    const costUserInput = lotDictParams['lot.cost.user_input'];
    const infill_development = lotDictParams["lot.current.infill_development"];

    const currentAreas = existingUnitNum + lotCurrentOfficeArea + lotCurrentRetailArea

    const rentResidentialCost =  (infill_development) ? 0 : Math.floor(existingUnitNum * rentPerMonth * ( durationMonths + 2));
    const movingCost = (infill_development) ? Math.floor(existingUnitNum * movingPerUnit * 2) * 0.5 : Math.floor(existingUnitNum * movingPerUnit * 2);
    const rentRetailCost = (infill_development) ? 0 : lotCurrentRetailArea * ( lotValueRetail * 0.0727 / 12) * ( durationMonths + 2);
    const rentOfficeCost = (infill_development) ? 0 : lotCurrentOfficeArea * ( lotValueOffice * 0.0727 / 12 ) * (durationMonths + 2);
    const legalCost = Math.floor(existingUnitNum * legalPerUnit);
    supervisionCost = (currentAreas > 0) ? Math.floor(durationMonths * supervisionCost) : 0;
    const managementCost = Math.floor(existingUnitNum * managementFee * 12*10*0.5);

    const totalTenantsCosts = rentResidentialCost + movingCost + rentRetailCost + rentOfficeCost + legalCost + supervisionCost + managementCost + costUserInput;

    marketableLotEntity.properties.landUseParams['lot.cost.current_residents'] = Math.floor(totalTenantsCosts);

    return marketableLotEntity;
  }

  lotCalculateFinancingCost(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const lotDirectCosts = lotDictParams['lot.cost.direct'];
    const lotIndirectCosts = lotDictParams['lot.cost.indirect'];
    const lotTenantsCosts = lotDictParams['lot.cost.current_residents'];
    const fundingFees = lotDictParams['lot.cost.funding_fees'];
    const landCost = lotDictParams['lot.cost.land_cost'];

    const totalFinancingCosts = Math.floor((lotDirectCosts + lotIndirectCosts + lotTenantsCosts) * fundingFees / 100);
    const totalCosts = Math.floor(lotDirectCosts + lotIndirectCosts + lotTenantsCosts + totalFinancingCosts + landCost);

    marketableLotEntity.properties.landUseParams['lot.cost.financing'] = totalFinancingCosts;
    marketableLotEntity.properties.landUseParams['lot.cost.total'] = totalCosts;

    return marketableLotEntity;
  }

  lotCalculateBettermentLevy(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const lotNumOfUnits = lotDictParams['lot.unit.num'];
    const mamadAreaPerUnit = lotDictParams['lot.betterment.mamad.area_per_unit'];
    const valuePerM2Residential = lotDictParams['lot.value.residential.per_area'];
    const valuePerM2Office = lotDictParams['lot.value.office.per_area'];
    const valuePerM2Retail = lotDictParams['lot.value.retail.per_area'];
    const officeRevenue = lotDictParams['lot.revenue.office'];
    const retailRevenue = lotDictParams['lot.revenue.retail'];
    const hotelRevenue = lotDictParams['lot.revenue.hotel'];
    const seniorHousingRevenue = lotDictParams['lot.revenue.senior_housing'];
    const lotCurrentRetailArea = lotDictParams['lot.current.retail.floor_area'];
    const lotCurrentOfficeArea = lotDictParams['lot.current.office.floor_area'];
    const balconiesAreaPerUnit = lotDictParams['lot.balcony.floor_area.per_unit'];
    const revenueFromBalconies = lotDictParams['lot.balcony.revenue_ratio'];
    const lotNetAreaResidential = lotDictParams['lot.floor_area.residential.net'];
    const vat = lotDictParams['lot.vat'];   
    const developerProfitRatio = lotDictParams['lot.betterment.developer_profit_ratio'];
    const lotCostDirect = lotDictParams['lot.cost.direct'];
    const lotCostIndirect = lotDictParams['lot.cost.indirect'];
    const lotCostTennants = lotDictParams['lot.cost.current_residents'];
    const lotCostFinancing = lotDictParams['lot.cost.financing'];
    const numOfExistingUnits = lotDictParams['lot.current.unit.num'];
    const avgExistingUnitSize = lotDictParams['lot.current.unit.floor_area.avg'];
    const newToExistingValueRatioResidential = lotDictParams['lot.betterment.new_to_existing_value_ratio.residential'];
    const newToExistingValueRatioRetailOffice = lotDictParams['lot.betterment.new_to_existing_value_ratio.retail_office'];
    const bettermentAdditionalCosts = lotDictParams['lot.betterment.additional_costs'];
    const bettermentRatio = lotDictParams["lot.betterment.ratio"];
    const landCost = lotDictParams["lot.cost.land_cost"]

    const newUnitsTotalRevenue = (((-mamadAreaPerUnit + (balconiesAreaPerUnit * revenueFromBalconies / 100)) * lotNumOfUnits) + lotNetAreaResidential) * valuePerM2Residential;
    const newUnitsTotalRevenueNoVAT = newUnitsTotalRevenue / ((100 + vat) / 100);
    const lotTotalNewBuildingsRevenue = newUnitsTotalRevenueNoVAT + officeRevenue + retailRevenue + hotelRevenue + seniorHousingRevenue;
    const lotTotalNewBuildingsRevenueWithoutProfit = lotTotalNewBuildingsRevenue / ((developerProfitRatio + 100) / 100);
    const landValue = lotTotalNewBuildingsRevenueWithoutProfit - ( lotCostDirect + lotCostIndirect + lotCostTennants + lotCostFinancing );
    // const landValue = lotTotalNewBuildingsRevenueWithoutProfit - (lotCostDirect * (100 + bettermentAdditionalCosts) / 100); // check if correct
    const existingUnitsValue = numOfExistingUnits * avgExistingUnitSize * valuePerM2Residential * newToExistingValueRatioResidential / 100 / ((100 + vat) / 100);
    const existingRetailOfficeValue = lotCurrentRetailArea * valuePerM2Retail * newToExistingValueRatioRetailOffice / 100 + lotCurrentOfficeArea * valuePerM2Office * newToExistingValueRatioRetailOffice / 100;
    const lotTotalRevenue = Math.max(0, landValue - existingUnitsValue - existingRetailOfficeValue);
    const bettermentLevy25 = lotTotalRevenue * 0.25;
    const bettermentLevy50 = lotTotalRevenue * 0.5;
    const bettermentLevy75 = lotTotalRevenue * 0.75;

    const bettermentLevyUser =  (landCost > 0) ? 0 : lotTotalRevenue * bettermentRatio / 100;


    marketableLotEntity.properties.landUseParams['lot.land_value'] = Math.round(landValue);
    marketableLotEntity.properties.landUseParams['lot.cost.betterment_levy.25%'] = Math.round(bettermentLevy25);
    marketableLotEntity.properties.landUseParams['lot.cost.betterment_levy.50%'] = Math.round(bettermentLevy50);
    marketableLotEntity.properties.landUseParams['lot.cost.betterment_levy.75%'] = Math.round(bettermentLevy75);
    marketableLotEntity.properties.landUseParams['lot.cost.betterment_levy.user'] = Math.round(bettermentLevyUser);

    return marketableLotEntity;
  }

  lotCalculatePurchaseTax(lotDictEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const landValue = lotDictEntity.properties.landUseParams['lot.land_value'];
    const lotNetAreaResidential = lotDictEntity.properties.landUseParams['lot.floor_area.residential.net'];
    const numOfExistingUnits = lotDictEntity.properties.landUseParams['lot.current.unit.num'];
    const avgExistingUnitSize = lotDictEntity.properties.landUseParams['lot.current.unit.floor_area.avg'];
    const additionalAreaForExistingUnit = lotDictEntity.properties.landUseParams['lot.current.unit.floor_area.additional_area'];
    const purchaseTax = lotDictEntity.properties.landUseParams['lot.cost.purchase_tax'];
    const lotTotalCosts = lotDictEntity.properties.landUseParams['lot.cost.total'];

    const directCostTotal = lotDictEntity.properties.landUseParams['lot.cost.direct'];
    const totalIndirectCosts = lotDictEntity.properties.landUseParams['lot.cost.indirect'];
    const totalTenantsCosts = lotDictEntity.properties.landUseParams['lot.cost.current_residents'];
    const totalFinancingCosts = lotDictEntity.properties.landUseParams['lot.cost.financing'];
    const bettermentLevy25 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.25%'];
    const bettermentLevy50 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.50%'];
    const bettermentLevy75 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.75%'];
    const marketing = lotDictEntity.properties.landUseParams['lot.cost.marketing'];
    const planning = lotDictEntity.properties.landUseParams['lot.cost.planning'];
    const legal = lotDictEntity.properties.landUseParams['lot.cost.legal'];
    const lotTotalRevenue = lotDictEntity.properties.landUseParams['lot.revenue'];
    const bettermentLevyUser = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.user'];
    const landCost = lotDictEntity.properties.landUseParams["lot.cost.land_cost"];
    const agreementType = lotDictEntity.properties.landUseParams['lot.agreement_type']; //add anat
    const lotCombinationRatio = lotDictEntity.properties.landUseParams['lot.combination_ratio']; //add anat


    const existingToNetAreaRatio = numOfExistingUnits * (avgExistingUnitSize + additionalAreaForExistingUnit)/ lotNetAreaResidential
    const costIndirectForPurchaseTax = totalIndirectCosts - lotTotalRevenue * (marketing + legal)/ 100
    const costPurchaseTax0betterment = (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100
    const costPurchaseTax25betterment = (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + bettermentLevy25+ totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100
    const costPurchaseTax50betterment = (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + bettermentLevy50 + totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100
    const costPurchaseTax75betterment = (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + bettermentLevy75 + totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100
    // const costPurchaseTaxUserbetterment = (landCost > 0) ? landCost * purchaseTax / 100 : (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + bettermentLevyUser + totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100;
    let costPurchaseTaxUserbetterment;
    if (agreementType === 'combination') {
      costPurchaseTaxUserbetterment = (directCostTotal + costIndirectForPurchaseTax + totalFinancingCosts) * lotCombinationRatio/100 * purchaseTax / 100
    } else {
      costPurchaseTaxUserbetterment = (landCost > 0) ? landCost * purchaseTax / 100 : (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + bettermentLevyUser + totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100;
    }
    
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.0betterment.result'] = costPurchaseTax0betterment;
    lotDictEntity.properties.landUseParams['lot.cost.0betterment.total'] = Math.round(lotTotalCosts + costPurchaseTax0betterment);
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.25betterment.result'] = costPurchaseTax25betterment;
    lotDictEntity.properties.landUseParams['lot.cost.25betterment.total'] = Math.round(lotTotalCosts + costPurchaseTax25betterment + bettermentLevy25);
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.50betterment.result'] = costPurchaseTax50betterment;
    lotDictEntity.properties.landUseParams['lot.cost.50betterment.total'] = Math.round(lotTotalCosts + costPurchaseTax50betterment + bettermentLevy50);
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.75betterment.result'] = costPurchaseTax75betterment;
    lotDictEntity.properties.landUseParams['lot.cost.75betterment.total'] = Math.round(lotTotalCosts + costPurchaseTax75betterment + bettermentLevy75);
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.userBetterment.result'] = costPurchaseTaxUserbetterment;
    lotDictEntity.properties.landUseParams['lot.cost.userBetterment.total'] = Math.round(lotTotalCosts + costPurchaseTaxUserbetterment + bettermentLevyUser);



    return lotDictEntity;
  }

  lotCalculateProfit(lotDictEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    // console.log("lotCalculateProfit")
    // const lotTotalCosts = lotDictEntity.properties.landUseParams['lot.cost.total'];
    // const lotTotalRevenue = lotDictEntity.properties.landUseParams['lot.revenue'];
    // const bettermentLevy25 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.25%'];
    // const bettermentLevy50 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.50%'];
    // const bettermentLevy75 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.75%'];

    // const profitBetterment0 = lotTotalRevenue - lotTotalCosts;
    // const profitCostRatioBetterment0 = profitBetterment0 / lotTotalCosts;
    // const profitBetterment25 = lotTotalRevenue - lotTotalCosts - bettermentLevy25;
    // const profitCostRatioBetterment25 = profitBetterment25 / lotTotalCosts;
    // const profitBetterment50 = lotTotalRevenue - lotTotalCosts - bettermentLevy50;
    // const profitCostRatioBetterment50 = profitBetterment50 / lotTotalCosts;
    // const profitBetterment75 = lotTotalRevenue - lotTotalCosts - bettermentLevy75;
    // const profitCostRatioBetterment75 = profitBetterment75 / lotTotalCosts;

    const lotTotalRevenue = lotDictEntity.properties.landUseParams['lot.revenue'];
    const lotTotalRevenueCombination = lotDictEntity.properties.landUseParams["lot.revenue.combination"];
    const lotTotalCosts0betterment = lotDictEntity.properties.landUseParams['lot.cost.0betterment.total'];
    const lotTotalCosts25betterment = lotDictEntity.properties.landUseParams['lot.cost.25betterment.total'];
    const lotTotalCosts50betterment = lotDictEntity.properties.landUseParams['lot.cost.50betterment.total'];
    const lotTotalCosts75betterment = lotDictEntity.properties.landUseParams['lot.cost.75betterment.total'];
    const lotTotalCostsUserBetterment = lotDictEntity.properties.landUseParams['lot.cost.userBetterment.total'];
    const agreementType = lotDictEntity.properties.landUseParams['lot.agreement_type'];

    const profitBetterment0 = lotTotalRevenue - lotTotalCosts0betterment;
    const profitCostRatioBetterment0 = profitBetterment0 / lotTotalCosts0betterment;
    const profitBetterment25 = lotTotalRevenue - lotTotalCosts25betterment;
    const profitCostRatioBetterment25 = profitBetterment25 / lotTotalCosts25betterment;
    const profitBetterment50 = lotTotalRevenue - lotTotalCosts50betterment;
    const profitCostRatioBetterment50 = profitBetterment50 / lotTotalCosts50betterment;
    const profitBetterment75 = lotTotalRevenue - lotTotalCosts75betterment;
    const profitCostRatioBetterment75 = profitBetterment75 / lotTotalCosts75betterment;
    // const profitBettermentUser = lotTotalRevenue - lotTotalCostsUserBetterment;
    // const profitCostRatioBettermentUser = profitBettermentUser / lotTotalCostsUserBetterment;

    let profitBettermentUser;
    if (agreementType === 'combination') {
      profitBettermentUser = lotTotalRevenueCombination - lotTotalCostsUserBetterment;
    } else { 
      profitBettermentUser = lotTotalRevenue - lotTotalCostsUserBetterment;
    }
    
    const profitCostRatioBettermentUser = profitBettermentUser / lotTotalCostsUserBetterment;

    lotDictEntity.properties.landUseParams['lot.profit.betterment_0%'] = profitBetterment0;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.betterment_0%'] = profitCostRatioBetterment0;
    lotDictEntity.properties.landUseParams['lot.profit.betterment_25%'] = profitBetterment25;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.betterment_25%'] = profitCostRatioBetterment25;
    lotDictEntity.properties.landUseParams['lot.profit.betterment_50%'] = profitBetterment50;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.betterment_50%'] = profitCostRatioBetterment50;
    lotDictEntity.properties.landUseParams['lot.profit.betterment_75%'] = profitBetterment75;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.betterment_75%'] = profitCostRatioBetterment75;
    lotDictEntity.properties.landUseParams['lot.profit.bettermentUser'] = profitBettermentUser;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.bettermentUser%'] = profitCostRatioBettermentUser;

    return lotDictEntity;
  }

  lotCalculateProgram(lotDictEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = lotDictEntity.properties.landUseParams;

    const householdSize = lotDictParams['lot.household_size'];
    // const conversionRatio = lotDictParams['lot.conversion_ratio'];
    const annualChildRatio = lotDictParams['lot.annual_child_ratio'];
    const isRenewal = lotDictParams['lot.renewal_or_new'] === 'Urban Renewal';
    const numOfExistingUnits = lotDictParams['lot.current.unit.num'];
    const plannedUnits = lotDictParams['lot.unit.num'] - numOfExistingUnits;

    const totalPopulation = plannedUnits * householdSize;
    const annualChildren = Utils.roundUp(totalPopulation * annualChildRatio / 100);

    const publicAreaNeeded = 19 * plannedUnits;

    const greenAreaRatio = isRenewal ? 10 : 16;
    const greenAreaNeeded = greenAreaRatio * plannedUnits;

    const daycareClasses = Utils.roundUp(annualChildren * 0.5 * 3 / 20);
    const daycareArea = Utils.roundUp(daycareClasses / 5) * 1000
    // / 1 dunam to every 5 classes

    const kindergartenClasses = Utils.roundUp(annualChildren * 3 / 30);
    const kindergartenArea = Utils.round(kindergartenClasses * 0.5) * 1000;

    const elementaryClasses = Utils.roundUp(annualChildren * 6 / 27);

    let elementaryArea: number;

    if (elementaryClasses <= 12) {
      elementaryArea = isRenewal ? 3600 : 4800;
    } else if (elementaryClasses <= 18) {
      elementaryArea = isRenewal ? 5400 : 7200;
    } else {
      elementaryArea = isRenewal ? 7200 : 9600;
    }

    lotDictEntity.properties.landUseParams['lot.public_needs.population'] = totalPopulation;
    lotDictEntity.properties.landUseParams['lot.public_needs.anual_children'] = annualChildren;
    lotDictEntity.properties.landUseParams['lot.public_needs.public_area_needed'] = publicAreaNeeded;
    lotDictEntity.properties.landUseParams['lot.public_needs.green_area_needed'] = greenAreaNeeded;
    lotDictEntity.properties.landUseParams['lot.public_needs.daycare.classes' ] = daycareClasses;
    lotDictEntity.properties.landUseParams['lot.public_needs.daycare.area' ] = daycareArea;
    lotDictEntity.properties.landUseParams['lot.public_needs.kindergarten.classes'] = kindergartenClasses;
    lotDictEntity.properties.landUseParams['lot.public_needs.kindergarten.area'] = kindergartenArea;
    lotDictEntity.properties.landUseParams['lot.public_needs.elementary.classes'] = elementaryClasses;
    lotDictEntity.properties.landUseParams['lot.public_needs.elementary.area'] = elementaryArea;

    return lotDictEntity
  }

  private presentValue(cash: number, t: number, discountRate: number): number {
    let pv = 0;
    for (let i = 1; i <= t; i++) {
        pv += cash / Math.pow(1 + discountRate, i);
    }
    return pv;
  }

  calculatePresentValue(
    rate: number,
    minimumLeasePeriod: number,
    totalAffordableHousingRevenue: number
  ): number {
    const numerator = Math.pow(1 + rate, minimumLeasePeriod) - 1;
    const sumOfRevenues = (numerator / rate) * totalAffordableHousingRevenue;
    const discountFactor = Math.pow(1 + rate, minimumLeasePeriod);
  
    const presentValue = sumOfRevenues / discountFactor;
    return presentValue;
  }
  
}
