import { Injectable } from '@angular/core';
import { Feature, Geometry } from 'geojson';
import { ENTITIES_TYPES } from 'src/app/config';
import { ENTITY_CATEGORIES } from 'src/app/enums/enums';
import { EntityMap } from 'src/app/models/entity-map';
import { EntityProperties } from 'src/app/models/entity-properties';
import { IStorey, StoreysList } from 'src/app/models/floor-range';
import { PlanInterface } from 'src/app/models/plan-interface';
import Utils from 'src/app/utils/utils';

interface BuildingGFACalculationResult {
  residential: { GFA: number, floors: number },
  office: { GFA: number, floors: number },
  retail: { GFA: number, floors: number },
  public: { GFA: number, floors: number },
  parking: { GFA: number, floors: number },
  hotel: { GFA: number, floors: number },
  other: { GFA: number, floors: number },
  total_GFA: number
}

interface BuildingAreaCalculationResult {
  residential: { service_area: number, net_area: number },
  retail: { service_area: number, net_area: number },
  office: { service_area: number, net_area: number },
  public: { service_area: number, net_area: number },
  hotel: { service_area: number, net_area: number },
  parking: { service_area: number, net_area: number },
  other: { service_area: number, net_area: number },
  storeyGrossArea: number,
  typical_storey_net_area: number,
  net_area_total: number,
  service_area_total: number
}

interface CalculatedResidentialUnitsResult {
  num_of_units: number,
  num_of_unit_in_floor: number,
  unit_area_avg_calculated: number
}

interface BuildingAreaCalculationResultNew {
  residential: { gross: number, net: number, floors: number, service: number },
  office: { gross: number, net: number, floors: number, service: number },
  retail: { gross: number, net: number, floors: number, service: number },
  public: { gross: number, net: number, floors: number, service: number },
  hotel: { gross: number, net: number, floors: number, service: number },
  parking: { gross: number, net: number, floors: number, service: number },
  other: { gross: number, net: number, floors: number, service: number },
  storeyGrossArea: number,
  typical_storey_net_area: number,
  net_area_total: number,
  service_area_total: number,
  total_units: number,
  unit_counts: { [key: string]: number },
  unit_area_avg: number,
  unit_size_counts: { [key: number]: number }
}

@Injectable({
  providedIn: 'root'
})
export class BuildingDataService {


  defaultLandUseParams = {
    'building.storey.num' : 10,
    'building.storey.ground.height' : 6,
    'building.storey.typical.height' : 4,
    'building.usage_per_storey': [{"start": 0, "end": 0,"usage": "retail"}, {"start": 1, "end": 9,"usage": "residential"}],
    'building.unit.floor_area.avg' : 100,
    'parcel.parking_ratio.residential' : 1,
    'parent_lot.area' : 1500,
    'building.balcony.floor_area.avg' : 12
  }


  _buildingUsageConfig = {
    "residential" : "residential",
    "retail" : "retail",
    "office" : "office",
    "public" : "public",
    "hotel" : "hotel",
    "parking" : "parking", 
    "other" : "building"
  }

  defaultLotProperties: { [key: string]: any } = {
    'lot.parking_standart.residential.per_unit': 1,
    'lot.parking_standart.office.per_area': 40,
    'lot.parking_standart.retail.per_area': 25,
    'lot.parking_standart.public.per_area': 40,
    'lot.parking_standart.hotel.per_area': 200,
    'lot.parking_standart.underground.area.gross.per_spot': 40,
    'lot.impervious_surface.ratio': 15,
    'lot.storage.floor_area.per_unit': 6,
    'lot.value.residential.per_area': 0,
    'lot.value.office.per_area': 0,
    'lot.value.retail.per_area': 0,
    'lot.value.hotel.per_area': 0,
    'lot.balcony.floor_area.per_unit': 12,
    'lot.balcony.revenue_ratio': 50,
    'lot.vat': 17,
    'lot.revenue.user_defined' : 0,
    'lot.cost.residential.per_area': 0,
    'lot.cost.underground.per_area': 0,
    'lot.cost.reduction.office_vs_residential': -1000,
    'lot.cost.reduction.retail_vs_residential': -1000,
    'lot.cost.reduction.public_vs_residential': -1000,
    'lot.cost.reduction.balcony_vs_residential': 45,
    'lot.cost.above_ground_parking.per_area' : 3500,
    'lot.cost.demolition_per_m2': 500,
    'lot.cost.user_defined' : 0,
    'lot.cost.local_fees.per_area': 350,
    'lot.cost.electric.per_unit': 3000,
    'lot.cost.electric.commercial.per_area': 60,
    'lot.cost.purchase_tax': 6,
    'lot.cost.managment_oversight': 5,
    'lot.cost.legal': 1.5,
    'lot.cost.planning': 4,
    'lot.cost.marketing': 2.5,
    'lot.cost.contingency': 5,
    'lot.cost.funding_fees': 6,
    'lot.cost.duration.month': 42,
    'lot.cost.moving.per_unit': 5000,
    'lot.cost.legal.per_unit': 20000,
    'lot.cost.supervision': 10000,
    'lot.cost.management_fee': 450,
    'lot.cost.user_input': 0,
    'lot.current.unit.num': 0,
    'lot.current.retail.floor_area': 0,
    'lot.current.office.floor_area': 0,
    'lot.current.public.floor_area': 0,
    'lot.current.unit.floor_area.avg': 0,
    'lot.current.unit.floor_area.additional_area': 12,
    'lot.current.unit.rent.per_month': 3800,
    'lot.betterment.mamad.area_per_unit': 12,
    'lot.betterment.developer_profit_ratio': 15,
    'lot.betterment.new_to_existing_value_ratio.residential': 80,
    'lot.betterment.new_to_existing_value_ratio.retail_office': 80,
    'lot.household_size': 3.2,
    'lot.annual_percentage': 2,
    'lot.conversion_ratio': 2.7,
    'lot.annual_child_ratio': 2,
  };



  constructor() { }


  calculateBuildingAndLotNew(currentPlanEntities, planConfig, lotEntity, buildingEntity) {
    const storeysList = this.createStoreysList(buildingEntity);
    const storeysFeatureList = this.create3DStoreyFeatures(buildingEntity, storeysList);
    const serviceAreaMethod = buildingEntity.properties.landUseParams['building.service.area.method'];
    const serviceAreaRatio = (serviceAreaMethod === 'Auto' ? this.calculateServiceAreaRatio(buildingEntity) : buildingEntity.properties.landUseParams['building.service.area.ratio']) || 0;
    const calculateGFAandNetFloorArea = this.calculateGFAandNetFloorAreaNew(buildingEntity, storeysList, serviceAreaRatio)

    const buildingHeight = this.getBuildingHeightFromStoreyList(storeysList);

    // const calculatedGFA = this.calculateGFAperUsage(buildingEntity, storeysList);
    // const isMixUse = this.isMixUse(buildingEntity);
    // const serviceAreaRatio = this.calculateServiceAreaRatio(buildingEntity);
    // const calculatedNetFloorArea = this.calculateNetFloorArea(buildingEntity, serviceAreaRatio, calculatedGFA);
    // const calculatedResidentialUnits = this.calculateResidentialUnits(buildingEntity, serviceAreaRatio, storeysList);
    const calculatedBalconies = this.calculateBalconiesNew(buildingEntity, calculateGFAandNetFloorArea.total_units);

    const updatedBuildingEntity = Utils.clone(buildingEntity);


    // updatedBuildingEntity.properties.landUseParams['building.storey.typical.floor_area'] = calculateGFAandNetFloorArea.storeyGrossArea;
    updatedBuildingEntity.properties.landUseParams['building.height'] = buildingHeight;
    // units
    updatedBuildingEntity.properties.landUseParams['building.unit.num'] = calculateGFAandNetFloorArea.total_units;
    updatedBuildingEntity.properties.landUseParams['building.unit.count'] = calculateGFAandNetFloorArea.unit_counts;
    updatedBuildingEntity.properties.landUseParams['building.unit.size_count'] = calculateGFAandNetFloorArea.unit_size_counts;
    // updatedBuildingEntity.properties.landUseParams['building.unit.floor_area.avg'] = calculateGFAandNetFloorArea.unit_area_avg;
    updatedBuildingEntity.properties.landUseParams['building.unit.floor_area.avg_calculated'] = calculateGFAandNetFloorArea.unit_area_avg;

    // Gross Floor Area
    updatedBuildingEntity.properties.landUseParams['building.floor_area.residential.gross'] = Utils.round(calculateGFAandNetFloorArea.residential.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.office.gross'] = Utils.round(calculateGFAandNetFloorArea.office.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.retail.gross'] = Utils.round(calculateGFAandNetFloorArea.retail.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.public.gross'] = Utils.round(calculateGFAandNetFloorArea.public.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.hotel.gross'] = Utils.round(calculateGFAandNetFloorArea.hotel.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.parking.gross'] = Utils.round(calculateGFAandNetFloorArea.parking.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.other.gross'] = Utils.round(calculateGFAandNetFloorArea.other.gross, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.gross'] = Utils.round(calculateGFAandNetFloorArea.storeyGrossArea, 2);
    // Gross Floor Area - Service)
    updatedBuildingEntity.properties.landUseParams['building.service.area.ratio'] = serviceAreaRatio;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.residential.service'] = Utils.round(calculateGFAandNetFloorArea.residential.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.office.service'] = Utils.round(calculateGFAandNetFloorArea.office.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.public.service'] = Utils.round(calculateGFAandNetFloorArea.public.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.retail.service'] = Utils.round(calculateGFAandNetFloorArea.retail.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.hotel.service'] = Utils.round(calculateGFAandNetFloorArea.hotel.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.parking.service'] = Utils.round(calculateGFAandNetFloorArea.parking.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.other.service'] = Utils.round(calculateGFAandNetFloorArea.other.service, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.service'] = Utils.round(calculateGFAandNetFloorArea.service_area_total, 2);
    // Gross Floor Area - Net 
    updatedBuildingEntity.properties.landUseParams['building.floor_area.residential.net'] = Utils.round(calculateGFAandNetFloorArea.residential.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.office.net'] = Utils.round(calculateGFAandNetFloorArea.office.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.public.net'] = Utils.round(calculateGFAandNetFloorArea.public.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.retail.net'] = Utils.round(calculateGFAandNetFloorArea.retail.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.hotel.net'] = Utils.round(calculateGFAandNetFloorArea.hotel.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.parking.net'] =Utils.round( calculateGFAandNetFloorArea.parking.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.other.net'] = Utils.round(calculateGFAandNetFloorArea.other.net, 2) || 0;
    updatedBuildingEntity.properties.landUseParams['building.floor_area.net'] = Utils.round(calculateGFAandNetFloorArea.net_area_total, 2)
    //Number of Stories
    updatedBuildingEntity.properties.landUseParams['building.storey.residential.num'] = calculateGFAandNetFloorArea.residential.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.office.num'] = calculateGFAandNetFloorArea.office.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.public.num'] = calculateGFAandNetFloorArea.public.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.retail.num'] = calculateGFAandNetFloorArea.retail.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.hotel.num'] = calculateGFAandNetFloorArea.hotel.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.parking.num'] = calculateGFAandNetFloorArea.parking.floors || 0;
    updatedBuildingEntity.properties.landUseParams['building.storey.other.num'] = calculateGFAandNetFloorArea.other.floors || 0;
    // updatedBuildingEntity.properties.landUseParams['building.storey.num'] = 
    // Balconies
    updatedBuildingEntity.properties.landUseParams['building.balcony.floor_area.avg'] = buildingEntity.properties.landUseParams['building.balcony.floor_area.avg'] || this.defaultLandUseParams['building.balcony.floor_area.avg']
    updatedBuildingEntity.properties.landUseParams['building.balcony.num'] = calculatedBalconies.balconies_num
    updatedBuildingEntity.properties.landUseParams['building.balcony.floor_area.gross'] = calculatedBalconies.balconies_floor_area_total

    // return updatedBuildingEntity

    // update lot 
    const updatedLotEntity = Utils.clone(lotEntity);

    updatedLotEntity.properties.landUseParams['lot.area'] = lotEntity.properties.area.value * 1000;
    updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] = Utils.round(calculateGFAandNetFloorArea.storeyGrossArea, 2);
    updatedLotEntity.properties.landUseParams['lot.floor_area.residential.gross'] = Utils.round(calculateGFAandNetFloorArea.residential.gross, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.floor_area.retail.gross'] = Utils.round(calculateGFAandNetFloorArea.retail.gross, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.floor_area.public.gross'] = Utils.round(calculateGFAandNetFloorArea.public.gross, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.floor_area.office.gross'] = Utils.round(calculateGFAandNetFloorArea.office.gross, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.gross'] = Utils.round(calculateGFAandNetFloorArea.hotel.gross, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.floor_area.parking.gross'] = Utils.round(calculateGFAandNetFloorArea.parking.gross, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.floor_area.other.gross'] = Utils.round(calculateGFAandNetFloorArea.other.gross, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.floor_area.net'] = Utils.round(calculateGFAandNetFloorArea.net_area_total, 2);
    updatedLotEntity.properties.landUseParams['lot.floor_area.service'] = Utils.round(calculateGFAandNetFloorArea.service_area_total, 2);
    updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] = Utils.round(calculateGFAandNetFloorArea.residential.net, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.floor_area.residential.service'] = Utils.round(calculateGFAandNetFloorArea.residential.service, 2) || 0;
    updatedLotEntity.properties.landUseParams['lot.unit.num'] = calculateGFAandNetFloorArea.total_units;
    updatedLotEntity.properties.landUseParams['lot.building.height'] = buildingHeight;
    // updatedLotEntity.properties.landUseParams['lot.built_area.ground'] = calculateGFAandNetFloorArea.storeyGrossArea;
    updatedLotEntity.properties.landUseParams['lot.built_area.ground'] = buildingEntity.properties.area.value;


    const lotOtherChildren: string[] = this.findOtherChildrenById(planConfig, updatedLotEntity.id, updatedBuildingEntity.id);

    
    if (lotOtherChildren.length > 0) {
      lotOtherChildren.forEach(id => {
        updatedLotEntity.properties.landUseParams['lot.built_area.ground'] += currentPlanEntities[id].properties.area.value;
        updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.retail.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.retail.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.public.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.public.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.office.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.office.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.hotel.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.parking.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.parking.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.other.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.other.gross'] || 0;

        // updatedLotEntity.properties.landUseParams['lot.floor_area.underground.gross'] += currentPlanEntities[id]['properties']['landUseParams']['building.floor_area.underground.gross'] || 0;

        updatedLotEntity.properties.landUseParams['lot.floor_area.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.service'] || 0;

        updatedLotEntity.properties.landUseParams['lot.unit.num'] += currentPlanEntities[id].properties.landUseParams['building.unit.num'] || 0;

        updatedLotEntity.properties.landUseParams['lot.building.height.max'] = Math.max(updatedLotEntity.properties.landUseParams['lot.building.height'] || 0, currentPlanEntities[id].properties.landUseParams['building.height'] || 0)
        // updatedLotEntity.properties.landUseParams['built_area.ground'] = calculatedNetFloorArea.typical_storey_net_area
      })
    }
    const calculatedFAR = updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] / updatedLotEntity.properties.landUseParams['lot.area'];
    const coverageAreaRatioCalculated = updatedLotEntity.properties.landUseParams['lot.built_area.ground'] / updatedLotEntity.properties.landUseParams['lot.area'] * 100;
    updatedLotEntity.properties.landUseParams['lot.far'] = calculatedFAR;
    updatedLotEntity.properties.landUseParams['lot.far.calc'] = calculatedFAR;
    updatedLotEntity.properties.landUseParams['lot.building_coverage.ratio'] = coverageAreaRatioCalculated;
    updatedLotEntity.properties.landUseParams['lot.unit.floor_area.avg'] = updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] / updatedLotEntity.properties.landUseParams['lot.unit.num']

    this.updateEntityProperties(updatedLotEntity, this.defaultLotProperties);

    this.lotCalculateParking(updatedLotEntity);
    this.lotCalculateRevenue(updatedLotEntity);
    this.lotCalculateDirectCosts(updatedLotEntity);
    this.lotCalculateInDirectCosts(updatedLotEntity);
    this.lotCalculateTenantsCosts(updatedLotEntity);
    this.lotCalculateFinancingCost(updatedLotEntity);
    this.lotCalculateBettermentLevy(updatedLotEntity);
    this.lotCalculatePurchaseTax(updatedLotEntity);
    this.lotCalculateProfit(updatedLotEntity);
    this.lotCalculateProgram(updatedLotEntity);


    return {
      result: {
        lot: updatedLotEntity, 
        building: updatedBuildingEntity,
        floors: storeysFeatureList
      }
    };

  }

  reCalculateLot(lotEntity) {

    const updatedLotEntity = Utils.clone(lotEntity);

    this.updateEntityProperties(updatedLotEntity, this.defaultLotProperties);

    this.lotCalculateParking(updatedLotEntity);
    this.lotCalculateRevenue(updatedLotEntity);
    this.lotCalculateDirectCosts(updatedLotEntity);
    this.lotCalculateInDirectCosts(updatedLotEntity);
    this.lotCalculateTenantsCosts(updatedLotEntity);
    this.lotCalculateFinancingCost(updatedLotEntity);
    this.lotCalculateBettermentLevy(updatedLotEntity);
    this.lotCalculatePurchaseTax(updatedLotEntity);
    this.lotCalculateProfit(updatedLotEntity);
    this.lotCalculateProgram(updatedLotEntity);

    return updatedLotEntity;
  }

  resetAndCalculateLot(lotEntity: Feature<Geometry, EntityProperties> , currentPlan: PlanInterface, currentPlanEntities: EntityMap) {
    const updatedLotEntity = Utils.clone(lotEntity)
    const lotChildrenIds: string[] = this.findOtherChildrenById(currentPlan.planConfig, lotEntity.id);
    if (lotChildrenIds.length > 0) {
      updatedLotEntity.properties.landUseParams = {};
      updatedLotEntity.properties.landUseParams['lot.area'] = lotEntity.properties.area.value * 1000;
      updatedLotEntity.properties.landUseParams = this.initializeLandUseParams(updatedLotEntity.properties.landUseParams);

      lotChildrenIds.forEach(id => {
        updatedLotEntity.properties.landUseParams['lot.built_area.ground'] += currentPlanEntities[id].properties.area.value;
        updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.retail.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.retail.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.public.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.public.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.office.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.office.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.hotel.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.hotel.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.parking.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.parking.gross'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.other.gross'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.other.gross'] || 0;

        updatedLotEntity.properties.landUseParams['lot.floor_area.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.service'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.net'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.net'] || 0;
        updatedLotEntity.properties.landUseParams['lot.floor_area.residential.service'] += currentPlanEntities[id].properties.landUseParams['building.floor_area.residential.service'] || 0;

        updatedLotEntity.properties.landUseParams['lot.unit.num'] += currentPlanEntities[id].properties.landUseParams['building.unit.num'] || 0;

        updatedLotEntity.properties.landUseParams['lot.building.height.max'] = Math.max(updatedLotEntity.properties.landUseParams['lot.building.height'] || 0, currentPlanEntities[id].properties.landUseParams['building.height'] || 0)
      })

      const calculatedFAR = updatedLotEntity.properties.landUseParams['lot.floor_area.gross'] / updatedLotEntity.properties.landUseParams['lot.area'];
      const coverageAreaRatioCalculated = updatedLotEntity.properties.landUseParams['lot.built_area.ground'] / updatedLotEntity.properties.landUseParams['lot.area'] * 100;
      updatedLotEntity.properties.landUseParams['lot.far'] = calculatedFAR;
      updatedLotEntity.properties.landUseParams['lot.far.calc'] = calculatedFAR;
      updatedLotEntity.properties.landUseParams['lot.building_coverage.ratio'] = coverageAreaRatioCalculated;

      this.updateEntityProperties(updatedLotEntity, this.defaultLotProperties);

      this.lotCalculateParking(updatedLotEntity);
      this.lotCalculateRevenue(updatedLotEntity);
      this.lotCalculateDirectCosts(updatedLotEntity);
      this.lotCalculateInDirectCosts(updatedLotEntity);
      this.lotCalculateTenantsCosts(updatedLotEntity);
      this.lotCalculateFinancingCost(updatedLotEntity);
      this.lotCalculateBettermentLevy(updatedLotEntity);
      this.lotCalculatePurchaseTax(updatedLotEntity);
      this.lotCalculateProfit(updatedLotEntity);
      this.lotCalculateProgram(updatedLotEntity);
    } else {
      updatedLotEntity.properties.landUseParams = {};
    }
    

    return updatedLotEntity;
  }

  initializeLandUseParams(params) {
    const defaultParams = {
      'lot.built_area.ground': 0,
      'lot.floor_area.gross': 0,
      'lot.floor_area.residential.gross': 0,
      'lot.floor_area.retail.gross': 0,
      'lot.floor_area.public.gross': 0,
      'lot.floor_area.office.gross': 0,
      'lot.floor_area.hotel.gross': 0,
      'lot.floor_area.parking.gross': 0,
      'lot.floor_area.other.gross': 0,
      'lot.floor_area.net': 0,
      'lot.floor_area.service': 0,
      'lot.floor_area.residential.net': 0,
      'lot.floor_area.residential.service': 0,
      'lot.unit.num': 0,
      'lot.building.height.max': 0
    };
  
    return Object.assign({}, defaultParams, params);
  }

  findOtherChildrenById(planConfig, parentId: string | number, excludeBuildingId?: string): string[] {
    return Object.keys(planConfig).filter(id => planConfig[id].parentId === parentId && id !== excludeBuildingId);
  }

  updateEntityProperties(entity: Feature,  defaultProperties: { [key: string]: any }): Feature {
    // Iterating over the default properties
    for (const key in defaultProperties) {
      // If the property doesn't exist or it is null or undefined, update it
      if (
        !entity.properties.landUseParams.hasOwnProperty(key) ||
        entity.properties.landUseParams[key] === null ||
        entity.properties.landUseParams[key] === undefined
      ) {
        entity.properties.landUseParams[key] = defaultProperties[key];
      }
    }

    return entity;
  }

  // new method !!!
  createStoreysList(building: Feature<Geometry, EntityProperties>): StoreysList {
    let storeysList: StoreysList = [];
    let landUseParams = building.properties.landUseParams;

    let storey_num = 0;
    while (storey_num < landUseParams['building.storey.num']) {
        let storey_dict: IStorey = {
            storey_number: storey_num,
            base_height: 0,
            top_height: 0,
            landUse: 'residential',
            floor_area: building.properties.area.value
        };

        let storeyInfo = landUseParams['building.usage_per_storey'].find((range: any) =>
            storey_num >= range.start && storey_num <= range.end
        );

        // Calculate storey top and base height
        if (storey_num === 0) {
            storey_dict.base_height = 0;
            storey_dict.top_height = storeyInfo?.height ?? landUseParams['building.storey.ground.height'];
        } else {
            let previousStorey = storeysList[storeysList.length - 1];
            storey_dict.base_height = previousStorey.top_height;
            storey_dict.top_height = previousStorey.top_height + (storeyInfo?.height ?? landUseParams['building.storey.typical.height']);
        }

        // Round heights to 1 decimal place
        storey_dict.base_height = parseFloat(storey_dict.base_height.toFixed(1));
        storey_dict.top_height = parseFloat(storey_dict.top_height.toFixed(1));

        // Define land use type
        storey_dict.landUse = storeyInfo?.primaryUsage ?? storeyInfo?.usage?.toLowerCase() ?? 'residential';

        // Add area
        storey_dict.floor_area = storeyInfo?.area ?? building.properties.area.value;

        // Add mainUsageList and serviceUsageList if they exist
        if (storeyInfo?.mainUsageList) {
            storey_dict.mainUsageList = storeyInfo.mainUsageList;
        }
        if (storeyInfo?.serviceUsageList) {
            storey_dict.serviceUsageList = storeyInfo.serviceUsageList;
        }

        // Add geometry if it exists
        if (storeyInfo?.geometry) {
          storey_dict.geometry = storeyInfo.geometry;
        }

        storeysList.push(storey_dict);
        storey_num++;
    }

    return storeysList;
  }


  create3DStoreyFeatures(building: Feature<Geometry, EntityProperties>, storeysList: StoreysList): Feature<Geometry, EntityProperties>[] {
    let storeyFeaturesList = [];

    for (let storey_dict of storeysList) {
        let storeyGeometry = storey_dict.geometry ? storey_dict.geometry : building.geometry;

        let storeyFeature = {
            type: 'Feature',
            geometry: storeyGeometry,
            properties: {
                id: Date.now(),
                storey_number: storey_dict.storey_number,
                heightProperties: {
                    height: storey_dict.top_height,
                    base_height: storey_dict.base_height
                },
                landUse: storey_dict.landUse,
                floor_area: storey_dict.floor_area,
                entityCategory: ENTITY_CATEGORIES.BUILDING,
                entityType: ENTITIES_TYPES.building.floor,
                // isHide: building.properties.isHide,
                // name: building.properties.name,
                // description: building.properties.description,
                // entityVersion: building.properties.entityVersion,
                // landUseParams: building.properties.landUseParams,
                // projectName: building.properties.projectName,
                // isLock: building.properties.isLock,
                // date: building.properties.date,
                // area: building.properties.area,
            }
        };

        storeyFeaturesList.push(storeyFeature);
    }

    return storeyFeaturesList;
  }


  // with unit counting 
  // calculateGFAandNetFloorAreaNew(building: Feature<Geometry, EntityProperties>, storeysList: StoreysList, serviceAreaRatio: number): BuildingAreaCalculationResultNew {
  //   let usageAreas: { [key: string]: { gross: number, net: number, floors: number, service: number } } = {};
  //   let totalUnits = 0;
  //   let unitCounts: { [key: string]: number } = {};
  //   let unitAreaSum = 0;
  //   let totalGrossArea = 0;
  //   let unitSizeCounts: { [key: number]: number } = {};

  //   for (let storey of storeysList) {
  //       const mainUsages = storey.mainUsageList || [];
  //       const serviceUsages = storey.serviceUsageList || [];
  //       const primaryUsage = storey.landUse.toLowerCase();

  //       if (!usageAreas[primaryUsage]) {
  //           usageAreas[primaryUsage] = { gross: 0, net: 0, floors: 0, service: 0 };
  //       }

  //       let grossArea = 0;
  //       let netArea = 0;
  //       let serviceArea = 0;

  //       totalGrossArea += storey.floor_area;

  //       if (mainUsages.length > 0) {
  //           for (let usage of mainUsages) {
  //               const usageType = usage.usageType.toLowerCase();
  //               if (!usageAreas[usageType]) {
  //                   usageAreas[usageType] = { gross: 0, net: 0, floors: 0, service: 0 };
  //               }
  //               usageAreas[usageType].gross += storey.floor_area;
  //               usageAreas[usageType].net += usage.area;
  //               grossArea += storey.floor_area;
  //               netArea += usage.area;

  //               // Count units
  //               if (usage.unitsList && usage.unitsList.length > 0) {
  //                   for (let unit of usage.unitsList) {
  //                       const unitType = unit.unit_type.toLowerCase();
  //                       if (!unitCounts[unitType]) {
  //                           unitCounts[unitType] = 0;
  //                       }
  //                       unitCounts[unitType] += unit.count || 1;
  //                       totalUnits += unit.count || 1;
  //                       unitAreaSum += unit.unit_size;

  //                       // Count units by size
  //                       const unitSize = unit.unit_size;
  //                       if (!unitSizeCounts[unitSize]) {
  //                         unitSizeCounts[unitSize] = 0;
  //                       }
  //                       unitSizeCounts[unitSize] += unit.count || 1;
  //                   }
  //               }
  //           }
  //       } else {
  //           grossArea = storey.floor_area;
  //           netArea = storey.floor_area * (1 - serviceAreaRatio / 100);
  //           usageAreas[primaryUsage].gross += grossArea;
  //           usageAreas[primaryUsage].net += netArea;
  //       }

  //       if (serviceUsages.length > 0) {
  //           for (let usage of serviceUsages) {
  //               const serviceForUsage = usage.serviceForUsage.toLowerCase();
  //               if (!usageAreas[serviceForUsage]) {
  //                   usageAreas[serviceForUsage] = { gross: 0, net: 0, floors: 0, service: 0 };
  //               }
  //               serviceArea += usage.area;
  //               usageAreas[serviceForUsage].service += usage.area;
  //           }
  //       } else {
  //           serviceArea = storey.floor_area * serviceAreaRatio / 100;
  //           usageAreas[primaryUsage].service += serviceArea;
  //       }

  //       // Ensure each floor is counted only once for its primary usage
  //       usageAreas[primaryUsage].floors += 1;

  //       // Count default units if no units are specified
  //       if (primaryUsage === 'residential' && mainUsages.length === 0) {
  //           let netFloorArea = netArea;
  //           let avgUnitArea = building.properties.landUseParams['building.unit.floor_area.avg'];
  //           let numUnitsInFloor = Math.round(netFloorArea / avgUnitArea);
  //           const calculatedAvgUnitArea = Math.round(netFloorArea / numUnitsInFloor) 
  //           unitCounts['apartment'] = (unitCounts['apartment'] || 0) + numUnitsInFloor;
  //           totalUnits += numUnitsInFloor;
  //           unitAreaSum += netFloorArea;

  //           // Count default units by size
  //           if (!unitSizeCounts[calculatedAvgUnitArea]) {
  //             unitSizeCounts[calculatedAvgUnitArea] = 0;
  //           }
  //           unitSizeCounts[calculatedAvgUnitArea] += numUnitsInFloor;
  //       }
  //   }

  //   const result: BuildingAreaCalculationResultNew = {
  //       residential: this.extractAreaData(usageAreas['residential']),
  //       office: this.extractAreaData(usageAreas['office']),
  //       retail: this.extractAreaData(usageAreas['retail']),
  //       public: this.extractAreaData(usageAreas['public']),
  //       hotel: this.extractAreaData(usageAreas['hotel']),
  //       parking: this.extractAreaData(usageAreas['parking']),
  //       other: this.extractAreaData(usageAreas['other']),
  //       storeyGrossArea: totalGrossArea,
  //       typical_storey_net_area: (totalGrossArea / storeysList.length) * (1 - serviceAreaRatio / 100),
  //       net_area_total: 0,
  //       service_area_total: 0,
  //       total_units: totalUnits,
  //       unit_counts: unitCounts,
  //       unit_area_avg: totalUnits > 0 ? unitAreaSum / totalUnits : 0,
  //       unit_size_counts: unitSizeCounts
  //   };

  //   result.net_area_total = Object.values(result).reduce((sum, area) => sum + (area?.net || 0), 0);
  //   result.service_area_total = Object.values(result).reduce((sum, area) => sum + (area?.service || 0), 0);
  //   return result;
  // }

  calculateGFAandNetFloorAreaNew(building: Feature<Geometry, EntityProperties>, storeysList: StoreysList, serviceAreaRatio: number): BuildingAreaCalculationResultNew {
    let usageAreas: { [key: string]: { gross: number, net: number, floors: number, service: number } } = {};
    let totalUnits = 0;
    let unitCounts: { [key: string]: number } = {};
    let unitAreaSum = 0;
    let totalGrossArea = 0;
    let unitSizeCounts: { [key: number]: number } = {};
  
    for (let storey of storeysList) {
      const mainUsages = storey.mainUsageList || [];
      const serviceUsages = storey.serviceUsageList || [];
      const primaryUsage = storey.landUse.toLowerCase();
  
      if (!usageAreas[primaryUsage]) {
        usageAreas[primaryUsage] = { gross: 0, net: 0, floors: 0, service: 0 };
      }
  
      let netArea = 0;
      let serviceArea = 0;
  
      totalGrossArea += storey.floor_area;
  
      if (mainUsages.length > 0) {
        for (let usage of mainUsages) {
          const usageType = usage.usageType.toLowerCase();
          if (!usageAreas[usageType]) {
            usageAreas[usageType] = { gross: 0, net: 0, floors: 0, service: 0 };
          }
          usageAreas[usageType].net += usage.area;
          netArea += usage.area;
  
          // Count units
          if (usage.unitsList && usage.unitsList.length > 0) {
            for (let unit of usage.unitsList) {
              const unitType = unit.unit_type.toLowerCase();
              if (!unitCounts[unitType]) {
                unitCounts[unitType] = 0;
              }
              unitCounts[unitType] += unit.count || 1;
              totalUnits += unit.count || 1;
              unitAreaSum += unit.unit_size;
  
              // Count units by size
              const unitSize = unit.unit_size;
              if (!unitSizeCounts[unitSize]) {
                unitSizeCounts[unitSize] = 0;
              }
              unitSizeCounts[unitSize] += unit.count || 1;
            }
          }
        }
      } else {
        netArea = storey.floor_area * (1 - serviceAreaRatio / 100);
        usageAreas[primaryUsage].net += netArea;
      }
  
      if (serviceUsages.length > 0) {
        for (let usage of serviceUsages) {
          const serviceForUsage = usage.serviceForUsage.toLowerCase();
          if (!usageAreas[serviceForUsage]) {
            usageAreas[serviceForUsage] = { gross: 0, net: 0, floors: 0, service: 0 };
          }
          usageAreas[serviceForUsage].service += usage.area;
          serviceArea += usage.area;
        }
      } else {
        serviceArea = storey.floor_area * serviceAreaRatio / 100;
        usageAreas[primaryUsage].service += serviceArea;
      }
  
      // Ensure each floor is counted only once for its primary usage
      usageAreas[primaryUsage].floors += 1;
  
      // Count default units if no units are specified
      if (primaryUsage === 'residential' && mainUsages.length === 0) {
        let netFloorArea = netArea;
        let avgUnitArea = building.properties.landUseParams['building.unit.floor_area.avg'];
        let numUnitsInFloor = Math.round(netFloorArea / avgUnitArea);
        const calculatedAvgUnitArea = Math.round(netFloorArea / numUnitsInFloor);
        unitCounts['apartment'] = (unitCounts['apartment'] || 0) + numUnitsInFloor;
        totalUnits += numUnitsInFloor;
        unitAreaSum += netFloorArea;
  
        // Count default units by size
        if (!unitSizeCounts[calculatedAvgUnitArea]) {
          unitSizeCounts[calculatedAvgUnitArea] = 0;
        }
        unitSizeCounts[calculatedAvgUnitArea] += numUnitsInFloor;
      }
    }
  
    // After processing all storeys, set gross = net + service for each usage type
    for (let usageType in usageAreas) {
      usageAreas[usageType].gross = usageAreas[usageType].net + usageAreas[usageType].service;
    }
  
    const result: BuildingAreaCalculationResultNew = {
      residential: this.extractAreaData(usageAreas['residential']),
      office: this.extractAreaData(usageAreas['office']),
      retail: this.extractAreaData(usageAreas['retail']),
      public: this.extractAreaData(usageAreas['public']),
      hotel: this.extractAreaData(usageAreas['hotel']),
      parking: this.extractAreaData(usageAreas['parking']),
      other: this.extractAreaData(usageAreas['other']),
      storeyGrossArea: totalGrossArea,
      typical_storey_net_area: (totalGrossArea / storeysList.length) * (1 - serviceAreaRatio / 100),
      net_area_total: 0,
      service_area_total: 0,
      total_units: totalUnits,
      unit_counts: unitCounts,
      unit_area_avg: totalUnits > 0 ? unitAreaSum / totalUnits : 0,
      unit_size_counts: unitSizeCounts
    };
  
    result.net_area_total = Object.values(usageAreas).reduce((sum, area) => sum + (area?.net || 0), 0);
    result.service_area_total = Object.values(usageAreas).reduce((sum, area) => sum + (area?.service || 0), 0);
  
    return result;
  }
  
  

  extractAreaData(areaData: { gross: number, net: number, floors: number, service: number } | undefined) {
    if (!areaData) {
        return { gross: 0, net: 0, floors: 0, service: 0 };
    }
    return {
        gross: areaData.gross,
        net: areaData.net,
        floors: areaData.floors,
        service: areaData.service
    };
  }


  calculateGFAperUsage(building: Feature<Geometry, EntityProperties>, storiesList: any[]): BuildingGFACalculationResult {

    let residential_GFA_calculated = 0, retail_GFA_calculated = 0, office_GFA_calculated = 0,
        public_GFA_calculated = 0, hotel_GFA_calculated = 0, parking_GFA_calculated = 0, other_GFA_calculated = 0, total_GFA_calculated = 0,
        residential_num_of_floors = 0, retail_num_of_floors = 0, office_num_of_floors = 0,
        public_num_of_floors = 0, hotel_num_of_floors = 0,  parking_num_of_floors = 0, other_num_of_floors = 0;

    for (let storey_dict of storiesList) {
      let usage = storey_dict['landUse']
      let area = building.properties.area?.value;

      switch (usage) {
        case "residence":
        case this._buildingUsageConfig.residential:
          residential_GFA_calculated += area;
          residential_num_of_floors++;
          break;
        case this._buildingUsageConfig.office:
          office_GFA_calculated += area;
          office_num_of_floors++;
          break;
        case this._buildingUsageConfig.retail:
          retail_GFA_calculated += area;
          retail_num_of_floors++;
          break;
        case this._buildingUsageConfig.public:
          public_GFA_calculated += area;
          public_num_of_floors++;
          break;
        case this._buildingUsageConfig.hotel:
          hotel_GFA_calculated += area;
          hotel_num_of_floors++;
          break;
        case this._buildingUsageConfig.parking:
          parking_GFA_calculated += area;
          parking_num_of_floors++;
          break;
        default:
          other_GFA_calculated += area;
          other_num_of_floors++;
          break;
      }
    }

    total_GFA_calculated = residential_GFA_calculated + office_GFA_calculated + retail_GFA_calculated + public_GFA_calculated + hotel_GFA_calculated + parking_GFA_calculated + other_GFA_calculated;

    return {
      residential: { GFA: residential_GFA_calculated, floors: residential_num_of_floors },
      office: { GFA: office_GFA_calculated, floors: office_num_of_floors },
      retail: { GFA: retail_GFA_calculated, floors: retail_num_of_floors },
      public: { GFA: public_GFA_calculated, floors: public_num_of_floors },
      hotel: { GFA: hotel_GFA_calculated, floors: hotel_num_of_floors},
      parking: {GFA: parking_GFA_calculated, floors: parking_num_of_floors},
      other: { GFA: other_GFA_calculated, floors: other_num_of_floors },
      total_GFA: total_GFA_calculated
    };
  }

  calculateBuildingHeight(building: Feature<Geometry, EntityProperties>): number {
    let landUseParams = building.properties.landUseParams;
    let max_height = (landUseParams['building.storey.num'] - 1) * landUseParams['building.storey.typical.height'] + landUseParams['building.storey.ground.height'];
    return max_height;
  }

  getBuildingHeightFromStoreyList(storeysList: StoreysList) {
    if(storeysList[storeysList.length - 1]){
      return storeysList[storeysList.length - 1].top_height || 0;
    }
    return 0;
    
  }


  isMixUse(building: Feature<Geometry, EntityProperties>): boolean {
    // Initialize floor counts
    let residential_num_of_floors = 0;
    let retail_num_of_floors = 0;
    let office_num_of_floors = 0;
    let public_num_of_floors = 0;
    let hotel_num_of_floors = 0;
    let parking_num_of_floors = 0;
    let other_num_of_floors = 0;

    // Calculate floor counts from usage_per_storey data
    const usage_per_storey = building.properties.landUseParams['building.usage_per_storey'];

    for (let usage of usage_per_storey) {
        const floors = usage.end - usage.start + 1;
        const usageType = usage.primaryUsage?.toLowerCase() || usage.usage?.toLowerCase();
        switch (usageType) {
            case 'residential':
                residential_num_of_floors += floors;
                break;
            case 'retail':
                retail_num_of_floors += floors;
                break;
            case 'office':
                office_num_of_floors += floors;
                break;
            case 'public':
                public_num_of_floors += floors;
                break;
            case 'hotel':
                hotel_num_of_floors += floors;
                break;
            case 'parking':
                parking_num_of_floors += floors;
                break;
            default:
                other_num_of_floors += floors;
                break;
        }
    }

    // Determine ground floor usage
    const ground_floor_usage = usage_per_storey.find(i => i.start === 0)?.primaryUsage?.toLowerCase() || 
                               usage_per_storey.find(i => i.start === 0)?.usage?.toLowerCase();

    // Count number of usages in building
    const usageCounts = {
        residential: residential_num_of_floors,
        retail: retail_num_of_floors,
        office: office_num_of_floors,
        public: public_num_of_floors,
        hotel: hotel_num_of_floors,
        parking: parking_num_of_floors,
        other: other_num_of_floors,
    };

    const num_of_usages_in_building = Object.keys(usageCounts)
        .filter(usage => usageCounts[usage] > 0 && 
            (usageCounts[usage] > 1 || ground_floor_usage !== usage))
        .length;

    // Determine if building is mixed use
    return num_of_usages_in_building > 1;
}


  calculateServiceAreaRatio(building: Feature<Geometry, EntityProperties>): number {
    const buildingStoreyNum = building.properties.landUseParams['building.storey.num'];
    const isMixUse = this.isMixUse(building);
    
    switch (true) {
      case (buildingStoreyNum <= 2):
        return 0;
  
      case (buildingStoreyNum >= 3 && buildingStoreyNum <= 5):
        return isMixUse ? 25 : 15;
      
      case (buildingStoreyNum >= 6 && buildingStoreyNum <= 9):
        return isMixUse ? 30 : 20;
      
      case (buildingStoreyNum >= 10 && buildingStoreyNum <= 20):
        return isMixUse ? 40 : 25;
  
      case (buildingStoreyNum >= 21):
        return isMixUse ? 45 : 30;
  
      default:
        return 0;
    }
  }



  calculateNetFloorArea(building: Feature<Geometry, EntityProperties>, serviceAreaRatio: number, calcResults: BuildingGFACalculationResult): BuildingAreaCalculationResult {
    
    const { residential, retail, office, public: publicArea, hotel, parking, other, total_GFA } = calcResults;
    
    const calculateAreas = (GFA: number) => {
      const service_area = GFA * serviceAreaRatio / 100;
      const net_area = GFA - service_area;
      return { service_area, net_area };
    };
    
    const areas = {
      residential: calculateAreas(residential.GFA),
      retail: calculateAreas(retail.GFA),
      office: calculateAreas(office.GFA),
      public: calculateAreas(publicArea.GFA),
      hotel: calculateAreas(hotel.GFA),
      parking: calculateAreas(parking.GFA),
      other: calculateAreas(other.GFA)
    };
    
    const storeyGrossArea = building.properties.area.value; 
    const typical_storey_net_area = storeyGrossArea / (100 + serviceAreaRatio) / 100; // check if this is correct

    const net_area_total = Object.values(areas).reduce((sum, area) => sum + area.net_area, 0);
    const service_area_total = Object.values(areas).reduce((sum, area) => sum + area.service_area, 0);

    return {
      ...areas,
      storeyGrossArea,
      typical_storey_net_area,
      net_area_total,
      service_area_total
    };
  }

  calculateResidentialUnits(building: Feature<Geometry, EntityProperties>, serviceAreaRatio: number, storeysList): CalculatedResidentialUnitsResult {
    let num_of_units = 0;
    let num_of_unit_in_floor = 0;
    let unit_area_avg_calculated = 0;
    

    storeysList.forEach(storey => {
      if (storey.landUse === this._buildingUsageConfig.residential) {
        let net_floor_area = storey.floor_area / ((100 + serviceAreaRatio) / 100);
        num_of_unit_in_floor = Math.round(net_floor_area / building.properties.landUseParams['building.unit.floor_area.avg']);
        unit_area_avg_calculated = Math.round(net_floor_area / num_of_unit_in_floor);
        num_of_units += num_of_unit_in_floor;
      }
    });

    return {
      num_of_units,
      num_of_unit_in_floor,
      unit_area_avg_calculated
    };
  }

  calculateBalconies(building: Feature<Geometry, EntityProperties>, residentialUnits: CalculatedResidentialUnitsResult): {balconies_num: number, balconies_floor_area_total: number} {
    
    let num_of_units = residentialUnits.num_of_units;
    
    let balcony_avg_floor_area = building.properties.landUseParams['building.balcony.floor_area.avg'] || this.defaultLandUseParams['building.balcony.floor_area.avg'];
    let balconies_num = num_of_units;
    let balconies_floor_area_total = balconies_num * balcony_avg_floor_area;

    return {balconies_num, balconies_floor_area_total};
  }

  calculateBalconiesNew(building: Feature<Geometry, EntityProperties>, residentialUnits: number = 0): {balconies_num: number, balconies_floor_area_total: number} {
    
    let num_of_units = residentialUnits;
    
    let balcony_avg_floor_area = building.properties.landUseParams['building.balcony.floor_area.avg'] || this.defaultLandUseParams['building.balcony.floor_area.avg'];
    let balconies_num = num_of_units;
    let balconies_floor_area_total = balconies_num * balcony_avg_floor_area;

    return {balconies_num, balconies_floor_area_total};
  }

  lotCalculateParking(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const marketableLotParams = marketableLotEntity.properties.landUseParams;

    const parkingStandardResidential = marketableLotParams['lot.parking_standart.residential.per_unit'];
    const parkingStandardOffice = marketableLotParams['lot.parking_standart.office.per_area'];
    const parkingStandardRetail = marketableLotParams['lot.parking_standart.retail.per_area'];
    const parkingStandardPublic = marketableLotParams['lot.parking_standart.public.per_area'];
    const parkingStandardHotel = marketableLotParams['lot.parking_standart.hotel.per_area'];
    const parkingSpotGrossAreaUg = marketableLotParams['lot.parking_standart.underground.area.gross.per_spot'];
    const lotParkingGFA = marketableLotParams['lot.floor_area.parking.gross'];
    const lotNumOfUnits = marketableLotParams['lot.unit.num'];
    const lotRetailGFA = marketableLotParams['lot.floor_area.retail.gross'];
    const lotOfficeGFA = marketableLotParams['lot.floor_area.office.gross'];
    const lotPublicGFA = marketableLotParams['lot.floor_area.public.gross'];
    const lotHotelGFA = marketableLotParams['lot.floor_area.hotel.gross'];
    const lotArea = marketableLotParams['lot.area'];
    const lotImperviousSurfaceRatio = marketableLotParams['lot.impervious_surface.ratio'];
    const lotStorageFGAPerUnit = marketableLotParams['lot.storage.floor_area.per_unit'];
    const lotCostUndergroundPerArea = marketableLotParams['lot.cost.underground.per_area'];
    const vat = marketableLotParams['lot.vat'];

    const numOfParkingSpotsResidential = Math.ceil(parkingStandardResidential * lotNumOfUnits);

    const numOfParkingSpotsOffice = Math.ceil(lotOfficeGFA / parkingStandardOffice);
    const numOfParkingSpotsRetail = Math.ceil(lotRetailGFA / parkingStandardRetail);
    const numOfParkingSpotsPublic = Math.ceil(lotPublicGFA / parkingStandardPublic);
    const numOfParkingSpotsHotel = Math.ceil(lotHotelGFA / parkingStandardHotel);
    const numOfParkingSpotsInLot = numOfParkingSpotsResidential + numOfParkingSpotsOffice + numOfParkingSpotsRetail + numOfParkingSpotsPublic + numOfParkingSpotsHotel;
    const GFA_NeededForParking = numOfParkingSpotsInLot * parkingSpotGrossAreaUg;
    const GFA_NeededForStorage = lotNumOfUnits * lotStorageFGAPerUnit;
    const GFA_NeededUndergroundTotal = GFA_NeededForParking + GFA_NeededForStorage;
    const availableAreaUnderground = lotArea * (100 - lotImperviousSurfaceRatio) / 100;
    const numOfUndergroundStories = Math.ceil(GFA_NeededUndergroundTotal / availableAreaUnderground);
    const lotCostUndergroundNoVat = ((numOfParkingSpotsResidential + numOfParkingSpotsOffice + numOfParkingSpotsRetail + numOfParkingSpotsHotel) * parkingSpotGrossAreaUg + GFA_NeededForStorage) * lotCostUndergroundPerArea;
    const lotCostUndergroundWithVat = numOfParkingSpotsPublic * parkingSpotGrossAreaUg * (100 + vat) / 100;
    const lotCostUnderground = lotCostUndergroundNoVat + lotCostUndergroundWithVat;
    marketableLotEntity.properties.landUseParams['lot.parking.num.parking_floor'] = Math.floor(lotParkingGFA / parkingSpotGrossAreaUg);

    marketableLotEntity.properties.landUseParams['lot.parking.num'] = Math.floor(numOfParkingSpotsInLot + marketableLotEntity.properties.landUseParams['lot.parking.num.parking_floor']);
    marketableLotEntity.properties.landUseParams['lot.floor_area.underground.gross'] = GFA_NeededUndergroundTotal;
    marketableLotEntity.properties.landUseParams['lot.storey.underground.num'] = numOfUndergroundStories;
    marketableLotEntity.properties.landUseParams['lot.cost.underground'] = lotCostUnderground;

    marketableLotEntity.properties.landUseParams['lot.parking.num.residential'] = numOfParkingSpotsResidential;
    marketableLotEntity.properties.landUseParams['lot.parking.num.office'] = numOfParkingSpotsOffice;
    marketableLotEntity.properties.landUseParams['lot.parking.num.retail'] = numOfParkingSpotsRetail;
    marketableLotEntity.properties.landUseParams['lot.parking.num.public'] = numOfParkingSpotsPublic;
    marketableLotEntity.properties.landUseParams['lot.parking.num.hotel'] = numOfParkingSpotsHotel;


    return marketableLotEntity;
  }

  lotCalculateRevenue(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const valuePerM2Residential = lotDictParams['lot.value.residential.per_area'];
    const valuePerM2Office = lotDictParams['lot.value.office.per_area'];
    const valuePerM2Retail = lotDictParams['lot.value.retail.per_area'];
    const valuePerM2Hotel = lotDictParams['lot.value.hotel.per_area'];
    const balconiesArea = lotDictParams['lot.balcony.floor_area.per_unit'];
    const revenueFromBalconies = lotDictParams['lot.balcony.revenue_ratio'];
    const vat = lotDictParams['lot.vat'];
    const residentialNetArea = lotDictParams['lot.floor_area.residential.net'];
    const officeGFA = lotDictParams['lot.floor_area.office.gross'];
    const retailGFA = lotDictParams['lot.floor_area.retail.gross'];
    const hotelGFA = lotDictParams['lot.floor_area.hotel.gross'];
    const lotNumOfUnits = lotDictParams['lot.unit.num'];
    const numOfExistingUnits = lotDictParams['lot.current.unit.num'];
    const avgExistingUnitSize = lotDictParams['lot.current.unit.floor_area.avg'];
    const additionalAreaForExistingUnit = lotDictParams['lot.current.unit.floor_area.additional_area'];
    const additionalRevenueUserDefined = lotDictParams['lot.revenue.user_defined'];
    const currnetOfficeFloorArea = lotDictParams['lot.current.office.floor_area'];
    const currnetRetailFloorArea = lotDictParams['lot.current.retail.floor_area'];
    const currnetRetailFloorAreaAdditionalArea = lotDictParams['lot.current.retail.floor_area.additional_area'];

    const balconiesAreaForRevenue = (lotNumOfUnits - numOfExistingUnits) * balconiesArea * revenueFromBalconies / 100;
    const existingUnitsArea = numOfExistingUnits * (avgExistingUnitSize + additionalAreaForExistingUnit);
    const residentialAreaForRevenueCalc = residentialNetArea + balconiesAreaForRevenue - existingUnitsArea;
    const revenueFromResidentialIncludeVAT = residentialAreaForRevenueCalc * valuePerM2Residential;
    const revenueFromResidentialNoVAT = revenueFromResidentialIncludeVAT / ((100 + vat) / 100);

    const officeRevenue = (officeGFA - currnetOfficeFloorArea) * valuePerM2Office;
    const retailRevenue = (retailGFA - (currnetRetailFloorArea * (100 + currnetRetailFloorAreaAdditionalArea) / 100)) * valuePerM2Retail; // check if correct
    const hotelRevenue = hotelGFA * valuePerM2Hotel;
    const lotTotalRevenue = revenueFromResidentialNoVAT + officeRevenue + retailRevenue + hotelRevenue + additionalRevenueUserDefined;

    marketableLotEntity.properties.landUseParams['lot.revenue'] = lotTotalRevenue;
    marketableLotEntity.properties.landUseParams['lot.revenue.residential'] = revenueFromResidentialNoVAT;
    marketableLotEntity.properties.landUseParams['lot.revenue.office'] = officeRevenue;
    marketableLotEntity.properties.landUseParams['lot.revenue.retail'] = retailRevenue;
    marketableLotEntity.properties.landUseParams['lot.revenue.hotel'] = hotelRevenue;

    return marketableLotEntity;
  }

  lotCalculateDirectCosts(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const costPerM2Residential = lotDictParams['lot.cost.residential.per_area'];
    const costPerM2OfficeReduction = lotDictParams['lot.cost.reduction.office_vs_residential'];
    const costPerM2RetailReduction = lotDictParams['lot.cost.reduction.retail_vs_residential'];
    const costPerM2PublicReduction = lotDictParams['lot.cost.reduction.public_vs_residential'];
    const costPerM2BalconyReduction = lotDictParams['lot.cost.reduction.balcony_vs_residential'];
    const costPerM2AboveGroundParking = lotDictParams['lot.cost.above_ground_parking.per_area'];
    const lotResidentialGFA = lotDictParams['lot.floor_area.residential.gross'];
    const lotRetailGFA = lotDictParams['lot.floor_area.retail.gross'];
    const lotOfficeGFA = lotDictParams['lot.floor_area.office.gross'];
    const lotPublicGFA = lotDictParams['lot.floor_area.public.gross'];
    const lotHotelGFA = lotDictParams['lot.floor_area.hotel.gross'];
    const lotParkingGFA = lotDictParams['lot.floor_area.parking.gross'];
    const lotNumOfUnits = lotDictParams['lot.unit.num'];
    const balconiesArea = lotDictParams['lot.balcony.floor_area.per_unit'];
    const vat = lotDictParams['lot.vat'];
    const lotCostUnderground = lotDictParams['lot.cost.underground'];
    const lotCostDemolitionPerM2 = lotDictParams['lot.cost.demolition_per_m2'];
    const lotCurrentUnitNum = lotDictParams['lot.current.unit.num'];
    const existingUnitAvgArea = lotDictParams['lot.current.unit.floor_area.avg'];
    const lotCurrentRetailArea = lotDictParams['lot.current.retail.floor_area'];
    const lotCurrentOfficeArea = lotDictParams['lot.current.office.floor_area'];
    const lotCurrentPublicArea = lotDictParams['lot.current.public.floor_area'];
    const lotCostUserDefined = lotDictParams['lot.cost.user_defined'];

    const costResidential = costPerM2Residential * lotResidentialGFA;
    const costBalconies = lotNumOfUnits * balconiesArea * costPerM2Residential * (costPerM2BalconyReduction / 100);
    const costOffice = (costPerM2Residential + costPerM2OfficeReduction) * lotOfficeGFA;
    const costRetail = (costPerM2Residential + costPerM2RetailReduction) * lotRetailGFA;
    const costHotel = (costPerM2Residential * lotHotelGFA);
    const costAboveGroundParking = costPerM2AboveGroundParking * lotParkingGFA;
    const costPublic = (costPerM2Residential + costPerM2PublicReduction) * lotPublicGFA * ((100 + vat) / 100);
    const costDemolition = (lotCurrentUnitNum * existingUnitAvgArea * 1.15 + lotCurrentRetailArea + lotCurrentOfficeArea + lotCurrentPublicArea) * lotCostDemolitionPerM2;
    const directCostTotal = costResidential + costBalconies + costOffice + costRetail + costPublic + costHotel + costAboveGroundParking + lotCostUnderground + costDemolition + lotCostUserDefined;

    marketableLotEntity.properties.landUseParams['lot.cost.direct'] = Math.floor(directCostTotal);

    return marketableLotEntity;
  }

  lotCalculateInDirectCosts(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const localFeesPerM2 = lotDictParams['lot.cost.local_fees.per_area'];
    const electricResidential = lotDictParams['lot.cost.electric.per_unit'];
    const electricCommercial = lotDictParams['lot.cost.electric.commercial.per_area'];
    const managmentOversight = lotDictParams['lot.cost.managment_oversight'];
    const legal = lotDictParams['lot.cost.legal'];
    const marketing = lotDictParams['lot.cost.marketing'];
    const planning = lotDictParams['lot.cost.planning'];
    const contingency = lotDictParams['lot.cost.contingency'];
    const lotAboveGroundGFA = lotDictParams['lot.floor_area.gross'];
    const lotUndergroundGFA = lotDictParams['lot.floor_area.underground.gross'];
    const existingUnitNum = lotDictParams['lot.current.unit.num'];
    const existingUnitAvgArea = lotDictParams['lot.current.unit.floor_area.avg'];
    const currnetOfficeFloorArea = lotDictParams['lot.current.office.floor_area'];
    const currnetRetailFloorArea = lotDictParams['lot.current.retail.floor_area'];
    const lotNumOfUnits = lotDictParams['lot.unit.num'];
    const lotRetailGFA = lotDictParams['lot.floor_area.retail.gross'];
    const lotOfficeGFA = lotDictParams['lot.floor_area.office.gross'];
    const lotHotelGFA = lotDictParams['lot.floor_area.hotel.gross'];
    const lotPublicGFA = lotDictParams['lot.floor_area.public.gross'];
    const totalDirectCosts = lotDictParams['lot.cost.direct'];
    const lotTotalRevenue = lotDictParams['lot.revenue'];
    const costUserDefined = lotDictParams['lot.cost.user_defined']

    const localFeesCost = Math.floor((lotAboveGroundGFA + lotUndergroundGFA - (existingUnitNum * existingUnitAvgArea + currnetOfficeFloorArea + currnetRetailFloorArea)) * localFeesPerM2);
    const electricityConnectionCost = Math.floor((lotNumOfUnits * electricResidential) + ((lotRetailGFA + lotOfficeGFA+ lotPublicGFA + lotHotelGFA) * electricCommercial));
    const marketingCost = Math.floor(lotTotalRevenue * (marketing) / 100);
    const planningCost = Math.floor(totalDirectCosts * (planning / 100));
    const managmentOversightCost = Math.floor(totalDirectCosts * (managmentOversight / 100));
    const legalCost = Math.floor(lotTotalRevenue * legal / 100);
    const contingencyCost = Math.floor(totalDirectCosts * contingency / 100);
    const totalIndirectCosts = localFeesCost + electricityConnectionCost + managmentOversightCost + legalCost + marketingCost + planningCost + contingencyCost + costUserDefined;

    marketableLotEntity.properties.landUseParams['lot.cost.indirect'] = Math.floor(totalIndirectCosts);

    return marketableLotEntity;
  }

  lotCalculateTenantsCosts(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const rentPerMonth = lotDictParams['lot.current.unit.rent.per_month'];
    const durationMonths = lotDictParams['lot.cost.duration.month'];
    const movingPerUnit = lotDictParams['lot.cost.moving.per_unit'];
    const legalPerUnit = lotDictParams['lot.cost.legal.per_unit'];
    let supervisionCost = lotDictParams['lot.cost.supervision'];
    const managementFee = lotDictParams['lot.cost.management_fee'];
    const existingUnitNum = lotDictParams['lot.current.unit.num'];
    const lotCurrentRetailArea = lotDictParams['lot.current.retail.floor_area'];
    const lotCurrentOfficeArea = lotDictParams['lot.current.office.floor_area'];
    const lotValueRetail = lotDictParams['lot.value.retail.per_area'];
    const lotValueOffice = lotDictParams['lot.value.office.per_area'];
    const costUserInput = lotDictParams['lot.cost.user_input'];

    const currentAreas = existingUnitNum + lotCurrentOfficeArea + lotCurrentRetailArea

    const rentResidentialCost = Math.floor(existingUnitNum * rentPerMonth * (durationMonths+2));
    const movingCost = Math.floor(existingUnitNum * movingPerUnit);
    const rentRetailCost = lotCurrentRetailArea * (lotValueRetail*0.727/12) * (durationMonths+2);
    const rentOfficeCost = lotCurrentOfficeArea * (lotValueOffice*0.727/12) * (durationMonths+2);
    const legalCost = Math.floor(existingUnitNum * legalPerUnit);
    supervisionCost = (currentAreas > 0) ? Math.floor(durationMonths * supervisionCost) : 0;
    const managementCost = Math.floor(existingUnitNum * managementFee * 12*10*0.5);

    const totalTenantsCosts = rentResidentialCost + movingCost + rentRetailCost + rentOfficeCost + legalCost + supervisionCost + managementCost + costUserInput;

    marketableLotEntity.properties.landUseParams['lot.cost.current_residents'] = Math.floor(totalTenantsCosts);

    return marketableLotEntity;
  }

  lotCalculateFinancingCost(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const lotDirectCosts = lotDictParams['lot.cost.direct'];
    const lotIndirectCosts = lotDictParams['lot.cost.indirect'];
    const lotTenantsCosts = lotDictParams['lot.cost.current_residents'];
    const fundingFees = lotDictParams['lot.cost.funding_fees'];

    const totalFinancingCosts = Math.floor((lotDirectCosts + lotIndirectCosts + lotTenantsCosts) * fundingFees / 100);
    const totalCosts = Math.floor(lotDirectCosts + lotIndirectCosts + lotTenantsCosts + totalFinancingCosts);

    marketableLotEntity.properties.landUseParams['lot.cost.financing'] = totalFinancingCosts;
    marketableLotEntity.properties.landUseParams['lot.cost.total'] = totalCosts;

    return marketableLotEntity;
  }

  lotCalculateBettermentLevy(marketableLotEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = marketableLotEntity.properties.landUseParams;

    const lotNumOfUnits = lotDictParams['lot.unit.num'];
    const mamadAreaPerUnit = lotDictParams['lot.betterment.mamad.area_per_unit'];
    const valuePerM2Residential = lotDictParams['lot.value.residential.per_area'];
    const valuePerM2Office = lotDictParams['lot.value.office.per_area'];
    const valuePerM2Retail = lotDictParams['lot.value.retail.per_area'];
    const officeRevenue = lotDictParams['lot.revenue.office'];
    const retailRevenue = lotDictParams['lot.revenue.retail'];
    const hotelRevenue = lotDictParams['lot.revenue.hotel'];
    const lotCurrentRetailArea = lotDictParams['lot.current.retail.floor_area'];
    const lotCurrentOfficeArea = lotDictParams['lot.current.office.floor_area'];
    const balconiesAreaPerUnit = lotDictParams['lot.balcony.floor_area.per_unit'];
    const revenueFromBalconies = lotDictParams['lot.balcony.revenue_ratio'];
    const lotNetAreaResidential = lotDictParams['lot.floor_area.residential.net'];
    const vat = lotDictParams['lot.vat'];   
    const developerProfitRatio = lotDictParams['lot.betterment.developer_profit_ratio'];
    const lotCostDirect = lotDictParams['lot.cost.direct'];
    const lotCostIndirect = lotDictParams['lot.cost.indirect'];
    const lotCostFinancing = lotDictParams['lot.cost.financing'];
    const numOfExistingUnits = lotDictParams['lot.current.unit.num'];
    const avgExistingUnitSize = lotDictParams['lot.current.unit.floor_area.avg'];
    const newToExistingValueRatioResidential = lotDictParams['lot.betterment.new_to_existing_value_ratio.residential'];
    const newToExistingValueRatioRetailOffice = lotDictParams['lot.betterment.new_to_existing_value_ratio.retail_office'];
    const bettermentAdditionalCosts = lotDictParams['lot.betterment.additional_costs'];

    const newUnitsTotalRevenue = (((-mamadAreaPerUnit + (balconiesAreaPerUnit * revenueFromBalconies / 100)) * lotNumOfUnits) + lotNetAreaResidential) * valuePerM2Residential;
    const newUnitsTotalRevenueNoVAT = newUnitsTotalRevenue / ((100 + vat) / 100);
    const lotTotalNewBuildingsRevenue = newUnitsTotalRevenueNoVAT + officeRevenue + retailRevenue + hotelRevenue;
    const lotTotalNewBuildingsRevenueWithoutProfit = lotTotalNewBuildingsRevenue / ((developerProfitRatio + 100) / 100);
    const landValue = lotTotalNewBuildingsRevenueWithoutProfit - (lotCostDirect * (100 + bettermentAdditionalCosts) / 100); // check if correct
    const existingUnitsValue = numOfExistingUnits * avgExistingUnitSize * valuePerM2Residential * newToExistingValueRatioResidential / 100 / ((100 + vat) / 100);
    const existingRetailOfficeValue = lotCurrentRetailArea * valuePerM2Retail * newToExistingValueRatioRetailOffice / 100 + lotCurrentOfficeArea * valuePerM2Office * newToExistingValueRatioRetailOffice / 100;
    const lotTotalRevenue = Math.max(0, landValue - existingUnitsValue - existingRetailOfficeValue);
    const bettermentLevy25 = lotTotalRevenue * 0.25;
    const bettermentLevy50 = lotTotalRevenue * 0.5;
    const bettermentLevy75 = lotTotalRevenue * 0.75;

    marketableLotEntity.properties.landUseParams['lot.land_value'] = Math.round(landValue);
    marketableLotEntity.properties.landUseParams['lot.cost.betterment_levy.25%'] = Math.round(bettermentLevy25);
    marketableLotEntity.properties.landUseParams['lot.cost.betterment_levy.50%'] = Math.round(bettermentLevy50);
    marketableLotEntity.properties.landUseParams['lot.cost.betterment_levy.75%'] = Math.round(bettermentLevy75);

    return marketableLotEntity;
  }

  lotCalculatePurchaseTax(lotDictEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const landValue = lotDictEntity.properties.landUseParams['lot.land_value'];
    const lotNetAreaResidential = lotDictEntity.properties.landUseParams['lot.floor_area.residential.net'];
    const numOfExistingUnits = lotDictEntity.properties.landUseParams['lot.current.unit.num'];
    const avgExistingUnitSize = lotDictEntity.properties.landUseParams['lot.current.unit.floor_area.avg'];
    const additionalAreaForExistingUnit = lotDictEntity.properties.landUseParams['lot.current.unit.floor_area.additional_area'];
    const purchaseTax = lotDictEntity.properties.landUseParams['lot.cost.purchase_tax'];
    const lotTotalCosts = lotDictEntity.properties.landUseParams['lot.cost.total'];

    const directCostTotal = lotDictEntity.properties.landUseParams['lot.cost.direct'];
    const totalIndirectCosts = lotDictEntity.properties.landUseParams['lot.cost.indirect'];
    const totalTenantsCosts = lotDictEntity.properties.landUseParams['lot.cost.current_residents'];
    const totalFinancingCosts = lotDictEntity.properties.landUseParams['lot.cost.financing'];
    const bettermentLevy25 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.25%'];
    const bettermentLevy50 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.50%'];
    const bettermentLevy75 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.75%'];
    const marketing = lotDictEntity.properties.landUseParams['lot.cost.marketing'];
    const planning = lotDictEntity.properties.landUseParams['lot.cost.planning'];
    const legal = lotDictEntity.properties.landUseParams['lot.cost.legal'];
    const lotTotalRevenue = lotDictEntity.properties.landUseParams['lot.revenue'];


    const existingToNetAreaRatio = numOfExistingUnits * (avgExistingUnitSize + additionalAreaForExistingUnit)/ lotNetAreaResidential
    const costIndirectForPurchaseTax = totalIndirectCosts - lotTotalRevenue * (marketing + legal)/ 100
    const costPurchaseTax0betterment = (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100
    const costPurchaseTax25betterment = (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + bettermentLevy25+ totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100
    const costPurchaseTax50betterment = (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + bettermentLevy50 + totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100
    const costPurchaseTax75betterment = (directCostTotal * existingToNetAreaRatio + costIndirectForPurchaseTax * existingToNetAreaRatio + totalTenantsCosts + bettermentLevy75 + totalFinancingCosts * existingToNetAreaRatio) * purchaseTax / 100
    
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.0betterment.result'] = costPurchaseTax0betterment;
    lotDictEntity.properties.landUseParams['lot.cost.0betterment.total'] = Math.round(lotTotalCosts + costPurchaseTax0betterment);
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.25betterment.result'] = costPurchaseTax25betterment;
    lotDictEntity.properties.landUseParams['lot.cost.25betterment.total'] = Math.round(lotTotalCosts + costPurchaseTax25betterment + bettermentLevy25);
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.50betterment.result'] = costPurchaseTax50betterment;
    lotDictEntity.properties.landUseParams['lot.cost.50betterment.total'] = Math.round(lotTotalCosts + costPurchaseTax50betterment + bettermentLevy50);
    lotDictEntity.properties.landUseParams['lot.cost.purchase_tax.75betterment.result'] = costPurchaseTax75betterment;
    lotDictEntity.properties.landUseParams['lot.cost.75betterment.total'] = Math.round(lotTotalCosts + costPurchaseTax75betterment + bettermentLevy75);


    return lotDictEntity;
  }

  lotCalculateProfit(lotDictEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    // console.log("lotCalculateProfit")
    // const lotTotalCosts = lotDictEntity.properties.landUseParams['lot.cost.total'];
    // const lotTotalRevenue = lotDictEntity.properties.landUseParams['lot.revenue'];
    // const bettermentLevy25 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.25%'];
    // const bettermentLevy50 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.50%'];
    // const bettermentLevy75 = lotDictEntity.properties.landUseParams['lot.cost.betterment_levy.75%'];

    // const profitBetterment0 = lotTotalRevenue - lotTotalCosts;
    // const profitCostRatioBetterment0 = profitBetterment0 / lotTotalCosts;
    // const profitBetterment25 = lotTotalRevenue - lotTotalCosts - bettermentLevy25;
    // const profitCostRatioBetterment25 = profitBetterment25 / lotTotalCosts;
    // const profitBetterment50 = lotTotalRevenue - lotTotalCosts - bettermentLevy50;
    // const profitCostRatioBetterment50 = profitBetterment50 / lotTotalCosts;
    // const profitBetterment75 = lotTotalRevenue - lotTotalCosts - bettermentLevy75;
    // const profitCostRatioBetterment75 = profitBetterment75 / lotTotalCosts;

    const lotTotalRevenue = lotDictEntity.properties.landUseParams['lot.revenue'];
    const lotTotalCosts0betterment = lotDictEntity.properties.landUseParams['lot.cost.0betterment.total'];
    const lotTotalCosts25betterment = lotDictEntity.properties.landUseParams['lot.cost.25betterment.total'];
    const lotTotalCosts50betterment = lotDictEntity.properties.landUseParams['lot.cost.50betterment.total'];
    const lotTotalCosts75betterment = lotDictEntity.properties.landUseParams['lot.cost.75betterment.total'];

    const profitBetterment0 = lotTotalRevenue - lotTotalCosts0betterment;
    const profitCostRatioBetterment0 = profitBetterment0 / lotTotalCosts0betterment;
    const profitBetterment25 = lotTotalRevenue - lotTotalCosts25betterment;
    const profitCostRatioBetterment25 = profitBetterment25 / lotTotalCosts25betterment;
    const profitBetterment50 = lotTotalRevenue - lotTotalCosts50betterment;
    const profitCostRatioBetterment50 = profitBetterment50 / lotTotalCosts50betterment;
    const profitBetterment75 = lotTotalRevenue - lotTotalCosts75betterment;
    const profitCostRatioBetterment75 = profitBetterment75 / lotTotalCosts75betterment;

    lotDictEntity.properties.landUseParams['lot.profit.betterment_0%'] = profitBetterment0;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.betterment_0%'] = profitCostRatioBetterment0;
    lotDictEntity.properties.landUseParams['lot.profit.betterment_25%'] = profitBetterment25;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.betterment_25%'] = profitCostRatioBetterment25;
    lotDictEntity.properties.landUseParams['lot.profit.betterment_50%'] = profitBetterment50;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.betterment_50%'] = profitCostRatioBetterment50;
    lotDictEntity.properties.landUseParams['lot.profit.betterment_75%'] = profitBetterment75;
    lotDictEntity.properties.landUseParams['lot.profit_on_cost_ratio.betterment_75%'] = profitCostRatioBetterment75;

    return lotDictEntity;
  }

  lotCalculateProgram(lotDictEntity: Feature<Geometry, EntityProperties>): Feature<Geometry, EntityProperties> {
    const lotDictParams = lotDictEntity.properties.landUseParams;

    const householdSize = lotDictParams['lot.household_size'];
    // const conversionRatio = lotDictParams['lot.conversion_ratio'];
    const annualChildRatio = lotDictParams['lot.annual_child_ratio'];
    const isRenewal = lotDictParams['lot.renewal_or_new'] === 'Urban Renewal';
    const numOfExistingUnits = lotDictParams['lot.current.unit.num'];
    const plannedUnits = lotDictParams['lot.unit.num'] - numOfExistingUnits;

    const totalPopulation = plannedUnits * householdSize;
    const annualChildren = Utils.roundUp(totalPopulation * annualChildRatio / 100);

    const publicAreaNeeded = 19 * plannedUnits;

    const greenAreaRatio = isRenewal ? 10 : 16;
    const greenAreaNeeded = greenAreaRatio * plannedUnits;

    const daycareClasses = Utils.roundUp(annualChildren * 0.5 * 3 / 20);
    const daycareArea = Utils.roundUp(daycareClasses / 5) * 1000
    // / 1 dunam to every 5 classes

    const kindergartenClasses = Utils.roundUp(annualChildren * 3 / 30);
    const kindergartenArea = Utils.round(kindergartenClasses * 0.5) * 1000;

    const elementaryClasses = Utils.roundUp(annualChildren * 6 / 27);

    let elementaryArea: number;

    if (elementaryClasses <= 12) {
      elementaryArea = isRenewal ? 3600 : 4800;
    } else if (elementaryClasses <= 18) {
      elementaryArea = isRenewal ? 5400 : 7200;
    } else {
      elementaryArea = isRenewal ? 7200 : 9600;
    }

    lotDictEntity.properties.landUseParams['lot.public_needs.population'] = totalPopulation;
    lotDictEntity.properties.landUseParams['lot.public_needs.anual_children'] = annualChildren;
    lotDictEntity.properties.landUseParams['lot.public_needs.public_area_needed'] = publicAreaNeeded;
    lotDictEntity.properties.landUseParams['lot.public_needs.green_area_needed'] = greenAreaNeeded;
    lotDictEntity.properties.landUseParams['lot.public_needs.daycare.classes' ] = daycareClasses;
    lotDictEntity.properties.landUseParams['lot.public_needs.daycare.area' ] = daycareArea;
    lotDictEntity.properties.landUseParams['lot.public_needs.kindergarten.classes'] = kindergartenClasses;
    lotDictEntity.properties.landUseParams['lot.public_needs.kindergarten.area'] = kindergartenArea;
    lotDictEntity.properties.landUseParams['lot.public_needs.elementary.classes'] = elementaryClasses;
    lotDictEntity.properties.landUseParams['lot.public_needs.elementary.area'] = elementaryArea;

    return lotDictEntity
  }
  
}
