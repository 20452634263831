import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {StateService} from '../../services/state/state.service';
import {ENTITY_GEOMETRIC_TYPES, ENTITY_CATEGORIES, PANEL_TABS, ENTITY_ACCESS_TYPE} from '../../enums/enums';
import {
  PLANBOUNDARY_LANDUSE,
  SCOPELINE_INDEX_OLD,
  BLUELINE_MAVAT_CODE,
  DEFAULT_ENTITY_LANDUSE, ENTITIES_TYPES, TEMP_NAME,
  URBAN_S3_URL
} from '../../config';
import {Feature, Geometry} from 'geojson';
import * as TurfCenter from '@turf/center';
import {Subject, combineLatest, forkJoin, of} from 'rxjs';
import {ImportEntitiesActionTypes} from '../../shared/types/types';
import {NzMessageService} from 'ng-zorro-antd/message';
import {EntityProperties} from '../../models/entity-properties';
import Utils from '../../utils/utils';
import {EntitiesService} from '../../services/entities/entities.service';
import {PlansService} from '../../services/plans/plans.service';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import {mavatUrbanDashboard} from '../../enums/mavat-urban-dashboard';
import { StoresManagerService } from 'src/app/services/storesManagerService/stores-manager.service';
import { ProjectStateStoreService } from 'src/app/services/projectStateStore/project-state-store.service';
import {switchMap, take, takeUntil,} from 'rxjs/operators';
import { AddUpdateDeleteEntitiesParams, EntityEntry } from 'src/app/models/entity-map';
import { IParentChildRelationship } from 'src/app/models/parent-child-relationship';
import { ProjectStoreService } from 'src/app/services/projectStore/project-store.service';
import { BuildingDataService } from 'src/app/services/building-pipeline/building-data.service';
import { LeafletService } from './panels/panel-map-main/leaflet-map/leaflet.service';
import { GeomanControlService } from './panels/panel-map-main/leaflet-map/services/geoman-control.service';
import { MenuDataStoreService } from 'src/app/services/menuDataStore/menu-data-store.service';

@Injectable({
  providedIn: 'root'
})
export class MainPageService implements OnDestroy {
  enterPressed$: Subject<void> = new Subject<void>();
  escPressed$: Subject<void> = new Subject<void>();
  resizeMap$: Subject<void> = new Subject<void>();

  confirmModal?: NzModalRef;

  componentDestroyed$: Subject<boolean> = new Subject();

  private keyActions: { [key: string]: () => void } = {
    'e': () => {
      this.editEntity();
      this.leafletService.nextCloseMenu();
    },
    'd': () => {
      this.geomanControlService.handleEntityDuplication();
      this.leafletService.nextCloseMenu();
    },
    's': () => {
      this.geomanControlService.handleEntityScale();
      this.leafletService.nextCloseMenu();
    },
    'm': () => {
      this.geomanControlService.handleMoveEntity();
      this.leafletService.nextCloseMenu();
    },
    'r': () => {
      this.geomanControlService.handleRotateEntity();
      this.leafletService.nextCloseMenu();
    }
  };

  private _flyToCenterSource = new Subject<any>();
  flyToCenter$ = this._flyToCenterSource.asObservable();

  constructor(
    private projectStoreService: ProjectStoreService,
    private storesManagerService: StoresManagerService,
    private projectStateStoreService: ProjectStateStoreService,
    private nzMessageService: NzMessageService,
    private buildingDataService: BuildingDataService,
    private geomanControlService: GeomanControlService,
    private leafletService: LeafletService,
    private modal: NzModalService) {
  }


  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }


  undo() {
    // const currentIndex = this.stateService.historyIndex;
    // const prevIndex = 0 <= currentIndex - 1 ? currentIndex - 1 : null;
    // if (prevIndex === null || prevIndex < 0) {
    //   this.stateService.historyIndex = 0;
    //   return;
    // }
    // this.stateService.prevHistoryStep(currentIndex);
  }

  redo() {
    // const currentIndex = this.stateService.historyIndex;
    // const nextIndex = currentIndex + 1 < this.stateService.stateHistory.length ? currentIndex + 1 : null;
    // if (!nextIndex) {
    //   return;
    // }
    // this.stateService.changeHistoryState(nextIndex);
  }

  
  handleShiftAndKey(key: string) {
    if (this.keyActions[key]) {
      this.keyActions[key]();
    }
  }

  editEntity(): void {
    this.projectStateStoreService.selectedEntities$.pipe(
      take(1)
    ).subscribe(selectedEntitiesArray => {
      if(!selectedEntitiesArray || selectedEntitiesArray.length === 0){
        this.nzMessageService.warning('Please select an entity to edit');
      }else{
        this.leafletService.nextEnableEntitesEditMode(selectedEntitiesArray.map(entity => entity.id.toString()))
      }
    });
  }


  openDeleteModal() {
    this.projectStateStoreService.selectedEntities$.pipe(
      take(1)
    ).subscribe(selectedEntitiesArray => {
      const buildingEntities = selectedEntitiesArray.filter(entity => entity.properties.entityType === 'building');
      let marketableLotsToRecalculate = [];
  
      if (buildingEntities.length > 0) {
        this.projectStateStoreService.currentPlan$.pipe(
          take(1)
        ).subscribe(currentPlan => {
          const buildingEntitiesWithParents = buildingEntities.filter(entity => currentPlan.planConfig[entity.id]?.parentId);
  
          if (buildingEntitiesWithParents.length > 0) {
            const parentIds = buildingEntitiesWithParents.map(entity => currentPlan.planConfig[entity.id]?.parentId).filter(parentId => parentId);
  
            this.projectStoreService.getEntitiesByIds(parentIds).pipe(
              take(1)
            ).subscribe(parentEntities => {
              marketableLotsToRecalculate = parentEntities
                // .filter(parentEntity => parentEntity.properties.entityType === "marketable lot" && !selectedEntitiesArray.includes(parentEntity));
                .filter(parentEntity => parentEntity.properties.entityCategory === ENTITY_CATEGORIES.PARCEL && !selectedEntitiesArray.includes(parentEntity));
            });
          }
        });
      }
  
      const entitiesNames = selectedEntitiesArray.reduce((prev, cur) => {
        prev.push(cur.properties.name);
        return prev;
      }, []);
  
  
      const text = entitiesNames.length === 1 ? entitiesNames[0] : entitiesNames.length > 1 ? entitiesNames.length + ' entities' : '';
  
      this.confirmModal = this.modal.confirm({
        nzTitle: `Are you sure you wish to delete ${text} ?`,
        nzOkText: 'Yes',
        nzCancelText: 'No',
        nzOnCancel: () => {},
        nzOnOk: () => {
          this.storesManagerService.deleteEntitiesFromProjectAndPlanConfig(selectedEntitiesArray).pipe(
            take(1),
            switchMap(() => forkJoin([
              this.projectStateStoreService.getCurrentPlanEntities().pipe(take(1)),
              this.projectStateStoreService.currentPlan$.pipe(take(1))
            ])),
            switchMap(([currentPlanEntities, currentPlan]) => {
              const recalculations$ = marketableLotsToRecalculate
                .map(lotEntity => {
                  const recalculatedEntity = this.buildingDataService.resetAndCalculateLot(lotEntity, currentPlan, currentPlanEntities);
                  return this.storesManagerService.updateEntity(recalculatedEntity).pipe(take(1));
                });
  
              return recalculations$.length ? forkJoin(recalculations$) : of([]);
            })
          ).subscribe();
        }
      });
    });
  }
  

  sendCenter(Center: any) {
    this._flyToCenterSource.next(Center);
  }

}
