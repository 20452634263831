import * as TurfTruncate from '@turf/truncate';
import {GEOJSON_PRECISION} from '../config';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export default class Utils {

  static tryParseFloat(i: any) {
    try {
      return parseFloat(i.toString());
    } catch (e) {

    }
    return 0;
  }

  static clone = (obj: {}) => {
    if (obj !== 0 && !obj) {
      return null;
    }

    return JSON.parse(JSON.stringify(obj));
  }

  static getFromStorage(key: string) {
    try {
      let s = localStorage.getItem(key);
      return JSON.parse(s);
    } catch (e) {
      console.error(e);
    }

  }

  static setInStorage(key: string, obj: object) {
    try {
      let s = JSON.stringify(obj);
      localStorage.setItem(key, s);
    } catch (e) {
      console.error(e);
    }
  }

  static setInSessionStorage(key: string, obj: object) {
    try {
      let s = JSON.stringify(obj);
      sessionStorage.setItem(key, s);
    } catch (e) {
      console.error(e);
    }
  }

  static getFromSessionStorage(key: string) {
    try {
      let s = sessionStorage.getItem(key);
      return JSON.parse(s);
    } catch (e) {
      console.error(e);
    }

  }

  static removeItemFromSessionStorage(key: string) {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      console.error(e);
    }
  }

  static async readFile(file: File) {
    let reader = new FileReader();
    return await new Promise((resolve, reject) => {
      reader.onload = () => {
        try {
          resolve(reader.result.toString());
        } catch (e) {
          reject(e);
        }
      };

      reader.readAsText(file);
    });
  }

  static async parseGeoJSONFile(file: File) {
    try {
      const fileContentString: any = await Utils.readFile(file);
      return JSON.parse(fileContentString);
    } catch (e) {
      console.error(e);
    }
  }

  static readFileObs(file: File): Observable<string> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onload = () => {
        observer.next(reader.result.toString());
        observer.complete();
      };
      reader.onerror = (error) => {
        observer.error(error);
      };
      reader.readAsText(file);
    });
  }
  
  static parseGeoJSONFileObs(file: File): Observable<any> {
    return Utils.readFileObs(file).pipe(
      map((fileContentString) => JSON.parse(fileContentString)),
      catchError((error) => {
        console.error('Error parsing GeoJSON file:', error);
        return throwError('Failed to parse GeoJSON file.');
      })
    );
  }

  // deprecated
  static isLot(entityType) {
    return entityType.includes('lot');
  }

  static round(value: number, decimalPlaces?: number) {
    if (isNaN(value)) {
      return value;
    }
    let decimal = 100;
    let newDecimal;
    if (decimalPlaces) {
      let dec = '1';
      for (let i = 0; i < decimalPlaces; i++) {
        dec += '0';
      }
      newDecimal = parseInt(dec, 10);
    }
    decimal = decimalPlaces ? newDecimal : decimal;
    return Math.round(value * decimal) / decimal;
  }

  static roundUp(value, decimalPlaces = 0) {
    const factor = Math.pow(10, decimalPlaces);
    return Math.ceil(value * factor) / factor;
  }

  static truncateGeoJson(feature) {
    TurfTruncate.default(feature, {coordinates: 2, mutate: true, precision: GEOJSON_PRECISION});
  }

  static formArrayDifference(mutatedArray, oldArray, keys?) {
    if (mutatedArray.length <= oldArray.length && !keys) {
      const mutatedElementArr = oldArray.filter(x => !mutatedArray.includes(x));
      const type = mutatedArray.length < oldArray.length ? 'remove' : 'set';
      return {type, index: oldArray.indexOf(mutatedElementArr[0])};
    }
    if (mutatedArray.length < oldArray.length) {
      for (let i = 0; i < oldArray.length; i++) {
        if (!mutatedArray[i]) {
          return {type: 'remove', index: i};
        }
        if (mutatedArray[i][keys[0]] !== oldArray[i][keys[0]]) {
          return {type: 'remove', index: i};
        }
      }
    }
    if (oldArray.length < mutatedArray.length) {
      return {type: 'add', key: mutatedArray[mutatedArray.length - 1]};
    }

    if (keys) {
      let type = 'set';
      for (let i = 0; i < mutatedArray.length; i++) {
        for (const key of keys) {
          if (mutatedArray[i][key] !== oldArray[i][key]) {
            return {type, key, index: i};
          }
        }
      }
    }
    return {type: undefined, key: undefined};
  }

  // static formArrayDifferenceNew(mutatedArray, oldArray, keys) {
  //   if (mutatedArray.length < oldArray.length) {
  //       for (let i = 0; i < oldArray.length; i++) {
  //           if (!mutatedArray[i]) {
  //               return {type: 'remove', index: i};
  //           }
  //           if (keys) {
  //               for (const key of keys) {
  //                   if (mutatedArray[i][key] !== oldArray[i][key]) {
  //                       return {type: 'remove', index: i};
  //                   }
  //               }
  //           } else {
  //               if (mutatedArray[i] !== oldArray[i]) {
  //                   return {type: 'remove', index: i};
  //               }
  //           }
  //       }
  //   }

  //   if (oldArray.length < mutatedArray.length) {
  //       return {type: 'add', key: mutatedArray[mutatedArray.length - 1]};
  //   }

  //   if (keys) {
  //       for (let i = 0; i < mutatedArray.length; i++) {
  //           for (const key of keys) {
  //               if (mutatedArray[i][key] !== oldArray[i][key]) {
  //                   return {type: 'set', key, index: i};
  //               }
  //           }
  //       }
  //   } else {
  //       for (let i = 0; i < mutatedArray.length; i++) {
  //           if (mutatedArray[i] !== oldArray[i]) {
  //               return {type: 'set', index: i};
  //           }
  //       }
  //   }

  //   return {type: undefined, key: undefined};
  // }

  static formArrayDifferenceNew(mutatedArray, oldArray, keys) {
    function deepEqual(a, b) {
        if (a === b) return true;

        if (Array.isArray(a) && Array.isArray(b)) {
            if (a.length !== b.length) return false;
            return a.every((val, index) => deepEqual(val, b[index]));
        }

        if (typeof a === 'object' && typeof b === 'object' && a != null && b != null) {
            const keysA = Object.keys(a);
            const keysB = Object.keys(b);
            if (keysA.length !== keysB.length) return false;
            return keysA.every(key => deepEqual(a[key], b[key]));
        }

        return false;
    }

    if (mutatedArray.length < oldArray.length) {
        for (let i = 0; i < oldArray.length; i++) {
            if (!mutatedArray[i]) {
                return {type: 'remove', index: i};
            }
            if (keys) {
                for (const key of keys) {
                    const val1 = mutatedArray[i][key];
                    const val2 = oldArray[i][key];
                    if (!deepEqual(val1, val2)) {
                        return {type: 'remove', index: i};
                    }
                }
            } else {
                if (!deepEqual(mutatedArray[i], oldArray[i])) {
                    return {type: 'remove', index: i};
                }
            }
        }
    }

    if (oldArray.length < mutatedArray.length) {
        return {type: 'add', key: mutatedArray[mutatedArray.length - 1]};
    }

    if (keys) {
        for (let i = 0; i < mutatedArray.length; i++) {
            for (const key of keys) {
                const val1 = mutatedArray[i][key];
                const val2 = oldArray[i][key];
                if (!deepEqual(val1, val2)) {
                    return {type: 'set', key, index: i};
                }
            }
        }
    } else {
        for (let i = 0; i < mutatedArray.length; i++) {
            if (!deepEqual(mutatedArray[i], oldArray[i])) {
                return {type: 'set', index: i};
            }
        }
    }

    return {type: undefined, key: undefined};
  }


  static parseNumber(num) {
    if (!num) {
      return null;
    }
    return parseFloat(num.toString());
  }

  static parseTwoNumbers(a, b) {
    if ((!a || !b) && a != 0 && b != 0) {
      return null;
    }
    a = parseFloat(a.toString());
    b = parseFloat(b.toString());
    return [a, b];
  }

  static multiply(a, b) {
    const d = this.parseTwoNumbers(a, b);
    return d ? d[0] * d[1] : null;
  }

  static multiplyBy(a, b, num) {
    const d = this.parseTwoNumbers(a, b);
    return d ? d[0] * d[1] * num : null;
  }

  static divide(numerator, denominator) {
    const d = this.parseTwoNumbers(numerator, denominator);
    return d[1] != 0 ? d[0] / d[1] : null;
  }

  static sum(a, b) {
    return this.parseNumber(a) + this.parseNumber(b);
  }

  static sumArray(prevValuesArray: any[], newValue) {

    let sum = prevValuesArray.reduce((prev, cur) => {
      prev += this.parseNumber(cur);
      return prev;
    }, 0);

    sum += this.parseNumber(newValue);
    return sum;

  }

  static sumArrayTo1(param: { value: number; key: string }, prevValues, keys: string[]): { value: number; key: string }[] {
    let array = keys.map(key => {
      return {key, value: prevValues[key] || 0};
    });
    array = array.filter(kv => kv.key !== param.key);
    if (array.length === 0) {
      return [{key: param.key, value: prevValues[param.key]}];
    }
    if (array.length === 1) {
      return [{key: array[0].key, value: 1 - param.value}];
    }

    const valuesArray = array.map(arr => arr.value);
    const maxValueIndex = valuesArray.indexOf(Math.max(...valuesArray));
    const remainder = 1 - param.value;
    if (array[maxValueIndex].value >= 1) {
      return array.map((value, index) => {
        if (index === maxValueIndex) {
          return {key: value.key, value: remainder};
        }
        return {key: value.key, value: value.value};
      });
    }

    const arrayNoZeros = array.filter((usage) => {
      return usage.value > 0;
    })
    const sumNoZeros = arrayNoZeros.reduce((prev, cur) => {
      const noRatioKey = cur.key.split('.ratio')[0];
      prev += this.parseNumber(prevValues[noRatioKey]);
      return prev;
    },0);

    if (sumNoZeros === 0) {
      return array;
    }

    const newUsageRealValue = (param.value * sumNoZeros) / (1 - param.value);
    const newSumOfAllUsages = newUsageRealValue + sumNoZeros;
    return array.map((usage) => {
      const noRatioKey = usage.key.split('.ratio')[0];
      const gfaValue = this.parseNumber(prevValues[noRatioKey]);
      const parsedNum = this.parseNumber(gfaValue);
      usage.value = parsedNum / newSumOfAllUsages ? parsedNum / newSumOfAllUsages : 0;
      return usage
    });
  }

  static sumArrayOfArraysTo1(tempData: any, prevValuesArray: any[], strings: string[]) {
    return [];
  }

  static copyToClipboard(text) {
    let input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    let result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
  }

  static isValidUrl(url: string) {
    try {
      return Boolean(new URL(url));
    } catch(e){
      return false;
    }
  }

  static downloadObjectAsJson(exportObj, exportName) {
    let dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
    let downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', exportName + '.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  // input can be either rgba, rgb or hex
  static getHexColor(color: string) {
    if (!color ) {
      return '#cdcdcd';
    }
    if (color.includes('#') || !color.includes('rgba') || !color.includes('rgb')) {
      return color;
    }
    const rgb = color.replace('rgba(', '').replace('rgb(', '').replace(')', '').split(',').map(str => parseFloat(str));
    let outParts = [
      rgb[0].toString(16),
      rgb[1].toString(16),
      rgb[2].toString(16)
    ];
    if (rgb.length === 4) {
      outParts.push(Math.round(rgb[3] * 255).toString(16).substring(0, 2));
    }

    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    });
    return ('#' + outParts.join(''));
  }

  static capitalizeWords(words) {
    if (!words) {
      return null;
    }
    const splitStr = words.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
  return splitStr.join(' ');
  }

  static getInitials(name: string = 'Urban Dashboard'): string {
    let words = name.split(' ');
    let initials = words.slice(0, 2).reduce((acc, word) => acc + word.charAt(0).toUpperCase(), '');
    return initials;
  }

}
