import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import type {  Feature, Geometry  } from 'geojson';
import { NzDropdownMenuComponent } from 'ng-zorro-antd/dropdown';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { from, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { MainPageService } from 'src/app/components/main-page/main-page.service';
import { LeafletService } from 'src/app/components/main-page/panels/panel-map-main/leaflet-map/leaflet.service';
import { URBAN_S3_FOLDERS } from 'src/app/config';
import { DROPDOWN_STATES, ENTITY_CATEGORIES, ENTITY_GEOMETRIC_TYPES } from 'src/app/enums/enums';
import { AttachedFile, EntityProperties } from 'src/app/models/entity-properties';
import { PlanInterface } from 'src/app/models/plan-interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CrudService } from 'src/app/services/crud/crud.service';
import { EntitiesService } from 'src/app/services/entities/entities.service';
import { MenuDataStoreService } from 'src/app/services/menuDataStore/menu-data-store.service';
import { StoresManagerService } from 'src/app/services/storesManagerService/stores-manager.service';
import {NzModalRef, NzModalService} from 'ng-zorro-antd/modal';
import { EntityMap } from 'src/app/models/entity-map';
import { ProjectStateStoreService } from 'src/app/services/projectStateStore/project-state-store.service';
import { GeomanControlService } from 'src/app/components/main-page/panels/panel-map-main/leaflet-map/services/geoman-control.service';
import { BuildingPipelineService } from 'src/app/services/building-pipeline/building-pipeline.service';
import Utils from 'src/app/utils/utils';


@Component({
  selector: 'app-entity-edit-menu',
  templateUrl: './entity-edit-menu.component.html',
  styleUrls: ['./entity-edit-menu.component.scss']
})
export class EntityEditMenuComponent implements OnInit, AfterViewInit{
  
  @ViewChild(NzDropdownMenuComponent, { static: true }) menu: NzDropdownMenuComponent;
  @ViewChild('editMenu', { static: true }) dropdownMenu: NzDropdownMenuComponent;
  editMenu: NzDropdownMenuComponent;
  @Input() feature: Feature<Geometry, EntityProperties>;
  @Input() entityTypesToTurn: any;
  @Input() currentPlan: PlanInterface;
  @Input() dropdownState: DROPDOWN_STATES;

  private confirmModal: NzModalRef<unknown>;


  selectedEntitiesMap: EntityMap;
  selectedEntities: Feature<Geometry, EntityProperties>[];
  currentEntities: EntityMap;
  componentDestroyed$: Subject<boolean> = new Subject();
  isPlanLocked: boolean;
  allParams
  DROPDOWN_STATES = DROPDOWN_STATES;
  ENTITY_GEOMETRIC_TYPES = ENTITY_GEOMETRIC_TYPES;

  get isPolygon(){
    return this.feature.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON;
  }



  constructor(
    private leafletService: LeafletService,
    private menuDataStoreService: MenuDataStoreService,
    private entitiesService: EntitiesService,
    private nzMessageService: NzMessageService, 
    private crudService: CrudService, 
    private authService: AuthService,
    private storesManagerService: StoresManagerService,
    private mainPageService: MainPageService,
    private modal: NzModalService,
    private projectStateStoreService: ProjectStateStoreService,
    private geomanControlsService: GeomanControlService,
    private buildingPipelineService: BuildingPipelineService
  ) { }

  ngOnInit(): void {
    this.subscriptions();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  toUpperCase(string){
    return string.toUpperCase().replace(/ /g, '').replace(/-/g, '');
  }


  subscriptions() {
    this.menuDataStoreService.menuData$.pipe(
      takeUntil(this.componentDestroyed$),
      filter(data => data !== null)
    ).subscribe(allParams => {
      this.allParams = allParams;
    });

    this.projectStateStoreService.selectedEntities$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(selectedEntities => {
      // FRN
      this.selectedEntities = selectedEntities;
      this.selectedEntitiesMap = this.storesManagerService.entitiesArrayToEntityMap(selectedEntities);
    });

    this.projectStateStoreService.getCurrentPlanEntities().pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe(currentEntityMap => {
      this.currentEntities = currentEntityMap
    })


    // this.menu.mouseState$.pipe(
    // ).subscribe(() => {
    //   // handle the case when the entity is chosen from the map
    //   console.log('mouseState$')
    // });
    
  }



  openTurnToWarningModal(turnToType, turnToCategory) {
    const entityTypeReal = this.entitiesService.findKeyByDisplayName(this.allParams[turnToCategory].landUse, turnToType);
    
    // Check if turnToType is 'scope', 'plan_boundary', or 'normative_area'
    if (['scope', 'plan_boundary', 'normative_area'].includes(entityTypeReal)) {
      // Subscribe to getCurrentPlanEntities only if the condition is met
      this.projectStateStoreService.getCurrentPlanEntities().pipe(
        take(1)
      ).subscribe((entities: EntityMap) => {
        const existingEntity = Object.values(entities).find(entity => entity.properties.entityType === entityTypeReal);
  
        const modalText = existingEntity 
          ? `A ${turnToType} already exists in the plan. Do you want to replace it?` 
          : `Are you sure you want to turn ${this.getEntityText()} to ${turnToType}?`;
  
        // Show the confirmation modal with the entity to delete if it exists
        this.showConfirmationModal(modalText, turnToType, turnToCategory, existingEntity);
      });
    } else {
      // If turnToType does not match, show the confirmation modal directly
      const modalText = `Are you sure you want to turn ${this.getEntityText()} to ${turnToType}?`;
      this.showConfirmationModal(modalText, turnToType, turnToCategory);
    }
  }
  
  private getEntityText(): string {
    return this.selectedEntities.length === 1 
      ? this.selectedEntities[0].properties.name 
      : this.selectedEntities.length > 1 
        ? this.selectedEntities.length + ' entities' 
        : '';
  }


  private showConfirmationModal(modalText: string, turnToType: string, turnToCategory: ENTITY_CATEGORIES, entityToDelete?: Feature<Geometry, EntityProperties>): void {
    this.confirmModal = this.modal.confirm({
      nzTitle: modalText,
      nzOkText: 'Yes',
      nzCancelText: 'No',
      nzOkDanger: !!entityToDelete,
      nzOnCancel: () => {},
      nzOnOk: () => {
        let turnEntityInto$: Observable<Feature<Geometry, EntityProperties>[]>;
  
        if (entityToDelete) {
          // Delete the existing entity first
          turnEntityInto$ = this.storesManagerService.deleteEntityFromProjectAndPlanConfig(entityToDelete).pipe(
              take(1),
              switchMap(() =>
                this.entitiesService.turnEntityInto(turnToCategory, turnToType, this.selectedEntities, this.allParams)
              )
            );
        } else {
          // Directly transform the entities
          turnEntityInto$ = this.entitiesService.turnEntityInto(turnToCategory, turnToType, this.selectedEntities, this.allParams);
        }
  
        turnEntityInto$.pipe(
            tap(transformedEntities => {
              const buildingEntities = transformedEntities.filter(entity => entity.properties.entityCategory === ENTITY_CATEGORIES.BUILDING);
              const nonBuildingEntities = transformedEntities.filter(entity => entity.properties.entityCategory !== ENTITY_CATEGORIES.BUILDING);
  
              if (buildingEntities.length > 0) {
                const landUseParamsNewBuilding = {
                  'building.service.area.method': "Auto",
                  'building.service.area.ratio': 20,
                  'building.storey.ground.height': 5,
                  'building.storey.num': 1,
                  'building.storey.residential.num': 0,
                  'building.storey.typical.height': 3.2,
                  'building.unit.floor_area.avg': 100,
                  'building.usage_per_storey': [{start: 0, end: 0, primaryUsage: 'residential', mainUsageList: [], serviceUsageList: []}]
                };

                buildingEntities.forEach(entity => {
                  this.buildingPipelineService.calcBuilding2(landUseParamsNewBuilding, entity);
                });
              }

              // Set selected entities for non-building entities
              if (nonBuildingEntities.length > 0) {
                this.storesManagerService.setSelectedEntities(nonBuildingEntities, false, false);
              }
            }),
            catchError(error => {
              console.error('Error during entity transformation or building calculation:', error);
              return of(null);
            })
        ).subscribe();
      }
    });
  }
  

  editEntity() {
    this.leafletService.nextEnableEntitesEditMode(this.selectedEntities.map(entity => entity.id.toString()))
  }

  duplicateEntity() {
    this.selectedEntities.forEach(entity => this.entitiesService.duplicateEntity(entity, this.currentEntities, this.currentPlan, this.allParams));
    this.leafletService.nextCloseMenu();
  }

  lockEntity() {
    this.selectedEntities.forEach(entity => this.entitiesService.lockEntity(entity));
  }

  unlockEntity() {
    this.selectedEntities.forEach(entity => this.entitiesService.unlockEntity(entity));
  }

  deleteEntity() {
    this.mainPageService.openDeleteModal();
  }
  
  moveEntity(){
    this.geomanControlsService.handleMoveEntity();
    this.leafletService.nextCloseMenu();
  }

  scaleEntity(){
    this.geomanControlsService.handleEntityScale();
    this.leafletService.nextCloseMenu();
  }

  rotateEntity(){
    this.geomanControlsService.handleRotateEntity();
    this.leafletService.nextCloseMenu();
  }

  exportEntity() {
    if (this.selectedEntities && this.selectedEntities.length > 0) {
      const feature: Feature<Geometry, EntityProperties> = this.selectedEntities[0];
      const exportName = `ud-${feature.properties.name}-${Date.now()}`;

      const exportObj = {
        type: 'Feature',
        geometry: feature.geometry,
        properties: feature.properties
      };

      Utils.downloadObjectAsJson(exportObj, exportName);
    } else {
      // console.warn('No selected entity to download');
      this.nzMessageService.error('No selected entity to download')
    }
  }

  getLockState(): boolean {
    return this.feature.properties.isLock;
  }

  ngAfterViewInit(): void {
      this.editMenu = this.dropdownMenu
  }

  isMatchingLandUse(entityType: string, turnToType: string): boolean {
    if (entityType && turnToType) {
      const normalize = (str: string) =>
        str.replace(/[_ ]/g, "-").toLowerCase(); // replace both underscores and spaces with hyphens
  
      return normalize(entityType) === normalize(turnToType);
    }
    return false;
  }

  getObjectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      console.error('File must be smaller than 5MB!');
      return false;
    }
  
    const validTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/tiff', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    if (validTypes.indexOf(file.type) === -1) {
      console.error('You can only upload PDF, JPEG, PNG, TIFF, DOC, or DOCX files!');
      this.nzMessageService.error('You can only upload PDF, JPEG, PNG, TIFF, DOC, or DOCX files!')
      return false;
    }
  
    return true; // Proceed with the upload if the checks pass
  };

  customRequest = (item: any): Subscription => {
    const folderName = `${URBAN_S3_FOLDERS.ATTACHED_FILES}-${this.currentPlan.projectName}-${this.currentPlan.planName}`;
    return this.crudService.uploadFileObs(item.file, folderName)
      .subscribe(
        (response) => {
          item.onSuccess(response, item.file, null); // Invoke onSuccess callback
  
          // Add the file URL to the attachedFiles array
          const attachedFile: AttachedFile = {
            url: response.url, // Assuming the response contains the URL
            originalName: item.file.name,
            uploadedBy: this.authService.getUsername(),
            mimeType: item.file.type
          };
          this.feature.properties.attachedFiles = this.feature.properties.attachedFiles || [];
          this.feature.properties.attachedFiles.push(attachedFile);
  
          // Update the entity
          this.storesManagerService.updateEntity(this.feature).pipe(
              take(1)
            ).subscribe(
            // () => console.log('Entity updated with new file'),
            error => console.error('Error updating entity', error) 
          );
        },
        (error) => {
          console.error('Upload error', error);
          item.onError(error, item.file); // Invoke onError callback
        }
      );
  };

  getIconType(category: string, item: string): string {
    // Replace any spaces or special characters as needed to match file naming conventions
    const formattedItem = item.replace(/[\s-]+/g, '_').toLowerCase();
    const formattedCategory = category.toLowerCase();
    // Construct the path to the icon file in the assets folder
    return `/assets/turnIntoMenu/${formattedCategory}/${formattedItem}.svg`;
  }

}
