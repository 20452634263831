import { Injectable } from '@angular/core';
import { Feature, Geometry } from 'geojson';
import { combineLatest, Observable, of, throwError, forkJoin } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, take, tap, retry, first } from 'rxjs/operators';
import { ENTITIES_TYPES, SCOPELINE_INDEX_OLD } from 'src/app/config';
import { BASE_MAPS, ENTITY_ACCESS_TYPE, ENTITY_CATEGORIES, PANEL_TABS, SELECT_SOURCE } from 'src/app/enums/enums';
import { AddUpdateDeleteEntitiesParams, EntityEntryMap, EntityMap } from 'src/app/models/entity-map';
import { EntityProperties } from 'src/app/models/entity-properties';
import { PlanInterface } from 'src/app/models/plan-interface';
import { InitResponse } from 'src/app/models/server-response';
import Utils from 'src/app/utils/utils';
import { CrudService } from '../crud/crud.service';
import { MenuDataStoreService } from '../menuDataStore/menu-data-store.service';
import { PlansStoreService } from '../plansStore/plans-store.service';
import { ProjectStateStoreService } from '../projectStateStore/project-state-store.service';
import { ProjectStoreService } from '../projectStore/project-store.service';
import { IParentChildRelationship } from 'src/app/models/parent-child-relationship';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HistoryService } from '../history/history.service';
import { HistoryState } from 'src/app/models/state-history.model';


@Injectable({
  providedIn: 'root'
})
export class StoresManagerService {

  managerActionFlag: boolean;

  constructor(
    private crudService: CrudService, 
    private projectStoreService: ProjectStoreService,
    private menuDataStoreService: MenuDataStoreService,
    private plansStoreService: PlansStoreService,
    private projectStateStoreService: ProjectStateStoreService,
    private historyService: HistoryService,
    private nzMessageService: NzMessageService,) { }


  loadProject(projectName) {
    this.projectStateStoreService.projectName = projectName;
    const initLoadProject$: Observable<InitResponse> = this.crudService.fetchProject(projectName).pipe(
      tap(projectData => this.menuDataStoreService.nextMenuData(projectData.menuData)),
      catchError(err => {
        console.log(err);
        // alert error to user
        return throwError(err);
      })
    );
  
    const lazyEntities$ = this.crudService.fetchLazyEntities(projectName).pipe(
      catchError(err => {
        console.log(err);
        // alert error to user
        return throwError(err);
      })
    );
  
    initLoadProject$.pipe(
      concatMap(initProjectData =>
        lazyEntities$.pipe(
          map(lazyEntities => ({
            ...initProjectData,
            entities: lazyEntities.length ? [...initProjectData.entities, ...lazyEntities] : initProjectData.entities,
          })),
          catchError(err => {
            console.log(err);
            // alert error to user
            return of(initProjectData);
          })
        )
      ),
      tap(projectData => {
        const entityMap: EntityMap = {};
        projectData.entities.forEach((entity: Feature<Geometry, EntityProperties>) => {
          entityMap[entity.id] = entity;
        });
        this.projectStoreService.nextProjectEntities(entityMap);
        this.plansStoreService.nextPlans(projectData.plans);
        this.projectStateStoreService.nextCurrentPlan(projectData.plans[0]);
      })
    ).subscribe();
  }


  addNewEntityToProjectAndPlanConfig3(entity: Feature<Geometry, EntityProperties>, isHistory: boolean = false): void {
    const originalEntityMap = Utils.clone(this.projectStoreService.projectEntitiesMapSubjectValue());
    let originalPlan;

    // history state (undo redo)
    if (!isHistory) {
      const newHistoryState: HistoryState = {
        action: 'add',
        entities: {newState: [Utils.clone(entity)]}
      }
      this.historyService.addAction(newHistoryState)
    }
    
    this.projectStateStoreService.currentPlan$.pipe(
      take(1),
      concatMap(plan => {
        originalPlan = Utils.clone(plan);
  
        this.projectStoreService.addNewEntity(entity);
  
        return this.plansStoreService.addEntityToPlanConfig2(entity, plan.planName).pipe(
          mergeMap(updatedPlan => { 
            this.setSelectedEntities([entity], false, false);
            this.projectStateStoreService.nextCurrentPlan(updatedPlan);
  
            const upsertEntity$ = this.crudService.upsertEntity(entity).pipe(
              take(1),
              // tap(console.log),
            );
            const upsertPlan$ = this.crudService.upsertPlanObservable(updatedPlan);
  
            return upsertEntity$.pipe(
              concatMap(() => upsertPlan$),
              take(1),
              retry(1)
            );
          })
        );
      }),
      catchError(err => {
        console.error('Error saving entity and plan, retrying...', err);
  
        // Retry logic here
        return throwError(err);
      }),
      catchError(err => {
        console.error('Error after retry, reverting changes...', err);
  
        // Revert changes
        this.projectStoreService.nextProjectEntities(originalEntityMap);
        this.plansStoreService.updatePlan(originalPlan);
        this.changeCurrentPlan(originalPlan, false);
  
        return throwError(err);
      }),
    ).subscribe();
  }
  
  // !! Working !!
  deleteEntityFromProjectAndPlanConfig(entity: Feature<Geometry, EntityProperties>): Observable<any> {
    let originalEntityMap: EntityMap;
  
    return this.projectStateStoreService.currentPlan$.pipe(
      take(1),
      concatMap(currentPlan => {
        return this.projectStoreService.deleteEntity(entity).pipe(
          tap(() => {
            // Save a copy of the original entity map for error handling
            originalEntityMap = Utils.clone(this.projectStoreService.projectEntitiesMapSubjectValue());
          }),
          concatMap(() => {
            return this.plansStoreService.removeEntityFromPlanConfig(entity, currentPlan.planName);
          }),
          catchError(error => {
            // Revert changes if there was an error
            this.projectStoreService.nextProjectEntities(originalEntityMap);
  
            return throwError(error);
          })
        );
      })
    );
  }

  addNewPlanToPlansNScope(plan: PlanInterface, isHistory: boolean = false) {
    const originalPlans = this.plansStoreService.plansValue();
    const addPlan$ = this.plansStoreService.addPlan(plan);

    // history state (undo redo)
    if (!isHistory) {
      const newHistoryState: HistoryState = {
        action: 'add',
        plan: {newState: Utils.clone(plan)}
      }
      this.historyService.addAction(newHistoryState)
    }

    const updateScopeLine$ = this.projectStoreService.findEntityById(SCOPELINE_INDEX_OLD).pipe(
      switchMap(entity => {
        const scopeLineToUpdate = Utils.clone(entity);
        scopeLineToUpdate.properties.plansOrder.push(plan.planName);
        return this.projectStoreService.upsertEntity(scopeLineToUpdate);
      }),
      catchError(error => {
        this.plansStoreService.nextPlans(originalPlans);
        return throwError(error);
      })
    );
  
    combineLatest([addPlan$, updateScopeLine$]).pipe(
      take(1),
      tap(() => this.setPanelTab(PANEL_TABS.Plan_Layer, false)),
      catchError(error => {
        // Proper error handling, such as logging or showing a user-friendly error message
        return throwError(error);
      })
    ).subscribe();
  }
  

  updateProjectPlansOrder(newPlansOrder: PlanInterface[], isHistory: boolean = false) {
    const newPlanOrderNamesList = newPlansOrder.map(plan => plan.planName);
    this.plansStoreService.nextPlans(newPlansOrder);
  
    this.projectStoreService.findEntityById(SCOPELINE_INDEX_OLD).pipe(
      take(1),
      switchMap(entity => {
        const scopeLineToUpdate = Utils.clone(entity);
        scopeLineToUpdate.properties.plansOrder = newPlanOrderNamesList;

        // history state (undo redo)
        if (!isHistory) {
          const prevState = Utils.clone(entity);
          const newState = Utils.clone(scopeLineToUpdate);
          const newHistoryState: HistoryState = {
            action: 'update',
            entities: {newState, prevState}
          }
          this.historyService.addAction(newHistoryState)
        }

        return this.projectStoreService.upsertEntity(scopeLineToUpdate);
      }),
      catchError(error => {
        // Handle errors here
        console.error(error);
        return throwError(error);
      })
    ).subscribe();
  }

  changeCurrentPlan(plan: PlanInterface, recordInHistory: boolean = true) {

    if (recordInHistory) {
      // Fetch the current plan and selected entities to include in the history state
      combineLatest([
        this.projectStateStoreService.currentPlan$,
        this.projectStateStoreService.selectedEntities$
      ]).pipe(
        take(1),
        tap(([currentPlan, selectedEntities]) => {
          const historyState: HistoryState = {
            action: 'changePlan',
            plan: {
              prevState: currentPlan,
              newState: plan
            },
            selectedEntitiesID: {
              newState: selectedEntities.map(entity => entity.id)
            }
          };
          // Add the action to history
          this.historyService.addAction(historyState);
        })
      ).subscribe();
    }
    // reset the selected entities first ?
    this.projectStateStoreService.nextSelectedEntities([]);
    this.projectStateStoreService.nextCurrentPlan(plan);
  }

  // setSelectedEntities(entities: Feature<Geometry, EntityProperties>[], isPush: boolean, recordInHistory: boolean = true) {
  //   if (isPush) {
  //     this.projectStateStoreService.addEntitiesToSelectedEntities(entities)
  //   } else {
  //     // const entitiesMap = this.entitiesArrayToEntityMap(entities)
  //     this.projectStateStoreService.nextSelectedEntities(entities)
  //   }
  // }

  setSelectedEntities(entities: Feature<Geometry, EntityProperties>[], isPush: boolean, recordInHistory: boolean = true, selectionSource: SELECT_SOURCE = SELECT_SOURCE.MAP) {
    // Helper function to record history
    const recordHistory = (entities: Feature<Geometry, EntityProperties>[]) => {
      if (recordInHistory) {
        this.projectStateStoreService.selectedEntities$.pipe(
          take(1)
        ).subscribe(currentSelectedEntities => {
          const historyState: HistoryState = {
            action: 'select',
            selectedEntitiesID: {
              newState: entities.map(entity => entity.id),
              prevState: currentSelectedEntities.map(entity => entity.id)
            }
          }
          this.historyService.addAction(historyState);
        });
        
      }
    };
  
    if (isPush) {
      this.projectStateStoreService.addEntitiesToSelectedEntities(entities).pipe(
        take(1)
      ).subscribe(allEntitiesSelected => {
        recordHistory(allEntitiesSelected);
        this.projectStateStoreService.nextEntitiesSelectedSource(selectionSource);
        this.projectStateStoreService.nextSelectedEntities(allEntitiesSelected);
      });
    } else {
      recordHistory(entities);
      this.projectStateStoreService.nextEntitiesSelectedSource(selectionSource);
      this.projectStateStoreService.nextSelectedEntities(entities);
    }
  }


  entitiesArrayToEntityMap(entities: Feature<Geometry, EntityProperties>[]): EntityMap {
    const entityMap = {}
    entities.forEach((entity: Feature<Geometry, EntityProperties>) => {
      entityMap[entity.id] = entity;
    });
    return entityMap;
  }


  setCurrentDashboardType(type: 'plan' | 'selection', recordInHistory: boolean = true) {

    if (recordInHistory) {
      this.projectStateStoreService.dashboardType$.pipe(
        take(1)
      ).subscribe(dashboardType => {
        const historyState: HistoryState = {
          action: 'dashboardChange',
          dashboardType: {
            newState: type,
            prevState: dashboardType
          }
        }
        this.historyService.addAction(historyState);
      });
    }

    this.projectStateStoreService.nextDashboardType(type);
  }

  setBasemap(basemap: BASE_MAPS, recordInHistory: boolean = true) {

    if (recordInHistory) {
      this.projectStateStoreService.basemap$.pipe(
        take(1)
      ).subscribe(currentBaseMap => {
        const historyState: HistoryState = {
          action: 'baseMapChange',
          baseMap: {
            newState: basemap,
            prevState: currentBaseMap
          }
        }
        this.historyService.addAction(historyState);
      });
    }
    this.projectStateStoreService.nextBasemap(basemap);
  }

  setPanelTab(panelTab: PANEL_TABS, recordInHistory: boolean = true) {

    if (recordInHistory) {
      // Use combineLatest to subscribe to both observables at once
      combineLatest([
        this.projectStateStoreService.panelTab$.pipe(take(1)),
        this.projectStateStoreService.selectedEntities$.pipe(
          take(1),
          map(entities => entities.map(entity => entity.id)) // Transform entities to their IDs
        )
      ]).subscribe(([currentPanelTab, selectedEntityIds]) => {
        // Now you have both the currentPanelTab and the selectedEntityIds
        const historyState: HistoryState = {
          action: 'panelTabChange',
          panelTab: {
            newState: panelTab,
            prevState: currentPanelTab
          },
          selectedEntitiesID: {
            prevState: selectedEntityIds // Set the previously selected entity IDs here
          }
        };
        this.historyService.addAction(historyState);
      });
    }

    this.projectStateStoreService.nextPanelTab(panelTab);
  }


  // Add this method inside the StoresManagerService class
  addEntitiesToProjectAndPlan(entities: Feature<Geometry, EntityProperties>[], parentId?: string, isHistory: boolean = false): Observable<any> {
    let originalEntityMap: EntityMap;

    // history state (undo redo)
    if (!isHistory) {
      const newHistoryState: HistoryState = {
        action: 'add',
        entities: {
          newState: entities, 
          parentId: parentId
        }
      }
      this.historyService.addAction(newHistoryState)
    }

    return this.projectStateStoreService.currentPlan$.pipe(
      take(1),
      concatMap(currentPlan => {
        return this.projectStoreService.addEntities(entities).pipe(
          tap(() => {
            // Save a copy of the original entity map for error handling
            originalEntityMap = Utils.clone(this.projectStoreService.projectEntitiesMapSubjectValue());
          }),
          concatMap(() => {
            return this.plansStoreService.addEntitiesToPlanConfigWithParentId(entities, currentPlan.planName, parentId);
          }),
          catchError(error => {
            // Revert changes if there was an error
            this.projectStoreService.nextProjectEntities(originalEntityMap);
            return throwError(error);
          })
        );
      })
    );
  }


  getStoreysEntitiesByReference(plan, entitiesDx) {
    const childEntityIds = [];
  
    for (const id of Object.keys(plan.planConfig)) {
      const entityConfig = plan.planConfig[id];
      if (entityConfig.parentId && entitiesDx.some(dxEntity => dxEntity === entityConfig.parentId)) {
        childEntityIds.push(id);
      }
    }
    return this.projectStoreService.getEntitiesByIds(childEntityIds).pipe(
      map(childEntities => childEntities.filter(child =>
        child.properties.heightProperties && Object.keys(child.properties.heightProperties).length > 0)
      ),
      // map(filteredChildren => filteredChildren.map(child => child.id))
    );
  }


  // working final draft removeconfig first 
  deleteEntitiesFromProjectAndPlanConfig(entities: Feature<Geometry, EntityProperties>[], isHistory: boolean = false): Observable<any> {
    const originalEntityMap = Utils.clone(this.projectStoreService.projectEntitiesMapSubjectValue());
    let originalPlan;

    // history state (undo redo)
    if (!isHistory) {
      const newHistoryState: HistoryState = {
        action: 'delete',
        entities: {prevState: entities}
      }
      this.historyService.addAction(newHistoryState)
    }
  
    return this.projectStateStoreService.currentPlan$.pipe(
      take(1),
      switchMap(plan => {
        originalPlan = Utils.clone(plan);
  
        // Get storeys of entities
        return this.getStoreysEntitiesByReference(plan, entities.map(entity => entity.id)).pipe(
          take(1),
          switchMap(storeyEntities => {
            // Combine entities and storeyEntities for deletion
            const combinedEntities = [...entities, ...storeyEntities];
  
            // Remove entities from the plan config
            return this.plansStoreService.removeEntitiesFromPlanConfig(combinedEntities, originalPlan.planName).pipe(
              switchMap(() => {
                // Delete entities
                return this.projectStoreService.deleteEntities(combinedEntities);
              }),
              catchError(error => {
                // Revert changes if there was an error
                this.projectStoreService.nextProjectEntities(originalEntityMap);
                return throwError(error);
              })
            );
          })
        );
      }),
      tap(() => {
        // Set selected entities with empty array and change PanelTab to Plan_Layer
        // this.setSelectedEntities([], false);
        this.setPanelTab(PANEL_TABS.Plan_Layer, false);
      }),
      switchMap(() => {
        // Get the updated plan
        return this.plansStoreService.getPlanByName(originalPlan.planName);
      }),
      tap(updatedPlan => {
        // Set the updated plan as the current plan
        if (updatedPlan) {
          this.changeCurrentPlan(updatedPlan, false);
        }
      }),
      catchError(err => {
        console.error('Error deleting entities without storeys, reverting changes...', err);
  
        // Revert changes
        this.projectStoreService.nextProjectEntities(originalEntityMap);
        this.plansStoreService.updatePlan(originalPlan);
        this.changeCurrentPlan(originalPlan, false);
  
        return throwError(err);
      })
    );
  }
  

  updateEntity(
    entity: Feature<Geometry, EntityProperties>,
    parentChildRelationship?: IParentChildRelationship,
    isHistory: boolean = false
  ): Observable<any> {
    return this.projectStateStoreService.currentPlan$.pipe(
      take(1),
      switchMap(currentPlan => {
        const planName = currentPlan.planName;
        let updateParentId$ = of(null);
  
        if (parentChildRelationship) {
          if (parentChildRelationship.parentId) {
            updateParentId$ = this.plansStoreService.addParentIdToEntityInPlanConfig(
              parentChildRelationship.entityId,
              parentChildRelationship.parentId,
              planName
            );
          } else {
            updateParentId$ = this.plansStoreService.removeParentIdFromPlanConfig(
              parentChildRelationship.entityId,
              planName
            );
          }

          if (parentChildRelationship.childrenId?.length > 0) {
            parentChildRelationship.childrenId.forEach(child => {
              this.plansStoreService.addParentIdToEntityInPlanConfig(
                child.building,
                child.lot,
                planName
              ).subscribe();
            });
          }
        }

        const prevState = Utils.clone(this.projectStoreService.projectEntitiesMapSubjectValue()[entity.id]);
        // history state (undo redo)
        if (!isHistory) {
          const newHistoryState: HistoryState = {
            action: 'update',
            entities: {
              newState: [Utils.clone(entity)],
              prevState: [prevState], 
              parentChildRelationship: parentChildRelationship || undefined
            }
          }
          this.historyService.addAction(newHistoryState)
        }
  
        return this.projectStateStoreService.selectedEntities$.pipe(
          take(1),
          switchMap(selectedEntities => {
            // proceed to the update process
            return updateParentId$.pipe(
              take(1),
              switchMap(() => this.projectStoreService.upsertEntity(entity)),
              tap(() => {
                if (selectedEntities.some(selectedEntity => selectedEntity.id === entity.id)) {

                  const updatedSelectedEntities = selectedEntities.map(selectedEntity => 
                    selectedEntity.id === entity.id ? entity : selectedEntity
                  );
                  this.projectStateStoreService.nextSelectedEntities(updatedSelectedEntities);
                }
              }),
              catchError(err => {
                // Revert changes in case of error
                if (parentChildRelationship) {
                  if (parentChildRelationship.parentId) {
                    this.plansStoreService.removeParentIdFromPlanConfig(
                      parentChildRelationship.entityId,
                      planName
                    ).subscribe();
                  } else {
                    this.plansStoreService.addParentIdToEntityInPlanConfig(
                      parentChildRelationship.entityId,
                      parentChildRelationship.parentId,
                      planName
                    ).subscribe();
                  }
                }
                return throwError(err);
              })
            );
          }),
        );
      })
    );
  }

 
  updatePlanInStores(plan: PlanInterface, isHistory: boolean = false): Observable<any> {
    const prevState = Utils.clone(this.plansStoreService.plansValue().find(p => p.planName === plan.planName));
    // Update the plan using the PlansStoreService
    return this.plansStoreService.updatePlan(plan).pipe(
      tap(() => {
        // history state (undo redo)
        if (!isHistory) {
          const newHistoryState: HistoryState = {
            action: 'update',
            plan: {
              prevState: prevState,
              newState: plan
            }
          }
          this.historyService.addAction(newHistoryState)
        }
        // Handle any application-wide state changes or side effects here
        // For example, updating the current plan in ProjectStateStoreService
        this.projectStateStoreService.nextCurrentPlan(plan);
        this.nzMessageService.success('Plan updated successfully.');
      }),
      catchError(error => {
        // Handle any errors specific to the StoresManagerService context
        // This might include user notifications or additional logging
        this.nzMessageService.error('Error updating plan.');
        return throwError(error);
      })
    );
  }


  renamePlan(newPlanName: string, plan: PlanInterface, isHistory: boolean = false): Observable<any> {
    const originalPlan = Utils.clone(plan)
    newPlanName = newPlanName.trim();

    if (newPlanName === originalPlan.planName || newPlanName === originalPlan.planName.trim()) {
        return of(undefined);
    }

    return combineLatest([
        this.plansStoreService.plans$,
        this.projectStoreService.findEntityById(SCOPELINE_INDEX_OLD)
    ]).pipe(
        take(1),
        switchMap(([plans, scopeline]) => {
            if (plans.findIndex(p => p.planName === newPlanName) > -1) {
                this.nzMessageService.warning(`Name already exists`);
                return throwError('Name already exists');
            }

            const planDoesExistIndex = scopeline.properties.plansOrder.findIndex(n => n === newPlanName);
            if(planDoesExistIndex > -1) {
                scopeline.properties.plansOrder.splice(planDoesExistIndex, 1);
            }

            const copyOfOriginalScopeLine = Utils.clone(scopeline);
            const planDxInPlanOrder = scopeline.properties.plansOrder.findIndex(n => n === originalPlan.planName);
            scopeline.properties.plansOrder.splice(planDxInPlanOrder, 1, newPlanName);

            const prevPlanName = originalPlan.planName;
            const mutatedPlan = Utils.clone(plan);
            mutatedPlan.planName = newPlanName;

            const planIndex = plans.findIndex(p => p.planName === prevPlanName)
            plans[planIndex].planName = newPlanName;

            plans.sort((a, b) => scopeline.properties.plansOrder.indexOf(a.planName) - scopeline.properties.plansOrder.indexOf(b.planName));

            this.plansStoreService.nextPlans(plans);
            this.projectStateStoreService.nextCurrentPlan(mutatedPlan);

            this.projectStoreService.updateEntity(scopeline);

            if (!isHistory) {
              const newHistoryState: HistoryState = {
                action: 'rename',
                plan: {
                  prevState: originalPlan,
                  newState: mutatedPlan
                }
              }
              this.historyService.addAction(newHistoryState)
            }

            return this.crudService.renamePlan({plan: originalPlan, newPlanName, scopeline: copyOfOriginalScopeLine}).pipe(
                // tap(console.log),
                catchError(error => {
                    this.nzMessageService.error('Plan rename error');
                    plans[planIndex].planName = prevPlanName;
                    plans.sort((a, b) => copyOfOriginalScopeLine.properties.plansOrder.indexOf(a.planName) - copyOfOriginalScopeLine.properties.plansOrder.indexOf(b.planName));
                    this.plansStoreService.nextPlans(plans);
                    this.projectStoreService.updateEntity(copyOfOriginalScopeLine);
                    this.projectStateStoreService.nextCurrentPlan(originalPlan);
                    return throwError(error);
                })
            );
        })
    );
  }


  deletePlanAndPlanEntities(plan: PlanInterface, isHistory: boolean = false): Observable<PlanInterface> {
    let entityMapSnapshot: EntityMap;
    const entitiesIds = Object.keys(plan.planConfig);

    if (!isHistory) {
      // Capture the state before deletion for history recording
      this.projectStoreService.projectEntitiesMap$.pipe(
        take(1),
        map(entitiesMap => entitiesIds.map(id => entitiesMap[id]).filter(entity => !!entity && entity.id !== SCOPELINE_INDEX_OLD))
      ).subscribe(entitiesBeforeDeletion => {
        const newHistoryState: HistoryState = {
          action: 'delete',
          entities: {prevState: entitiesBeforeDeletion},
          plan: { prevState: Utils.clone(plan)}, 
        };
        this.historyService.addAction(newHistoryState);
      });
    }
  
    // Get the entities of the plan from the entities map
    return this.projectStoreService.projectEntitiesMap$.pipe(
      take(1),
      map(entitiesMap => {
        return entitiesIds.reduce((result, entityId) => {
          const entity = entitiesMap[entityId];
          if (entity) {
            result[entityId] = entity;
          }
          return result;
        }, {} as EntityMap);
      }),
      tap((entities: EntityMap) => {
        entityMapSnapshot = Utils.clone(entities);
      }),
      concatMap((entities: EntityMap) => {
        const scopelineEntity = Object.values(entities).find(entity => entity.id === SCOPELINE_INDEX_OLD);
        const entitiesToDelete = Object.values(entities).filter(entity => entity.id !== SCOPELINE_INDEX_OLD);
  
        // Update the scopeline entity
        scopelineEntity.properties.plansOrder = scopelineEntity.properties.plansOrder.filter(name => name !== plan.planName);
  
        // Update the scopeline entity in the store
        this.projectStoreService.updateEntity(scopelineEntity);
  
        // Delete entities
        this.projectStoreService.removeEntities(entitiesToDelete);
  
        // Remove plan
        return this.plansStoreService.removePlan(plan).pipe(
          catchError(error => {
            // Revert changes in case of an error
            this.projectStoreService.nextProjectEntities(entityMapSnapshot);
            return throwError(error);
          })
        );
      })
    );
  }


  addPlanWithEntities(newPlanWithEntities$: Observable<{ newPlan: PlanInterface, entities: Feature<Geometry, EntityProperties>[] }>, isHistory: boolean = false): Observable<any> {
    let currentPlansSnapshot: PlanInterface[];
    let currentEntitiesSnapshot: EntityMap;
  
    return newPlanWithEntities$.pipe(
      take(1),
      tap(({ newPlan, entities }) => {
        // Clone the current plans and entities for reverting changes in case of an error
        currentPlansSnapshot = Utils.clone(this.plansStoreService.plansValue());
        currentEntitiesSnapshot = Utils.clone(this.projectStoreService.projectEntitiesMapSubjectValue());
      }),
      concatMap(({ newPlan, entities }) => {

        // Prepare the history action before making any changes
        if (!isHistory) {
          this.historyService.addAction({
            action: 'add',
            plan: { newState: newPlan }, // Assuming you want to record the plan being added
            entities: { newState: entities }, // Assuming you want to record the entities being added
          });
        }
        // Add the new plan
        return this.plansStoreService.addPlan(newPlan).pipe(
          take(1),
          catchError(error => {
            // If there's an error adding the plan, revert the changes and throw an error
            this.plansStoreService.nextPlans(currentPlansSnapshot);
            return throwError(error);
          }),
          // Add the new entities
          switchMap(() => this.projectStoreService.addEntities(entities).pipe(
            take(1),
            catchError(error => {
              this.plansStoreService.nextPlans(currentPlansSnapshot);
              return throwError(error);
            })
          )),
          // Update the SCOPELINE_INDEX_OLD entity
          switchMap(() => this.projectStoreService.findEntityById(SCOPELINE_INDEX_OLD).pipe(
            switchMap(entity => {
              const scopeLineToUpdate = Utils.clone(entity);
              scopeLineToUpdate.properties.plansOrder.push(newPlan.planName);
              return this.projectStoreService.upsertEntity(scopeLineToUpdate);
            }),
            catchError(error => {
              // If there's an error updating the SCOPELINE_INDEX_OLD entity, revert the changes and throw an error
              this.plansStoreService.nextPlans(currentPlansSnapshot);
              this.projectStoreService.nextProjectEntities(currentEntitiesSnapshot);
              return throwError(error);
            })
          ))
        );
      }),
      catchError(error => {
        // If there's an error adding the entities, revert the changes and throw an error
        this.projectStoreService.nextProjectEntities(currentEntitiesSnapshot);
        return throwError(error);
      })
    );
  }


  addUpdateDeleteEntities(entitiesUpdateObject: AddUpdateDeleteEntitiesParams, planName, isHistory: boolean = false) {
    this.managerActionFlag = true;
    let entitiesToSave: Feature<Geometry, EntityProperties>[] = [];
    const currentEntityMap: EntityMap = Utils.clone(this.projectStoreService.projectEntitiesMapSubjectValue());
    const currentEntityMapBackup: EntityMap = Utils.clone(currentEntityMap);
    const currentPlans: PlanInterface[] = Utils.clone(this.plansStoreService.plansValue());
    const currentPlansBackup: PlanInterface[] = Utils.clone(currentPlans)
    const targetPlan: PlanInterface = Utils.clone(currentPlans.find(plan => plan.planName === planName));
    const targetPlanBackup: PlanInterface = Utils.clone(targetPlan)
    const projectName: string = currentEntityMap[SCOPELINE_INDEX_OLD].properties.projectName;
    let targetPlanChanged;

    if (!isHistory) {
      let entitiesPrevUpdate: EntityEntryMap;
      if (Object.keys(entitiesUpdateObject.entitiesToUpdate).length > 0) {
        entitiesPrevUpdate = this.filterPrevUpdatedEntities(currentEntityMap, entitiesUpdateObject.entitiesToUpdate)
      }
      let entitiesPrevDelete: EntityEntryMap;
      if (entitiesUpdateObject.entitiesToDelete?.length > 0) {
        entitiesPrevDelete = this.filterPrevDeletedEntities(currentEntityMap, entitiesUpdateObject.entitiesToDelete)
      }

      this.historyService.addAction({
        action: 'modify',
        modified: {
          entitiesNewState: Utils.clone(entitiesUpdateObject),
          entitiesUpdatedPrevState: entitiesPrevUpdate,
          entitiesDeletedPrevState: entitiesPrevDelete,
          planName: planName
        }
      });
    }

    // Delete entities
    if (entitiesUpdateObject.entitiesToDelete?.length > 0) {
      for (let key of entitiesUpdateObject.entitiesToDelete) {
        delete currentEntityMap[key];
        if (targetPlan && targetPlan.planConfig[key]) {
          delete targetPlan.planConfig[key];
        }
      }
    }
     // Add entities
     if (entitiesUpdateObject.entitiesToAdd) {
      for (let key in entitiesUpdateObject.entitiesToAdd) {
        const entity = entitiesUpdateObject.entitiesToAdd[key].entity;
        const parentId = entitiesUpdateObject.entitiesToAdd[key].parentId;
        currentEntityMap[key] = entity;
        if (!targetPlan.planConfig[entity.id]) {
          if (parentId) {
            targetPlan.planConfig[entity.id] = { version: 'v0' , parentId: parentId}
          } else {
            targetPlan.planConfig[entity.id] = { version: 'v0' };
          }
        }
        entitiesToSave.push(entity)
      }
    }

    // Update entities
    if (entitiesUpdateObject.entitiesToUpdate) {
      for (let key in entitiesUpdateObject.entitiesToUpdate) {
        const entity = entitiesUpdateObject.entitiesToUpdate[key].entity;
        const parentId = entitiesUpdateObject.entitiesToUpdate[key].parentId;
        if (currentEntityMap[key]) {
          currentEntityMap[key] = entity;
          // if (parentId) {
          //   targetPlan.planConfig[entity.id] = { version: 'v0' , parentId: parentId}
          //   targetPlanChanged = true;
          // }
          // Ensure we have a planConfig entry for the entity
          if (!targetPlan.planConfig[entity.id]) {
            targetPlan.planConfig[entity.id] = { version: 'v0' };
          }

          // If parentId is defined, update it; if it's undefined, remove it
          if (parentId !== undefined) {
            targetPlan.planConfig[entity.id].parentId = parentId;
          } else {
            delete targetPlan.planConfig[entity.id].parentId;
          }
          targetPlanChanged = true;
        }
        entitiesToSave.push(entity)
      }
    }

    const updatedPlans = currentPlans.map(plan => 
      plan.planName === targetPlan.planName ? Utils.clone(targetPlan) : plan
    );
    

    this.projectStoreService.nextProjectEntities(currentEntityMap);
    this.plansStoreService.nextPlans(updatedPlans);
    this.projectStateStoreService.nextCurrentPlan(targetPlan);

    let crudObservables: Observable<any>[] = [];

    if (entitiesUpdateObject.entitiesToAdd || entitiesUpdateObject.entitiesToUpdate){ 
      const saveEntitiesObservable = this.crudService.saveEntitiesConfigsBulkObservable(entitiesToSave).pipe(
        catchError(err => {
          // Revert the update in case of error
          // this.projectEntitiesMapSubject.next(entityMapCloneForError);
          return throwError(err);
        })
      );
      crudObservables.push(saveEntitiesObservable);
    }

    if (entitiesUpdateObject.entitiesToDelete?.length > 0) {
      const deleteEntitiesObservable = this.crudService.deleteEntitiesConfigsBulkObservable(projectName, entitiesUpdateObject.entitiesToDelete).pipe(
        catchError(err => {
          // Revert the update in case of error
          console.log("Error in deleteEntitiesConfigsBulkObservable")
          return throwError(err);
        })
      );
      crudObservables.push(deleteEntitiesObservable);
    }

    if (entitiesUpdateObject.entitiesToDelete?.length > 0 || entitiesUpdateObject.entitiesToAdd || targetPlanChanged) {
      const updatePlanObservable = this.crudService.upsertPlanObservable(targetPlan).pipe(
        catchError(err => {
          console.log("Error in upsertPlanObservable")
          return throwError(err);
        })
      );
      crudObservables.push(updatePlanObservable)
    }

    // checking if selectedEntities needs to be replaced 
    return this.projectStateStoreService.selectedEntities$.pipe(
      first(),
      tap(selectedEntities => {
        let updatedSelectedEntities = selectedEntities;
        for (let key in entitiesUpdateObject.entitiesToUpdate) {
          const entity = entitiesUpdateObject.entitiesToUpdate[key].entity;
          if (selectedEntities.some(selectedEntity => selectedEntity.id === entity.id)) {
            updatedSelectedEntities = selectedEntities.map(selectedEntity => 
              selectedEntity.id === entity.id ? entity : selectedEntity
            );
          }
        }
        this.setSelectedEntities(updatedSelectedEntities, false, false)
      }),
      switchMap(() => {
        return forkJoin(crudObservables).pipe(
          // tap(console.log),
          retry(3),
          catchError(err => {
            this.projectStoreService.nextProjectEntities(currentEntityMapBackup);
            this.plansStoreService.nextPlans(currentPlansBackup);
            this.projectStateStoreService.nextCurrentPlan(targetPlanBackup);
            return throwError(err);
          })
        );
      })
    );

  }

  
  osmBuildingsInPolygon(polygon): Observable<string[]> {

    if (!polygon) {
      return of(null)
    }

    return this.crudService.osmBuildingsFromPolygonObs(polygon).pipe(
      map((data: { return: { osm_buildings_id: string[] } }) => data.return.osm_buildings_id)
    );
  }

  private filterPrevUpdatedEntities(projectEntities: EntityMap, entitiesToUpdate: EntityEntryMap): EntityEntryMap {
    const prevStateEntities: EntityEntryMap = {};

    Object.keys(entitiesToUpdate).forEach(key => {
      if (projectEntities.hasOwnProperty(key)) {
        prevStateEntities[key] = { entity: projectEntities[key] }
      }
    });
    return prevStateEntities;
  }

  private filterPrevDeletedEntities(projectEntities: EntityMap, entitiesToDelete: string[]): EntityEntryMap {
    const prevStateEntitiesToDelete: EntityEntryMap = {};

    for (let id of entitiesToDelete) {
      if (projectEntities.hasOwnProperty(id)) {
        prevStateEntitiesToDelete[id] = { entity: projectEntities[id]}
      }
    }

    return prevStateEntitiesToDelete;
  }


  // processEntities() {
  //   this.projectStoreService.projectEntitiesMap$.pipe(
  //     take(1)
  //   ).subscribe(entityMap => {
  //     const updatedEntities = [];
  //     Object.values(entityMap).forEach(entity => {
  //       if (entity.id === -1 && entity.geometry.type === 'LineString') {
  //         entity.properties.entityCategory = ENTITY_CATEGORIES.GUIDELINE_ELEMENTS;
  //         entity.properties.entityType = ENTITIES_TYPES.guideline_elements.plan_baundary
  //       }
  //       if (entity.properties.entityCategory === 'parcel' && entity.properties.landUse === 'default' && entity.properties.entityType === null) {
  //         entity.properties.entityType = ENTITIES_TYPES.parcel.default;
  //         updatedEntities.push(entity)
  //       }
  //       switch (entity.properties.entityType) {
  //         case 'marketable lot':
  //           entity.properties.entityCategory = ENTITY_CATEGORIES.PARCEL;
  //           entity.properties.entityType = ENTITIES_TYPES.parcel.residential;
  //           entity.properties.landUse = ENTITIES_TYPES.parcel.residential;
  //           entity.properties.accessType = ENTITY_ACCESS_TYPE.PRIVATE;
  //           updatedEntities.push(entity)
  //           break;
  //         case 'building':
  //           entity.properties.entityCategory = ENTITY_CATEGORIES.BUILDING;
  //           entity.properties.entityType = ENTITIES_TYPES.building.building;
  //           entity.properties.landUse = '';
  //           entity.properties.accessType = ENTITY_ACCESS_TYPE.PRIVATE;
  //           updatedEntities.push(entity)
  //           break;
  //     }})
  //     this.projectStoreService.upsertEntities(updatedEntities).pipe(
  //       take(1)
  //     ).subscribe
  //   });
  // }
  

}
