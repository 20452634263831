import { Injectable } from '@angular/core';
import type {  Feature, FeatureCollection, Geometry, LineString, Position  } from 'geojson';
import { CrudService } from '../crud/crud.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EntityProperties } from '../../models/entity-properties';
import { PlanInterface } from '../../models/plan-interface';
import {
  PLANBOUNDARY_LANDUSE,
  SCOPELINE_INDEX_OLD,
  DEFAULT_ENTITY_CATEGORY,
  DEFAULT_ENTITY_LANDUSE,
  DEFAULT_MAP_INFO,
  ENTITY_PLAN_TYPE, TEMP_NAME, ENTITIES_TYPES

} from '../../config';
import Utils from '../../utils/utils';
import {
  BASE_MAPS,
  ENTITY_GEOMETRIC_TYPES,
  ENTITY_CATEGORIES,
  SUN_HOUR_ANALYSIS_PARAMS,
  PANEL_TABS
} from '../../enums/enums';
import { ImportedLayer, ImportedLayerOpacity } from '../../models/imported-layer';
import * as TurfPolygonToLine from '@turf/polygon-to-line';
import * as BooleanIntersects from '@turf/boolean-intersects';
import * as TurfArea from '@turf/area';
import { NzMessageService } from 'ng-zorro-antd/message';
import { MAVAT_DATA } from '../../utils/mavat';
import { ExternalLayer } from '../../models/external-layer';
import { StateModel } from '../../models/state-model';
import { StateHistoryModel } from '../../models/state-history.model';
import { MenuDataStoreService } from '../menuDataStore/menu-data-store.service';
import { StoresManagerService } from '../storesManagerService/stores-manager.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  // private allProjectEntitiesSubject: BehaviorSubject<Array<Feature>> = new BehaviorSubject<Array<Feature>>([]);
  // allProjectEntities$: Observable<Array<Feature>> = this.allProjectEntitiesSubject.asObservable();

  private entitiesSubject: BehaviorSubject<FeatureCollection<Geometry, EntityProperties>> = new BehaviorSubject<FeatureCollection<Geometry, EntityProperties>>({ type: 'FeatureCollection', features: [] });
  entities$: Observable<FeatureCollection<Geometry, EntityProperties>> = this.entitiesSubject.asObservable()

  // private entitiesAsObjectSubject: BehaviorSubject<object> = new BehaviorSubject<object>({}); 
  // entitiesAsObject$: Observable<object> = this.entitiesAsObjectSubject.asObservable();

  // plans$: BehaviorSubject<PlanInterface[]> = new BehaviorSubject<any[]>([]);
  // entityDeleted$:  Subject<void> = new Subject<void>();
  // menuData$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // currentPlan$: BehaviorSubject<PlanInterface> = new BehaviorSubject<any>('');
  // selectedEntities$: BehaviorSubject<Array<Feature<Geometry, EntityProperties>>> =
  //   new BehaviorSubject<Array<Feature<Geometry, EntityProperties>>>([]);
  // selectedEntitiesIndexes$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  // hoveredEntitiesIndexes$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  // recenterCoordinates$: Subject<Position> = new Subject<Position>();
  // resetMap$: Subject<void> = new Subject<void>();
  // externalLayers$: BehaviorSubject<{ layers: ExternalLayer[], add: ExternalLayer[], remove: ExternalLayer[] }> = new BehaviorSubject<{ layers: ExternalLayer[], add: ExternalLayer[], remove: ExternalLayer[] }>({
  //   layers: [],
  //   add: [],
  //   remove: []
  // });
  // currentEditDraft$: Subject<{ source: string, entity: Feature<Geometry, EntityProperties> }> = new Subject<{ source: '', entity: Feature<Geometry, EntityProperties> }>();
  // viewOnlyFeatures$: BehaviorSubject<FeatureCollection<Geometry>> =
  //   new BehaviorSubject<FeatureCollection<Geometry>>({ type: 'FeatureCollection', features: [] });
  // private readonly _state$: BehaviorSubject<StateModel> = new BehaviorSubject<StateModel>({
  //   features: { add: [], remove: {}, entities: [], prev: [] },
  //   selectedFeatures: { add: [], remove: {}, entities: [], prev: [] }
  // });
  // readonly state$ = this._state$.asObservable();

  // private _zoom = DEFAULT_MAP_INFO.zoom;

  // filterCategories: any = {
  //   entityCategory: [],
  //   landUse: [],
  //   entityPlanType: [],
  // };
  // mapCurrentPosition: Position;
  // filterEntityTypes = { influence: [SUN_HOUR_ANALYSIS_PARAMS.SUMMER, SUN_HOUR_ANALYSIS_PARAMS.SPRING, SUN_HOUR_ANALYSIS_PARAMS.AUTUMN, SUN_HOUR_ANALYSIS_PARAMS.WINTER] };
  // panelTab: number = -1;
  // planBluelineId;

  // projectName: string;
  // basemap$: BehaviorSubject<BASE_MAPS.DEFAULT | BASE_MAPS.SATELLITE | BASE_MAPS.NONE> = new BehaviorSubject(BASE_MAPS.DEFAULT);

  // undo-redo
  // private _history: StateHistoryModel[] = [];
  // historyIndex = 0;
  // historyIsNewEntity: boolean;

  // Booleans
  // isEntitiesHidden = true;
  // isNewProjectMode = false;
  // isCutPolyCrossBlueline = false;
  // isEditMode: boolean;
  // isStateHistoryMode: boolean;

  // private _showScopeLineSource = new BehaviorSubject<boolean>(false);
  // showScopeLine$ = this._showScopeLineSource.asObservable();

  // set newMapZoom(val: number) {
  //   if (this._zoom !== val) {
  //     this._zoom = val;
  //   }
  // }

  // private get _externalLayersDefault(): { layers: ImportedLayer, opacity: ImportedLayerOpacity } {
  //   const layers: ImportedLayer = {
  //     mot: [],
  //     tlv: [],
  //     govmap: [],
  //     other: {
  //       xplan1: false,
  //       xplan2: false,
  //       xplan3: false,

  //       traffic: false,
  //       transit: false,
  //       bicycling: false,

  //       tlvSatellite: false,

  //       biogis: '',

  //       nyc: {
  //         boundaries: []
  //       }
  //     },
  //     kmlAndGeoJsonLayers: [],
  //     london: {}
  //   };
  //   const opacity: ImportedLayerOpacity = {
  //     xplan: 0.8,
  //     tlv: 0.5,
  //     mot: 0.8,
  //     govmap: 0.8,
  //     biogis: 0.8,
  //     nyc: 0.8,
  //     kmlAndGeoJsonLayers: {},
  //     london: {}
  //   };
  //   return { layers, opacity };
  // }

  // get allProjectEntities(): Feature<Geometry, EntityProperties>[] {
  //   return Utils.clone(this.getAllProjectEntitesSubjectValue());
  // }

  get entities(): FeatureCollection<Geometry, EntityProperties> {
    return Utils.clone(this.getEntitiesSubjectValue());
  }

  // get entitiesAsObject(): any {
  //   return Utils.clone(this.getEntitiesAsObjectSubjectValue());
  // }

  // get selectedEntities(): Feature<Geometry, EntityProperties>[] {
  //   return Utils.clone(this.selectedEntities$.getValue());
  // }

  // get generateId(): number {
  //   const nextEntityIndex = Date.now();
  //   const allPlansEntities = this.getAllProjectEntitesSubjectValue();
  //   for (const feature of allPlansEntities) {
  //     if (feature.id === nextEntityIndex.toString()) {
  //       return this.generateId;
  //     }
  //   }
  //   return nextEntityIndex;
  // }

  // get landUses() {
  //   // return this.menuData$.getValue();
  //   // FRN
  //   return this.menuDataStoreService.getValue();
  // }

  // get isProjectLock() {
  //   const entity = this.findEntity(SCOPELINE_INDEX_OLD);
  //   return entity && entity.properties.isLock;
  // }

  // get selectedEntitiesIndexes() {
  //   return this.selectedEntitiesIndexes$.getValue();
  // }

  // get hoveredEntitiesIndexes() {
  //   return this.hoveredEntitiesIndexes$.getValue();

  // }

  // get mapZoom() {
  //   return this._zoom;
  // }

  // get stateHistory() {
  //   return this._history;
  // }

  // get state() {
  //   return this._state$.getValue();
  // }

  // private _setState(state: StateModel) {
  //   this._state$.next(state);
  // }

  // set updateEntity(entity: Feature<Geometry, EntityProperties>) {
  //   entity = this.checkBluelineCrossing(entity);
  //   const entities = this.entities;
  //   const dx = entities.features.findIndex(ent => ent.id === entity.id);
  //   entities.features[dx] = { ...entity };
  //   // this.setCurrentEntities(entities);
  //   this.updateEntitiesArrayAndObject(entities);
  // }

  // set updateEntities(features: Feature<Geometry, EntityProperties>[]) {
  //   const featuresCollection: FeatureCollection<Geometry, EntityProperties> = this.entities;
  //   features.forEach(entity => {
  //     const dx = featuresCollection.features.findIndex(ent => ent.id === entity.id);
  //     if (-1 < dx) {
  //       featuresCollection.features[dx] = { ...entity };
  //     }
  //   });
  //   this.updateEntitiesArrayAndObject(featuresCollection);
  // }

  constructor(
    private crudService: CrudService, 
    private nzMessageService: NzMessageService, 
    private menuDataStoreService: MenuDataStoreService,
    private storesManagerService: StoresManagerService) {
  }

  // sendAllProjectEntities(projectEntities: Array<Feature>) {
  //   this.allProjectEntitiesSubject.next(projectEntities);
  // }

  // getAllProjectEntitesSubjectValue() {
  //   return this.allProjectEntitiesSubject.getValue();
  // }

  // sendEntities(entities: FeatureCollection<Geometry, EntityProperties>) {
  //   this.entitiesSubject.next(entities);
  // }

  getEntitiesSubjectValue() {
    return this.entitiesSubject.getValue();
  }

  // sendEntitiesAsObject(entitiesAsObject: object) {
  //   this.entitiesAsObjectSubject.next(entitiesAsObject);
  // }

  // getEntitiesAsObjectSubjectValue() {
  //   return this.entitiesAsObjectSubject.getValue();
  // }

  // toggleShowScope() {
  //   this._showScopeLineSource.next(!this._showScopeLineSource.value)
  // }

  // showScopeLine() {
  //   this._showScopeLineSource.next(true)
  // }

  // hideScopeLine() {
  //   this._showScopeLineSource.next(false);
  // }

  // findEntity(index: string | number): Feature<Geometry, EntityProperties> {
  //   return this.entitiesAsObject[index];
  // }

  // findEntityByEntityType(value: string): Feature<Geometry, EntityProperties>[] {
  //   return this.entities.features.filter(entity => entity.properties.entityType === value);
  // }

  // addEntities(entities: Feature<Geometry, EntityProperties>[]) {
  //   // const state = this.state;
  //   state.features.add = entities;
  //   state.features.entities = [...state.features.entities, ...entities];
  //   this._setState(state);
  // }

  // updateEntitiesArrayAndObject(entities: FeatureCollection<Geometry, EntityProperties>) {
  //   if (entities && Object.keys(entities).length > 0 && entities.features?.length > 0) {
  //     this.sendEntities(entities);
  //     const entsObj = this.convertEntitiesArrayToObj(entities.features);
  //     this.sendEntitiesAsObject(entsObj);
  //   }
  // }

  // convertEntitiesArrayToObj(entities: Feature<Geometry, EntityProperties>[]) {
  //   return entities.reduce((prev, cur) => {
  //     prev[cur.id] = cur;
  //     return prev;
  //   }, {});
  // }

  // convertEntitiesObjToArray(entitiesObj: object) {
  //   // @ts-ignore
  //   return Object.keys(entitiesObj).map(key => entitiesObj[key]);
  // }

  // deprecated
  // changeSelectedPlan(plan: PlanInterface) {
  //   // this.selectedEntities$.next([]);
  //   this.currentPlan$.next(plan);
  // }

  // generatePlanEntities(isGenerateAll: boolean) {
  //   const entitiesForCurrentPlan: Feature<Geometry, EntityProperties>[] = this.generateEntitiesForCurrentPlan(isGenerateAll);
  //   return this.sortFeatureCollectionByZIndexFactory(entitiesForCurrentPlan);
  // }

  // generateEntitiesForCurrentPlan(isGenerateAll: boolean) {
  //   // @ts-ignore
  //   const allFeatures: Feature<Geometry, EntityProperties>[] = this.getAllProjectEntitesSubjectValue();;
  //   const entitiesForCurrentPlan = [];
  //   for (const feature of allFeatures) {
  //     feature.properties.entityCategory = feature.properties.entityCategory ? feature.properties.entityCategory : ENTITY_CATEGORIES.PARCEL;
  //     let entity = this.generateEntityFromServer(feature, isGenerateAll);
  //     if (entity) {
  //       if (entity.id === SCOPELINE_INDEX_OLD) {
  //         !this._showScopeLineSource.value ? entity.properties.isHide = true : entity.properties.isHide = false;
  //       }
  //       if (entity.properties.entityCategory === ENTITY_CATEGORIES.INFLUENCE && entity.properties.landUse === BLUELINE_LANDUSE) {
  //         // this.planBluelineId = entity.id;
  //       }
  //       if (entity.properties.type === ENTITY_GEOMETRIC_TYPES.POLYGON && !entity.properties.hasOwnProperty('area')) {
  //         entity = this.calculateArea(entity);
  //       }
  //       entitiesForCurrentPlan.push(entity);
  //     }
  //   }
  //   return entitiesForCurrentPlan;
  // }

  // generateEntityFromServer(feature: Feature<Geometry, EntityProperties>, isShowAll: boolean): Feature<Geometry, EntityProperties> | null {
  //   if (!isShowAll && !this.isShow(feature)) {
  //     return null;
  //   }
  //   const entity = feature.properties;
  //   const currentPlan = this.currentPlan$.getValue();
  //   // @ts-ignore
  //   const entityVersion = ((currentPlan && currentPlan.planConfig) && currentPlan.planConfig[feature.id]) && currentPlan.planConfig[feature.id].version;
  //   if (entity.landUse === 'default_influence') {
  //     entity.landUse = DEFAULT_ENTITY_LANDUSE;
  //   }
  //   const landUse = entity.landUse || DEFAULT_ENTITY_LANDUSE;
  //   if (!((entityVersion && entity.entityVersion === entityVersion) || !currentPlan || !currentPlan.planConfig)) {
  //     return null;
  //   }
  //   // const menuData = this.menuData$.getValue();
  //   // FRN
  //   const menuData = this.menuDataStoreService.getValue();
  //   // console.log("MenuData: ", menuData)
  //   const category = entity.entityCategory || DEFAULT_ENTITY_CATEGORY;
  //   entity.entityPlanType = menuData[category] && menuData[category].landUse && menuData[category].landUse[landUse]
  //     && menuData[category].landUse[landUse].entityPlanType || ENTITY_PLAN_TYPE[0];

  //   const properties: EntityProperties = {
  //     name: entity.name,
  //     description: entity.description,
  //     style: typeof entity.style === 'object' ? entity.style : {},
  //     landUse,
  //     entityCategory: entity.entityCategory,
  //     entityType: entity.entityType,
  //     entityVersion,
  //     type: entity.type,
  //     projectName: entity.projectName,
  //     entityPlanType: entity.entityPlanType,
  //     date: entity.date || 0,
  //     isArchive: entity.isArchive,
  //     isLock: entity.isLock,
  //     isHide: entity.isHide ? false : entity.isHide,
  //   };

  //   if (entity.landuseCode) {
  //     Object.assign(properties, { landuseCode: entity.landuseCode });
  //   }
  //   if (entity.entityType) {
  //     Object.assign(properties, { entityType: entity.entityType });
  //     if (entity.entityType === 'road') {
  //       Object.assign(properties, { entityType: ENTITIES_TYPES.parcel.rightOfRoad });
  //     }
  //   }
  //   if (entity.isMavatLanduseCode) {
  //     Object.assign(properties, { isMavatLanduseCode: true });
  //   }
  //   if (entity.heightProperties) {
  //     properties.heightProperties = {
  //       height: entity.heightProperties.height,
  //       base_height: entity.heightProperties.base_height
  //     };
  //   }
  //   if (entity.area) {
  //     Object.assign(properties, { area: entity.area });
  //   }

  //   if (feature.id === SCOPELINE_INDEX_OLD) {
  //     properties.mapInfo = entity.mapInfo;
  //     properties.plansOrder = feature.properties.plansOrder || [this.plans$.getValue()[0].planName];
  //     if (feature.properties.layersV2) {
  //       properties.layersV2 = feature.properties.layersV2;
  //     }

  //   } else {
  //     const landUseParams = this.entityParamsValues(entity.entityCategory, entity.landUse);
  //     const isLandUseParamsObject = typeof entity.landUseParams === 'object';
  //     properties.landUseParams = isLandUseParamsObject ? entity.landUseParams : landUseParams;
  //   }
  //   if (entity.radius) {
  //     properties.radius = entity.radius;
  //   }
  //   return {
  //     id: feature.id,
  //     type: 'Feature',
  //     geometry: feature.geometry,
  //     properties
  //   };
  // }

  // isShow(entity: Feature<Geometry, EntityProperties>) {
  //   if (entity.id === SCOPELINE_INDEX_OLD) {
  //     return true;
  //   }

  //   if (entity.properties.entityCategory === ENTITY_CATEGORIES.INFLUENCE) {
  //     if (this.filterEntityTypes[ENTITY_CATEGORIES.INFLUENCE].find(type => type === entity.properties.landUse)) {
  //       return false;
  //     }
  //   }

  //   // const menuData = this.menuData$.getValue();
  //   // FRN
  //   const menuData = this.menuDataStoreService.getValue();
  //   // console.log("Menu DATA: ", menuData)
  //   const params = menuData[entity.properties.entityCategory] &&
  //     menuData[entity.properties.entityCategory].landUse[entity.properties.landUse];
  //   // if (params && params.maxZoom > 0 && params.maxZoom >= params.minZoom &&
  //   //   (params.minZoom > this.mapZoom || params.maxZoom < this.mapZoom)) {
  //   //   return false;
  //   // }

  //   for (const category in this.filterCategories) {
  //     // @ts-ignore
  //     if (this.filterCategories[category] && this.filterCategories[category].indexOf(entity.properties[category]) > -1) {
  //       if (category === 'entityCategory') {
  //         this.isEntitiesHidden = false;
  //       }
  //       return false;
  //     }
  //   }

  //   return true;
  // }

  // sortFeatureCollectionByZIndexFactory(val: Feature<Geometry, EntityProperties>[]): FeatureCollection<Geometry, EntityProperties> {
  //   const features: any = this.sortZIndex(val);
  //   return { type: 'FeatureCollection', features };
  // }

  // sortZIndex(entities: Feature<Geometry, EntityProperties>[]) {
  //   const menuData = this.menuData$.getValue();
  //   // FRN
  //   // const menuData = this.menuDataStoreService.getValue()
  //   return entities
  //     .sort((entityA, entityB) => {
  //       let z1 = (entityA.properties.style && entityA?.properties?.style?.zIndex > 0 ?
  //         entityA.properties.style.zIndex : this.getEntityParam(entityA.properties, 'zIndex', menuData) > 0 ?
  //           this.getEntityParam(entityA.properties, 'zIndex', menuData) : 1);
  //       let z2 = (entityB.properties.style && entityB.properties.style.zIndex > 0 ?
  //         entityB.properties.style.zIndex : this.getEntityParam(entityB.properties, 'zIndex', menuData) > 0 ?
  //           this.getEntityParam(entityB.properties, 'zIndex', menuData) : 1);
  //       z1 = Utils.tryParseFloat(z1);
  //       z2 = Utils.tryParseFloat(z2);
  //       return z1 - z2;
  //     });
  // }

  // private getZIndex(entity: Feature<Geometry, EntityProperties>, menuData: any): number {
  //   const zIndexFromStyle = entity.properties.style?.zIndex;
  //   const zIndexFromParam = this.getEntityParam(entity.properties, 'zIndex', menuData);
  
  //   if (zIndexFromStyle > 0) {
  //     return Utils.tryParseFloat(zIndexFromStyle);
  //   } else if (zIndexFromParam > 0) {
  //     return Utils.tryParseFloat(zIndexFromParam);
  //   } else {
  //     return 1;
  //   }
  // }
  
  // sortZIndex2(entities: Feature<Geometry, EntityProperties>[], menuData: any) {
  //   return entities.sort((entityA, entityB) => {
  //     const zIndexA = this.getZIndex(entityA, menuData);
  //     const zIndexB = this.getZIndex(entityB, menuData);
  //     return zIndexA - zIndexB;
  //   });
  // }
  

 // returns an observable
  // private getZIndex(entity: Feature<Geometry, EntityProperties>, menuData: any): number {
  //   const zIndexFromStyle = entity.properties.style?.zIndex;
  //   const zIndexFromParam = this.getEntityParam(entity.properties, 'zIndex', menuData);
  
  //   if (zIndexFromStyle > 0) {
  //     return Utils.tryParseFloat(zIndexFromStyle);
  //   } else if (zIndexFromParam > 0) {
  //     return Utils.tryParseFloat(zIndexFromParam);
  //   } else {
  //     return 1;
  //   }
  // }
  // returns an observable
  // sortZIndex2(entities: Feature<Geometry, EntityProperties>[]) {
  //   return this.menuData$.pipe(
  //     map(menuData => {
  //       return entities.sort((entityA, entityB) => {
  //         const zIndexA = this.getZIndex(entityA, menuData);
  //         const zIndexB = this.getZIndex(entityB, menuData);
  //         return zIndexA - zIndexB;
  //       });
  //     })
  //   );
  // }


  // deprecated moved to entities Service
  // getEntityParam(layer: EntityProperties, param: string, allParams: any) {
  //   try {
  //     if (param === 'color') {
  //       const mvt = MAVAT_DATA.find(mvt => mvt.landUse === layer.landUse);
  //       if (mvt && mvt.fillColor) {
  //         return mvt.fillColor;
  //       }
  //     }
  //     return allParams[layer.entityCategory].landUse[layer.landUse][param];
  //   } catch (e) {
  //     return 0;
  //   }
  // }

  // deprecated
  // setSelectedEntities(ids: any[], isPush: boolean, isEditMode?: boolean) {
  //   const filterEntities = (_ids: any[]) => {
  //     return _ids.reduce((prev, curr) => {
  //       const entity = this.findEntity(curr);
  //       if (!entity.properties.hasOwnProperty('heightProperties')) {
  //         prev.push(curr);
  //       }
  //       return prev;
  //     }, []);
  //   }
  //   if (isPush) {
  //     let indexes = this.selectedEntitiesIndexes;
  //     ids.forEach((id, index) => {
  //       const dx = indexes.indexOf(id);
  //       if (dx !== -1) {
  //         indexes.splice(dx, 1);
  //         ids.splice(index, 1);
  //       }
  //     });
  //     const values = indexes.concat(ids);
  //     const b = filterEntities([...new Set(values)])
  //     this.selectedEntitiesIndexes$.next(b);
  //   } else {
  //     const a = filterEntities(ids)
  //     this.selectedEntitiesIndexes$.next(a);
  //   }
  //   if (this.isNewProjectMode) {
  //     return;
  //   }
  //   this.isEditMode = isEditMode;
  //   this.setCurrentEntities(this.entities);
  // }

  // private setCurrentEntities(collection: FeatureCollection<Geometry, EntityProperties>) {
  //   const entitiesObj = this.convertEntitiesArrayToObj(collection.features);
  //   const entities: Array<Feature<Geometry, EntityProperties>> = [];
  //   for (const dx of this.selectedEntitiesIndexes) {
  //     entities.push(entitiesObj[dx]);
  //   }
  //   this.selectedEntities$.next(entities);
  //   this.updateEntitiesArrayAndObject(collection);
  // }

  // deprecated moved to projectStateStoreService
  // initMapCenter(options: any) {
  //   const entity = this.findEntity(SCOPELINE_INDEX_OLD);
  //   const blueline: any = entity ? entity.properties : null;
  //   try {
  //     options.center = [blueline.mapInfo.mapCenter[0], blueline.mapInfo.mapCenter[1]];
  //   } catch (e) {
  //     options.center = DEFAULT_MAP_INFO.coordinates;
  //   }
  //   this.newMapZoom = blueline && blueline.mapInfo && blueline.mapInfo.zoom || DEFAULT_MAP_INFO.zoom;
  //   options.zoom = this.mapZoom;
  //   console.log(options)
  //   return options;
  // }

  // filterEntities() {
  //   const planConfig = this.currentPlan$.getValue().planConfig;
  //   const entities = this.entities;
  //   // const allPlansEntities = this.getAllProjectEntitesSubjectValue();
  //   let add: FeatureCollection<Geometry, EntityProperties> = {
  //     type: 'FeatureCollection',
  //     features: []
  //   };
  //   let remove = [];
  //   const entsObject = this.entitiesAsObject;

  //   // for (const feature of allPlansEntities) {
  //   //   if (!entsObject.hasOwnProperty(feature.id) && (planConfig[feature.id] && planConfig[feature.id].version)) {
  //   //     // @ts-ignore
  //   //     const entity = this.generateEntityFromServer(feature, false);
  //   //     if (entity) {
  //   //       add.features.push(entity);
  //   //       entities.features.push(entity);
  //   //     }
  //   //   }
  //   // }

  //   const modifiedEntities = this.convertEntitiesArrayToObj(entities.features);
  //   entities.features.forEach(feature => {
  //     if (!this.isShow(feature)) {
  //       remove.push(feature.id);
  //       delete modifiedEntities[feature.id];
  //     }
  //   });
  //   const features = this.convertEntitiesObjToArray(modifiedEntities);
  //   const collection: FeatureCollection<Geometry, EntityProperties> = {
  //     type: 'FeatureCollection',
  //     features
  //   };
  //   this.updateEntitiesArrayAndObject(collection);
  // }


  // deprecated moved to entities service
  // entityParamsValues(entityCategory: string, landUse: string) {
  //   const isNumber = (num: any) => {
  //     const a = parseFloat(num.toString());
  //     return !isNaN(a);
  //   };
  //   const defaultParams = this.filterEntityParams(entityCategory, landUse);
  //   let obj = {};
  //   Object.keys(defaultParams).forEach(key => {
  //     // @ts-ignore
  //     defaultParams[key].forEach(param => {
  //       let value;
  //       if (param.value) {
  //         value = isNumber(param.value) ? param.value : (param.defaultValue || param.min || 0);
  //       } else {
  //         value = (param.defaultValue || param.min || 0);
  //       }
  //       Object.assign(obj, { [param.code]: value });
  //     });
  //   });
  //   return obj;
  // }


  // deprecated moved to entities service
  // filterEntityParams(entityCategory: string, landUse: string) {
  //   // const menuData = this.menuData$.getValue();
  //   // FRN
  //   const menuData = this.menuDataStoreService.getValue()
  //   const keys = Object.keys(menuData[entityCategory]).filter(x => x !== 'landUse');
  //   const menu = {};
  //   keys.forEach(menuKey => {
  //     if (menuData[entityCategory][menuKey]) {
  //       const arr = menuData[entityCategory][menuKey].filter((m: any) => {
  //         try {
  //           if (m.landUses && (m.landUses.indexOf(landUse) > -1 || m.landUses.indexOf('all_types') > -1)) {
  //             return m;
  //           }
  //         } catch (e) {
  //           console.error(e);
  //         }
  //     });
  //     if (arr && arr.length) {
  //       // @ts-ignore
  //       menu[menuKey] = arr;
  //     }
  // }});

  //   return menu;
  // }

  // deprecated moved to entities service
  // generateShapeFromGeometry(type: string) {
  //   if (type === ENTITY_GEOMETRIC_TYPES.LINE_STRING || type === 'MultiLineString') {
  //     return ENTITY_GEOMETRIC_TYPES.LINE_STRING;
  //   }
  //   if (type === ENTITY_GEOMETRIC_TYPES.POLYGON || type === 'MultiPolygon') {
  //     return ENTITY_GEOMETRIC_TYPES.POLYGON;
  //   }
  //   if (type === ENTITY_GEOMETRIC_TYPES.POINT || type === 'MultiPoint') {
  //     return ENTITY_GEOMETRIC_TYPES.POINT;
  //   }
  //   return null;
  // }

  // createScopeline(entity: any): Feature<LineString, EntityProperties> {
  //   const properties: EntityProperties = {
  //     date: Date.now(),
  //     description: '',
  //     entityCategory: null,
  //     entityPlanType: entity.properties.entityPlanType || ENTITY_PLAN_TYPE[0],
  //     entityType: null,
  //     entityVersion: 'v0',
  //     heightProperties: {},
  //     isArchive: false,
  //     isLock: false,
  //     isHide: false,
  //     landUse: 'mix-use',
  //     mapInfo: {
  //       mapCenter: entity.properties.mapCenter || DEFAULT_MAP_INFO.coordinates,
  //       zoom: 17,
  //       projectAddress: entity.properties.projectAddress,
  //       projectName: entity.properties.projectName,
  //       projectType: entity.properties.projectType
  //     },
  //     name: 'Scopeline',
  //     plansOrder: ['plan1'],
  //     projectName: '',
  //     type: ENTITY_GEOMETRIC_TYPES.LINE_STRING,
  //     style: {
  //       fillColor: '#C9ADBE'
  //     }
  //   };
  //   entity.properties = properties;
  //   entity.id = SCOPELINE_INDEX_OLD;
  //   if (entity.geometry && entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON) {
  //     const feature = TurfPolygonToLine.polygonToLine(entity);
  //     entity.geometry = feature.geometry;
  //   }
  //   return entity;
  // }

  // createEntity(entity: Feature<Geometry, EntityProperties>, options: { shape: any, params?: any, isImport?: boolean }): Feature<Geometry, EntityProperties> {
  //   console.log(Utils.clone(entity))
  //   if (this.isNewProjectMode) {
  //     // return this.createScopeline(entity);
  //   }
  //   // const nextEntityIndex: any = this.generateId;
  //   let entityCategory;
  //   let entityType;
  //   let landUse;
  //   if (options.params && options.params.entityCategory) {
  //     entityCategory = options.params.entityCategory;
  //     landUse = options.params.landUse || DEFAULT_ENTITY_LANDUSE;
  //   } else if (entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POINT) {
  //     entityCategory = 'poi';
  //     landUse = ENTITIES_TYPES.poi.POI;
  //   } else if (entity.geometry.type === ENTITY_GEOMETRIC_TYPES.LINE_STRING) {
  //     entityCategory = 'network';
  //     landUse = ENTITIES_TYPES.network.polyline;
  //   } else {
  //     entityCategory = DEFAULT_ENTITY_CATEGORY;
  //     landUse = DEFAULT_ENTITY_LANDUSE;
  //   }

  //   if (entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POINT && !entity.properties.radius) {
  //     entityType = ENTITY_CATEGORIES.POI;
  //   }

  //   // entity.id = nextEntityIndex.toString();
  //   const properties = {
  //     name: entity.properties && entity.properties.name ? entity.properties.name : TEMP_NAME,
  //     description: entity.properties && entity.properties.description ? entity.properties.description : '',
  //     entityCategory: entity.properties && entity.properties.entityCategory ? entity.properties.entityCategory : entityCategory,
  //     entityType: entity.properties && entity.properties.entityType ? entity.properties.entityType : null,
  //     entityVersion: 'v0',
  //     type: entity.geometry.type,
  //     landUse: entity.properties && entity.properties.landUse ? entity.properties.landUse : landUse,
  //     projectName: (this.currentPlan$.getValue()).projectName,
  //     entityPlanType: entity.properties.entityPlanType,
  //     style: entity.properties.style || {},
  //     date: Date.now()
  //   };
  //   if (entityType) {
  //     properties.entityType = entityType;
  //   }
  //   if (options.params) {
  //     if (options.params.landuseCode) {
  //       Object.assign(properties, { landuseCode: parseInt(options.params.landuseCode) });
  //     }
  //     if (options.params.entityType) {
  //       Object.assign(properties, { entityType: options.params.entityType });
  //     }
  //     if (options.params.isMavatLanduseCode) {
  //       Object.assign(properties, { isMavatLanduseCode: true });
  //     }
  //   }
  //   if (entity.properties.heightProperties) {
  //     Object.assign(properties, { heightProperties: entity.properties.heightProperties });
  //   }
  //   if (options.isImport && entity.properties) {
  //     // @ts-ignore
  //     entity.properties = { ...entity.properties, ...properties };
  //   } else {
  //     // @ts-ignore
  //     entity.properties = properties;
  //   }
  //   if (options.shape.type === ENTITY_GEOMETRIC_TYPES.CIRCLE) {
  //     if (!options.isImport) {
  //       entity.properties.entityCategory = 'influence';
  //       entity.properties.landUse = ENTITIES_TYPES.influence.influence;
  //     }
  //     entity.properties.radius = options.shape.radius;
  //     entity.properties.type = ENTITY_GEOMETRIC_TYPES.CIRCLE;
  //   }

  //   if (!(entity.properties.landUseParams && Object.keys(entity.properties.landUseParams).length > 0)) {
  //     const landUseParams = this.entityParamsValues(entity.properties.entityCategory, entity.properties.landUse);
  //     if (landUseParams) {
  //       entity.properties.landUseParams = landUseParams;
  //     }
  //   }
  //   if (properties.type === ENTITY_GEOMETRIC_TYPES.MULTI_LINE_STRING) {
  //     entity.properties.type = ENTITY_GEOMETRIC_TYPES.LINE_STRING;
  //   }
  //   if (properties.type === ENTITY_GEOMETRIC_TYPES.MULTI_POLYGON) {
  //     entity.properties.type = ENTITY_GEOMETRIC_TYPES.POLYGON;
  //   }
  //   if (properties.type === ENTITY_GEOMETRIC_TYPES.POLYGON) {
  //     // @ts-ignore
  //     entity.geometry.coordinates = entity.geometry.coordinates.map(co => co.map(coo => [coo[0], coo[1]]));
  //     entity = this.calculateArea(entity);
  //   }
  //   Utils.truncateGeoJson(entity);
  //   return this.checkBluelineCrossing(entity);
  // }

  // checkBluelineCrossing(entity: Feature<Geometry, EntityProperties>) {
  //   if (entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON && this.isCutPolyCrossBlueline) {
  //     const isIntersects = BooleanIntersects.default(entity, this.findEntity(SCOPELINE_INDEX_OLD));
  //     if (isIntersects) {
  //       // @ts-ignore
  //       const newCordinates = this.handleEntityOnBluelineCrossing(entity);
  //       if (newCordinates) {
  //         entity.geometry.coordinates = [...newCordinates];
  //       }
  //     }
  //   }
  //   return entity;
  // }

   // deprecated moved to entities service
  // uniqueIdValidator(features: Feature[], newId?: number): any[] {
  //   for (let i = 0; i < features.length; i++) {
  //     for (let j = 0; j < features.length; j++) {
  //       if (i === j) {
  //         continue;
  //       }
  //       if (features[i].id === features[j].id) {
  //         let generatedId = newId || this.generateId;
  //         do {
  //           generatedId++;
  //         } while (generatedId.toString() === features[j].id);
  //         features[i].id = generatedId.toString();
  //         return this.uniqueIdValidator(features, generatedId + 1);
  //       }
  //     }
  //   }
  //   return features;
  // }

  // deprecated moved to projectStateStore
  // addExternalLayers(newLayers: ExternalLayer[]) {
  //   const externalLayers = this.externalLayers$.getValue();
  //   externalLayers.remove = [];
  //   const currentLayers = externalLayers.layers.reduce((prev, curr) => {
  //     prev[curr.name] = curr.name;
  //     return prev;
  //   }, {});
  //   newLayers.forEach(layer => {
  //     const isLayerExists = currentLayers[layer.name];
  //     if (!isLayerExists) {
  //       externalLayers.layers.push(layer);
  //       externalLayers.add.push(layer);

  //     }
  //   });
  //   this.externalLayers$.next(externalLayers);
  // }

  // deprecated moved to projectStateStore
  // removeExternalLayers(removeLayers: ExternalLayer[]) {
  //   const externalLayers = this.externalLayers$.getValue();
  //   let layers = externalLayers.layers;
  //   const currentLayers = externalLayers.layers.reduce((prev, curr) => {
  //     prev[curr.name] = curr.name;
  //     return prev;
  //   }, {});
  //   removeLayers.forEach(layer => {
  //     const removeLyr = currentLayers[layer.name];
  //     if (removeLyr) {
  //       layers = layers.filter(lyr => lyr.name !== removeLyr);
  //     }
  //   });
  //   this.externalLayers$.next({ layers, add: [], remove: removeLayers });
  // }

  // changeExistingHistoryStateElement(data: StateHistoryModel) {
  //   this._history[this.historyIndex] = Utils.clone(data);
  // }

  // TODO: handle plan rename
  // updateHistoryState(data: StateHistoryModel) {
  //   if (0 < this._history.length && this.historyIndex !== this._history.length - 1) {
  //     this._history.length = this.historyIndex + 1;
  //   }

  //   if (data.action === 'remove' && this._history[this.historyIndex + 1]) {
  //     this._history.splice(this.historyIndex + 1);
  //   }
  //   if (this._history.length === 100) {
  //     this._history.shift();
  //     this.historyIndex--;
  //   }
  //   this._history.push(Utils.clone(data));
  //   this.historyIndex = this._history.length - 1;
  // }

  // async prevHistoryStep(currentIndex: number) {
  //   if (this._history[currentIndex].action === 'add') {
  //     // if (this.panelTab === PANEL_TABS.Edit_Layer) {
  //     //   this.panelTab = PANEL_TABS.Plan_Layer;
  //     // }
  //     // await this.deleteEntities(this._history[currentIndex].entities.map(entity => entity.id.toString()));
  //     this.changeHistoryState(currentIndex - 1);
  //     return;
  //   }
  //   this.changeHistoryState(currentIndex - 1);
  // }

  // changeHistoryState(index: number) {
  //   const data: StateHistoryModel = this.stateHistory[index];
  //   this.isStateHistoryMode = true;
  //   this.changeHistoryEntities(data);
  //   this.historyIndex = index;
  // }

  // changeHistoryEntities(data: StateHistoryModel) {
  //   if (data.action === 'remove') {
  //     const isToDelete = !!this.findEntity(data.entities[0].id);
  //     if (isToDelete) {
  //       // if (this.panelTab === PANEL_TABS.Edit_Layer) {
  //       //   this.panelTab = PANEL_TABS.Plan_Layer;
  //       // }
  //       // this.deleteEntities(data.entities.map(entity => entity.id.toString()));
  //       return;
  //     }
  //   }
  //   this.upsertHistoryEntities(data);
  // }

  // deprecated
  // upsertHistoryEntities(data: StateHistoryModel) {
  //   const entitiesObj = this.convertEntitiesArrayToObj(this.entities.features);
  //   data.entities.forEach(entity => entitiesObj[entity.id] = Utils.clone(entity));
  //   const featuresCollection: FeatureCollection<Geometry, EntityProperties> = {
  //     type: 'FeatureCollection',
  //     features: this.convertEntitiesObjToArray(entitiesObj)
  //   };
  //   this.updateEntitiesArrayAndObject(featuresCollection);
  //   // this.addEntitiesToState(data.entities);
  //   this.postEntitiesToServer(data.entities);
  //   // FRN use storesManagerService setSelectedEntities
  //   // this.setSelectedEntities(data.selected, false);
  //   this.currentPlan$.next(this.currentPlan$.getValue());
  //   // this.resetMap$.next();
  // }

  // resetHistory() {
  //   this._history = [];
  //   this.historyIndex = 0;
  // }

  // modifyPlanConfig(features: Feature[], param?, value?) {
  //   let isConfigChange: boolean;
  //   const plan = this.currentPlan$.getValue();
  //   if (!plan || !plan.planConfig) {
  //     return;
  //   }
  //   const plans = this.plans$.getValue();
  //   const currentPlanAdded = plans.find(p => p.planName === plan.planName);
  //   features.forEach((feature, index) => {
  //     if (!plan.planConfig[feature.id]) {
  //       currentPlanAdded.planConfig[feature.id] = { version: 'v0' };
  //       isConfigChange = true;
  //     }
  //     if (param && value) {
  //       currentPlanAdded.planConfig[feature.id][param] = Array.isArray(value) ? value[index] : value;
  //       isConfigChange = true;
  //     }
  //   });
  //   if (isConfigChange) {
  //     this.plans$.next(plans);
  //     this.currentPlan$.next(currentPlanAdded);
  //   }
  // }

  // addRemovePlanConfigParentId(parentId, entitiesDx) {
  //   const plan = this.currentPlan$.getValue();
  //   const plans = this.plans$.getValue();
  //   const currentModifiedPlan = plans.find(p => p.planName === plan.planName);
  //   Object.keys(currentModifiedPlan.planConfig).forEach(id => {
  //     if (currentModifiedPlan.planConfig[id].parentId && currentModifiedPlan.planConfig[id].parentId === parentId) {
  //       delete currentModifiedPlan.planConfig[id].parentId;
  //     }
  //   });
  //   if (entitiesDx) {
  //     entitiesDx.forEach(id => {
  //       currentModifiedPlan.planConfig[id].parentId = parentId;
  //     });
  //   }
  //   this.plans$.next(plans);
  //   this.currentPlan$.next(currentModifiedPlan);
  // }

  // deprecated
  // removeFromPlanConfig(entitiesDx: any[], plan: PlanInterface) {
  //   const entities = this.entities;
  //   const childrenDx = this.removeChildrenEntitiesByReference(plan, entitiesDx);
  //   const removedIds = entitiesDx.concat(childrenDx)
  //   removedIds.forEach(dx => {
  //     const index = entities.features.findIndex(ent => ent.id === dx);
  //     entities.features.splice(index, 1);
  //     delete plan.planConfig[dx];
  //   });
  //   return { entities, plan, removedIds };
  // }

  // removeChildrenEntitiesByReference(plan, entitiesDx) {
  //   return Object.keys(plan.planConfig).map(id => {
  //     if (plan.planConfig[id].parentId && entitiesDx.find(dx => dx === plan.planConfig[id].parentId)) {
  //       delete plan.planConfig[id];
  //       const child = this.findEntity(id);
  //       return child.properties.heightProperties && Object.keys(child.properties.heightProperties).length > 0 ? id : null;
  //     } else {
  //       return null;
  //     }
  //   }).filter(x => !!x);
  // }

  // addEntitiesToState(features: any[]) {
  //   const allPlansEntities = this.getAllProjectEntitesSubjectValue();
  //   // @ts-ignore
  //   const allPlansEntitiesObj = this.convertEntitiesArrayToObj(allPlansEntities);
  //   features.forEach(feature => {
  //     allPlansEntitiesObj[feature.id] = feature;
  //   });
  //   const modifiedAllPlansEntities = this.convertEntitiesObjToArray(allPlansEntitiesObj);
  //   this.sendAllProjectEntities(modifiedAllPlansEntities);
  // }

  // insertNewEntitiesLocally(entityArr: Feature<Geometry, EntityProperties>[]) {
  //   const entities = this.entities;
  //   if (this.isNewProjectMode) {
  //     entities.features = [...entityArr];
  //   } else {
  //     entities.features = [...entities.features, ...entityArr];
  //   }
  //   this.updateEntitiesArrayAndObject(entities);
  // }

  // prepareEntityForSaving(entity: Feature<any, EntityProperties>): Feature<any, EntityProperties> {
  //   const entityCategory = entity.properties.entityCategory || DEFAULT_ENTITY_CATEGORY;
  //   const landUse = entity.properties.landUse;
  //   // const menuData = this.menuData$.getValue();
  //   // FRN
  //   const menuData = this.menuDataStoreService.getValue()
  //   const entityPlanType = menuData[entityCategory] && menuData[entityCategory]
  //     && menuData[entityCategory].landUse && menuData[entityCategory].landUse[landUse] &&
  //     menuData[entityCategory].landUse[landUse].entityPlanType || ENTITY_PLAN_TYPE[0];
  //   const feature: Feature<any, EntityProperties> = {
  //     type: 'Feature',
  //     id: entity.id === SCOPELINE_INDEX_OLD ? entity.id : entity.id.toString(),
  //     // @ts-ignore
  //     geometry: this.findEntity(entity.id).geometry,
  //     properties: {
  //       name: entity.properties.name,
  //       description: entity.properties.description,
  //       projectName: entity.properties.projectName,
  //       style: entity.properties.style,
  //       entityCategory,
  //       entityType: entity.properties.entityType,
  //       type: entity.properties.type,
  //       landUse,
  //       entityPlanType,
  //       date: Date.now(),
  //       entityVersion: entity.properties.entityVersion,
  //       isArchive: entity.properties.isArchive || false,
  //       isLock: entity.properties.isLock || false,
  //       isHide: entity.properties.isHide || false,
  //     }
  //   };
  //   if (entity.properties.landuseCode) {
  //     Object.assign(feature.properties, { landuseCode: entity.properties.landuseCode });
  //   }
  //   if (entity.properties.entityType) {
  //     Object.assign(feature.properties, { entityType: entity.properties.entityType });
  //   }
  //   if (entity.properties.isMavatLanduseCode) {
  //     Object.assign(feature.properties, { isMavatLanduseCode: true });
  //   }
  //   if (entity.properties.heightProperties) {
  //     Object.assign(feature.properties, { heightProperties: entity.properties.heightProperties });
  //   }
  //   if (entity.properties.area) {
  //     Object.assign(feature.properties, { area: entity.properties.area });
  //   }
  //   if (feature.properties.type === ENTITY_GEOMETRIC_TYPES.CIRCLE) {
  //     feature.properties.radius = entity.properties.radius;
  //   }
  //   if (entity.id === SCOPELINE_INDEX_OLD) {
  //     feature.properties.mapInfo = entity.properties.mapInfo;
  //     feature.properties.plansOrder = entity.properties.plansOrder;
  //     if (entity.properties.layersV2) {
  //       feature.properties.layersV2 = entity.properties.layersV2;
  //     }
  //   } else {
  //     feature.properties.landUseParams = entity.properties.landUseParams;
  //   }
  //   return feature;
  // }

  // async saveEntity(entity: Feature<Geometry, EntityProperties>, isRegenerate?: boolean, isAvoidPlanModify?: boolean) {
  //   if (Object.keys(entity.geometry).length === 0) {
  //     // @ts-ignore
  //     entity.geometry = (this.findEntity(entity.id)).geometry;
  //   }
  //   entity = this.calculateArea(entity);
  //   this.updateEntity = entity;
  //   const feature = this.prepareEntityForSaving(entity);
  //   // this.addEntitiesToState([feature]);
  //   // this.panelTab = PANEL_TABS.Plan_Layer;
  //   await this.postEntityToServer(feature, isAvoidPlanModify);
  //   if (!this.isStateHistoryMode) {
  //     const isNewEntity = this.historyIsNewEntity;
  //     this.updateHistoryState({
  //       action: isNewEntity ? 'add' : 'upsert',
  //       entities: [entity],
  //       plan: this.currentPlan$.getValue(),
  //       // FRN
  //       // selected: isNewEntity ? [entity.id.toString()] : this.selectedEntitiesIndexes
  //     });
  //   }
  //   if (isRegenerate) {
  //     // for mock purpose
  //     const featureCollection = this.generatePlanEntities(true);
  //     try {
  //       const pipelineRes = await this.crudService.mockSaveEntity(featureCollection.features);
  //       // @ts-ignore
  //       return pipelineRes.result;
  //     } catch (e) {
  //       return null;
  //     }
  //   }
  // }

  // async saveEntities(entities: Feature<Geometry, EntityProperties>[]) {
  //   entities.forEach(entity => {
  //     if (!entity.properties.hasOwnProperty('heightProperties')) {
  //       entity = this.calculateArea(entity)
  //     }});
  //   this.updateEntities = entities;
  //   await this.postEntitiesToServer(entities);
  // }

  // deprecated moved to entities service
  // calculateArea(feature: Feature<Geometry, any>) {
  //   const calculateEntityAreaDunam = (sqm: number): number => {
  //     return sqm / 1000;
  //   }

  //   const calculateEntityAreaSqm = (feature: Feature<Geometry, any>): number => {
  //     // @ts-ignore
  //     return parseFloat(TurfArea.default(feature).toFixed(5));
  //   }
  //   if (feature.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON) {
  //     const sqm = calculateEntityAreaSqm(feature);
  //     const dunam = calculateEntityAreaDunam(sqm);
  //     if (feature.properties.entityType !== null && feature.properties.entityType === ENTITIES_TYPES.parcel.building) {
  //       feature.properties.area = { unit: 'sqm', value: sqm };
  //     } else {
  //       feature.properties.area = { unit: 'dunam', value: dunam };
  //     }
  //   }
  //   if (feature.geometry.type === ENTITY_GEOMETRIC_TYPES.MULTI_POLYGON) {
  //     feature.properties.area = { unit: 'dunam', value: 0 };
  //   }
  //   return feature;
  // }

  // async postEntityToServer(feature: Feature<Geometry, EntityProperties>, isAvoidPlanModify?: boolean) {
  //   const plan = this.currentPlan$.getValue();
  //   if (!plan || !plan.planConfig) {
  //     return;
  //   }
  //   try {
  //     if (!isAvoidPlanModify) {
  //       this.modifyPlanConfig([feature]);
  //       await this.crudService.saveEntityConfig(feature);
  //     }
  //     await this.crudService.upsertPlan(this.currentPlan$.getValue());
  //     this.isStateHistoryMode = false;
  //   } catch (e) {
  //     console.error(e);
  //     this.nzMessageService.error(`Something went wrong while saving...`);
  //   }
  // }

  // async postEntitiesToServer(features: Feature<Geometry, EntityProperties>[]) {
  //   try {
  //     this.modifyPlanConfig(features);
  //     await this.crudService.saveEntitiesConfigsBulk(features);
  //     await this.crudService.upsertPlan(this.currentPlan$.getValue());
  //     this.isStateHistoryMode = false;
  //     this.nzMessageService.success(`Saved`);
  //   } catch (e) {
  //     console.error(e);
  //     this.nzMessageService.error(`Something went wrong while saving...`);
  //   }
  // }

  // deprecated 
  // async deleteEntities(entitiesDx: string[], isResetSelectEntities?: boolean) {
  //   const findIfIsAllStoreys = (indexes) => {
  //     for (const id of indexes) {
  //       const entity = this.findEntity(id);
  //       if (entity && entity.properties.hasOwnProperty('heightProperties')) {
  //         return true;
  //       }
  //     }
  //     return false;
  //   }
  //   const currentPlan = this.currentPlan$.getValue();
  //   let isAllStoreys = findIfIsAllStoreys(entitiesDx);
  //   if (!(currentPlan && currentPlan.planConfig)) {
  //     return;
  //   }
  //   const { entities, plan, removedIds } = this.removeFromPlanConfig(entitiesDx, Utils.clone(currentPlan));
  //   const plans: PlanInterface[] = this.plans$.getValue();
  //   const planDX = plans.findIndex(p => p.planName === currentPlan.planName);
  //   plans[planDX] = plan;

  //   try {
  //     await this.crudService.upsertPlan(plan);
  //     await this.crudService.deleteEntitiesConfigsBulk(this.projectName, removedIds);
  //     this.isStateHistoryMode = false;
  //   } catch (e) {
  //     this.nzMessageService.error(`Something went wrong while saving...`);
  //   }
  //   if (isResetSelectEntities) {
  //     // this.setSelectedEntities([], false);
  //     this.storesManagerService.setSelectedEntities([], false)
  //   }
  //   const allPlansEntities = this.getAllProjectEntitesSubjectValue().filter(feature => !removedIds.includes(feature.id.toString()));
  //   const removedEntities = this.getAllProjectEntitesSubjectValue().filter(feature => removedIds.includes(feature.id.toString()));
  //   this.sendAllProjectEntities(allPlansEntities);
  //   this.plans$.next(plans);
  //   this.currentPlan$.next(plan);
  //   this.updateEntitiesArrayAndObject(entities);
  //   if (!isAllStoreys) {
  //     this.entityDeleted$.next();
  //   }
  //   if (!this.isStateHistoryMode && this._history[this.historyIndex].action !== 'add') {
  //     this.updateHistoryState({
  //       action: 'remove',
  //       // @ts-ignore
  //       entities: removedEntities,
  //       plan: plan,
  //       // FRN
  //       // selected: this.selectedEntitiesIndexes
  //     });
  //   }
  // }

  // async osmBuildingsInPolygon(polygonId = this.planBluelineId) {

  //   if (!polygonId) {
  //     return
  //   }

  //   try {
  //     const polygon = this.findEntity(polygonId);
  //     const resp: any = await this.crudService.osmBuildingsFromPolygon(polygon);
  //     const data = resp.return.osm_buildings_id;
  //     return data;
  //   } catch (e) {
  //     console.log(e)
  //   }

  // }
}
