<div class="contianer">
    <img src="../../../../assets/main-menu/BlueMapMarker.svg" alt="">
    <div class="text-container">
        <p class="large-text" for="">{{'ONBOARDING.HEREYOUCANFINDGIS' | translate }}</p>
        <p class="large-text" for="">{{'ONBOARDING.YOUCANTURNONANDOFF' | translate }}</p>
        <p class="small-text" for="">{{'ONBOARDING.CHECKTHE' | translate }} <span><i nz-icon nzType="info-circle" [nzTheme]="'twotone'"></i></span> {{'ONBOARDING.BUTTONFORMORINFO' | translate }}</p>
    </div>
    <div class="image-container">
        <img src="../../../../assets/main-menu/OnbaordingLeft.png" alt="">
        <img src="../../../../assets/main-menu/OnbaordingMiddle.png" alt="">
        <img src="../../../../assets/main-menu/OnbaordingRight.png" alt="">
    </div>
</div>
