
    <!-- <nz-input-group id="location-input"  [nzSize]="'small'" [nzCompact]="true">
      <input 
        nz-input 
        type="text" 
        placeholder="Fly to" 
        #location 
        [(ngModel)]="inputAddress"
        (keyup.enter)="flyTo()"
        [nzAutocomplete]="auto"
        (input)="searchAdress($event)"
      />
    </nz-input-group> -->

      <nz-input-group [nzCompact]="true" class="search-input">
        <div (click)="flyTo()" [ngClass]="isEdit ? 'search-icon-container-small' : 'search-icon-container'" >
          <i nz-icon nzType="search" nzTheme="outline"></i>
        </div>

        <nz-select [(ngModel)]="selectedSearchType" (ngModelChange)="onSearchTypeChange($event)">
          <nz-option [nzLabel]="'LOCATIONSEARCHBAR.BYADDRESS' | translate" [nzValue]="'0'"></nz-option>
          <nz-option [nzLabel]="'LOCATIONSEARCHBAR.BYPARCEL' | translate" [nzValue]="'1'"></nz-option>
        </nz-select>

        <div *ngIf="selectedSearchType === '0'">
          <input
          nz-input 
          type="text" 
          placeholder="{{'LOCATIONSEARCHBAR.ADDRESS' | translate}}"
          #location 
          [(ngModel)]="inputAddress"
          (keyup.enter)="flyTo()"
          [nzAutocomplete]="autoAddress"
          (input)="searchAddress($event)"
          [ngStyle]="{ 'width': dynamicWidth }"
 
        />
        </div>
        <div class="flyTo-container" *ngIf="selectedSearchType === '1'">
          <nz-input-group style="width: 50%;" [nzSuffix]="isSearching ? loading : isAddressExist ? blockErrorTpl : searchFailedTpl">
            <input
              nz-input
              type="text"
              placeholder="{{'LOCATIONSEARCHBAR.BLOCK' | translate}}"
              [formControl]="inputBlockControl"
              
            />
          </nz-input-group>
        
          <nz-input-group style="width: 50%;" [nzSuffix]="isSearching ? loading : isAddressExist ? parcelErrorTpl : searchFailedTpl">
            <input
              nz-input
              type="text"
              placeholder="{{'LOCATIONSEARCHBAR.PARCEL' | translate}}"
              [formControl]="inputParcelControl"
            />
          </nz-input-group>

          <ng-template #searchFailedTpl>
            <i 
              nzTooltipTitle="{{'LOCATIONSEARCHBAR.COULDNTFINDPARCELORBLOCK' | translate}}" nzTooltipPlacement="bottom" nz-button nz-tooltip
              nz-icon nzType="exclamation-circle" 
              nzTheme="outline" 
              style="color: red;">
            </i>
          </ng-template>

          <ng-template #blockErrorTpl>
            <i 
              nzTooltipTitle="{{'LOCATIONSEARCHBAR.PLEASEENTERVALIDBLOCK' | translate}}" nzTooltipPlacement="bottom" nz-button nz-tooltip
              *ngIf="!inputBlockControl.valid && inputBlockControl.dirty" 
              nz-icon nzType="exclamation-circle" 
              nzTheme="outline" 
              style="color: red;">
            </i>
          </ng-template>
        
          <ng-template #parcelErrorTpl>
            <i 
              nzTooltipTitle="{{'LOCATIONSEARCHBAR.PLEASEENTERVALIDPARCEL' | translate}}" nzTooltipPlacement="bottom" nz-button nz-tooltip
              *ngIf="!inputParcelControl.valid && inputParcelControl.dirty" 
              nz-icon nzType="exclamation-circle" 
              nzTheme="outline" 
              style="color: red;">
            </i>
          </ng-template>

          <ng-template #loading>
            <i nz-icon style="color: #1890ff !important;" nzTheme="outline"  nzType="loading"></i>
          </ng-template>

        </div>
      </nz-input-group>
      <!-- <ng-template #suffixIcon>
        <i nz-icon nzType="search" nzTheme="outline"></i>
      </ng-template> -->
  
    <nz-autocomplete #autoAddress>
      <nz-auto-option *ngFor="let addressOption of addressesOptions" [nzValue]="selectedSearchType == '0' ? addressOption : inputParcelControl.value" (click)="flyTo()">{{ addressOption }}</nz-auto-option>
    </nz-autocomplete>

    <!-- <nz-autocomplete #autoParcel>
      <nz-auto-option *ngFor="let parcelOption of parcelOptions" [nzValue]="parcelOptions" (click)="flyTo()">{{ parcelOption }}</nz-auto-option>
    </nz-autocomplete> -->
