import { ENTITY_CATEGORIES } from "./enums";

export const urbanEntityTypeColors = {
  'marketable lot': '#FFB33F',
  'public lot': '#AE8060',
  'right of right of road': '#E95D59',
  'open area': '#5EA75F',
  green: '#9BD647',
  infrastructure: '#b5838d',
  'water body': '#15A3C2',
  other: '#C8C7C1'
};


// new mavatUrbanDashboard 
export const mavatUrbanDashboard = {
  // MVT_GVUL
  "20010": {
    "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
    "urbanLandUse": "plan_boundary", 
    "mavatNameHebrew": "גבול תוכנית", 
    "pattern": "",
    "accessType": "other"
  },
  "10": {
    "entityCategory": ENTITY_CATEGORIES.PARCEL,
    "urbanLandUse": "residential", 
    "mavatNameHebrew": "מגורים", 
    "pattern": "10.png",
    "accessType": "private"
  },
  // MVT_PLAN
  "20": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "residential", "mavatNameHebrew": "מגורים א", "pattern": "20.png", "accessType": "private"},
  "60": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "residential", "mavatNameHebrew": "מגורים ב", "pattern": "60.png", "accessType": "private"},
  "100": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "residential", "mavatNameHebrew": "מגורים ג", "pattern": "100.png", "accessType": "private"},
  "140": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "residential", "mavatNameHebrew": "מגורים ד", "pattern": "140.png", "accessType": "private"},
  "145": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "residential", "mavatNameHebrew": "מגורים ה", "pattern": "145.png", "accessType": "private"},
  "150": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "residential", "mavatNameHebrew": "דיור מיוחד", "pattern": "150.png", "accessType": "private"},
  "160": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "residential", "mavatNameHebrew": "מגורים ביישוב כפרי", "pattern": "160.png", "accessType": "private"},
  "170": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "agriculture", "mavatNameHebrew": "משק עזר", "pattern": "170.png", "accessType": "private"},
  "200": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "office", "mavatNameHebrew": "תעסוקה", "pattern": "200.png", "accessType": "private"},
  "210": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "retail", "mavatNameHebrew": "מסחר", "pattern": "210.png", "accessType": "private"},
  "220": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "office", "mavatNameHebrew": "משרדים", "pattern": "220.png", "accessType": "private"},
  "230": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "industrial", "mavatNameHebrew": "תעשיה", "pattern": "230.png", "accessType": "private"},
  "240": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "industrial", "mavatNameHebrew": "אחסנה", "pattern": "240.png", "accessType": "private"},
  "250": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "industrial", "mavatNameHebrew": "תעשיה עתירת ידע", "pattern": "250.png", "accessType": "private"},
  "260": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "industrial", "mavatNameHebrew": "תעשיה קלה ומלאכה", "pattern": "260.png", "accessType": "private"},
  "280": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מתקנים הנדסיים", "pattern": "280.png", "accessType": "private"},
  "290": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "עירוני מעורב", "pattern": "290.png", "accessType": "private"},
  "300": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "agriculture", "mavatNameHebrew": "מבני משק", "pattern": "300.png", "accessType": "private"},
  "750": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "retail", "mavatNameHebrew": "גן אירועים", "pattern": "750.png", "accessType": "private"},
  "972": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "industrial", "mavatNameHebrew": "מרכז לוגיסטי", "pattern": "972.png", "accessType": "private"},
  "400": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "public_buildings", "mavatNameHebrew": "מבנים ומוסדות ציבור", "pattern": "400.png", "accessType": "private"},
  "600": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "hotel", "mavatNameHebrew": "ֿתיירות", "pattern": "600.png", "accessType": "private"},
  "610": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "hotel", "mavatNameHebrew": "אירוח כפרי", "pattern": "610.png", "accessType": "private"},
  "620": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "hotel", "mavatNameHebrew": "מלונאות אכסון מלונאי", "pattern": "620.png", "accessType": "private"},
  "630": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "hotel", "mavatNameHebrew": "אטרקציות תיירותיות", "pattern": "630.png", "accessType": "private"},
  "650": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "שטחים פתוחים", "pattern": "650.png", "accessType": "private"},
  "660": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "agriculture", "mavatNameHebrew": "קרקע חקלאית", "pattern": "660.png", "accessType": "private"},
  "661": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "agriculture", "mavatNameHebrew": "חקלאי פתוח", "pattern": "661.png", "accessType": "private"},
  "670": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "שטח ציבורי פתוח", "pattern": "670.png", "accessType": "public"},
  "680": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "green_area", "mavatNameHebrew": "פרטי פתוח", "pattern": "680.png", "accessType": "public"},
  "690": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "ספורט ונופש", "pattern": "690.png", "accessType": "public"},
  "700": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "פארק/ גן ציבורי", "pattern": "700.png", "accessType": "public"},
  "710": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "יער", "pattern": "710.png", "accessType": "public"},
  "711": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "יער נטע אדם", "pattern": "711.png", "accessType": "public"},
  "712": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "יער טבעי", "pattern": "712.png", "accessType": "public"},
  "713": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "יער פארק", "pattern": "713.png", "accessType": "public"},
  "5707": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "אתר הנצחה", "pattern": "5707.png", "accessType": "public"},
  "720": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "שמורת טבע", "pattern": "720.png", "accessType": "public"},
  "730": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "גן לאומי", "pattern": "730.png", "accessType": "public"},
  "740": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "water", "mavatNameHebrew": "נחל/ תעלת נחל", "pattern": "740.png", "accessType": "public"},
  "741": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "water", "mavatNameHebrew": "אתר איגום ו/או החדרה", "pattern": "741.png", "accessType": "public"},
  "760": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "pedestrian_path", "mavatNameHebrew": "טיילת", "pattern": "760.png", "accessType": "public"},
  "770": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "חוף רחצה", "pattern": "770.png", "accessType": "public"},
  "773": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "water", "mavatNameHebrew": "מרחב ימי פתוח", "pattern": "773.png", "accessType": "public"},
  "780": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "כיכר עירונית", "pattern": "780.png", "accessType": "public"},
  "800": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "תחבורה", "pattern": "800.png", "accessType": "public"},
  "810": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מרכז תחבורה", "pattern": "810.png", "accessType": "public"},
  "903": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "דרך לתחבורה ציבורית", "pattern": "903.png", "accessType": "public"},
  "902": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "תחנת תחבורה ציבורית"}, "pattern": "902.png", "accessType": "public",
  "820": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "road_existing", "mavatNameHebrew": "דרך מאושרת", "pattern": "820.png", "accessType": "public"},
  "830": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "road_new", "mavatNameHebrew": "דרך מוצעת", "pattern": "830.png", "accessType": "public"},
  "840": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "road_new", "mavatNameHebrew": "דרך משולבת", "pattern": "840.png", "accessType": "public"},
  "850": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "road_new", "mavatNameHebrew": "דרך ו/או טיפול נופי", "pattern": "850.png", "accessType": "public"},
  "855": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מסילה ו/או טיפול נופי", "pattern": "855.png", "accessType": "public"},
  "880": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מסילת ברזל מאושרת", "pattern": "880.png", "accessType": "public"},
  "890": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מסילת ברזל מוצעת", "pattern": "890.png", "accessType": "public"},
  "900": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "שטח לתפעול מסילה", "pattern": "900.png", "accessType": "public"},
  "971": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "שדה תעופה", "pattern": "971.png", "accessType": "public"},
  "860": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "pedestrian_path", "mavatNameHebrew": "שביל", "pattern": "860.png", "accessType": "public"},
  "870": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "חניון", "pattern": "870.png", "accessType": "public"},
  "940": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "דרך נופית", "pattern": "940.png", "accessType": "public"},
  "910": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "תחנת תדלוק", "pattern": "910.png", "accessType": "public"},
  "920": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מרכז להדרכת נהיגה", "pattern": "920.png", "accessType": "public"},
  "930": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מפגש דרך- מסילה", "pattern": "930.png", "accessType": "public"},
  "933": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "נמל", "pattern": "933.png", "accessType": "public"},
  "931": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מעגנה תחום יבשתי", "pattern": "931.png", "accessType": "public"},
  "932": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "מעגנה תחום ימי", "pattern": "932.png", "accessType": "public"},
  "935": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "נמל תעופה כללי", "pattern": "935.png", "accessType": "public"},
  "936": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "נמל תעופה מסלולים", "pattern": "936.png", "accessType": "public"},
  "937": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "נמל תעופה אזורי שירות", "pattern": "936.png", "accessType": "public"},
  "950": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "other_public", "mavatNameHebrew": "שטח לתכנון בעתיד", "pattern": "950.png", "accessType": "public"},
  "955": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "רצועת תשתיות", "pattern": "955.png", "accessType": "public"},
  "960": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "כריה חציבה", "pattern": "960.png", "accessType": "public"},
  "970": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "infrastructure", "mavatNameHebrew": "פסולת", "pattern": "970.png", "accessType": "public"},
  "980": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "park", "mavatNameHebrew": "בית קברות", "pattern": "980.png", "accessType": "public"},
  "990": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "other_public", "mavatNameHebrew": "מחנה צבאי", "pattern": "990.png", "accessType": "public"},
  "994": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "other_public", "mavatNameHebrew": "גבול הודעה בדבר הכנת תוכנית", "pattern": "994.png", "accessType": "public"},
  "995": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "other_public", "mavatNameHebrew": "ייעוד על פי תוכנית מאושרת אחרת", "pattern": "995.png", "accessType": "public"},
  "996": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "other_public", "mavatNameHebrew": "מגבלות בניה ופיתוח", "pattern": "996.png", "accessType": "public"},
  "997": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "other_public", "mavatNameHebrew": "מגבלות בניה ופיתוח ב", "pattern": "997.png", "accessType": "public"},
  "999": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "other_public", "mavatNameHebrew": "שטח שהתוכנית אינו חלה עליו", "pattern": "999.png", "accessType": "public"},
  "1000": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ומסחר", "pattern": "1000.png", "accessType": "private"},
  "1100": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ותעסוקה", "pattern": "1100.png", "accessType": "private"},
  "1050": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ותעסוקה", "pattern": "1050.png", "accessType": "private"},
  "1200": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ומשרדים", "pattern": "1200.png", "accessType": "private"},
  "1211": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ותעשיה עתירת ידע", "pattern": "1211.png", "accessType": "private"},
  "1221": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ומתקנים הנדסיים", "pattern": "1221.png", "accessType": "private"},
  "1250": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ומבנים ומוסדות ציבור", "pattern": "1250.png", "accessType": "private"},
  "1311": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ותיירות", "pattern": "1311.png", "accessType": "private"},
  "1321": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ותחבורה", "pattern": "1321.png", "accessType": "private"},
  "1350": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ודיור מיוחד", "pattern": "1350.png", "accessType": "private"},
  "1352": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים ואירוח כפרי", "pattern": "1352.png", "accessType": "private"},
  "1410": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר, מבנים ומוסדות ציבור", "pattern": "1410.png", "accessType": "private"},
  "1420": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ותיירות", "pattern": "1420.png", "accessType": "private"},
  "1430": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ומתקנים הנדסיים", "pattern": "1430.png", "accessType": "private"},
  "1440": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ותחבורה", "pattern": "1440.png", "accessType": "private"},
  "1460": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, מסחר ומעגנה", "pattern": "1460.png", "accessType": "private"},
  "1470": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה ותיירות", "pattern": "1470.png", "accessType": "private"},
  "1480": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה, מבנים ומוסדות ציבור", "pattern": "1480.png", "accessType": "private"},
  "1482": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה ומתקנים הנדסיים", "pattern": "1482.png", "accessType": "private"},
  "1484": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה ותחבורה", "pattern": "1484.png", "accessType": "private"},
  "1488": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תעסוקה ומעגנה", "pattern": "1488.png", "accessType": "private"},
  "1490": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תיירות ומתקנים הנדסיים", "pattern": "1490.png", "accessType": "private"},
  "1492": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תיירות, מבנים ומוסדות ציבור", "pattern": "1492.png", "accessType": "private"},
  "1494": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מגורים, תיירות ותחבורה", "pattern": "1494.png", "accessType": "private"},
  "1003": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "דיור מיוחד ותעסוקה", "pattern": "1003.png", "accessType": "private"},
  "1001": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "דיור מיוחד ומסחר", "pattern": "1001.png", "accessType": "private"},
  "1300": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "דיוק מיוחד ומבנים ומוסדות ציבור", "pattern": "1300.png", "accessType": "private"},
  "1500": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותעשיה", "pattern": "1500.png", "accessType": "private"},
  "1502": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותעסוקה", "pattern": "1510.png", "accessType": "private"},
  "1504": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ומתקנים הנדסיים", "pattern": "1504.png", "accessType": "private"},
  "1510": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותחבורה", "pattern": "1510.png", "accessType": "private"},
  "1520": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ומשרדים", "pattern": "1520.png", "accessType": "private"},
  "1530": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותעשיה עתירת ידע", "pattern": "1530.png", "accessType": "private"},
  "1540": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעשיה קלה ומלאכה", "pattern": "1540.png", "accessType": "private"},
  "1550": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ומבני ומוסדות ציבור", "pattern": "1550.png", "accessType": "private"},
  "1560": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ותיירות", "pattern": "1560.png", "accessType": "private"},
  "1562": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעסוקה ותיירות", "pattern": "1562.png", "accessType": "private"},
  "1563": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תחבורה ותיירות", "pattern": "1563.png", "accessType": "private"},
  "1564": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ואירוח כפרי", "pattern": "1564.png", "accessType": "private"},
  "1565": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר וספורט ונופש", "pattern": "1565.png", "accessType": "private"},
  "1570": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר ואחסנה", "pattern": "1570.png", "accessType": "private"},
  "1572": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעסוקה ומבנים הנדסיים", "pattern": "1572.png", "accessType": "private"},
  "1574": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעסוקה ותחבורה", "pattern": "1574.png", "accessType": "private"},
  "1576": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תעסוקה ומוסדות ציבור", "pattern": "1576.png", "accessType": "private"},
  "1578": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תיירות, מבנים ומוסדות ציבור", "pattern": "1578.png", "accessType": "private"},
  "1579": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מסחר, תחבורה, מבנים ומוסדות ציבור", "pattern": "1579.png", "accessType": "private"},
  "1580": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "תעשיה, תעשיה קלה ומלאכה", "pattern": "1580.png", "accessType": "private"},
  "1590": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "תעסוקה ותחבורה", "pattern": "1590.png", "accessType": "private"},
  "1600": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "תעסוקה ומבנים ומוסדות ציבור", "pattern": "1600.png", "accessType": "private"},
  "1602": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מבנים ומוסדות ציבור ומתקנים הנדסיים", "pattern": "1602.png", "accessType": "private"},
  "1604": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מבנים ומוסדות ציבור ותחבורה", "pattern": "1604.png", "accessType": "private"},
  "1610": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מבנים ומוסדות ציבור ומשרדים", "pattern": "1610.png", "accessType": "private"},
  "1620": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "תעסוקה ומבנים ומוסדות ציבור ותיירות", "pattern": "1620.png", "accessType": "private"},
  "1630": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "תעשיה ומשרדים", "pattern": "1630.png", "accessType": "private"},
  "1640": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "תעשיה ואחסנה", "pattern": "1640.png", "accessType": "private"},
  "1650": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "מבנים ומוסדות ציבור ותיירות", "pattern": "1650.png", "accessType": "private"},
  "1660": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "שטחים פתוחים ומתקניים הנדסיים", "pattern": "1660.png", "accessType": "private"},
  "1670": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "שטחים פתוחים מבנים ומוסדות ציבור", "pattern": "1670.png", "accessType": "private"},
  "1680": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "קרקע חקלאית ומתקנים הנדסיים", "pattern": "1680.png", "accessType": "private"},
  "1681": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "קרקע חקלאית ונופש כפרי", "pattern": "1681.png", "accessType": "private"},
  "1690": {"entityCategory": ENTITY_CATEGORIES.PARCEL, "urbanLandUse": "mix_use", "mavatNameHebrew": "כריה, חציבה ופסולת", "pattern": "1690.png", "accessType": "private"},
  //MVT_POL
  "24101": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אתר לתכנון עתידי",
      "pattern": "",
      "accessType": "other"
  },
  "24100": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אתר כריה וחציבה",
      "pattern": "",
      "accessType": "other"
  },
  "24090": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "הנחיות מיוחדות ב",
      "pattern": "",
      "accessType": "other"
  },
  "24040": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "דיור להשכרה",
      "pattern": "",
      "accessType": "other"
  },
  "23340": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "חורשה להעתקה",
      "pattern": "",
      "accessType": "other"
  },
  "22400": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "חורשה לעקירה",
      "pattern": "",
      "accessType": "other"
  },
  "22350": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "חורשה לשימור",
      "pattern": "",
      "accessType": "other"
  },
  "20450": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "תיחום מתוכנית קודמת",
      "pattern": "",
      "accessType": "other"
  },
  "20440": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "תחום השפעה",
      "pattern": "",
      "accessType": "other"
  },
  "20410": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גבול מגבלות בניה",
      "pattern": "",
      "accessType": "other"
  },
  "20400": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מנהרה/מעבר תחתי",
      "pattern": "",
      "accessType": "other"
  },
  "20390": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גשר/מעבר עילי",
      "pattern": "",
      "accessType": "other"
  },
  "20380": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מגבלות אקוסטיות",
      "pattern": "",
      "accessType": "other"
  },
  "20370": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שטח חילחול",
      "pattern": "",
      "accessType": "other"
  },
  "20360": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "ימי/ תת ימי",
      "pattern": "",
      "accessType": "other"
  },
  "20355": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "הנחיות מיוחדות ב",
      "pattern": "",
      "accessType": "other"
  },
  "20350": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "הנחיות מיוחדות",
      "pattern": "",
      "accessType": "other"
  },
  "20340": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "עיצוב מיוחד",
      "pattern": "",
      "accessType": "other"
  },
  "20330": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "טיפול נופי",
      "pattern": "",
      "accessType": "other"
  },
  "20320": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "דרך/מסילה לביטול",
      "pattern": "",
      "accessType": "other"
  },
  "20318": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו בנין תחתי",
      "pattern": "",
      "accessType": "other"
  },
  "20317": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו בנין",
      "pattern": "",
      "accessType": "other"
  },
  "20316": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו  בנין",
      "pattern": "",
      "accessType": "other"
  },
  "20315": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "right_of_way",
      "mavatNameHebrew": "זיקת  הנאה למעבר ברכב",
      "pattern": "",
      "accessType": "other"
  },
  "20310": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "right_of_way",
      "mavatNameHebrew": "זיקת הנאה למעבר ברכב",
      "pattern": "",
      "accessType": "other"
  },
  "20305": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "right_of_way",
      "mavatNameHebrew": "זיקת-הנאה למעבר רגלי",
      "pattern": "",
      "accessType": "other"
  },
  "20300": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "right_of_way",
      "mavatNameHebrew": "זיקת הנאה למעבר רגלי",
      "pattern": "",
      "accessType": "other"
  },
  "20295": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "right_of_way",
      "mavatNameHebrew": "זיקת הנאה",
      "pattern": "",
      "accessType": "other"
  },
  "20290": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "right_of_way",
      "mavatNameHebrew": "זיקת -הנאה",
      "pattern": "",
      "accessType": "other"
  },
  "20280": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "חניה",
      "pattern": "",
      "accessType": "other"
  },
  "20270": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "רצועת מתע\"נ תת-קרקעית",
      "pattern": "",
      "accessType": "other"
  },
  "20260": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "רצועת מתע\"נ",
      "pattern": "",
      "accessType": "other"
  },
  "20250": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "יעוד מתכנית ארצית או מחוזית",
      "pattern": "",
      "accessType": "other"
  },
  "20240": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שיקום/התחדשות",
      "pattern": "",
      "accessType": "other"
  },
  "20230": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אתר/מתחם לשימור",
      "pattern": "",
      "accessType": "other"
  },
  "20220": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אתר לאומי",
      "pattern": "",
      "accessType": "other"
  },
  "20210": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שטח עתיקות/הסטורי לשימור",
      "pattern": "",
      "accessType": "other"
  },
  "20200": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אתר עתיקות/אתר הסטורי",
      "pattern": "",
      "accessType": "other"
  },
  "20190": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שימור נופי",
      "pattern": "",
      "accessType": "other"
  },
  "20180": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "רצועה לתכנון",
      "pattern": "",
      "accessType": "other"
  },
  "20170": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שטח להשלמה",
      "pattern": "",
      "accessType": "other"
  },
  "20160": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שטח לתכנון מפורט",
      "pattern": "",
      "accessType": "other"
  },
  "20150": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שטח לאיחוד וחלוקה חדשה",
      "pattern": "",
      "accessType": "other"
  },
  "20140": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מבנה להעתקה/שיחזור",
      "pattern": "",
      "accessType": "other"
  },
  "20130": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מבנה לשימור",
      "pattern": "",
      "accessType": "other"
  },
  "20120": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מבנה  להריסה",
      "pattern": "",
      "accessType": "other"
  },
  "20110": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מבנה להריסה",
      "pattern": "",
      "accessType": "other"
  },
  "20100": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מבנה מוצע",
      "pattern": "",
      "accessType": "other"
  },
  "20090": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גבול מסדרון תשתיות ת\"ק",
      "pattern": "",
      "accessType": "other"
  },
  "20080": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גבול מסדרון תשתיות עילי",
      "pattern": "",
      "accessType": "other"
  },
  "20070": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גבול מתחם תלת מימדי",
      "pattern": "",
      "accessType": "other"
  },
  "20060": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מגרש המחולק לתאי שטח",
      "pattern": "",
      "accessType": "other"
  },
  "20050": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שמורת נוף",
      "pattern": "",
      "accessType": "other"
  },
  "20040": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גבול מתחם",
      "pattern": "",
      "accessType": "other"
  },
  "20035": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "לא ידוע",
      "pattern": "",
      "accessType": "other"
  },
  "20031": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שמורת טבע",
      "pattern": "",
      "accessType": "other"
  },
  "20030": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שטח שהתכנית אינה חלה עליו",
      "pattern": "",
      "accessType": "other"
  },
  "20020": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גן לאומי",
      "pattern": "",
      "accessType": "other"
  },
//MVT_ARC
  "21010": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו-בנין",
      "pattern": "",
      "accessType": "other"
  },
  "21012": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו בנין מתכנית קודמת",
      "pattern": "",
      "accessType": "other"
  },
  "21015": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו בנין",
      "pattern": "",
      "accessType": "other"
  },
  "21020": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו בנין עילי",
      "pattern": "",
      "accessType": "other"
  },
  "21025": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו  בנין עילי",
      "pattern": "",
      "accessType": "other"
  },
  "21030": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו בנין  תחתי/תת קרקעי",
      "pattern": "",
      "accessType": "other"
  },
  "21035": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו בנין תחתי/תת קרקעי",
      "pattern": "",
      "accessType": "other"
  },
  "21040": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו  בנין מתכנית קודמת",
      "pattern": "",
      "accessType": "other"
  },
  "21050": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גבול מרחב תכנון",
      "pattern": "",
      "accessType": "other"
  },
  "21060": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גבול שטח שיפוט",
      "pattern": "",
      "accessType": "other"
  },
  "21070": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "גבול תכנית מאושרת",
      "pattern": "",
      "accessType": "other"
  },
  "21080": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "טיילת",
      "pattern": "",
      "accessType": "other"
  },
  "21100": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "commercial_front",
      "mavatNameHebrew": "חזית מסחרית",
      "pattern": "",
      "accessType": "other"
  },
  "21110": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "חזית עם הוראות מיוחדות",
      "pattern": "",
      "accessType": "other"
  },
  "21120": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "להריסה",
      "pattern": "",
      "accessType": "other"
  },
  "21130": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "להריסה",
      "pattern": "",
      "accessType": "other"
  },
  "21140": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "לשימור",
      "pattern": "",
      "accessType": "other"
  },
  "21150": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "ציר נחל",
      "pattern": "",
      "accessType": "other"
  },
  "21160": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "קו החוף",
      "pattern": "",
      "accessType": "other"
  },
  "21170": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "תחום חוף הים",
      "pattern": "",
      "accessType": "other"
  },
  "21180": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "תחום הסביבה החופית",
      "pattern": "",
      "accessType": "other"
  },
  "21190": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "קו שבר גאולוגי",
      "pattern": "",
      "accessType": "other"
  },
  "21200": {
      "entityCategory": "ENTITY_CATEGORIES.NETWORK",
      "urbanLandUse": "pedestrian_path",
      "mavatNameHebrew": "ציר הולכי רגל",
      "pattern": "",
      "accessType": "other"
  },
  "21210": {
      "entityCategory": "ENTITY_CATEGORIES.NETWORK",
      "urbanLandUse": "bicycle_lane",
      "mavatNameHebrew": "ציר אופניים",
      "pattern": "",
      "accessType": "other"
  },
  "21220": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "ציר מערכת הסעת המונים",
      "pattern": "",
      "accessType": "other"
  },
  "21230": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "ציר תחבורה ציבורית",
      "pattern": "",
      "accessType": "other"
  },
  "24050": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "lot_line",
      "mavatNameHebrew": "קו בנין תחתי/ תת קרקעי",
      "pattern": "",
      "accessType": "other"
  },
  "24102": {
      "entityCategory": ENTITY_CATEGORIES.GUIDELINE_ELEMENTS,
      "urbanLandUse": "default",
      "mavatNameHebrew": "רצועה לתכנון",
      "pattern": "",
      "accessType": "other"
  },
//MVT_SYMBOL
"24107": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מוקד פעילות",
      "pattern": "",
      "accessType": "other"
  },
  "24109": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "בית קברות",
      "pattern": "",
      "accessType": "other"
  },
  "24106": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "חניון לרכב כבד",
      "pattern": "",
      "accessType": "other"
  },
  "24105": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "שמירת מבטים",
      "pattern": "",
      "accessType": "other"
  },
  "24104": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "חניון ציבורי",
      "pattern": "",
      "accessType": "other"
  },
  "24103": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מעגנה",
      "pattern": "",
      "accessType": "other"
  },
  "22600": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "ביטול",
      "pattern": "",
      "accessType": "other"
  },
  "22370": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "כניסה ",
      "pattern": "",
      "accessType": "other"
  },
  "22360": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "תחנת תדלוק",
      "pattern": "",
      "accessType": "other"
  },
  "22320": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מעבר גבול",
      "pattern": "",
      "accessType": "other"
  },
  "22310": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מסוף אוטובוסים/מוניות",
      "pattern": "",
      "accessType": "other"
  },
  "22300": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מסוף מתע\"נ",
      "pattern": "",
      "accessType": "other"
  },
  "22290": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אתר קידוח",
      "pattern": "",
      "accessType": "other"
  },
  "22280": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מתקן גז",
      "pattern": "",
      "accessType": "other"
  },
  "22270": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אגם החדרה",
      "pattern": "",
      "accessType": "other"
  },
  "22260": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "בריכת אגירה",
      "pattern": "",
      "accessType": "other"
  },
  "22250": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מתקן התפלה",
      "pattern": "",
      "accessType": "other"
  },
  "22240": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מכון טיהור שופכין",
      "pattern": "",
      "accessType": "other"
  },
  "22230": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "תחנת השנאה",
      "pattern": "",
      "accessType": "other"
  },
  "22220": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "תחנת כח",
      "pattern": "",
      "accessType": "other"
  },
  "22210": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אנטנה ג",
      "pattern": "",
      "accessType": "other"
  },
  "22200": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אנטנה ב",
      "pattern": "",
      "accessType": "other"
  },
  "22190": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אנטנה א",
      "pattern": "",
      "accessType": "other"
  },
  "22180": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מחלף",
      "pattern": "",
      "accessType": "other"
  },
  "22170": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "tree",
      "mavatNameHebrew": "עץ/עצים לשימור",
      "pattern": "",
      "accessType": "other"
  },
  "22160": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "tree",
      "mavatNameHebrew": "עץ/עצים להעתקה",
      "pattern": "",
      "accessType": "other"
  },
  "22150": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "tree",
      "mavatNameHebrew": "עץ/עצים לעקירה",
      "pattern": "",
      "accessType": "other"
  },
  "22140": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מבנה לשימור",
      "pattern": "",
      "accessType": "other"
  },
  "22130": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מבנה לשחזור/העתקה",
      "pattern": "",
      "accessType": "other"
  },
  "22120": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מבנה להריסה",
      "pattern": "",
      "accessType": "other"
  },
  "22110": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "אנדרטה",
      "pattern": "",
      "accessType": "other"
  },
  "21711": {
      "entityCategory": ENTITY_CATEGORIES.POI,
      "urbanLandUse": "default",
      "mavatNameHebrew": "מספר תכנית מאושרת",
      "pattern": "",
      "accessType": "other"
  }

}
