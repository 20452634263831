import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {InitResponse} from '../../models/server-response';
import {environment} from '../../../environments/environment';
import {Feature, FeatureCollection} from 'geojson';
import {shadowsMock} from './shadowsMock';
import { CORS_PROXY_URL } from 'src/app/config';
import { ImportedLayers } from 'src/app/models/entity-properties';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  constructor(private http: HttpClient) { }

  public async fetch(url) {
    return this.http.get(`${CORS_PROXY_URL}${url}`).toPromise();
  }

  public async getGeneric(url, responseType: any = 'json'): Promise<any> {
    return await this.http.get<any>(`${CORS_PROXY_URL}${encodeURIComponent(url)}`, {responseType}).toPromise();
  }

  public fetchProject(projectName: string) {
    return this.http.get<InitResponse>(`${environment.API_BASE_URL}/editor/init`, {
      params: {
        projectName
      }
    });
  }

  public fetchLazyEntities(projectName: string) {
    return this.http.get<any[]>(`${environment.API_BASE_URL}/editor/init/lazy`, {
      params: {
        projectName
      }
    });
  }

  public getPermissionToUser() {
    return this.http.get<any[]>(`${environment.URBAN_PAZ_EXCEL_SERVER_URL}/permission`,
      {
        params: {
          username: ''
        }
      }
    ).toPromise();
  }

  public getAllProjectNames(): Promise<any> {
    return this.http.get(`${environment.API_BASE_URL}/manage/init`).toPromise();
  }

  public getAllProjects(): Promise<any> {
    return this.http.get(`${environment.API_BASE_URL}/projects/all`).toPromise();
  }

  async giveOrRemovePermissionToUser(username, excelName, toRemove, permission) {
    return this.http.post(`${environment.URBAN_PAZ_EXCEL_SERVER_URL}/permission`,
      {}, {
        params: {
          excelName,
          username,
          toRemove,
          permission
        }
      }
    ).toPromise();
  }

  public async setMenuConfig(name) {
    try {
      return await this.http.post(`${environment.API_BASE_URL}/params`, {name}).toPromise();
    } catch (e) {
      return e;
    }
  }

  async deleteUser(username) {
    return this.http.delete(`${environment.URBAN_PAZ_EXCEL_SERVER_URL}/permission/user`,
      {
        params: {
          username
        }
      }
    ).toPromise();
  }

  async addUser(username, isEditor) {
    return this.http.post(`${environment.URBAN_PAZ_EXCEL_SERVER_URL}/permission/user`,
      {}, {
        responseType: 'text',
        params: {
          username,
          isEditor
        }
      }
    ).toPromise();
  }

  public async uploadGisDb(file): Promise<any> {
    const formFile: FormData = new FormData();
    formFile.append('file', file, file.name);

    return await this.http.post(`${environment.URBAN_UTILS_URL}/upload_gis_db/`,
      formFile,
      {responseType: 'json'}
    ).toPromise();
  }

  public async geomMetadata(projectName, index): Promise<any> {
    return await this.http.post(`${environment.URBAN_UTILS_URL}/query_gis_metadata/`,
      {
        proj_crs: projectName,
        index,
      },
      {responseType: 'text'}
    ).toPromise();
  }

  public async setProjectMenuConfig(projectName, paramConfigProfileName) {
    return this.http.put(`${environment.API_BASE_URL}/entity/paramConfig`, {projectName, paramConfigProfileName}).toPromise();
  }

  public async createNewMenuConfig(paramConfigProfileName) {
    return this.http.post(`${environment.API_BASE_URL}/params`, {paramConfigProfileName}).toPromise();
  }

  public uploadPlanDwg(file: File) {
    const formFile: FormData = new FormData();
    formFile.append('file', file, file.name);
    return this.http.post(`${environment.URBAN_UTILS_URL}/get_dwg_plan/`,
      formFile,
      {responseType: 'json'}
    ).toPromise();
  }

  public uploadPlanShp(file: File) {
    const formFile: FormData = new FormData();
    formFile.append('file', file, file.name);
    return this.http.post(`${environment.URBAN_UTILS_URL}/get_shp_mvt_plan/`,
      formFile,
      {responseType: 'json'}
    ).toPromise();
  }

  public saveEntityConfig(entity: Feature): Promise<any> {
    return this.http.post(`${environment.API_BASE_URL}/entity`, entity).toPromise();
  }

  public saveEntitiesConfigsBulk(entities: Feature[]): Promise<any> {
    return this.http.post(`${environment.API_BASE_URL}/entity/_bulk`, {
      entities
    }).toPromise();
  }

  upsertPlan(body: any) {
    return this.http.post(`${environment.API_BASE_URL}/plan`, body).toPromise();
  }

  // renamePlan(body: any) {
  //   return this.http.put(`${environment.API_BASE_URL}/plan`, body).toPromise();
  // }

  renamePlan(body: any) {
    return this.http.put(`${environment.API_BASE_URL}/plan`, body);
  }

  public deletePlan(projectName, planName): Promise<any> {
    return this.http.delete(`${environment.API_BASE_URL}/plan`, {
      params:
        {projectName, planName}
    })
      .toPromise();
  }

  public deleteEntitiesConfigsBulk(projectName, entitiesIndexes: string[]): Promise<any> {
    return this.http.delete(`${environment.API_BASE_URL}/entity/_bulk/`, {
      params: {
        projectName,
        entitiesIndexes
      }
    }).toPromise();
  }

  mockSaveEntity(entities: Feature[]) {
    return this.http.post(environment.BUILDING_PIPELINE_API + 'build/', entities).toPromise();
  }

  dashboardQuery(entity) {
    return this.http.post(`${environment.URBAN_UTILS_URL}/query_general_info/`, entity).toPromise();
  }

  calcBuilding(entities, plan, lot_id: string, building_id: string) {
    return this.http.post(environment.BUILDING_PIPELINE_API + 'calc_building/', {
      plan: {
        entities,
        plan: plan
      },
      lot_id,
      building_id
    }).toPromise();
  }

  osmBuildingsFromPolygon(polygon) {
    return this.http.post(`${environment.URBAN_UTILS_URL}/osm_buildings_import_by_polygon/`, polygon).toPromise();
  }

  osmBuildingsFromPolygonObs(polygon) {
    return this.http.post(`${environment.URBAN_UTILS_URL}/osm_buildings_import_by_polygon/`, polygon);
  }

  public getMenuConfig(projectName): Promise<any> {
    return this.http.get(`${environment.API_BASE_URL}/params`, {params: {projectName}}).toPromise();
  }

  public getAllMenuConfig(): Promise<any> {
    return this.http.get(`${environment.API_BASE_URL}/params/all-params`).toPromise();
  }

  public saveParamConfig(paramsConfig): Promise<any> {
    return this.http.put(`${environment.API_BASE_URL}/params`, paramsConfig).toPromise();
  }

  async uploadFile(file: File, folder): Promise<{fileName: string; url: string}> {
    const formFile: FormData = new FormData();
    formFile.append('file', file);
    return await this.http.post<any>(`${environment.API_BASE_URL}/s3/upload/${folder}`,
      formFile
    ).toPromise();
  }

  uploadFileObs(file: File, folder): Observable<{fileName: string; url: string}> {
    const formFile: FormData = new FormData();
    formFile.append('file', file);
    return this.http.post<any>(`${environment.API_BASE_URL}/s3/upload/${folder}`,
      formFile
    );
  }

  async fetchShadows(projectName, planName) {
    // const hours = {'month': 12, 'day': 21, 'hour': 9, 'hours_range': 7};
    // return this.http.post(environment.BUILDING_PIPELINE_API + 'shadow_analyze/', {projectName, planName, hours}).toPromise();
    // return shadowsMock;
  }

  fetchLayersV2() {
    return this.http.get<ImportedLayers[]>(`${environment.API_BASE_URL}/projects/layer-v2/all`).toPromise();
  }

  saveNewPlan(body) {
    try {
      return this.http.post(`${environment.API_BASE_URL}/projects/new`, body).toPromise();
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  getBuildingsHeight(polygon): Observable<any> {
    const bluelineTestPolygon2 = {
      "geometry": {
          "type": "Polygon",
          "coordinates": [polygon.geometry.coordinates]
      },
      "properties": {
          "osm_db_fusion": {
              "query_layer": [
                  "buildings_gush_dan_geo_simplify"
              ]
          }
      }
    }

    return this.http.post(`${environment.URBAN_UTILS_URL}/osm_db_fusion/`, bluelineTestPolygon2)
      .pipe(
        map(res => {
          let idHeightObject = {};
          for (let num of Object.keys(res['return']['height'])) {
            if (res['return']['height'][num] > 1) {
              idHeightObject[res['return']['osmid'][num]] = res['return']['height'][num];
            }
          }
          return idHeightObject;
        })
      );
  }

  public skeletonize(entities) {
    return this.http.post(`${environment.URBAN_UTILS_URL}/skeletonize/`, entities);
  }

  public mergeGIS(entities) {
    return this.http.post(`${environment.URBAN_UTILS_URL}/merge_gis/`, entities);
  }
 
  // similar to saveEntityConfig but without toPromis.
  public upsertEntity(entity) {
    return this.http.post(`${environment.API_BASE_URL}/entity`, entity);
  }

  public saveEntitiesConfigsBulkObservable(entities: Feature[]): Observable<any> {
    return this.http.post(`${environment.API_BASE_URL}/entity/_bulk`, {
      entities
    })
  }

  public deleteProject(projectName) {
    return this.http.delete(`${environment.API_BASE_URL}/projects`, {params: {projectName}});
  }

  public duplicateProject(newProjectName, projectName) {
    try {
      return this.http.post<any>(`${environment.API_BASE_URL}/projects/duplicate`,
        {newProjectName, projectName}
      )
    } catch (e) {
      return e;
    }
  }

  public getProjects(isArchive) {
    return this.http.get<any>(`${environment.API_BASE_URL}/projects`, {
      params: {isArchive}
    }).toPromise();
  }

  public getAllPermittedProjects(isArchive: boolean): Observable<{projectName: string, description: string}[]> {
    return this.http.get<any>(`${environment.API_BASE_URL}/projects/permitted`, {
      params: {isArchive}
    })
      .pipe(
        shareReplay()
      )
  }

  public getEntity(projectName: string,  entityIndex): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/entity`, {params: {projectName, entityIndex}});
  }

  public getAllProjectNamesObservable(): Observable<any> {
    return this.http.get(`${environment.API_BASE_URL}/manage/init`);
  }

  public upsertPlanObservable(body: any) {
    return this.http.post(`${environment.API_BASE_URL}/plan`, body);
  }

  public deletePlanObservable(projectName, planName): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/plan`, {
      params:
        {projectName, planName}
    });
  }

  public deleteEntitiesConfigsBulkObservable(projectName, entitiesIndexes: string[]): Observable<any> {
    return this.http.delete(`${environment.API_BASE_URL}/entity/_bulk/`, {
      params: {
        projectName,
        entitiesIndexes
      }
    });
  }

}
