import { Injectable } from '@angular/core';
import type {  Feature, Geometry, LineString  } from 'geojson';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import * as TurfPolygonToLine from '@turf/polygon-to-line';
import * as TurfCenter from '@turf/center';
import { DASHBOARD_WIDGET_TABS_DEFAULTS, DEFAULT_MAP_INFO, ENTITY_PLAN_TYPE, SCOPELINE_INDEX_OLD } from 'src/app/config';
import { ENTITY_ACCESS_TYPE, ENTITY_GEOMETRIC_TYPES } from 'src/app/enums/enums';
import { EntityProperties } from 'src/app/models/entity-properties';
import { CrudService } from 'src/app/services/crud/crud.service';
import { Router } from '@angular/router';
import { WorkingPageService } from '../workspace-page/working-page.service';
import { take, tap } from 'rxjs/operators';
import { concat } from 'rxjs'
import { AuthService } from 'src/app/services/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class ProjectEditService {

  private _flyToFeatureSource = new Subject<any>();
  flyToFeature$ = this._flyToFeatureSource.asObservable();

  private _drawScopeSource = new Subject<boolean>();
  drawScope$ = this._drawScopeSource.asObservable();

  private _drawerVisibleSource = new BehaviorSubject<boolean>(true);
  drawerVisible$ = this._drawerVisibleSource.asObservable();

  private projectForm;

  private projectScope;

  private projectCenter;

  editMode: boolean = false;

  initialScopeEntity;

  saveEditedSub: Subscription;

  constructor( 
    private crudeService: CrudService,
    private workingPageService: WorkingPageService,
    private authService: AuthService,
    private router: Router) { }

  sendFeature(feature: any) {
    this._flyToFeatureSource.next(feature);
  }

  sendDrawScope(drawScope: boolean) {
    this._drawScopeSource.next(drawScope)
  }

  sendDrawerVisiblity(visible: boolean) {
    this._drawerVisibleSource.next(visible)
  }

  setProjectForm(projForm) {
    this.projectForm = projForm;
  }

  get projCenter() {
    return this.projectCenter;
  }

  setProjCenter(center) {
    this.projectCenter = center;
  }

  get projForm() {
    return this.projectForm;
  }

  setProjectScopeFeature(projScope) {
    this.projectScope = projScope;
    // @ts-ignore
    const scopeCenter = TurfCenter.default(this.projectScope);
    this.setProjCenter(scopeCenter.geometry.coordinates)
  }

  get projScope() {
    return this.projectScope;
  }

  ngOnDestroy() {
    this.saveEditedSub.unsubscribe()
  }

  async saveProject() {
    const req = {
      projectName: this.projectForm.projectName,
      isLock: false,
      planName: 'plan1',
      calculate: false,
      planDate: Date.now(),
      agenda: null,
      planConfig: {[`${SCOPELINE_INDEX_OLD}`]: {version: 'v0'}},
      dashboardWidgetsTabs: DASHBOARD_WIDGET_TABS_DEFAULTS
    };
    const prjScope = this.createScopelineEntity(this.projScope);
    try {
      const savedPlan = await this.crudeService.saveNewPlan(req);
      const savedScope = await this.crudeService.saveEntityConfig(prjScope);
      if (!this.authService.isAdmin()) {
        const savedNewPermission = await this.crudeService.giveOrRemovePermissionToUser(this.authService.getUsername(), prjScope.properties.projectName, false, 'admin');
        if (savedNewPermission) {
          this.authService.initIsAdminOrEditor();
        }
      }
      

      if (savedPlan && savedScope) {
        this.workingPageService.addNewProjectToArray(prjScope.properties.projectName, prjScope.properties.description, prjScope.properties.createdBy, prjScope.properties.date);
      }
      const prjName = this.projectForm.projectName;
      this.setProjectForm('');
      this.router.navigate(['/project', prjName])
    } catch (e) {
      console.log(e);
    }
  }

  createScopelineEntity(entity: any): Feature<LineString, EntityProperties> {
    const properties: EntityProperties = {
      date: Date.now(),
      createdBy: this.authService.getUsername(),
      description: this.projectForm.description,
      entityCategory: null,
      entityPlanType: ENTITY_PLAN_TYPE[0],
      entityType: null,
      accessType: ENTITY_ACCESS_TYPE.OTHER,
      entityVersion: 'v0',
      heightProperties: {},
      isArchive: false,
      isLock: false,
      isHide: true,
      landUse: 'mix-use',
      mapInfo: {
        mapCenter: this.projCenter || DEFAULT_MAP_INFO.coordinates,
        zoom: 17,
        projectAddress: this.projForm.location,
        projectName: this.projectForm.projectName
      },
      name: 'Scopeline',
      plansOrder: ['plan1'],
      projectName: this.projectForm.projectName,
      type: ENTITY_GEOMETRIC_TYPES.LINE_STRING,
      style: {
        fillColor: '#C9ADBE'
      }
    };
    entity.properties = properties;
    entity.id = SCOPELINE_INDEX_OLD;
    if (entity.geometry && entity.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON) {
      const feature = TurfPolygonToLine.polygonToLine(entity);
      entity.geometry = feature.geometry;
    }
    return entity;
  }

  getProjectScope(projectName: string) {
    return this.crudeService.getEntity(projectName, SCOPELINE_INDEX_OLD)
      .pipe(
        take(1)
      )
  }

  saveEditedProject(initialProjectName) {

    if (!this.projForm && !this.projScope) {
      return this.router.navigate(['/workspace']);
    }
    let newScopeEntity = {
      ...this.initialScopeEntity,
    }
    if (this.projScope) {
      
      if (this.projScope.geometry && this.projScope.geometry.type === ENTITY_GEOMETRIC_TYPES.POLYGON) {
        const feature = TurfPolygonToLine.polygonToLine(this.projScope);
        newScopeEntity.geometry = {...feature.geometry};
      } else {
        newScopeEntity.geometry = {...this.projScope.geometry}
      }
      
      if(!this.projForm) {
        this.saveEditedSub = this.crudeService.upsertEntity(newScopeEntity).subscribe();
        return this.router.navigate(['/workspace']);
      }
    }

    if (this.projForm) {
      newScopeEntity.properties.description = this.projForm.description;
      newScopeEntity.properties.mapInfo.projectAddress = this.projForm.location;
      if (this.projCenter){
        newScopeEntity.properties.mapInfo.mapCenter = this.projCenter;
      }
    }

    if (this.projForm && this.projForm.projectName !== this.initialScopeEntity.properties.projectName){
      this.saveEditedSub = concat(
        this.crudeService.upsertEntity(newScopeEntity),
        this.crudeService.duplicateProject(this.projForm.projectName, this.initialScopeEntity.properties.projectName),
        this.crudeService.deleteProject(this.initialScopeEntity.properties.projectName)
      ).subscribe()
      
    } else {
      this.saveEditedSub = this.crudeService.upsertEntity(newScopeEntity).subscribe();
    }

    const editedProj = {
      projectName: this.projForm.projectName,
      description: this.projForm.description,
      createdBy: newScopeEntity.properties.createdBy,
      date: newScopeEntity.properties.date
    }

    this.workingPageService.replaceProjectWithEditedInArray(initialProjectName, editedProj);
    return this.router.navigate(['/workspace']);
  }
 

}
