import { Injectable, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzConfigService } from 'ng-zorro-antd/core/config';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private currentMessageId?: string;
  private currentNotification?: any;

  constructor(
    private notification: NzNotificationService,
    private translate: TranslateService,
    private nzMessageService: NzMessageService,
    private nzConfigService: NzConfigService
  ) { }

  private isRtlLanguage(): boolean {
    const lang = this.translate.currentLang;
    const rtlLanguages = ['ar', 'he', 'fa', 'ur'];
    return rtlLanguages.includes(lang);
  }

  showNotification(type: 'success' | 'info' | 'warning' | 'error', title: string, content: string): void {
    const isRTL = this.isRtlLanguage();

    // Dynamically update global nzDirection setting
    this.nzConfigService.set('notification', { nzDirection: isRTL ? 'rtl' : 'ltr' });

    this.notification.create(type, title, content, {
      nzDuration: 5000, 
      nzKey: 'customTemplate',
      nzStyle: { width: '500px' },
      nzClass: `custom-top-center`, // Apply dynamic class
    });
  }
  

  createMessage(type: 'success' | 'error' | 'info' | 'warning' | 'loading', description: string): void {
    // Remove previous message if exists
    if (this.currentMessageId) {
      this.nzMessageService.remove(this.currentMessageId);
    }
  
    // Remove previous notification if exists
    if (this.currentNotification) {
      this.notification.remove(this.currentNotification);
    }
  
    // Create a new message based on the type
    this.currentMessageId = this.nzMessageService[type](description).messageId;
  }
  
}
