import { info } from "console";
import { env } from "process";

const boundriesAndOwnershiplegendUrl = 'https://storage.googleapis.com/urban_files_bucket/layers/legends/BoundariesAndOwnerships/';
const transportationlegendUrl = 'https://storage.googleapis.com/urban_files_bucket/layers/legends/Transportation/';
const planningAndDevelopmentlegendUrl = 'https://storage.googleapis.com/urban_files_bucket/layers/legends/PlanningAndDevelopment/';
const localMasterPlanslegendUrl = 'https://storage.googleapis.com/urban_files_bucket/layers/legends/LocalMasterPlans/';

export const LayerConfig = {  //          legendUrl: `${legendBaseUrl}Districts.png`, 


    boundariesAndOwnershipPanelUrls: {
        'SECTION-MAP-LAYER.DISTRICTS': { name: 'SECTION-MAP-LAYER.DISTRICTS', isOn: false, icon: 'Districts.svg', legendUrl: `${boundriesAndOwnershiplegendUrl}Districts.png`, url: 'https://api.maptiler.com/tiles/e22a49ff-50ef-4ebe-b0a6-cb668de54fe5/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', info: 'SECTION-MAP-LAYER.DISTRICTSINFO',maxZoom : 18, minZoom : 12},
        'SECTION-MAP-LAYER.MUNICIPALITIES': { name: 'SECTION-MAP-LAYER.MUNICIPALITIES', isOn: false, icon: 'Municipalities.svg', legendUrl: `${boundriesAndOwnershiplegendUrl}Municipalities.png`, url: 'https://api.maptiler.com/tiles/f3af3fc4-d778-4c70-b4fe-0ad57ba97914/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', info: 'SECTION-MAP-LAYER.MUNICIPALITIESINFO',maxZoom : 18, minZoom : 12},
        'SECTION-MAP-LAYER.STATISTICAREAS': {name: 'SECTION-MAP-LAYER.STATISTICAREAS', type: 'govmap' , resource: 'AdditionalData', layerId: 298, legendUrl: `${boundriesAndOwnershiplegendUrl}StatisticAreas.png`, isOn: false, url: 'getGovMapLayer', icon: 'Statistic.svg', info: 'SECTION-MAP-LAYER.S-IMA-GM',maxZoom : 22, minZoom : 14},
        'SECTION-MAP-LAYER.BLOCKS': {name: 'SECTION-MAP-LAYER.BLOCKS', type: 'govmap' , resource: 'AdditionalData', layerId : 378, legendUrl: `${boundriesAndOwnershiplegendUrl}Blocks.png`, isOn: false, url: 'getGovMapLayer', icon: 'Blocks.svg', info: 'SECTION-MAP-LAYER.S-IMA-GM',maxZoom : 22, minZoom : 16},
        'SECTION-MAP-LAYER.PARCELS': {name: 'SECTION-MAP-LAYER.PARCELS', type: 'govmap' , resource: 'NewData', layerId: 1, legendUrl: `${boundriesAndOwnershiplegendUrl}Parcels.png`, isOn: false, url: 'getGovMapLayer',  icon: 'Parcels District.svg', info: 'SECTION-MAP-LAYER.S-IMA-GM',maxZoom : 22, minZoom : 16},
        'SECTION-MAP-LAYER.OWNERSHIPTYPE': {name: 'SECTION-MAP-LAYER.OWNERSHIPTYPE', type: 'govmap' , resource: 'AdditionalData', layerId: 311, legendUrl: `${boundriesAndOwnershiplegendUrl}BlocksOwnershipType.png`, isOn: false, url: 'getGovMapLayer',  icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-IMA-GM',maxZoom : 22, minZoom : 16},
    },

    boundariesAndOwnership: {
       
        'SECTION-MAP-LAYER.DISTRICTS': [], 
        'SECTION-MAP-LAYER.MUNICIPALITIES': [],
        'SECTION-MAP-LAYER.STATISTICAREAS': [], 
        'SECTION-MAP-LAYER.BLOCKS': [], 
        'SECTION-MAP-LAYER.PARCELS': [],
        'SECTION-MAP-LAYER.OWNERSHIPTYPE': [], 
      },

    planningAndDevelopmentPanelUrls: {
        'SECTION-MAP-LAYER.INPLANNINGPROCESSXPLAN': { name: 'xplan', isOn: false, url: 'getXplanLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.INPLANNINGPROCESSXPLANINFO',maxZoom : 18, minZoom : 16},
        'SECTION-MAP-LAYER.TAMA70': {name: 'SECTION-MAP-LAYER.TAMA70', isOn: false, url: '', icon: '', info: 'SECTION-MAP-LAYER.TAMA70INFO'},
        'SECTION-MAP-LAYER.TAMA1': {name: 'SECTION-MAP-LAYER.TAMA1', isOn: false, url: '', icon: '', info: 'SECTION-MAP-LAYER.TAMA1INFO'},
        'SECTION-MAP-LAYER.TAMA35': {name:  'SECTION-MAP-LAYER.TAMA35', isOn: false, url: 'https://api.maptiler.com/tiles/7a81cf2d-824a-47ec-964b-b24197dae179/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '', info: 'SECTION-MAP-LAYER.TAMA35INFO',maxZoom : 16, minZoom : 12},
        'SECTION-MAP-LAYER.DISTRICTMASTERPLANS': {name: '', isOn: false, url: '', icon: '', info: 'SECTION-MAP-LAYER.DISTRICTMASTERPLANSINFO',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.PLANSINPROGRESSILA': {name: 'SECTION-MAP-LAYER.PLANSINPROGRESSILA', type: 'govmap' , resource: 'AdditionalData', layerId: 308, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-ILA-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.PLANSFORMARKETINGILA': {name: 'SECTION-MAP-LAYER.PLANSFORMARKETINGILA', type: 'govmap' , resource: 'AdditionalData', layerId: 306, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-ILA-GM',maxZoom : 22, minZoom : 15},
        'SECTION-MAP-LAYER.MASTERPLANSLOTSILA': {name: 'SECTION-MAP-LAYER.MASTERPLANSLOTSILA', type: 'govmap' , resource: 'rami', layerId: 0, legendUrl: `${planningAndDevelopmentlegendUrl}MASTERPLANSLOTSILA.png` , isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-ILA-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.REALESTATETENDERSILA': {name: 'SECTION-MAP-LAYER.REALESTATETENDERSILA', type: 'govmap' , resource: 'AdditionalData', layerId: 310, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-ILA-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.PLANSINPROGRESS': {name: 'SECTION-MAP-LAYER.PLANSINPROGRESS', type: 'govmap' , resource: 'AdditionalData', layerId: 191, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-ILA-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.RESIDENTIALPLANSINVENTORY': {name: 'SECTION-MAP-LAYER.RESIDENTIALPLANSINVENTORY', type: 'govmap' , resource: 'AdditionalData', layerId: 384, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-ILA-GM',maxZoom : 22, minZoom : 11},
        'SECTION-MAP-LAYER.LOTSMINISTRYOFHOUSING': {name: 'SECTION-MAP-LAYER.LOTSMINISTRYOFHOUSING', type: 'govmap' , resource: 'AdditionalData', layerId: 312, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-MCH-GM',maxZoom : 22, minZoom : 14},
        'SECTION-MAP-LAYER.LANDUSEMAVAT': {name: 'SECTION-MAP-LAYER.LANDUSEMAVAT', type: 'govmap' , resource: 'AdditionalData', layerId: 366, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.GOVMAPAPI',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.PARCELSBOUNDRIESREGISTRATION': {name: 'SECTION-MAP-LAYER.PARCELSBOUNDRIESREGISTRATION', type: 'govmap' , resource: 'AdditionalData', layerId: 377, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.GOVMAPAPI',maxZoom : 22, minZoom : 14}
    },

    planningAndDevelopment: {
    'SECTION-MAP-LAYER.INPLANNINGPROCESSXPLAN': [], // 'In planning process - xplan'
    'SECTION-MAP-LAYER.TAMA70':[
        {
            name: 'SECTION-MAP-LAYER.BOUNDARIESTAMA70',
            isOn: false,
            icon: 'Baundaries.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}BOUNDARIESTAMA70.png`,
            url: 'https://api.maptiler.com/tiles/19e446a4-4da2-4027-b096-2dcfd955ad5a/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.BOUNDARIESINFO',
            maxZoom : 18, 
            minZoom : 12
        },
        {
            name: 'SECTION-MAP-LAYER.LANDUSAGETAMA70',
            isOn: false,
            icon: 'GroupLandusage.svg',
            url: 'https://api.maptiler.com/tiles/64d96e42-2567-4562-9252-533fa4885570/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.LANDUSAGEINFO',
            maxZoom : 18, 
            minZoom : 12
        },
        {
            name: 'SECTION-MAP-LAYER.SPATIALGUIDELINES',
            isOn: false,
            icon: 'GroupSpatial.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}SPATIALGUIDELINES.png`,
            url: 'https://api.maptiler.com/tiles/c5e08847-bd75-41e0-be21-db868b299649/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.SPATIALGUIDELINESINFO',
            maxZoom : 18, 
            minZoom : 12
        },
        {
            name: 'SECTION-MAP-LAYER.FARBYRING1',
            isOn: false,
            icon: 'FarEllipse.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}FARBYRING1.png`,
            url: 'https://api.maptiler.com/tiles/573fca23-0040-4299-926c-d257df6b2a48/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.FARBYRING1INFO',
            maxZoom : 19, 
            minZoom : 14
        },
        {
            name: 'SECTION-MAP-LAYER.FARBYRING2',
            isOn: false,
            icon: 'FarEllipse.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}FARBYRING2.png`,
            url: 'https://api.maptiler.com/tiles/9379de4e-9a14-45cf-b66c-1fccd92c129b/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.FARBYRING2INFO',
            maxZoom : 18, 
            minZoom : 14
        },
    ],
    'SECTION-MAP-LAYER.TAMA1': [
        {
            name: 'SECTION-MAP-LAYER.TAMA1PART1',
            isOn: false,
            icon: 'File.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}TAMA1.png`,
            url: 'https://api.maptiler.com/tiles/23221d11-bdf9-42de-9275-8c233b518a9a/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 14, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.TAMA1PART2',
            isOn: false,
            icon: 'File.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}TAMA1.png`,
            url: 'https://api.maptiler.com/tiles/08296511-8761-42b1-b242-47226f42d9ac/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 14, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.TAMA1PART3',
            isOn: false,
            icon: 'File.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}TAMA1.png`,
            url: 'https://api.maptiler.com/tiles/ada9f9b8-f6f1-463d-9d95-62ba5e6818ab/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 14, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.TAMA1PART4',
            isOn: false,
            icon: 'File.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}TAMA1.png`,
            url: 'https://api.maptiler.com/tiles/ba2f906a-18a8-4bad-9f6c-5823c48fad7a/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 14, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.TAMA1PART5',
            isOn: false,
            icon: 'File.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}TAMA1.png`,
            url: 'https://api.maptiler.com/tiles/bfd3e759-cb9c-4741-b599-53df58d36243/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 14, 
            minZoom : 9
        }
    ],
    'SECTION-MAP-LAYER.TAMA35': [],
    'SECTION-MAP-LAYER.DISTRICTMASTERPLANS':[
        {
            name: 'SECTION-MAP-LAYER.NORTHTAMAM',
            isOn: false,
            icon: '',
            url: '.',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            children: [
                {
                    name: 'SECTION-MAP-LAYER.NORTHTAMAMPART1',
                    isOn: false,
                    icon: 'File.svg',
                    legendUrl: `${planningAndDevelopmentlegendUrl}NORTHTAMAM.png`,
                    url: 'https://api.maptiler.com/tiles/9b60dc5a-0810-4f1d-8786-bd05edd61de0/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                    info: '!',
                    maxZoom : 15, 
                    minZoom : 9
                },
                {
                    name: 'SECTION-MAP-LAYER.NORTHTAMAMPART2',
                    isOn: false,
                    icon: 'File.svg',
                    legendUrl: `${planningAndDevelopmentlegendUrl}NORTHTAMAM.png`,
                    url: 'https://api.maptiler.com/tiles/44835208-7a46-415e-879a-836cc1f94086/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                    info: '!',
                    maxZoom : 15, 
                    minZoom : 9
                },
                {
                    name: 'SECTION-MAP-LAYER.NORTHTAMAMPART3',
                    isOn: false,
                    icon: 'File.svg',
                    legendUrl: `${planningAndDevelopmentlegendUrl}NORTHTAMAM.png`,
                    url: 'https://api.maptiler.com/tiles/3b307a27-f7fe-4e28-85fe-12b6bd72a5e7/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                    info: '!',
                    maxZoom : 15, 
                    minZoom : 9
                },
                {
                    name: 'SECTION-MAP-LAYER.NORTHTAMAMPART4',
                    isOn: false,
                    icon: 'File.svg',
                    legendUrl: `${planningAndDevelopmentlegendUrl}NORTHTAMAM.png`,
                    url: 'https://api.maptiler.com/tiles/59efab03-0a2d-4fd3-bb9e-beff026f00fe/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                    info: '!',
                    maxZoom : 15, 
                    minZoom : 9
                },

            ],
        },
        {
            name: 'SECTION-MAP-LAYER.HAIFATAMAM',
            isOn: false,
            icon: 'File.svg',
            legendUrl: `${planningAndDevelopmentlegendUrl}HAIFATAMAM.png`,
            url: 'https://api.maptiler.com/tiles/1747c8d2-54e1-4277-9c1e-f9d166e9d37e/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 15, 
            minZoom : 9
        },       
        {
            name: 'SECTION-MAP-LAYER.TELAVIVTAMAM',
            isOn: false,
            icon: 'File.svg',
            url: 'https://api.maptiler.com/tiles/8754e037-cbed-4509-a0d3-53053b59929a/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 15, 
            minZoom : 10
        },
        {
            name: 'SECTION-MAP-LAYER.CENTERTAMAM',
            isOn: false,
            icon: 'File.svg',
            url: 'https://api.maptiler.com/tiles/e852f809-c832-4018-aedf-416e0a3a2ecb/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 14, 
            minZoom : 9
        },       
        {
            name: 'SECTION-MAP-LAYER.JERUSALEMTAMAM',
            isOn: false,
            icon: 'File.svg',
            url: 'https://api.maptiler.com/tiles/9a69bf53-6090-45ee-a28e-008d263e5cf0/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 14, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.SOUTHTAMAM',
            isOn: false,
            icon: 'File.svg',
            url: 'https://api.maptiler.com/tiles/62d3983a-ca32-411a-8129-ffa402478802/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
            maxZoom : 13, 
            minZoom : 9
        },
    ],
    'SECTION-MAP-LAYER.PLANSINPROGRESSILA': [], // 'Plans in progress - ILA'
    'SECTION-MAP-LAYER.PLANSFORMARKETINGILA': [], // 'Plans for marketing - ILA'
    'SECTION-MAP-LAYER.MASTERPLANSLOTSILA': [], // 'Master plans lots - ILA'
    'SECTION-MAP-LAYER.REALESTATETENDERSILA': [], // 'Real estate tenders - ILA'
    'SECTION-MAP-LAYER.PLANSINPROGRESS': [], // 'Plans in progress'
    'SECTION-MAP-LAYER.RESIDENTIALPLANSINVENTORY': [], // 'Residential plans inventory'
    'SECTION-MAP-LAYER.LOTSMINISTRYOFHOUSING': [], // 'Lots - Ministry of Housing'
    'SECTION-MAP-LAYER.LANDUSEMAVAT': [], // 'Land use - Mavat'
     'SECTION-MAP-LAYER.PARCELSBOUNDRIESREGISTRATION': [], // 'Parcels boundaries registration'
    },

    localMasterPlansPanelUrls:{
        'SECTION-MAP-LAYER.AZOR': {name: 'SECTION-MAP-LAYER.AZOR', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.BNEYBRAK': {name: 'SECTION-MAP-LAYER.BNEYBRAK', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.BATYAM': {name: 'SECTION-MAP-LAYER.BATYAM', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.GIVATSHMUEL': {name: 'SECTION-MAP-LAYER.GIVATSHMUEL', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.GIVATAYIM': {name: 'SECTION-MAP-LAYER.GIVATAYIM', isOn: false, url: '', icon: '', info: 'SECTION-MAP-LAYER.S-IPA2020-U24'},
        'SECTION-MAP-LAYER.GEDERA': {name: 'SECTION-MAP-LAYER.GEDERA', isOn: false, url: '', icon: '', info: 'SECTION-MAP-LAYER.S-IPA2019-U24'},
        'SECTION-MAP-LAYER.GANEYTIKVA': {name: 'SECTION-MAP-LAYER.GANEYTIKVA', isOn: false, url: 'https://api.maptiler.com/tiles/2bd194e7-c80d-473f-95e6-1b1651fa543f/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '', info: '.'},
        'SECTION-MAP-LAYER.HODHASHARON': {name: 'SECTION-MAP-LAYER.HODHASHARON', isOn: false, url: 'https://api.maptiler.com/tiles/a8b127f9-07fc-4414-9cd9-add058ecb9fa/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '', info: '.'},
        'SECTION-MAP-LAYER.HADERA': {name: 'SECTION-MAP-LAYER.HADERA', isOn: false, url: '', icon: '', info: 'SECTION-MAP-LAYER.S-IPA2014-U24'},
        'SECTION-MAP-LAYER.HULON': {name: 'SECTION-MAP-LAYER.HULON', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.HAIFANUMB': {name: 'SECTION-MAP-LAYER.HAIFANUMB', isOn: false, url: 'https://api.maptiler.com/tiles/082b0817-886d-455b-9ae5-c0d21b31c9e0/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '', info: '.'},
        'SECTION-MAP-LAYER.NETANYA': {name: 'SECTION-MAP-LAYER.NETANYA', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.KFARSABA': {name: 'SECTION-MAP-LAYER.KFARSABA', isOn: false, url: 'https://api.maptiler.com/tiles/e42d5ca4-c63d-4c0e-8b1e-82d6e4666ad4/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '', info: 'SECTION-MAP-LAYER.S-IPA2017-U24'},
        'SECTION-MAP-LAYER.MODIEEN': {name: 'SECTION-MAP-LAYER.MODIEEN', isOn: false, url: '', icon: '', info: 'SECTION-MAP-LAYER.S-IPA2023-U24'},
        'SECTION-MAP-LAYER.PETAHTIKVA': {name: 'SECTION-MAP-LAYER.PETAHTIKVA', isOn: false, icon: '', url: 'https://api.maptiler.com/tiles/3cb1406b-97d6-458e-b90e-4fbd1b2228ce/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', info: 'SECTION-MAP-LAYER.S-IPA2022-U24'},
        'SECTION-MAP-LAYER.KIRIYATONO': {name: 'SECTION-MAP-LAYER.KIRIYATONO', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.ROSHHAAIN': {name: 'SECTION-MAP-LAYER.ROSHHAAIN', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.RISHONLEZION': {name: 'SECTION-MAP-LAYER.RISHONLEZION', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.RAMATGAN': {name: 'SECTION-MAP-LAYER.RAMATGAN', isOn: false, url: '', icon: '', info: '.'},
        'SECTION-MAP-LAYER.TELAVIV5000': {name: 'SECTION-MAP-LAYER.TELAVIV5000', isOn: false, url: '', icon: '', info: 'SECTION-MAP-LAYER.S-PA-TA5000-16'},
        'SECTION-MAP-LAYER.TELAVIV5500': {name: 'SECTION-MAP-LAYER.TELAVIV5500', isOn: false, url: '', icon: '',info: 'SECTION-MAP-LAYER.S-PA-UTA5000-23'},
    },


    localMasterPlans:{
        'SECTION-MAP-LAYER.AZOR':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}Azor.png`,
                url: 'https://api.maptiler.com/tiles/c5e3d3fc-a937-4c30-abd8-145ad50cdc85/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2023-U24',
                maxZoom : 18, 
                minZoom : 12
                
            },
        ],

        'SECTION-MAP-LAYER.BNEYBRAK':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}BneyBrak.png`,
                url: 'https://api.maptiler.com/tiles/c5e3d3fc-a937-4c30-abd8-145ad50cdc85/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2023-U24',
                maxZoom : 18, 
                minZoom : 12
                
            },
        ],
        'SECTION-MAP-LAYER.BATYAM':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/739fc192-8315-4dbb-989a-5c86e0fb5021/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2016-U24',
                maxZoom : 15, 
                minZoom : 11
                
            },
            {
                name: 'SECTION-MAP-LAYER.URBANDESIGN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/3a0f12af-b7dd-44dc-8473-9eedb3a47cd7/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2016-U24',
                maxZoom : 17, 
                minZoom : 11
                
            }
        ],

        'SECTION-MAP-LAYER.GIVATSHMUEL':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}GivatShmuel.png`,
                url: 'https://api.maptiler.com/tiles/0854bf19-b806-416c-92df-1b0d8186bbdb/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2020-U24',
                maxZoom : 18, 
                minZoom : 12
                
            },
        ],
        
        'SECTION-MAP-LAYER.GIVATAYIM':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}Givataim.png`,
                url: 'https://api.maptiler.com/tiles/6f84376e-4f21-453d-8568-adec11ceaf38/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2019-U24',
                maxZoom : 18, 
                minZoom : 12

            }
        ],
        'SECTION-MAP-LAYER.GEDERA':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}Gedera.png`,
                url: 'https://api.maptiler.com/tiles/3ee655ca-9060-4e3d-8f18-240e0445c734/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2019-U24',
                maxZoom : 17, 
                minZoom : 11

            }
        ],
        'SECTION-MAP-LAYER.GANEYTIKVA':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}GanyTikva.png`,
                url:  'https://api.maptiler.com/tiles/2bd194e7-c80d-473f-95e6-1b1651fa543f/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2020-U24',
                maxZoom : 18, 
                minZoom : 12
            }
        ],
        'SECTION-MAP-LAYER.HODHASHARON':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}HodHasharon.png`,
                url:  'https://api.maptiler.com/tiles/a8b127f9-07fc-4414-9cd9-add058ecb9fa/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2020-U24',
                maxZoom : 17, 
                minZoom : 11
            }
        ],

        'SECTION-MAP-LAYER.HADERA':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}Hadera.png`,
                url: 'https://api.maptiler.com/tiles/ee7c7586-3a13-4717-ad1b-ce8220cf0ec8/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2014-U24',
                maxZoom : 20, 
                minZoom : 11
                
            },
        ],

        'SECTION-MAP-LAYER.HULON':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}Hulon.png`,
                url: 'https://api.maptiler.com/tiles/ee7c7586-3a13-4717-ad1b-ce8220cf0ec8/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2014-U24',
                maxZoom : 20, 
                minZoom : 11
                
            },
        ],
        'SECTION-MAP-LAYER.HAIFANUMB':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/082b0817-886d-455b-9ae5-c0d21b31c9e0/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2020-U24',
                maxZoom : 16, 
                minZoom : 10
                
            },
        ],
        'SECTION-MAP-LAYER.NETANYA':[
            {
                name: 'SECTION-MAP-LAYER.NT2035P1',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}Netaniya.png`,
                url: 'https://api.maptiler.com/tiles/2f173c34-b836-40b8-aacc-509130eeba4d/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2021-U24',
                maxZoom : 17, 
                minZoom : 10
                
            },            {
                name: 'SECTION-MAP-LAYER.NT2035P2',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}Netaniya.png`,
                url: 'https://api.maptiler.com/tiles/7c016af8-dda8-470a-a78c-35ad0d0537a6/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2021-U24',
                maxZoom : 17, 
                minZoom : 10
                
            }
        ],

        'SECTION-MAP-LAYER.KFARSABA':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/69c2543b-0c36-4541-9de0-41cd3f6143f5/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2017-U24-NYA',
                maxZoom : 17, 
                minZoom : 10
                
            },
        ],

        'SECTION-MAP-LAYER.MODIEEN':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/74eb0b2c-1c6d-4237-8f46-3a8d8a03eff1/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2023-U24',
                maxZoom : 17, 
                minZoom : 10
                
            },
        ],


        'SECTION-MAP-LAYER.PETAHTIKVA':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLANPT2040',
                isOn: false,
                icon: 'File.svg',
                url: '.',
                info: 'SECTION-MAP-LAYER.S-IPA2022-U24',
                maxZoom : 17, 
                minZoom : 11
            },
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/3cb1406b-97d6-458e-b90e-4fbd1b2228ce/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2022-U24',
                maxZoom : 17, 
                minZoom : 11
            }
        ],

        'SECTION-MAP-LAYER.KIRIYATONO':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/254e31b5-2683-4517-b0d9-31aa7f48334b/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2022-U24',
                maxZoom : 18, 
                minZoom : 12
                
            },
        ],
        'SECTION-MAP-LAYER.ROSHHAAIN':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/364e0f52-7994-4399-8a2e-084be03bcf36/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2024-U24',
                maxZoom : 18, 
                minZoom : 11
                
            },
        ],
        'SECTION-MAP-LAYER.RISHONLEZION':[
            {
                name: 'SECTION-MAP-LAYER.MASTERPLAN',
                isOn: false,
                icon: 'File.svg',
                url: 'https://api.maptiler.com/tiles/10698a58-cdc8-4ad6-b0c1-b98a2ff1a6b5/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-IPA2017-U24',
                maxZoom : 16, 
                minZoom : 11
            },
        ],

        'SECTION-MAP-LAYER.RAMATGAN':[],
        'SECTION-MAP-LAYER.TELAVIV5000':[
            {
                name: 'SECTION-MAP-LAYER.ZONING',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/Zoning5000.png`,
                url: 'https://api.maptiler.com/tiles/6ae1a7d6-cd00-4961-8021-8cf5ab03bbc1/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-TA5000-16'  ,
                maxZoom : 17, 
                minZoom : 11    
            },
            {
                name: 'SECTION-MAP-LAYER.PLANNINGZONES',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/PlanningZones5000.png`,
                url: 'https://api.maptiler.com/tiles/99025db6-e371-4edc-ab31-ae3549558288/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-TA5000-16',
                maxZoom : 17, 
                minZoom : 11 
            },
            {
                name: 'SECTION-MAP-LAYER.URBANDESIGN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/UrbanDesign5000.png`,
                url: 'https://api.maptiler.com/tiles/83e9cea4-f4e2-48e0-8ae5-60fd397de165/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-TA5000-16',
                maxZoom : 17, 
                minZoom : 11 
            },
            {
                name: 'SECTION-MAP-LAYER.TRANSPORTATIONTELAVIV',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/Transportation5000.png`,
                url: 'https://api.maptiler.com/tiles/38c11dbf-09cd-4259-9761-44dcc34156c1/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-TA5000-16',
                maxZoom : 17, 
                minZoom : 11 
            },
            {
                name: 'SECTION-MAP-LAYER.INFRASTRUCTURE',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/Infrastructure5000.png`,
                url: 'https://api.maptiler.com/tiles/dd0950d0-b603-4b06-97b8-d481780ce8a5/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-TA5000-16',
                maxZoom : 17, 
                minZoom : 11 
            },
        ],
        'SECTION-MAP-LAYER.TELAVIV5500':[
            {
                name: 'SECTION-MAP-LAYER.ZONING',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/Zoning5500.png`,
                url: 'https://api.maptiler.com/tiles/d6841f2e-4c83-4d3b-9d91-3776579b2069/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-UTA5000-23',
                maxZoom : 17, 
                minZoom : 12
                
            },
            {
                name: 'SECTION-MAP-LAYER.PLANNINGZONES',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/PlanningZones5500.png`,
                url: 'https://api.maptiler.com/tiles/11c1fc0a-b5ba-48f2-bfe2-74a6cc2d7219/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-UTA5000-23',
                maxZoom : 17, 
                minZoom : 11
            },
            {
                name: 'SECTION-MAP-LAYER.URBANDESIGN',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/UrbanDesign5500.png`,
                url: 'https://api.maptiler.com/tiles/906c3553-46e3-4835-8e7a-9e757d221223/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-UTA5000-23',
                maxZoom : 17, 
                minZoom : 11
            },
            {
                name: 'SECTION-MAP-LAYER.TRANSPORTATIONTELAVIV',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/Transportation5500.png`,
                url: 'https://api.maptiler.com/tiles/f835a351-7495-4d88-9ed7-d03bb5fa0cec/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-UTA5000-23',
                maxZoom : 17, 
                minZoom : 11
            },
            {
                name: 'SECTION-MAP-LAYER.INFRASTRUCTURE',
                isOn: false,
                icon: 'File.svg',
                legendUrl: `${localMasterPlanslegendUrl}TelAviv/Infrastructure5500.png`,
                url: 'https://api.maptiler.com/tiles/481ca731-3618-4775-8419-4b1bac224e40/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
                info: 'SECTION-MAP-LAYER.S-PA-UTA5000-23',
                maxZoom : 17, 
                minZoom : 11
            },
        ],
    },


    transportationPanelUrls: {
        'SECTION-MAP-LAYER.METRO': { name: 'SECTION-MAP-LAYER.METRO', isOn: false, icon: '', url: '', info: 'SECTION-MAP-LAYER.METROINFO' },
        'SECTION-MAP-LAYER.LIGHTRAILTRANSIT': { name: 'SECTION-MAP-LAYER.LIGHTRAILTRANSIT', isOn: false, icon: '', url: '', info: 'SECTION-MAP-LAYER.LIGHTRAILTRANSITINFO' },
        'SECTION-MAP-LAYER.HEAVYTRAINTRANSIT': { name: 'SECTION-MAP-LAYER.HEAVYTRAINTRANSIT', isOn: false, icon: '', url: '', info: 'SECTION-MAP-LAYER.HEAVYTRAINTRANSITINFO' },
        'SECTION-MAP-LAYER.BUSRAPIDTRANSIT(BRT)': { name: 'SECTION-MAP-LAYER.BUSRAPIDTRANSIT(BRT)', isOn: false, icon: '', url: '', info: 'SECTION-MAP-LAYER.BUSRAPIDTRANSIT(BRT)INFO' },
        'SECTION-MAP-LAYER.BUSES': { name: 'SECTION-MAP-LAYER.BUSES', isOn: false, icon: '', url: '', info: 'SECTION-MAP-LAYER.DOT-Q3-23' },
        'SECTION-MAP-LAYER.BICYCLE': { name: 'SECTION-MAP-LAYER.BICYCLE', isOn: false, icon: '', url: '', info: 'SECTION-MAP-LAYER.DOT-Q2-22' },
        'SECTION-MAP-LAYER.PRIORITYLANES': { name: 'SECTION-MAP-LAYER.PRIORITYLANES', isOn: false, icon: '', url: '', info: 'SECTION-MAP-LAYER.NA-Q2-20' },
        'SECTION-MAP-LAYER.STRATEGICPLANFORTERMINAL': { name: 'SECTION-MAP-LAYER.STRATEGICPLANFORTERMINAL', type: 'govmap' , resource: 'moti', layerId: 62, legendUrl: `${transportationlegendUrl}STRATEGICPLANFORTERMINAL.png`, isOn: false, icon: '', url: 'getGovMapLayer', info: 'SECTION-MAP-LAYER.NA-Q2-23-EXTRA' },
        'SECTION-MAP-LAYER.DEPOTFORLIGHTRAILANDMETRO': { name: 'SECTION-MAP-LAYER.DEPOTFORLIGHTRAILANDMETRO', type: 'govmap' , resource: 'moti', layerId: 69, legendUrl: `${transportationlegendUrl}DEPOTFORLIGHTRAILANDMETRO.png`, isOn: false, icon: '', url: 'getGovMapLayer', info: 'SECTION-MAP-LAYER.NTA-JERUSALEM-Q3-24' },
        'SECTION-MAP-LAYER.METRONIT': { name: 'SECTION-MAP-LAYER.METRONIT', type: 'govmap' , resource: 'moti', layerId: 33, legendUrl: `${transportationlegendUrl}METRONIT.png`, isOn: false, icon: '', url: 'getGovMapLayer', info: 'SECTION-MAP-LAYER.NA-NI-Q3-24' },
        'SECTION-MAP-LAYER.PUBLICTRANSPORTTERMINALS': { name: 'SECTION-MAP-LAYER.PUBLICTRANSPORTTERMINALS', type: 'govmap' , resource: 'moti', layerId: 63, legendUrl: `${transportationlegendUrl}PUBLICTRANSPORTTERMINALS.png`, isOn: false, icon: '', url: 'getGovMapLayer', info: 'SECTION-MAP-LAYER.NA-PT' },
        'SECTION-MAP-LAYER.METROFAN': { name: 'SECTION-MAP-LAYER.METROFAN', type: 'govmap' , resource: 'moti', layerId: 85, legendUrl: `${transportationlegendUrl}METROFAN.png`, isOn: false, icon: '', url: 'getGovMapLayer', info: 'SECTION-MAP-LAYER.FORUM-DOT-Q1-24' },
        'SECTION-MAP-LAYER.FLIGHTZONES': {name: 'SECTION-MAP-LAYER.FLIGHTZONES', isOn: false, icon: 'FlightZones.svg', url: 'https://api.maptiler.com/tiles/cec33df0-ac2f-4dc2-aeba-80159a21a5bd/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', info: 'SECTION-MAP-LAYER.FLIGHTZONESINFO',maxZoom : 17, minZoom : 9 }
    },

    transportation: {
    'SECTION-MAP-LAYER.METRO': [
        {
            name: 'SECTION-MAP-LAYER.METRO-LINES',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 73, 
            legendUrl: `${transportationlegendUrl}METRO-LINES.png`, 
            isOn: false, 
            icon: 'MetroLines.svg',
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.NTA-Q2-24',
            maxZoom : 22, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.METROSTATIONS',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 72, 
            legendUrl: `${transportationlegendUrl}METROSTATIONS.png`, 
            isOn: false, 
            icon: 'MetroStations.svg',
            url: 'getGovMapLayer', 
            info: 'SECTION-MAP-LAYER.NTA-Q2-24',
            maxZoom : 22, 
            minZoom : 13
        },
    ],
    'SECTION-MAP-LAYER.LIGHTRAILTRANSIT': [ 
        {
            name: 'SECTION-MAP-LAYER.LIGHTRAILLINES', 
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 71, 
            legendUrl: `${transportationlegendUrl}LIGHTRAILLINES.png`, 
            isOn: false, 
            icon: 'LRTLines.svg',
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.NTA-TZEVET-Q3-24',
            maxZoom : 22, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.LIGHTRAILSSTATIONS', 
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 70, 
            legendUrl: `${transportationlegendUrl}LIGHTRAILSSTATIONS.png`, 
            isOn: false, 
            icon: 'LRTStations.svg',
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.NTA-TZEVET-Q4-24',
            maxZoom : 22, 
            minZoom : 13
        },
    ],
    'SECTION-MAP-LAYER.HEAVYTRAINTRANSIT': [ 
        {
            name: 'SECTION-MAP-LAYER.HEAVYRAILTRACKS',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 51, 
            legendUrl: `${transportationlegendUrl}HEAVYRAILTRACKS.png`, 
            isOn: false, 
            icon: 'HeavyTrainLines.svg',
            url: 'getGovMapLayer', 
            info: 'SECTION-MAP-LAYER.IR-Q2-24',
            maxZoom : 22, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.HEAVYRAILSPASSSTATION',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 52, 
            legendUrl: `${transportationlegendUrl}HEAVYRAILSPASSSTATION.png`, 
            isOn: false, 
            icon: 'HeavyTrainStations.svg',
            url: 'getGovMapLayer', 
            info: 'SECTION-MAP-LAYER.IR-Q1-23',
            maxZoom : 22, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.STRATEGICPLAN2040',
            isOn: false,
            icon: 'HeavyTrainStrategicPlan.svg',
            url: '/',
            info: 'SECTION-MAP-LAYER.STRATEGICPLAN2040INFO',
            maxZoom : 22, 
            minZoom : 9
        },
    ],
    'SECTION-MAP-LAYER.BUSRAPIDTRANSIT(BRT)': [ 
        {
            name: 'SECTION-MAP-LAYER.LINESBRT', 
            isOn: false,
            icon: 'BRTLines.svg',
            url: 'https://api.maptiler.com/tiles/bd57dddb-73dc-401d-b623-fec71ad1d7bb/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8',
            info: 'SECTION-MAP-LAYER.LINESINFO',
            maxZoom : 19, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.STATIONSBRT', 
            isOn: false,
            icon: 'BRTStations.svg',
            url: '/',
            info: 'SECTION-MAP-LAYER.STATIONSINFO',
            maxZoom : 19, 
            minZoom : 9
        },
    ],
    'SECTION-MAP-LAYER.BUSES': [
        {
            name: 'SECTION-MAP-LAYER.BUSESDAILYFREQ', 
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 67, 
            legendUrl: `${transportationlegendUrl}BUSESDAILYFREQ.png`, 
            isOn: false, 
            icon: '', 
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.DOT-Q2-22',
            maxZoom : 22, 
            minZoom : 13
        },
        {
            name: 'SECTION-MAP-LAYER.BUSESSTATIONS', 
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 22, 
            legendUrl: `${transportationlegendUrl}BUSESSTATIONS.png`, 
            isOn: false, 
            icon: '', 
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.DOT-Q3-23',
            maxZoom : 22, 
            minZoom : 15
        },
    ],
    'SECTION-MAP-LAYER.BICYCLE': [
        {
            name: 'SECTION-MAP-LAYER.BICYCLELANESEXISTING',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 130, 
            legendUrl: `${transportationlegendUrl}BICYCLELANESEXISTING.png`, 
            isOn: false, 
            icon: 'ExistingLines.svg',
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.FORUM-DOT-Q1-24',
            maxZoom : 22, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.TELAVIVDISTRICTBICYCLE',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 88, 
            legendUrl: `${transportationlegendUrl}TELAVIVDISTRICTBICYCLE.png`, 
            isOn: false, 
            icon: 'TelAvivBicycle.svg',
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.DOT-Q2-22',
            maxZoom : 22, 
            minZoom : 10
            
        },
        {
            name: 'SECTION-MAP-LAYER.CENTRALDISTRICTBICYCLE',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 89, 
            legendUrl: `${transportationlegendUrl}CENTRALDISTRICTBICYCLE.png`, 
            isOn: false, 
            icon: '',
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.DOT-Q2-22',
            maxZoom : 22, 
            minZoom : 10
        },
        {
            name: 'SECTION-MAP-LAYER.OFNIDAN',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 86, 
            legendUrl: `${transportationlegendUrl}OFNIDAN.png`, 
            isOn: false, 
            icon: '', 
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.NA-Q2-24',
            maxZoom : 22, 
            minZoom : 11
        },
    ],
    'SECTION-MAP-LAYER.PRIORITYLANES': [
        {
            name: 'SECTION-MAP-LAYER.EXISTINGPRIORITYLANES',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 77, 
            legendUrl: `${transportationlegendUrl}EXISTINGPRIORITYLANES.png`, 
            isOn: false, 
            icon: '', 
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.DOT-Q1-24',
            maxZoom : 22, 
            minZoom : 9
        },
        {
            name: 'SECTION-MAP-LAYER.PLANNEDPRIORITYLANES',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 75, 
            legendUrl: `${transportationlegendUrl}PLANNEDPRIORITYLANES.png`, 
            isOn: false, 
            icon: '', 
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.DOT-Q3-24',
            maxZoom : 22, 
            minZoom : 9
            
        },
        {
            name: 'SECTION-MAP-LAYER.COMPILATIONFORPRIORITYLANES',
            type: 'govmap' , 
            resource: 'moti', 
            layerId: 76, 
            legendUrl: `${transportationlegendUrl}COMPILATIONFORPRIORITYLANES.png`, 
            isOn: false, 
            icon: '', 
            url: 'getGovMapLayer',
            info: 'SECTION-MAP-LAYER.NA-Q2-20',
            maxZoom : 22, 
            minZoom : 9
        },
    ],
    'SECTION-MAP-LAYER.STRATEGICPLANFORTERMINAL': [],
    'SECTION-MAP-LAYER.DEPOTFORLIGHTRAILANDMETRO': [],
    'SECTION-MAP-LAYER.METRONIT': [],
    'SECTION-MAP-LAYER.PUBLICTRANSPORTTERMINALS': [],
    'SECTION-MAP-LAYER.METROFAN': [],
    'SECTION-MAP-LAYER.FLIGHTZONES':[],
    // 'SECTION-MAP-LAYER.TRANSPORTATIONINFRA': [ // 'Transportation Infra'
    //     {
    //     name: 'SECTION-MAP-LAYER.FREEWAY', // 'Free Way'
    //     isOn: false,
    //     icon: 'Freeway.svg',
    //     url: ''
    //     },
    //     {
    //     name: 'SECTION-MAP-LAYER.HIGHWAYS', // 'Highways'
    //     isOn: false,
    //     icon: 'Highway.svg',
    //     url: ''
    //     },
    //     {
    //     name: 'SECTION-MAP-LAYER.STREETS', // 'Streets'
    //     isOn: false,
    //     icon: 'Street.svg',
    //     url: ''
    //     }
    // ],
    // 'SECTION-MAP-LAYER.MASSTRANSPORTATION': [  // 'Mass Transportation'
    //     {
    //     name: 'SECTION-MAP-LAYER.METROLINES', // 'Metro Lines'
    //     isOn: false,
    //     icon: 'Metro.svg',
    //     url: 'https://api.maptiler.com/tiles/56ffd0fa-08be-47e3-be47-6d99481a7512/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
    //     },
    //     {
    //     name: 'SECTION-MAP-LAYER.LIGHTRAILTRANSIT-LRT', // 'Light Rail Transit - LRT'
    //     isOn: false,
    //     icon: 'light_rail.svg',
    //     url: 'https://api.maptiler.com/tiles/56ffd0fa-08be-47e3-be47-6d99481a7512/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
    //     },
    //     {
    //     name: 'SECTION-MAP-LAYER.BUSRAPIDTRANSIT-BRT', // 'Bus Rapid Transit - BRT'
    //     isOn: false,
    //     icon: 'bus.svg',
    //     url: 'https://api.maptiler.com/tiles/56ffd0fa-08be-47e3-be47-6d99481a7512/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
    //     },
    //     {
    //     name: 'SECTION-MAP-LAYER.HEAVYTRAIN', // 'Heavy Train'
    //     isOn: false,
    //     icon: 'heavy_train.svg',
    //     url: 'https://api.maptiler.com/tiles/56ffd0fa-08be-47e3-be47-6d99481a7512/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
    //     }
    // ],
    // 'SECTION-MAP-LAYER.PATHS': [ // 'Paths'
    //     {
    //     name: 'SECTION-MAP-LAYER.BICYCLEPATH', // 'Bicycle Path'
    //     isOn: false,
    //     icon: 'BicyclePath.svg',
    //     url: ''
    //     },
    //     {
    //     name: 'SECTION-MAP-LAYER.PEDESTRIANPATH', // 'Pedestrian Path'
    //     isOn: false,
    //     icon: 'Pedestrian.svg',
    //     url: ''
    //     },
    // ]
    },

    environmentPanelUrls: {
        'SECTION-MAP-LAYER.COASTLINE075': {name:  'SECTION-MAP-LAYER.COASTLINE075', type: 'govmap' , resource: 'AdditionalData', layerId: 201, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-IMA2004-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.ELEVETIONLINES': {name: 'SECTION-MAP-LAYER.ELEVETIONLINES', type: 'govmap' , resource: 'AdditionalData', layerId: 198, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-IMA2011-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.100MLINE': {name: 'SECTION-MAP-LAYER.100MLINE', type: 'govmap' , resource: 'AdditionalData', layerId: 202, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-IMA2011-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.300MLINE': {name: 'SECTION-MAP-LAYER.300MLINE', type: 'govmap' , resource: 'AdditionalData', layerId: 203, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-IMA2011-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.AIRMONITORINGSTATIONS': {name: 'SECTION-MAP-LAYER.AIRMONITORINGSTATIONS', type: 'govmap' , resource: 'AdditionalData', layerId: 94, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-MEP-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.HERITAGETREES': {name: 'SECTION-MAP-LAYER.HERITAGETREES', type: 'govmap' , resource: 'AdditionalData', layerId: 148, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.GOVMAPAPI',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.FLOODPLAINS': {name: 'SECTION-MAP-LAYER.FLOODPLAINS', type: 'govmap' , resource: 'AdditionalData', layerId: 238, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.GOVMAPAPI',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.CONTAMINATEDSOILS': {name: 'SECTION-MAP-LAYER.CONTAMINATEDSOILS', type: 'govmap' , resource: 'AdditionalData', layerId: 16, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-MEP-GM',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.SOILGROUPS': {name:  'SECTION-MAP-LAYER.SOILGROUPS', type: 'govmap' , resource: 'AdditionalData', layerId: 328, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-MA-MP',maxZoom : 22, minZoom : 9},
        'SECTION-MAP-LAYER.ANCIENTRUINS': {name: 'SECTION-MAP-LAYER.ANCIENTRUINS', type: 'govmap' , resource: 'AdditionalData', layerId: 330, isOn: false, url: 'getGovMapLayer', icon: 'Xplan.svg', info: 'SECTION-MAP-LAYER.S-AA-GM',maxZoom : 22, minZoom : 9}
    },

    environment: {
        'SECTION-MAP-LAYER.COASTLINE075': [],
        'SECTION-MAP-LAYER.ELEVETIONLINES': [],
        'SECTION-MAP-LAYER.100MLINE': [],
        'SECTION-MAP-LAYER.300MLINE': [],
        'SECTION-MAP-LAYER.AIRMONITORINGSTATIONS': [],
        'SECTION-MAP-LAYER.HERITAGETREES': [],
        'SECTION-MAP-LAYER.FLOODPLAINS': [],
        'SECTION-MAP-LAYER.CONTAMINATEDSOILS': [],
        'SECTION-MAP-LAYER.SOILGROUPS': [],
        'SECTION-MAP-LAYER.ANCIENTRUINS': []
    },

    otherPanelUrls: {
        'SECTION-MAP-LAYER.TREES': {name: 'SECTION-MAP-LAYER.TREES', isOn: false, icon: 'Trees.svg', url: '/',info: 'SECTION-MAP-LAYER.TREESINFO'},
        'SECTION-MAP-LAYER.PRESERVATIONTELAVIV': {name: 'SECTION-MAP-LAYER.PRESERVATIONTELAVIV', isOn: false, icon: 'PreservationTelAviv.svg', url: '/' , info: 'SECTION-MAP-LAYER.PRESERVATIONTELAVIVINFO' },
        'SECTION-MAP-LAYER.SCHOOLS': {name: 'SECTION-MAP-LAYER.SCHOOLS', isOn: false, icon: 'Schools.svg', url: '/',info: 'SECTION-MAP-LAYER.SCHOOLSINFO' },
        'SECTION-MAP-LAYER.KINDERGARDEN': {name: 'SECTION-MAP-LAYER.KINDERGARDEN', isOn: false, icon: 'Kindergartens.svg', url: '/',info: 'SECTION-MAP-LAYER.KINDERGARDENINFO' }
    },

    other: {
        'SECTION-MAP-LAYER.TREES': [],
        'SECTION-MAP-LAYER.PRESERVATIONTELAVIV': [],
        'SECTION-MAP-LAYER.SCHOOLS': [],
        'SECTION-MAP-LAYER.KINDERGARDEN': []
    },

    smartLayers: {
    'SECTION-MAP-LAYER.NOLI': [], // 'Noli'
    'SECTION-MAP-LAYER.BUILDYEAR': [ //'Build Year'
        {
        name: '≤ 1970',
        isOn: false,
        icon: 'BuildYear/_1970.svg',
        url: ''
        },
        {
        name: '1971 - 1980',
        isOn: false,
        icon: 'BuildYear/1971-1980.svg',
        url: ''
        },
        {
        name: '1981 - 1990',
        isOn: false,
        icon: 'BuildYear/1981-1990.svg',
        url: ''
        },
        {
        name: '1991 - 2000',
        isOn: false,
        icon: 'BuildYear/1991-2000.svg',
        url: ''
        },
        {
        name: '2001 - 2010',
        isOn: false,
        icon: 'BuildYear/2001-2010.svg',
        url: ''
        },
        {
        name: '2011 - 2020',
        isOn: false,
        icon: 'BuildYear/2011-2020.svg',
        url: ''
        },
        {
        name: '≥ 2020',
        isOn: false,
        icon: 'BuildYear/_2020.svg',
        url: ''
        },
        {
        name: 'Unknown',
        isOn: false,
        icon: 'BuildYear/Unknown.svg',
        url: ''
        },
    ],
    'SECTION-MAP-LAYER.STOREYS': [ // 'Storeys'
        {
        name: 'Single Storey',
        isOn: false,
        icon: 'Storeys/SingleStorey.svg',
        url: ''
        },
        {
        name: 'Two Storeys',
        isOn: false,
        icon: 'Storeys/TwoStoreys.svg',
        url: ''
        },
        {
        name: '3- 9 Storeys',
        isOn: false,
        icon: 'Storeys/3-9Storeys.svg',
        url: ''
        },
        {
        name: '10 - 15 Storeys.',
        isOn: false,
        icon: 'Storeys/10-15Storeys.svg',
        url: ''
        },
        {
        name: '16 - 29 Storeys.',
        isOn: false,
        icon: 'Storeys/16-29.svg',
        url: ''
        },
        {
        name: '30+ Storeys.',
        isOn: false,
        icon: 'Storeys/30+.svg',
        url: ''
        },
        {
        name: 'Unknown',
        isOn: false,
        icon: 'Storeys/Unknown.svg',
        url: ''
        },],
    'SECTION-MAP-LAYER.FAR': [  // 'FAR'
        {
        name: '0 - 1',
        isOn: false,
        icon: 'FAR/0-1.svg',
        url: ''
        },
        {
        name: '1 - 2',
        isOn: false,
        icon: 'FAR/1-2.svg',
        url: ''
        },
        {
        name: '2 - 3',
        isOn: false,
        icon: 'FAR/2-3.svg',
        url: ''
        },
        {
        name: '3 - 4',
        isOn: false,
        icon: 'FAR/3-4.svg',
        url: ''
        },
        {
        name: '5 - 6',
        isOn: false,
        icon: 'FAR/5-6.svg',
        url: ''
        },
        {
        name: '6 - 7',
        isOn: false,
        icon: 'FAR/6-7.svg',
        url: ''
        },
        {
        name: '7 - 8',
        isOn: false,
        icon: 'FAR/7-8.svg',
        url: ''
        },
        {
        name: '9 - 10',
        isOn: false,
        icon: 'FAR/9-10.svg',
        url: ''
        },
        {
        name: '30+ Storeys',
        isOn: false,
        icon: 'FAR/30+.svg',
        url: ''
        },
        {
        name: 'Unknown',
        isOn: false,
        icon: 'FAR/farUnknown.svg',
        url: ''
        }],
    'SECTION-MAP-LAYER.EXECUTIONPROBABILITY': [ // 'Execution Probability'
        {
        name: '0 - 20',
        isOn: false,
        icon: 'ExecutionProbability/0-20.svg',
        url: ''
        },
        {
        name: '20 - 50',
        isOn: false,
        icon: 'ExecutionProbability/20-50.svg',
        url: ''
        },
        {
        name: '50 - 60',
        isOn: false,
        icon: 'ExecutionProbability/50-60.svg',
        url: ''
        },
        {
        name: '60 - 70',
        isOn: false,
        icon: 'ExecutionProbability/60-70.svg',
        url: ''
        },
        {
        name: '70 - 80',
        isOn: false,
        icon: 'ExecutionProbability/70-80.svg',
        url: ''
        },
        {
        name: '80 - 90',
        isOn: false,
        icon: 'ExecutionProbability/80-90.svg',
        url: ''
        },
        {
        name: '90 - 100',
        isOn: false,
        icon: 'ExecutionProbability/90-100.svg',
        url: ''
        },
        {
        name: 'Null',
        isOn: false,
        icon: 'ExecutionProbability/Null.svg',
        url: ''
        },
    ],
    'SECTION-MAP-LAYER.AVERAGERESIDENTIALPRICES': [ // 'Average Residential Prices'
        {
        name: '0 - 1M',
        isOn: false,
        icon: 'AverageResidentialPrices/0-1.svg',
        url: ''
        },
        {
        name: '1 - 2M',
        isOn: false,
        icon: 'AverageResidentialPrices/1-2.svg',
        url: ''
        },
        {
        name: '2 - 3M',
        isOn: false,
        icon: 'AverageResidentialPrices/2-3.svg',
        url: ''
        },
        {
        name: '3 - 4M',
        isOn: false,
        icon: 'AverageResidentialPrices/3-4.svg',
        url: ''
        },
        {
        name: '4 - 5M',
        isOn: false,
        icon: 'AverageResidentialPrices/4-5.svg',
        url: ''
        },
        {
        name: '5 - 6M',
        isOn: false,
        icon: 'AverageResidentialPrices/5-6.svg',
        url: ''
        },
        {
        name: '6 - 7M',
        isOn: false,
        icon: 'AverageResidentialPrices/6-7.svg',
        url: ''
        },
        {
        name: '7 - 8M',
        isOn: false,
        icon: 'AverageResidentialPrices/7-8.svg',
        url: ''
        },
    ],
    'SECTION-MAP-LAYER.TREEHEATMAP': [ // 'Tree Heat Map'
    ],
    'SECTION-MAP-LAYER.TREETYPES': [ // 'Tree Types'
    ],
    'SECTION-MAP-LAYER.CURRENTLANDUSE': [ // Current Land Use
        {
        name: 'Parks & open area',
        isOn: false,
        icon: 'parks&open_area.svg',
        url: 'https://api.maptiler.com/tiles/2eea6396-19e1-44f8-85c7-444353899d6d/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
        },
        {
        name: 'Marketable',
        isOn: false,
        icon: 'Marketable.svg',
        url: 'https://api.maptiler.com/tiles/76c480cb-c25c-4282-bd61-b87d23a77a84/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
        },
        {
        name: 'Right Of Road',
        isOn: false,
        icon: 'RightofRoad.svg',
        url: 'https://api.maptiler.com/tiles/ffb878ea-c363-4e82-96df-45ddb8a1699a/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
        },
        {
        name: 'Transportation Infra',
        isOn: false,
        icon: 'TransportationInfra.svg',
        url: 'https://api.maptiler.com/tiles/f92f629a-d2fe-4ce5-b16f-dadd6d6f5c89/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
        },
        {
        name: 'Public Buildings',
        isOn: false,
        icon: 'PublicBuildings.svg',
        url: 'https://api.maptiler.com/tiles/b74ee762-42ac-4ab8-af88-32bd4f3d87a7/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
        },
        {
        name: 'Other',
        isOn: false,
        icon: 'Other.svg',
        url: 'https://api.maptiler.com/tiles/4be353f5-946e-4c5a-9b24-ee02e363d657/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8'
        }
    ]
    }, 

    customizedLayers: {},

    smartLayersPanelUrls: {
        'SECTION-MAP-LAYER.NOLI': { name: 'SECTION-MAP-LAYER.NOLI', isOn: false, url: 'https://api.maptiler.com/tiles/79346eb3-746a-4f73-aca6-a7f5e3b60868/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: 'Noli.svg' },
        'SECTION-MAP-LAYER.BUILDYEAR': { name: 'SECTION-MAP-LAYER.BUILDYEAR', isOn: false, url: 'https://api.maptiler.com/tiles/20ac66b6-53e7-4040-ab15-a19d501e188b/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '' },
        'SECTION-MAP-LAYER.STOREYS': { name: 'SECTION-MAP-LAYER.STOREYS', isOn: false, url: 'https://api.maptiler.com/tiles/bef42d62-76f4-4316-9c49-633819b74b26/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '' },
        'SECTION-MAP-LAYER.FAR': { name: 'SECTION-MAP-LAYER.FAR', isOn: false, url: 'https://api.maptiler.com/tiles/3fed0e6c-20a2-4373-a8fd-8688f5c632c4/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '' },
        'SECTION-MAP-LAYER.EXECUTIONPROBABILITY': { name: 'SECTION-MAP-LAYER.EXECUTIONPROBABILITY', isOn: false, url: 'https://api.maptiler.com/tiles/7b5405ee-da82-40fc-9f95-3e2fd1360372/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '' },
        'SECTION-MAP-LAYER.AVERAGERESIDENTIALPRICES': { name: 'SECTION-MAP-LAYER.AVERAGERESIDENTIALPRICES', isOn: false, url: 'https://api.maptiler.com/tiles/4176d39d-9799-4a95-9fb6-7bd885a20235/{z}/{x}/{y}.png?key=8BjhptJnVjQEcCDbVjO8', icon: '' },
        'TREEHEATMAP': { name: 'TREEHEATMAP', isOn: false, url: 'https://api.mapbox.com/styles/v1/ormosco41/cl3h24ezh002l15qgk383dy3v/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3Jtb3NjbzQxIiwiYSI6ImNsMW5lYmQyaTBjODkzZXBkd3UzdWlwdmIifQ.e9boa76o2-pZ8SNFH1Wcwg', icon: 'TreeHeatMap.svg' },
        'TREETYPES': { name: 'TREETYPES', isOn: false, url: 'https://api.mapbox.com/styles/v1/ormosco41/cl3h28xcj009y14rx1gdlxyva/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoib3Jtb3NjbzQxIiwiYSI6ImNsMW5lYmQyaTBjODkzZXBkd3UzdWlwdmIifQ.e9boa76o2-pZ8SNFH1Wcwg', icon: 'TreeTypes.svg' }
    },


    customizedLayersUrls: {},

}