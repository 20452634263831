<nz-dropdown-menu #menu="nzDropdownMenu">
    <ng-container *ngIf="feature.properties.entityType === 'comment'; else EntityEditMenu">
        <ul nz-menu>
            <li 
                nz-menu-item 
                data-cy="delete-entity" 
                [nzDisabled]="getLockState()" 
                (click)="deleteEntity()">
                {{ 'ENTITYEDITMENU.DELETE' | translate}}
                <i nz-icon nzType="delete" nzTheme="outline"></i>
            </li>
    
            <li nz-menu-item>
                <nz-upload
                [nzBeforeUpload]="beforeUpload"
                [nzCustomRequest]="customRequest"
                [nzShowUploadList]="false">
                {{ 'ENTITYEDITMENU.UPLOAD' | translate}}<i nz-icon nzType="upload" nzTheme="outline"></i>
                </nz-upload>
            </li>
        
        </ul>
    </ng-container>
</nz-dropdown-menu>


<ng-template #EntityEditMenu>
    <ng-container *ngIf="dropdownState !== DROPDOWN_STATES.TURNINTO_BUTTON">
        <ul [nzSelectable]="false" nz-menu>
            <li 
                nz-menu-item 
                data-cy="edit-entity" 
                (click)="editEntity()">
                <div class="menuLabelContainer">
                    <i nz-icon nzType="edit"nzTheme="outline"></i>
                    {{ 'ENTITYEDITMENU.EDIT' | translate}}
                </div>
                <label class="shortcut-label"><img class="shift-img" src="../../../../../assets/leaflet-menu/ShiftForMenu.svg" alt=""> E</label>

            </li>
            <li 
                nz-menu-item 
                data-cy="duplicate-entity" 
                (click)="duplicateEntity()">
                <div class="menuLabelContainer">
                    <i nz-icon nzType="copy" nzTheme="outline"></i>
                    {{ 'ENTITYEDITMENU.DUPLICATE' | translate}}
                </div>
                <label class="shortcut-label"><img class="shift-img" src="../../../../../assets/leaflet-menu/ShiftForMenu.svg" alt=""> D</label>
            </li>
            <!-- <li 
                class="lock"
                nz-menu-item 
                data-cy="lock-entity" 
                (click)="lockEntity()" 
                *ngIf="!getLockState()">
                {{ 'ENTITYEDITMENU.LOCK' | translate}}
                <i nz-icon nzType="lock" nzTheme="outline"></i>
            </li>
            <li 
                class="lock"
                nz-menu-item 
                data-cy="unlock-entity" 
                (click)="unlockEntity()" 
                *ngIf="getLockState()">
                {{ 'ENTITYEDITMENU.UNLOCK' | translate}}
                <i nz-icon nzType="unlock" nzTheme="outline"></i>
            </li> -->
            <li *ngIf="dropdownState === DROPDOWN_STATES.ELLIPSIS_MENU" nz-menu-item class="entity-file-upload-menu">
                <nz-upload
                [nzBeforeUpload]="beforeUpload"
                [nzCustomRequest]="customRequest"
                [nzShowUploadList]="false">
                <div class="menuLabelContainer">
                    <i nz-icon nzType="upload" nzTheme="outline"></i>
                    {{ 'ENTITYEDITMENU.UPLOAD' | translate}}
                </div>
                </nz-upload>
                
            </li>
            <li 
                nz-menu-item 
                data-cy="delete-entity" 
                [nzDisabled]="getLockState()" 
                (click)="deleteEntity()">
                <div class="menuLabelContainer">
                    <i nz-icon nzType="delete" nzTheme="outline"></i>
                    {{ 'ENTITYEDITMENU.DELETE' | translate}}
                </div>
                <label class="shortcut-label">Delete</label>
            </li>
            <li 
            
                nz-menu-item 
                data-cy="scale-entity" 
                (click)="scaleEntity()">
                <div class="menuLabelContainer">
                    <i nz-icon nzType="fullscreen" nzTheme="outline"></i>
                    {{ 'ENTITYEDITMENU.SCALE' | translate}}
                </div>
                <label class="shortcut-label"><img class="shift-img" src="../../../../../assets/leaflet-menu/ShiftForMenu.svg" alt=""> S</label>
            </li>
            <li 
                nz-menu-item 
                data-cy="move-entity" 
                (click)="moveEntity()">
                <div class="menuLabelContainer">
                    <i nz-icon nzType="drag" nzTheme="outline"></i>
                    {{ 'ENTITYEDITMENU.MOVE' | translate}}
                </div>
                <!-- <label class="shortcut-label">sss</label> -->
                <label class="shortcut-label"><img class="shift-img" src="../../../../../assets/leaflet-menu/ShiftForMenu.svg" alt=""> M</label>
            </li>
            <li 
                nz-menu-item 
                data-cy="duplicate-entity" 
                (click)="rotateEntity()">
                <div class="menuLabelContainer">
                    <i nz-icon nzType="reload" nzTheme="outline"></i>
                    {{ 'ENTITYEDITMENU.ROTATE' | translate}}
                </div>
                <label class="shortcut-label"><img class="shift-img" src="../../../../../assets/leaflet-menu/ShiftForMenu.svg" alt=""> R</label>
            </li>
            <li 
                nz-menu-item 
                (click)="exportEntity()">
                <div class="menuLabelContainer">
                    <i nz-icon nzType="export" nzTheme="outline"></i>
                    {{ 'ENTITYEDITMENU.EXPORT' | translate}}
                </div>
                <!-- <label class="shortcut-label"><img class="shift-img" src="../../../../../assets/leaflet-menu/ShiftForMenu.svg" alt=""> R</label> -->
            </li>
            <li 
                class="entity-turn-into"
                nz-submenu 
                [nzDisabled]="getLockState()" 
                data-cy="turn-entity-into" 
                nzTitle="{{ 'ENTITYEDITMENU.TURNINTO' | translate}}">
                <ul >
                <!-- Iterate over each entity type (e.g., 'parcel', 'building') -->
                <ng-container *ngFor="let entityType of getObjectKeys(entityTypesToTurn)">
                    <ng-container *ngIf="entityType === 'building'; else entityCategorySubMenu">
                        <li nz-menu-item data-cy="entity-building" (click)="openTurnToWarningModal( 'building', 'building')">
                            {{ 'ENTITYEDITMENU.BUILDING' | translate}}
                        </li>
                    </ng-container>
                    <ng-template #entityCategorySubMenu>
                        <li nz-submenu  nzTitle="{{ 'ENTITYEDITMENU.' + toUpperCase(entityType) | translate }}" [attr.data-cy]="'entity-' + entityType">
                            <ul >
                            <!-- Iterate over subcategories (e.g., 'private', 'public') within each entity type -->
                            <ng-container *ngFor="let subCategory of getObjectKeys(entityTypesToTurn[entityType])">
                                <li *ngIf="subCategory !== 'default'" nz-menu-group>
                                <label class="label-group" *ngIf="subCategory !== 'default'" >{{ 'ENTITYEDITMENU.' + toUpperCase(subCategory) | translate }}</label>
                                <nz-divider class="divider"></nz-divider>
                                <!-- List each item within the subcategory -->
                                    <ul>
                                        <li nz-menu-item *ngFor="let item of entityTypesToTurn[entityType][subCategory]"
                                            (click)="openTurnToWarningModal(item,entityType)"
                                            [attr.data-cy]="'type-' + item"
                                            [nzDisabled]="getLockState() ||  isMatchingLandUse(feature.properties.entityType,item)">
                                        <img [src]="getIconType(entityType, item)" alt="{{ item | titlecase }}">
                                        {{ 'ENTITYEDITMENU.' + toUpperCase(item) | translate }}
                                        </li>
                                    </ul>
                                </li>
                                <!-- Handle 'default' subcategory without a title -->
                                <ng-container *ngIf="subCategory === 'default'">
                                    <ul>
                                        <li nz-menu-item *ngFor="let item of entityTypesToTurn[entityType][subCategory]"
                                            (click)="openTurnToWarningModal(item,entityType)"
                                            [attr.data-cy]="'type-' + item"
                                            [nzDisabled]="getLockState() || isMatchingLandUse(feature.properties.entityType,item)">
                                        <img [src]="getIconType(entityType, item)" alt="{{ item | titlecase }}">
                                        {{ 'ENTITYEDITMENU.' + toUpperCase(item) | translate }}
                                        </li>
                                    </ul>
                                </ng-container>
                            </ng-container>
                            </ul>
                        </li>
                    </ng-template>
                    
                </ng-container>
                </ul>
            </li>
        </ul>
    </ng-container>
    <ng-container *ngIf="dropdownState === DROPDOWN_STATES.TURNINTO_BUTTON">
        <ul [nzSelectable]="false" class="turnIntoLocation" nz-menu >
            <!-- Iterate over each entity type (e.g., 'parcel', 'building') -->
            <ng-container *ngFor="let entityType of getObjectKeys(entityTypesToTurn)">
                <ng-container *ngIf="entityType === 'building'; else entityCategorySubMenu">
                    <li nz-menu-item data-cy="entity-building" (click)="openTurnToWarningModal( 'building', 'building')">
                        {{ 'ENTITYEDITMENU.BUILDING' | translate}}
                    </li>
                </ng-container>
                <ng-template  #entityCategorySubMenu>
                    <li class="containerForBtn" nz-submenu nzTitle="{{ 'ENTITYEDITMENU.' + toUpperCase(entityType) | translate }}" [attr.data-cy]="'entity-' + entityType">
                        <ul >
                        <!-- Iterate over subcategories (e.g., 'private', 'public') within each entity type -->
                        <ng-container *ngFor="let subCategory of getObjectKeys(entityTypesToTurn[entityType])">
                            <li *ngIf="subCategory !== 'default'" nz-menu-group>
                            <label class="label-group" *ngIf="subCategory !== 'default'" >{{ 'ENTITYEDITMENU.' + toUpperCase(subCategory) | translate }}</label>
                            <nz-divider class="divider"></nz-divider>
                            <!-- List each item within the subcategory -->
                                <ul>
                                    <li nz-menu-item *ngFor="let item of entityTypesToTurn[entityType][subCategory]"
                                        (click)="openTurnToWarningModal(item,entityType)"
                                        [attr.data-cy]="'type-' + item"
                                        [nzDisabled]="getLockState() || isMatchingLandUse(feature.properties.entityType,item)">
                                    <img [src]="getIconType(entityType, item)" alt="{{ item | titlecase }}">
                                    {{ 'ENTITYEDITMENU.' + toUpperCase(item) | translate }}
                                    </li>
                                </ul>
                            </li>
                            <!-- Handle 'default' subcategory without a title -->
                            <ng-container *ngIf="subCategory === 'default'">
                                <ul>
                                    <li nz-menu-item *ngFor="let item of entityTypesToTurn[entityType][subCategory]"
                                        (click)="openTurnToWarningModal(item,entityType)"
                                        [attr.data-cy]="'type-' + item"
                                        [nzDisabled]="getLockState() || isMatchingLandUse(feature.properties.entityType,item)">
                                    <img [src]="getIconType(entityType, item)" alt="{{ item | titlecase }}">
                                    {{ 'ENTITYEDITMENU.' + toUpperCase(item) | translate }}
                                    </li>
                                </ul>
                            </ng-container>
                        </ng-container>
                        </ul>
                    </li>
                </ng-template>
                
            </ng-container>
            </ul>
       </ng-container>
</ng-template>





<!-- <nz-dropdown-menu #commentEditmenu="nzDropdownMenu">
    <ul nz-menu>
        <li 
            nz-menu-item 
            data-cy="delete-entity" 
            [nzDisabled]="getLockState()" 
            (click)="deleteEntity.emit()">
            Delete
            <i nz-icon nzType="delete" nzTheme="outline"></i>
        </li>

        <li nz-menu-item>
            <nz-upload
            [nzBeforeUpload]="beforeUpload"
            [nzCustomRequest]="customRequest"
            [nzShowUploadList]="false">
                Upload<i nz-icon nzType="upload" nzTheme="outline"></i>
            </nz-upload>
        </li>
  
    </ul>
</nz-dropdown-menu> -->


