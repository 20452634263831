export const MENU_DATA = {
    "influence": {
        "השפעה מרחבית - תמא 70 ": [
            {
                "code": "include/disclude_ring1",
                "defaultValue": "YES",
                "increment": "",
                "inputParameter": "?האם נדרש לכלול את המתחם בתחום הטבעת הפנימית בה המתחם קיים",
                "landUses": [
                    "urban_landmark",
                    "urban_preservation_zone"
                ],
                "max": "YES",
                "min": "NO",
                "valueType": "1",
                "widget": "CHECKBOX"
            },
            {
                "code": "metro_station__type",
                "widget": "DROPDOWN",
                "inputParameter": "אפיון אורבני של סביבת תחנת המטרו",
                "values": "שכונתי,רובעי,עירוני,מטרופליטני",
                "defaultValue": "",
                "landUses": [
                    "metro_ring_2_polygon"
                ]
            },
            {
                "code": "metro_station_dominant_usage",
                "widget": "DROPDOWN",
                "inputParameter": "אופי מרחב התחנה",
                "max": "מ",
                "values": "מוטה מגורים,מוטה תעסוקה,מעורב,שימור",
                "landUses": [
                    "metro_ring_2_polygon"
                ]
            }
        ],
        "מטרו": [
            {
                "code": "metro_ground_coverage_ratio",
                "increment": "0.5",
                "inputParameter": "תכסית שטחים סחירים ",
                "landUses": [],
                "max": "80",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_mix-use_ratio_resident ",
                "increment": "0.5",
                "inputParameter": "עירוב שימושים סביב המטרו - % מגורים",
                "landUses": [],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_influence_radius",
                "defaultValue": "100",
                "increment": "1",
                "inputParameter": "רדיוס מהתחנה ",
                "landUses": [],
                "max": "1000",
                "min": "0",
                "valueType": "m",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "metro_parking_factor",
                "increment": "0.1",
                "inputParameter": "תקן חניה סמוך למטרו",
                "landUses": [],
                "max": "2",
                "min": "0",
                "widget": "SLIDER"
            },
            {
                "code": "metro_radius_core_type",
                "defaultValue": "Centoid",
                "increment": "1",
                "inputParameter": "אפיון מדידת הרדיוס",
                "landUses": [
                    "metro_core"
                ],
                "max": "2",
                "min": "1",
                "values": "Centoid,Entances,Layout",
                "widget": "DROPDOWN"
            }
        ],
        "מרחב תכנון מצב קיים": [
            {
                "code": "nd_current_far",
                "defaultValue": "1",
                "increment": "0.5",
                "inputParameter": "רח\"ק ממוצע קיים במתחם",
                "landUses": [
                    "Neighbourhood"
                ],
                "max": "10",
                "min": "0",
                "widget": "SLIDER"
            },
            {
                "code": "nd_current_typology",
                "defaultValue": "-",
                "inputParameter": "טיפולוגית בינוי מצב קיים",
                "landUses": [
                    "Neighbourhood"
                ],
                "widget": "INPUT_TEXT"
            },
            {
                "code": "nd_approved_far",
                "defaultValue": "-",
                "increment": "0.1",
                "inputParameter": "רחק מאושר ממוצע במתחם",
                "landUses": [
                    "Neighbourhood",
                    "urban_renewal_zone"
                ],
                "max": "10",
                "min": "0",
                "widget": "SLIDER"
            },
            {
                "code": "area_built_res_current_m2",
                "inputParameter": "מ\"ר בנוי קיים מגורים",
                "landUses": [
                    "Neighbourhood"
                ],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "area_built_comm_current_m2",
                "inputParameter": "מ\"ר בנוי קיים תעסוקה",
                "landUses": [
                    "Neighbourhood"
                ],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "area_built_retail_current_m2",
                "inputParameter": "מ\"ר בנוי קיים מסחר",
                "landUses": [
                    "Neighbourhood"
                ],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "year_built_current_avg",
                "inputParameter": "שנת בנייה ממוצעת במתחם",
                "landUses": [
                    "Neighbourhood"
                ],
                "max": "2020",
                "min": "1930",
                "valueType": "int",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "junctions_density_index",
                "inputParameter": "מדד רשת צמתים",
                "landUses": [
                    "Neighbourhood"
                ],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "unit_density_current_index",
                "inputParameter": "מדד צפיפות מצב קיים",
                "landUses": [
                    "Neighbourhood"
                ],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "nd_avrg_lot_size",
                "defaultValue": "500",
                "increment": "",
                "inputParameter": "גודל מגרש ממוצע",
                "landUses": [
                    "Neighbourhood"
                ],
                "max": "3000",
                "min": "0",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "nd_num_of_appartments",
                "defaultValue": "",
                "increment": "",
                "inputParameter": "מספר יח\"ד",
                "landUses": [
                    "Neighbourhood"
                ],
                "max": "30000",
                "min": "0",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "nd_building_height_avrg",
                "defaultValue": "15",
                "inputParameter": "גובה בינוי ממוצע",
                "landUses": [
                    "Neighbourhood"
                ],
                "max": "200",
                "min": "0",
                "widget": "INPUT_NUMBER"
            }
        ],
        "פרמטרים לתכנון": [
            {
                "code": "radius_from_station_center",
                "defaultValue": "300",
                "increment": "50",
                "inputParameter": "רדיוס טבעת",
                "landUses": [
                    "metro"
                ],
                "max": "1000",
                "min": "100",
                "valueType": "'מ",
                "widget": "SLIDER"
            },
            {
                "code": "implementation_res_ratio ",
                "defaultValue": "100",
                "increment": "5",
                "inputParameter": "אחוז מימוש יעוד מגורים בשנת היעד",
                "landUses": [
                    "Neighbourhood"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "implementation_comm_ratio ",
                "defaultValue": "50",
                "increment": "5",
                "inputParameter": "אחוז מימוש תעסוקה בשנת היעד",
                "landUses": [
                    "Neighbourhood"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "implementation_retail_ratio ",
                "defaultValue": "50",
                "increment": "5",
                "inputParameter": "אחוז מימוש מסחר בשנת היעד ",
                "landUses": [
                    "Neighbourhood"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_far",
                "increment": "0.1",
                "inputParameter": "רח\"ק מוצע תמ\"א 70",
                "landUses": [],
                "max": "15",
                "min": "0",
                "valueType": "",
                "widget": "SLIDER"
            },
            {
                "code": "metro_far_ring1",
                "widget": "INPUT_NUMBER",
                "inputParameter": " רח\"ק מוצע טבעת ראשונה",
                "max": "15",
                "min": "0",
                "increment": "0.1",
                "landUses": [
                    "metro_ring_2_polygon"
                ]
            },
            {
                "code": "metro_far_ring2",
                "widget": "INPUT_NUMBER",
                "inputParameter": " רח\"ק מוצע טבעת שנייה",
                "max": "15",
                "min": "0",
                "increment": "0.1",
                "landUses": [
                    "metro_ring_2_polygon"
                ]
            },
            {
                "code": "metro_dev_zone_ring1.far",
                "widget": "INPUT_NUMBER",
                "inputParameter": "(רח\"ק מוצע מתחם לפיתוח (טבעת ראשונה",
                "max": "15",
                "min": "0",
                "increment": "0.1",
                "landUses": [
                    "urban_dev_zone"
                ]
            },
            {
                "code": "metro_dev_zone_ring2.far",
                "widget": "INPUT_NUMBER",
                "inputParameter": "רח\"ק מוצע מתחם לפיתוח (טבעת שנייה)",
                "max": "15",
                "min": "0",
                "increment": "0.1",
                "landUses": [
                    "urban_dev_zone"
                ]
            },
            {
                "code": "units.floor_area.avg",
                "widget": "SLIDER",
                "inputParameter": "שטח יח\"ד ממוצעת בשנת היעד",
                "landUses": [
                    "metro_ring_1_polygon",
                    "metro_ring_2_polygon",
                    "urban_dev_zone",
                    "urban_preservation_zone",
                    "urban_renewal_zone"
                ],
                "max": "120",
                "min": "60",
                "increment": "5",
                "defaultValue": "100"
            },
            {
                "code": "units.index.average_household_size",
                "widget": "SLIDER",
                "inputParameter": "גודל משק בית ממוצע בשנת היעד",
                "landUses": [
                    "Neighbourhood",
                    "metro_ring_2_polygon",
                    "urban_dev_zone",
                    "urban_preservation_zone",
                    "urban_renewal_zone"
                ],
                "max": "6",
                "min": "1",
                "increment": "0.1",
                "defaultValue": "3.2"
            },
            {
                "code": "expropriation_for_public_purposes.ratio",
                "widget": "SLIDER",
                "inputParameter": "אחוז הפקעה לצרכי ציבור (שכבת מתחמי התחדשות)",
                "max": "50",
                "valueType": "%",
                "min": "0",
                "increment": "5",
                "defaultValue": "10",
                "landUses": [
                    "metro_ring_2_polygon"
                ]
            }
        ],
        "שכבות בסיס": [
            {
                "code": "2base_layer_type",
                "defaultValue": "רצף-רמי",
                "inputParameter": "שכבת בסיס לייבוא",
                "landUses": [
                    "import_patch_base_layer"
                ],
                "values": "רצף-רמי,xplan,GIS-local",
                "widget": "DROPDOWN"
            }
        ],
        "תמהילים על פי אזורי יעוד": [
            {
                "code": "residential.floor_area.ratio",
                "defaultValue": "50",
                "increment": "5",
                "inputParameter": "מגורים טבעת שניה",
                "landUses": [
                    "metro_ring_2_polygon",
                    "urban_dev_zone"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "office.floor_area.ratio",
                "defaultValue": "40",
                "increment": "5",
                "inputParameter": " תעסוקה ומסחר טבעת שניה ",
                "landUses": [
                    "metro_ring_2_polygon",
                    "urban_dev_zone"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "retail.floor_area.ratio.ring_2",
                "defaultValue": "0",
                "increment": "1",
                "inputParameter": "מסחר",
                "landUses": [],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "other.floor_area.ratio",
                "defaultValue": "0",
                "increment": "1",
                "inputParameter": " אחר טבעת שניה",
                "landUses": [
                    "metro_ring_2_polygon",
                    "urban_dev_zone"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "ring1.residential.floor_area.ratio",
                "widget": "SLIDER",
                "inputParameter": "מגורים טבעת ראשונה",
                "max": "100",
                "min": "0",
                "increment": "5",
                "valueType": "%",
                "defaultValue": "50",
                "landUses": [
                    "metro_ring_2_polygon",
                    "urban_dev_zone"
                ]
            },
            {
                "code": "ring1.office.floor_area.ratio",
                "widget": "SLIDER",
                "inputParameter": "מסחר ותעסוקה טבעת ראשונה",
                "max": "100",
                "min": "0",
                "increment": "5",
                "valueType": "%",
                "defaultValue": "50",
                "landUses": [
                    "metro_ring_2_polygon",
                    "urban_dev_zone"
                ]
            },
            {
                "code": "ring1.other.floor_area.ratio",
                "widget": "SLIDER",
                "inputParameter": "אחר טבעת ראשונה",
                "max": "100",
                "min": "0",
                "increment": "5",
                "valueType": "%",
                "defaultValue": "0",
                "landUses": [
                    "metro_ring_2_polygon",
                    "urban_dev_zone"
                ]
            }
        ],
        "Smart card FAR": [],
        "landUse": {
            "Neighbourhood": {
                "color": "#99ccff",
                "displayName": "אזור תכנון",
                "lazyLoading": false,
                "mavatCode": "1",
                "maxZoom": 22,
                "minZoom": 16,
                "pattern": "1617519486340-metro-nd.png",
                "strokeColor": "#ce2f13",
                "thickness": 5,
                "zIndex": "250",
                "lineDash": 2,
                "opacity": 1
            },
            "city_center": {
                "color": "#efcbba",
                "displayName": "לב העיר",
                "maxZoom": 22,
                "minZoom": 14,
                "strokeColor": "#e30f91",
                "thickness": 6
            },
            "green_corridor": {
                "color": "#3f7105",
                "displayName": "ציר ירוק",
                "lineDash": 0,
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1622743423241-GREEN-CORRADOR-METRO.png",
                "strokeColor": "#3f7105",
                "thickness": 5,
                "zIndex": "150"
            },
            "interurban_transportation__corridor": {
                "color": "#0f0d0d",
                "displayName": "דרכים עירוניות",
                "maxZoom": 22,
                "minZoom": 14,
                "thickness": 8
            },
            "metro": {
                "color": "rgba(30,30,30,0.5)",
                "displayName": " טבעת רדיוס",
                "lazyLoading": true,
                "lineDash": 2,
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1617516948888-transparent.png",
                "strokeColor": "rgba(30,30,30,0.5)",
                "thickness": 2,
                "zIndex": "145"
            },
            "metro_core": {
                "color": "rgba(105,0,0,0.66)",
                "displayName": "מרחב ליבה",
                "lineDash": 0,
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1623348262669-Metro-core2.png",
                "strokeColor": "rgba(152,10,10,0.39)",
                "thickness": 2,
                "zIndex": "157"
            },
            "metro_open_green": {
                "color": "#54AD4D",
                "displayName": "אזור יעוד שטחים פתוחים ",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1622717676466-670.png",
                "strokeColor": "#54ad4d"
            },
            "metro_ring_1_polygon": {
                "color": "#9f2600",
                "displayName": "מטרו פוליגון טבעת ראשונה",
                "lineDash": 1,
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1617516979277-transparent.png",
                "strokeColor": "#9f2600",
                "thickness": 2,
                "zIndex": "155"
            },
            "metro_ring_2_polygon": {
                "color": "#0097FF",
                "displayName": "מטרו פוליגון טבעת שנייה",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1617516985453-transparent.png",
                "strokeColor": "#0097ff",
                "thickness": 7,
                "zIndex": "160"
            },
            "metro_station_poligon": {
                "color": "#ef9347",
                "displayName": "קו מתאר תחנת המטרו",
                "maxZoom": 21,
                "minZoom": 14,
                "strokeColor": "#ef9347",
                "thickness": 2
            },
            "metro_zone_infrastructure": {
                "color": "#969493",
                "displayName": "אזור יעוד תשתית",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1623870473849-210.png",
                "thickness": 4
            },
            "metro_zone_mix_use_center": {
                "color": "#3EA0BA ",
                "displayName": " אזור יעוד מעורב מטרו",
                "lazyLoading": true,
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1617519289286-metro-mix.png",
                "strokeColor": "#c5faff"
            },
            "metro_zone_public": {
                "color": "#D38946",
                "displayName": "אזור יעוד מבני ציבור",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1617519309133-400.png",
                "strokeColor": "#d38946",
                "zIndex": "112"
            },
            "metro_zone_public_green": {
                "color": "#55c533",
                "displayName": "אזור יעוד שצ\"פ",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1622743445417-SHATZAP-METRO.png",
                "strokeColor": "#b2f89f",
                "zIndex": "110"
            },
            "metro_zone_roads": {
                "color": "#949391 ",
                "displayName": "אזור יעוד דרכים",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1617519641961-roads.png",
                "strokeColor": "#bfbdbd",
                "thickness": 1
            },
            "network_densification_zone": {
                "color": "#b68f9f",
                "displayName": "אזור לציפוף רשת",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1623871531321-NETWORK-DENSITY-METRO2.png",
                "strokeColor": "#030000",
                "thickness": 2
            },
            "polygonized_block": {
                "color": "#61d0d9",
                "displayName": "בלוק לתחום השפעה תחנה",
                "lineDash": 1,
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1618989144876-metro-nd.png",
                "strokeColor": "#577f82",
                "thickness": 2
            },
            "reference_rings": {
                "color": "#312F2F",
                "displayName": "טבעת רדיוס",
                "lineDash": 3,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1617519538325-metro-ring2.png",
                "strokeColor": "#f4eaea",
                "thickness": 1
            },
            "search_area for_different_level_crossing": {
                "color": "#7e4aab",
                "displayName": "מרחב חיפוש למעבר ציבורי במפלס שונה",
                "maxZoom": 22,
                "minZoom": 14,
                "strokeColor": "#7e4aab",
                "thickness": 3,
                "zIndex": "170"
            },
            "special_instructions_zone": {
                "color": "rgba(34,34,34,0.83)",
                "displayName": "אזור הנחיות מיוחדות",
                "lineDash": 2,
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1624774780123-210.png",
                "strokeColor": "rgba(145,141,142,0.91)",
                "thickness": 3,
                "zIndex": "171"
            },
            "special_planning_zone": {
                "color": "#97735e",
                "displayName": "תחום תכנון מיוחד",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1623522912956-Metro-brown3.png",
                "strokeColor": "rgba(151,115,94,0.8)",
                "zIndex": "115"
            },
            "transportation_corridor": {
                "color": "#656161",
                "displayName": "רחוב ראשי לתנועה מוטורית",
                "lineDash": 2,
                "maxZoom": 22,
                "minZoom": 14,
                "strokeColor": "#686060",
                "thickness": 5,
                "zIndex": "123"
            },
            "urban_corridor": {
                "color": "#f38901",
                "displayName": "רחוב עירוני ראשי",
                "lineDash": 0,
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1617519626607-metro-roads.png",
                "strokeColor": "#f38901",
                "thickness": 8,
                "zIndex": "120"
            },
            "urban_dev_zone": {
                "color": "#f38989",
                "displayName": "אזור פיתוח",
                "maxZoom": 22,
                "minZoom": 14,
                "strokeColor": "#f38989",
                "zIndex": "108"
            },
            "urban_preservation_zone": {
                "color": "#eeddd4",
                "displayName": "אזור שימור",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1617518560544-metro-ring2.png",
                "strokeColor": "#fbede6",
                "zIndex": "90"
            },
            "urban_renewal_zone": {
                "color": "rgba(116,222,219,0.57)",
                "displayName": "אזור התחדשות",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1622744982546-renual.png",
                "strokeColor": "#f7d677",
                "zIndex": "80"
            },
            "metro_blue_line": {
                "displayName": "קו כחול מרחב מטרו",
                "color": "#033799",
                "strokeColor": "#033799",
                "maxZoom": 22,
                "minZoom": 14,
                "zIndex": "210",
                "entityPlanType": "general"
            },
            "Intercity_corridor": {
                "displayName": "כבישים בינעירוניים",
                "color": "#e019ae",
                "minZoom": 14,
                "thickness": 8,
                "maxZoom": 22
            },
            "plan_boundary": {
                "displayName": "Plan blue line",
                "color": "rgba(55,180,242,0.98)",
                "zIndex": "300",
                "maxZoom": 22,
                "minZoom": 7
            },
            "default": {
                "color": "rgba(202,202,202,0.4)",
                "displayName": "ברירת מחדל השפעה",
                "maxZoom": 22,
                "strokeColor": "#a5a5a5",
                "thickness": 4,
                "minZoom": 12
            },
            "newwww": {
                "displayName": "TRY OR",
                "color": "#9ffcad",
                "strokeColor": "#11ffe0",
                "thickness": 8
            },
            "sun_hour_shade_layout_winter": {
                "displayName": "Sun hour shade layout winter",
                "color": "#ffff",
                "thickness": 4,
                "minZoom": 16,
                "maxZoom": 22,
                "strokeColor": "#4f52d0"
            },
            "sun_hour_shade_layout_summer": {
                "displayName": "Sun hour shade layout summer",
                "color": "#ffff",
                "strokeColor": "#d965ae",
                "thickness": 4,
                "maxZoom": 22,
                "minZoom": 16,
                "opacity": 0.3
            },
            "sun_hour_shade_layout_autumn": {
                "displayName": "Sun hour shade layout autumn",
                "color": "#ffff",
                "strokeColor": "#c265d9",
                "thickness": 4,
                "maxZoom": 22,
                "minZoom": 16,
                "opacity": 0.3
            },
            "sun_hour_shade_layout_spring": {
                "displayName": "Sun hour shade layput spring",
                "color": "#ffff",
                "strokeColor": "#8065d9",
                "thickness": 4,
                "maxZoom": 22,
                "opacity": 0.3,
                "minZoom": 16
            },
            "try_1": {
                "displayName": "Or yamima and liat",
                "color": "#415ca2",
                "thickness": 4,
                "maxZoom": 20,
                "minZoom": 19,
                "strokeColor": "#3135b2",
                "pattern": "1649237340724-mixed_use_2_1255__uqextf.ico"
            },
            "influence": {
                "displayName": "influence",
                "color": "#4b4a49",
                "thickness": 2,
                "maxZoom": 17,
                "minZoom": 15
            }
        }
    },
    "network": {
        "כבישים": [
            {
                "avg": "",
                "code": "street_segement_width",
                "id": 53,
                "increment": 0.5,
                "inputParameter": "רוחב רחוב",
                "landUses": [
                    "road"
                ],
                "max": 150,
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "min": 1,
                "widget": "SLIDER"
            },
            {
                "avg": "",
                "code": "network_segment_name",
                "id": 51,
                "inputParameter": "שם מקטע דרך",
                "landUses": [
                    "road"
                ],
                "max": "",
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "min": "",
                "type": "String",
                "widget": "INPUT_TEXT"
            },
            {
                "avg": "",
                "code": "road_segement_width",
                "id": 52,
                "increment": 0.5,
                "inputParameter": "רוחב כביש",
                "landUses": [
                    "road"
                ],
                "max": 100,
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "min": 1,
                "widget": "SLIDER"
            },
            {
                "avg": "",
                "code": "street_segement_centrality_weight",
                "id": 54,
                "increment": 1,
                "inputParameter": "מרכזיות הדרך והרחוב",
                "landUses": [
                    "road"
                ],
                "max": 5,
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "min": 0,
                "value1": "Path - Pedestrian",
                "value10": "Main street",
                "value2": "Bicycle way",
                "value3": "Way - Service road",
                "value4": "Side street",
                "value5": "Street",
                "value6": "Cul-de-sac",
                "value7": "Dutch street",
                "value8": "avenue",
                "value9": "Boulevard",
                "widget": "SLIDER"
            },
            {
                "avg": "",
                "code": "movement_directions",
                "id": 55,
                "inputParameter": "כיוון נסיעה",
                "landUses": [
                    "road"
                ],
                "max": "",
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "min": "",
                "type": "",
                "values": [
                    "both ways",
                    "Arrow left",
                    "Arrow right"
                ],
                "widget": "DROPDOWN"
            },
            {
                "avg": "",
                "code": "allow_vehicle_ entrance_to_buildings",
                "id": 56,
                "inputParameter": "גישה לרכב לכניסה ויציאה מבניין",
                "landUses": [
                    "road"
                ],
                "max": "",
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "min": "",
                "type": "",
                "widget": "CHECKBOX"
            },
            {
                "avg": "",
                "code": "street_parallel_parking",
                "id": 57,
                "inputParameter": "חנייה במקביל לדרך",
                "landUses": [
                    "road"
                ],
                "max": "",
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "min": "",
                "type": "",
                "values": [
                    "No",
                    "Parallel",
                    "Perpendicular"
                ],
                "widget": "SLIDER"
            },
            {
                "code": "new_road",
                "id": 58,
                "inputParameter": "דרך קיימת",
                "landUses": [
                    "road"
                ],
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "widget": "CHECKBOX"
            },
            {
                "code": "allowed_uses_on _the_road",
                "id": 59,
                "inputParameter": "משתמשים מותרים ברחוב",
                "landUses": [
                    "road"
                ],
                "menuLevel1": "roads",
                "menuLevel2": "roads",
                "type": "List",
                "values": [
                    "Pedestrians",
                    "Cars",
                    "Bicycles",
                    "Buses",
                    "Lightweight trucks",
                    "Heavy trucks"
                ],
                "widget": "MULTISELECT"
            },
            {
                "avg": "",
                "code": "setback_line_from_street_center_line_side_a",
                "id": 60,
                "increment": 0.5,
                "inputParameter": "קו בניין מרחוב צד A",
                "landUses": [
                    "road"
                ],
                "max": 30,
                "menuLevel1": "roads",
                "menuLevel2": "Setback table",
                "min": 0,
                "widget": "SLIDER"
            },
            {
                "avg": "",
                "code": "setback_line_from_street_center_line_side_b",
                "id": 61,
                "increment": 0.5,
                "inputParameter": "קו בניין מרחוב צד B",
                "landUses": [
                    "road"
                ],
                "max": 30,
                "menuLevel1": "roads",
                "menuLevel2": "Setback table",
                "min": 0,
                "widget": "SLIDER"
            },
            {
                "avg": "",
                "code": "street_side_a_building_setback_from_floor",
                "id": 62,
                "increment": 0.5,
                "inputParameter": "נסיגה בבינוי מקומה צדA",
                "landUses": [
                    "road"
                ],
                "max": 30,
                "menuLevel1": "roads",
                "menuLevel2": "Setback table",
                "min": 0,
                "widget": "SLIDER"
            },
            {
                "avg": "",
                "code": "street_side_b_building_setback_from_floor",
                "id": 63,
                "increment": 0.5,
                "inputParameter": "נסיגה בבינוי מקומה צד B",
                "landUses": [
                    "road"
                ],
                "max": 30,
                "menuLevel1": "roads",
                "menuLevel2": "Setback table",
                "min": 0,
                "widget": "SLIDER"
            }
        ],
        "פרמטרים מתקדמים": [
            {
                "avg": "",
                "code": "driveway_parking_type",
                "id": 34,
                "inputParameter": "סוג גישת רכבים למגרשים",
                "landUses": [
                    "Commercial",
                    "Depot",
                    "Office",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "residence"
                ],
                "max": "",
                "menuLevel1": "Advanced",
                "menuLevel2": "Advance",
                "min": "",
                "type": "String",
                "values": "Front street per lot,Front street center to all block,Side street per building,Side street per block,Underground rump front street per lot,Underground rump side street per lot,Underground rump front street per block,Underground rump side street per block",
                "widget": "DROPDOWN"
            }
        ],
        "קווי בניין ומגבלות": [
            {
                "avg": "",
                "code": "building_length",
                "defaultValue": "35",
                "id": 47,
                "increment": 0.5,
                "inputParameter": "הגבלת אורך חזית המבנה",
                "landUses": [
                    "road"
                ],
                "max": 500,
                "menuLevel1": "Setbacks and constrains",
                "menuLevel2": "Setback table",
                "min": 10,
                "widget": "INPUT_NUMBER"
            },
            {
                "avg": "",
                "code": "max_building_height",
                "id": 50,
                "increment": 0.5,
                "inputParameter": "הגבלת גובה מבנה",
                "landUses": [
                    "road"
                ],
                "max": "150",
                "menuLevel1": "Setbacks and constrains",
                "menuLevel2": "Setback table",
                "min": 0,
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "depth_east_side_setback_line",
                "defaultValue": "0",
                "increment": "0.5",
                "inputParameter": "קו בניין צד מזרח",
                "landUses": [
                    "road"
                ],
                "widget": "SLIDER"
            },
            {
                "code": "depth_west_side_setback_line",
                "defaultValue": "0",
                "increment": "0.5",
                "inputParameter": "קו בניין צד מערב",
                "landUses": [
                    "road"
                ],
                "widget": "SLIDER"
            }
        ],
        "landUse": {
            "Pedestrian": {
                "color": "#66B34F",
                "displayName": "דרך הליכה",
                "lineDash": 4,
                "maxZoom": 22,
                "minZoom": 16,
                "thickness": 3
            },
            "Street": {
                "color": "#38588D ",
                "displayName": "רחוב ראשי",
                "lineDash": 0,
                "maxZoom": 22,
                "minZoom": 16,
                "pattern": "1619078778937-metro-roads.png",
                "thickness": 3
            },
            "bicycle": {
                "color": "#ECCF5A",
                "displayName": "שביל אופניים",
                "lineDash": 2,
                "maxZoom": 22,
                "minZoom": 16,
                "thickness": 2
            },
            "boulevard": {
                "color": "#387D23 ",
                "displayName": "שדרה עירונית",
                "lineDash": 1,
                "maxZoom": 22,
                "minZoom": 15,
                "thickness": 1
            },
            "heavy_train": {
                "color": "#88531A",
                "displayName": "רכבת כבדה",
                "maxZoom": 22,
                "minZoom": 15,
                "strokeColor": "#88531a",
                "thickness": 2
            },
            "metro_station_borderline": {
                "color": "#f41919",
                "displayName": "קו הפרדה בין תחנות מטרו",
                "lineDash": 3,
                "maxZoom": 22,
                "minZoom": 14,
                "strokeColor": "#f41919",
                "thickness": 3
            },
            "road": {
                "color": "#75777b",
                "displayName": "דרך נהוגה",
                "lazyLoading": true,
                "lineDash": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619078767358-roads.png",
                "strokeColor": "#a6a6a6",
                "thickness": 1
            },
            "sketch_line": {
                "color": "#fa933e",
                "displayName": "קו מנחה לתכנון",
                "maxZoom": 22,
                "minZoom": 15,
                "strokeColor": "#fa933e",
                "thickness": 2,
                "zIndex": "50"
            },
            "sketch_line_2": {
                "color": "#568e6a",
                "displayName": "קו מנחה לתכנון 2",
                "lineDash": 2,
                "maxZoom": 21,
                "minZoom": 15,
                "strokeColor": "#568e6a",
                "zIndex": "51"
            },
            "default": {
                "displayName": "ברירת מחדל דרכים",
                "color": "rgba(210,209,209,0.54)",
                "strokeColor": "rgba(210,209,209,0.54)",
                "maxZoom": 22,
                "minZoom": 13,
                "thickness": 3
            },
            "Commercial_front": {
                "displayName": "Commercial front",
                "color": "#ae55bb",
                "thickness": 3,
                "zIndex": "1"
            },
            "polyline": {
                "displayName": "polyline",
                "color": "#706f6b",
                "minZoom": 17,
                "maxZoom": 22,
                "thickness": 2
            }
        }
    },
    "parcel": {
        "Current status": [
            {
                "code": "parcel.lot.residential.gross_floor_area.current.int.sum",
                "inputParameter": "שטח בנוי מגורים",
                "landUses": [],
                "valueType": "",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "parcel.lot.retail.gross_floor_area.current.int.sum",
                "inputParameter": "שטח בנוי מסחר",
                "landUses": [],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "parcel.lot.office.gross_floor_area.current.int.sum",
                "inputParameter": "שטח בנוי משרדים",
                "landUses": [],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "parcel.green-public.area.current.float.sum",
                "inputParameter": "שטח ירוק",
                "landUses": [],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "parcel.lot.public.gross_floor_area.current.int.sum",
                "inputParameter": "שטח ציבורי בנוי",
                "landUses": [],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "parcel.area.dunam.sum",
                "inputParameter": "שטח בלוק",
                "landUses": [],
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "parcel.lot.residential.units.current.int.sum",
                "defaultValue": "20",
                "increment": "1",
                "inputParameter": "דירות לפינוי",
                "landUses": [],
                "max": "10000",
                "min": "24",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "parcel.lot.residential.units.area_current.m2.avg",
                "defaultValue": "60",
                "increment": "1",
                "inputParameter": "שטח דירות לפינוי במ\"ר",
                "landUses": [],
                "max": "120",
                "min": "30",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "parcel.lot.residential.units.area_renewal_delta.m2.avg",
                "defaultValue": "25",
                "increment": "1",
                "inputParameter": "תוספת ממוצעת לדירת תמורה במ\"ר",
                "landUses": [],
                "max": "60",
                "min": "0",
                "widget": "INPUT_NUMBER"
            }
        ],
        "Orientation and Setbacks": [
            {
                "code": "lot.setback.front",
                "inputParameter": "Front Setback",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "New_road",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ],
                "max": "40",
                "min": "0",
                "valueType": "",
                "widget": "SLIDER",
                "increment": "0.5",
                "defaultValue": "4"
            },
            {
                "code": "lot.setback.right",
                "widget": "SLIDER",
                "inputParameter": "Right Setback",
                "increment": "0.5",
                "max": "40",
                "min": "0",
                "defaultValue": "6",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "lot.setback.left",
                "widget": "SLIDER",
                "inputParameter": "Left Setback",
                "max": "40",
                "min": "0",
                "increment": "0.5",
                "defaultValue": "6",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "lot.setback.back",
                "widget": "SLIDER",
                "inputParameter": "Rear Setback",
                "increment": "0,5",
                "max": "40",
                "min": "0",
                "defaultValue": "5",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "parcel.landuse_category",
                "widget": "DROPDOWN",
                "landUses": [
                    "Commercial",
                    "New_road",
                    "Office",
                    "Park",
                    "Park_private",
                    "Retail",
                    "mix_use",
                    "park_public",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_C",
                    "road"
                ],
                "defaultValue": "marketable",
                "values": "marketable,green,open,walkway,public building,transport,infrastructure,roads,other",
                "inputParameter": "Land-use category"
            }
        ],
        "Parcel Subdivision": [
            {
                "code": "division.type",
                "widget": "DROPDOWN",
                "inputParameter": "Type of Subdivision",
                "values": "by length and depth,split to 2 pieces,no division",
                "defaultValue": "by length and depth",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "lot.frontage.length.avg",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Lot Average Frontage Length ",
                "max": "200",
                "min": "15",
                "defaultValue": "30",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions"
                ],
                "valueType": "m"
            },
            {
                "code": "lot.depth.length.avg",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Lot Average Depth Length ",
                "max": "200",
                "min": "24",
                "defaultValue": "30",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions"
                ],
                "valueType": "m"
            },
            {
                "code": "lot.area.min",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Lot Minimum Area",
                "max": "1000",
                "min": "100",
                "defaultValue": "200",
                "increment": "0.5",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions"
                ],
                "valueType": "m2"
            },
            {
                "code": "lot.area.max",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Lot Maximum Area",
                "max": "5000",
                "min": "100",
                "increment": "0,5",
                "defaultValue": "3000",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions"
                ],
                "valueType": "m2"
            },
            {
                "code": "split.direction",
                "widget": "DROPDOWN",
                "inputParameter": "Split Direction",
                "values": "Perpendicular to front,Parallel to front,Perpendicular to road",
                "defaultValue": "Perpendicular to front",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "New_road",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "water_body",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "split.pieces.ratio",
                "widget": "SLIDER",
                "inputParameter": "Ratio Between Pieces",
                "values": "Equal (50%),by Percentage,by Specific Area ",
                "defaultValue": "50",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "water_body",
                    "retail_and_commercial"
                ],
                "max": "100",
                "min": "0",
                "increment": "1",
                "valueType": "%"
            },
            {
                "code": "lot.depth.avg",
                "widget": "SLIDER",
                "inputParameter": " Average Row Depth",
                "increment": "0.5",
                "max": "150",
                "min": "10",
                "valueType": "m",
                "defaultValue": "40",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "New_road",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "road",
                    "water_body"
                ]
            },
            {
                "code": "parcel.division.method",
                "widget": "DROPDOWN",
                "inputParameter": "Subdivision Method",
                "values": "Number of Lots,Avg. Area,Avg, Front and Depth,No Division",
                "defaultValue": "Number of Lots",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "New_road",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "road",
                    "water_body",
                    "retail_and_commercial",
                    "building",
                    "lot_boundry",
                    "lot_setback",
                    "lot_front",
                    "parcel_front",
                    "open_area",
                    "parking",
                    "access_road",
                    "pedestrian_path",
                    "default"
                ]
            }
        ],
        "Building Configuration": [
            {
                "code": "parcel.typology",
                "widget": "DROPDOWN",
                "inputParameter": "Building Typology",
                "values": "Random,Block,Row,Tower,Big Box",
                "defaultValue": "Random",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "New_road",
                    "Office",
                    "Open_green_and_Public_building",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "parcel.built_volume_method",
                "widget": "MULTISELECT",
                "inputParameter": "Built Volume Input Method",
                "values": "FAR,gross floor area,unit num+unit size,floor area by usage,by typology",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial",
                    "building"
                ]
            },
            {
                "code": "parcel.far",
                "widget": "SLIDER",
                "inputParameter": "FAR",
                "max": "40",
                "min": "0.5",
                "increment": "0.5",
                "defaultValue": "5",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions"
                ]
            },
            {
                "code": "lot.building_coverege.ratio",
                "widget": "SLIDER",
                "inputParameter": "Building Coverege Ratio",
                "max": "100",
                "min": "5",
                "increment": "1",
                "valueType": "%",
                "defaultValue": "60",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "building.depth.length",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Building Depth Length",
                "max": "60",
                "min": "10",
                "increment": "0.5",
                "defaultValue": "13",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions"
                ],
                "valueType": "m"
            },
            {
                "code": "building.height.max",
                "widget": "SLIDER",
                "inputParameter": "Building Max Height",
                "max": "500",
                "min": "3",
                "increment": "0.5",
                "defaultValue": "70",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ],
                "valueType": "m"
            },
            {
                "code": "building.storey.first.height",
                "widget": "SLIDER",
                "inputParameter": "First Storey Height",
                "max": "20",
                "min": "3",
                "increment": "0.5",
                "valueType": "m",
                "defaultValue": "6",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "building.storey.residential.height",
                "widget": "SLIDER",
                "inputParameter": "Residential Storey Height",
                "max": "20",
                "min": "3",
                "increment": "0.5",
                "valueType": "m",
                "defaultValue": "3.5",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "New_road",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "building.storey.office.height",
                "widget": "SLIDER",
                "inputParameter": "Commerce/Office Storey Height",
                "max": "20",
                "min": "3",
                "increment": "0.5",
                "valueType": "m",
                "defaultValue": "4.5",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "water_body",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "building.frontage.length",
                "widget": "SLIDER",
                "inputParameter": "Buillding Frontage Length",
                "max": "200",
                "min": "10",
                "increment": "1",
                "valueType": "m",
                "defaultValue": "30",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "parcel.storey.num.max",
                "widget": "SLIDER",
                "inputParameter": "Max Num of Storeis"
            }
        ],
        "Built Floor Areas": [
            {
                "code": "parcel.floor_area.commercial.gross",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Commerce - Gross floor Area",
                "max": "300000",
                "min": "0",
                "increment": "1",
                "valueType": "M2",
                "defaultValue": "0",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "parcel.floor_area.office.gross",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Office - Gross floor Area",
                "max": "300000",
                "min": "0",
                "increment": "1",
                "valueType": "M2",
                "defaultValue": "0",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "parcel.floor_area.other.gross",
                "widget": "SLIDER",
                "inputParameter": "Other - Gross floor Area",
                "max": "300000",
                "min": "0",
                "increment": "1",
                "valueType": "M2",
                "defaultValue": "0",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "parcel.unit.floor_area.avg",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Avg. Unit Area",
                "max": "500",
                "min": "0",
                "increment": "1",
                "valueType": "M2",
                "defaultValue": "100",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "parcel.unit.num",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Number of Units",
                "max": "20000",
                "min": "0",
                "increment": "1",
                "defaultValue": "0",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "retail_and_commercial"
                ]
            },
            {
                "code": "parcel.floor_area.residential.gross",
                "widget": "INPUT_NUMBER",
                "inputParameter": "Residential - Gross floor Area",
                "max": "300000",
                "min": "0",
                "increment": "1",
                "valueType": "M2",
                "defaultValue": "0",
                "landUses": [
                    "Commercial",
                    "Mix_use_res_comm_public",
                    "Mix_use_res_retail_public",
                    "Office",
                    "Open_green_and_Public_building",
                    "Park",
                    "Park_private",
                    "Retail",
                    "Special_Housing",
                    "Workshops",
                    "mix_use",
                    "park_public",
                    "pinui_binui",
                    "preservation_block",
                    "public",
                    "residence",
                    "residence_A",
                    "residence_B",
                    "residence_C",
                    "residence_D",
                    "residential_and_public_buildings_and_Institutions",
                    "water_body",
                    "retail_and_commercial"
                ]
            }
        ],
        "Landuse category": [],
        "A": null,
        "test": [
            {
                "code": "A",
                "widget": "SLIDER",
                "inputParameter": "A"
            }
        ],
        "landUse": {
            "Commercial": {
                "color": "#f44df8",
                "displayName": "Commercial",
                "lineDash": 0,
                "mavatCode": "200",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1626078149841-Commerce-DEMO.png",
                "thickness": 1,
                "strokeColor": "#171414"
            },
            "Mix_use_res_comm_public": {
                "color": "#f79090",
                "displayName": "Residential, Commercial & Public Buildings And Institutions ",
                "entityPlanType": "urban",
                "mavatCode": "1480",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619014595808-1480.png",
                "strokeColor": "#0f0d0d"
            },
            "Mix_use_res_retail_public": {
                "color": "#ccaa08",
                "displayName": "Residential Retail & Public Buildings And Institutions",
                "mavatCode": "1410",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619080554929-1410.png",
                "strokeColor": "#111010"
            },
            "New_road": {
                "color": "#ff471c",
                "displayName": "New road",
                "mavatCode": "830",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1618755188936-830.png",
                "strokeColor": "#0b0a0a"
            },
            "Office": {
                "color": "#BD65FC",
                "displayName": "Office",
                "lazyLoading": false,
                "lineDash": 0,
                "mavatCode": "220",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1639403521913-office2.png",
                "strokeColor": "#070505",
                "thickness": 1
            },
            "Open_green_and_Public_building": {
                "color": "#c8f462",
                "displayName": "Nature & Public buildings",
                "mavatCode": "1670",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619079744802-1670.png",
                "strokeColor": "#050404"
            },
            "Park": {
                "color": "#adfb7b",
                "displayName": "Public Park",
                "mavatCode": "700",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1618754962445-700.png",
                "strokeColor": "#090808"
            },
            "Park_private": {
                "color": "#27b96f",
                "displayName": "Park private",
                "lineDash": 0,
                "mavatCode": "680",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1618754874211-670.png",
                "strokeColor": "#0f110f",
                "thickness": 1
            },
            "Retail": {
                "color": "#a29d9d",
                "displayName": "Retail",
                "mavatCode": "210",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619078736101-210.png",
                "strokeColor": "#050404"
            },
            "Special_Housing": {
                "color": "#ffde52",
                "displayName": "Special Housing",
                "mavatCode": "150",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619035118681-150.png",
                "strokeColor": "#070606"
            },
            "Workshops": {
                "color": "#E3A1E5",
                "displayName": "Workshops",
                "lineDash": 0,
                "mavatCode": "260",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "260_1_102102102.png",
                "strokeColor": "#090809"
            },
            "mix_use": {
                "color": "#FD458F",
                "displayName": "Mix Use",
                "lineDash": 0,
                "mavatCode": "1050",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1618989046647-600.png",
                "strokeColor": "#0f0c0c"
            },
            "park_public": {
                "color": "#ADDD00",
                "displayName": "Green public",
                "lineDash": 0,
                "mavatCode": "670",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1626078106587-GREEN-DEMO.png",
                "strokeColor": "#090707"
            },
            "pinui_binui": {
                "color": "yellow",
                "displayName": "Renawal Residential",
                "lineDash": 0,
                "mavatCode": "",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "10.png",
                "strokeColor": "#030202"
            },
            "preservation_block": {
                "color": "#f6d9bd",
                "displayName": "Preservation Zone",
                "mavatCode": "",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619022015284-metro-ring2.png",
                "strokeColor": "#050404"
            },
            "public": {
                "color": "#d28f4d",
                "displayName": "Public",
                "lineDash": 0,
                "mavatCode": "400",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "ROAD.png",
                "strokeColor": "#070606"
            },
            "residence": {
                "color": "#FFDF7E",
                "displayName": "Residential",
                "lazyLoading": false,
                "lineDash": 0,
                "mavatCode": "10",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1617648600957-10.png",
                "strokeColor": "#090707"
            },
            "residence_A": {
                "color": "#fdf600",
                "displayName": "Residential A",
                "mavatCode": "20",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619033323326-20.png",
                "strokeColor": "#0d0b0b"
            },
            "residence_B": {
                "color": "#ffbd4f",
                "displayName": "Residential B",
                "mavatCode": "60",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619033366928-60.png",
                "strokeColor": "#110e0e"
            },
            "residence_C": {
                "color": "#ef8354",
                "displayName": "Residential C",
                "mavatCode": "100",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619033453298-100.png",
                "strokeColor": "#090808"
            },
            "residence_D": {
                "color": "#ff9200",
                "displayName": "Residential D",
                "mavatCode": "140",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619033420097-140_1_165000000.png",
                "strokeColor": "#0b0909"
            },
            "residential_and_public_buildings_and_Institutions": {
                "color": "#e6c883",
                "displayName": "Residential & Public Buildings And Institutions",
                "mavatCode": "1250",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1625921525026-1300.png",
                "strokeColor": "#0f0d0d"
            },
            "road": {
                "color": "#b4abab",
                "displayName": "Road",
                "lineDash": 0,
                "mavatCode": "820",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1618755020806-820.png",
                "strokeColor": "#0f0c0c"
            },
            "water_body": {
                "displayName": "Stream / Canal / Water Reservoir",
                "color": "#74e6e5",
                "pattern": "1636620566278-740.png",
                "maxZoom": 22,
                "minZoom": 15,
                "mavatCode": "740",
                "strokeColor": "#0d0c0c"
            },
            "retail_and_commercial": {
                "displayName": "Retail & Commercial ",
                "color": "#e61cff",
                "pattern": "1636621344169-1502.png",
                "maxZoom": 22,
                "minZoom": 15,
                "strokeColor": "#070606"
            },
            "building": {
                "displayName": "Building",
                "color": "#eae1e1",
                "thickness": 1,
                "maxZoom": 22,
                "minZoom": 16,
                "opacity": 0.85,
                "pattern": "1638217559752-building.png",
                "zIndex": "350",
                "strokeColor": "#050405"
            },
            "lot_boundry": {
                "displayName": "Lot Boundry",
                "color": "#6482f1",
                "thickness": 2,
                "maxZoom": 22,
                "minZoom": 16,
                "zIndex": "307"
            },
            "lot_setback": {
                "displayName": "Lot Setback",
                "color": "#949fff",
                "lineDash": 1,
                "thickness": 3,
                "maxZoom": 22,
                "minZoom": 16,
                "zIndex": "308"
            },
            "lot_front": {
                "displayName": "Lot Front Segment",
                "color": "#ff10b2",
                "lineDash": 1,
                "thickness": 4,
                "maxZoom": 22,
                "minZoom": 16,
                "opacity": 0.2,
                "zIndex": "310"
            },
            "parcel_front": {
                "displayName": "Parcel Front Segment",
                "color": "#8d01ff",
                "thickness": 4,
                "lineDash": 1,
                "minZoom": 16,
                "maxZoom": 22,
                "opacity": 0.2,
                "zIndex": "309"
            },
            "open_area": {
                "displayName": "Open Area",
                "color": "#9dd968",
                "maxZoom": 22,
                "minZoom": 16,
                "zIndex": "301",
                "strokeColor": "#110e0e"
            },
            "parking": {
                "displayName": "Parking",
                "color": "#ffff",
                "maxZoom": 22,
                "minZoom": 16,
                "zIndex": "302",
                "strokeColor": "#0d0d0d"
            },
            "access_road": {
                "displayName": "Access Road",
                "color": "#a0a0a0",
                "lineDash": 0,
                "minZoom": 16,
                "maxZoom": 22,
                "zIndex": "303"
            },
            "pedestrian_path": {
                "displayName": "Pedestrin Path",
                "color": "#e7e2e2",
                "maxZoom": 22,
                "minZoom": 16,
                "zIndex": "340"
            },
            "default": {
                "displayName": "Default parcel",
                "color": "#e2e1e6",
                "strokeColor": "#0b0a0a",
                "thickness": 2,
                "maxZoom": 22,
                "minZoom": 13,
                "opacity": 0.75
            },
            "Urban Square": {
                "displayName": "Urban Square",
                "color": "#e0ba8b",
                "minZoom": 14,
                "maxZoom": 22,
                "pattern": "1647423006450-780.png",
                "mavatCode": "780",
                "strokeColor": "#030303"
            },
            "Commercial & Public Buildings And Institutions": {
                "displayName": "Commercial & Public Buildings And Institutions  ",
                "color": "#bb9ae4",
                "minZoom": 15,
                "maxZoom": 22,
                "mavatCode": "1600",
                "pattern": "1647423557707-1576.png"
            },
            "demo_ny": {
                "displayName": "Demo NY",
                "color": "rgba(117,53,164,0.53)",
                "thickness": 2,
                "maxZoom": 22,
                "minZoom": 14,
                "lineDash": 1
            },
            "lot": {
                "displayName": "lot",
                "color": "#ffb33f",
                "minZoom": 14,
                "maxZoom": 22,
                "zIndex": "1",
                "strokeColor": "#706f6b",
                "opacity": 0.2
            },
            "public_lot": {
                "displayName": "public lot",
                "color": "#ae8060",
                "minZoom": 14,
                "maxZoom": 22,
                "zIndex": "1",
                "strokeColor": "#706f6b",
                "opacity": 0.2
            },
            "road_new": {
                "displayName": "road",
                "color": "#e95d59",
                "minZoom": 14,
                "maxZoom": 22,
                "zIndex": "1",
                "strokeColor": "#706f6b",
                "thickness": 1,
                "opacity": 0.2
            },
            "open_area_new": {
                "displayName": "open area",
                "color": "#5ea75f",
                "zIndex": "1",
                "opacity": 0.2,
                "strokeColor": "#706f6b",
                "minZoom": 14,
                "maxZoom": 22
            },
            "green": {
                "displayName": "green",
                "color": "#9bd647",
                "strokeColor": "#706f6b",
                "maxZoom": 22,
                "minZoom": 14,
                "zIndex": "1",
                "opacity": 0.7
            },
            "infrastructure": {
                "displayName": "infrastructure",
                "color": "#b5838d",
                "thickness": 1,
                "minZoom": 14,
                "maxZoom": 22,
                "opacity": 0.8,
                "strokeColor": "#706f6b",
                "zIndex": "1"
            },
            "water_body_new": {
                "displayName": "water body",
                "color": "#15a3c2",
                "strokeColor": "#706f6b",
                "minZoom": 14,
                "maxZoom": 22,
                "opacity": 0.2,
                "zIndex": "2"
            },
            "polygon": {
                "displayName": "polygon",
                "color": "#ffff",
                "strokeColor": "#080808",
                "minZoom": 14,
                "maxZoom": 22,
                "mavatCode": "",
                "zIndex": "1",
                "thickness": 2
            },
            "marketable lot": {
                "displayName": "marketable lot",
                "color": "#ffb33f",
                "strokeColor": "#090909"
            },
            "anat_test": {
                "displayName": "anat_test",
                "color": "#aa5c5c",
                "thickness": 5,
                "lineDash": 1,
                "strokeColor": "#4c0000",
                "minZoom": 17,
                "maxZoom": 22
            },
            "open area": {
                "displayName": "open area",
                "color": "#5ea75f",
                "maxZoom": 20,
                "minZoom": 14,
                "thickness": 1,
                "strokeColor": "#121111"
            },
            "right of road": {
                "displayName": "right of road",
                "color": "#e95d59",
                "thickness": 1,
                "strokeColor": "#080707",
                "minZoom": 5,
                "maxZoom": 22
            },
            "public lot": {
                "displayName": "public lot",
                "color": "#ae8060",
                "strokeColor": "#060505",
                "maxZoom": 22,
                "minZoom": 2
            },
            "water body": {
                "displayName": "water body",
                "color": "#15a3c2",
                "strokeColor": "#080707",
                "opacity": 0.7,
                "maxZoom": 21,
                "minZoom": 18
            },
            "public building lot": {
                "displayName": "public lot",
                "color": "#ae8060",
                "strokeColor": "#040404"
            }
        }
    },
    "poi": {
        "הערות ": [
            {
                "code": "comments",
                "inputParameter": "הערות לתכנון",
                "landUses": [
                    "comments"
                ],
                "widget": "INPUT_TEXT"
            }
        ],
        "הפרשות לצרכי ציבור": [
            {
                "code": "public_open_space_area_ratio",
                "defaultValue": "15",
                "increment": "1",
                "inputParameter": "שצפ שכונתי כאחוז משטח התכנית - מגרשים יעודיים",
                "landUses": [
                    "metro_station"
                ],
                "max": "30",
                "min": "10",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "public_buildings_area_dunam",
                "defaultValue": "50",
                "increment": "1",
                "inputParameter": "מגרשים למבני ציבור",
                "landUses": [
                    "metro_station"
                ],
                "max": "1000",
                "min": "100",
                "valueType": "D",
                "widget": "SLIDER"
            },
            {
                "code": "street_network_area_ratio",
                "defaultValue": "20",
                "increment": "1",
                "inputParameter": "אחוז הפרשה לדרכים",
                "landUses": [
                    "metro_station"
                ],
                "max": "30",
                "min": "10",
                "valueType": "%",
                "widget": "SLIDER"
            }
        ],
        "מטרו: מאפייני טבעת ראשונה": [
            {
                "code": "metro_influence_radius_ring1",
                "defaultValue": "300",
                "increment": "10",
                "inputParameter": "רדיוס טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "1000",
                "min": "50",
                "valueType": "m",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "metro_far_ring1",
                "defaultValue": "4",
                "increment": "0.01",
                "inputParameter": "קיבולת מוצעת (רח\"ק) טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "40",
                "min": "0",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "main_street_influence_ring1",
                "defaultValue": "100",
                "increment": "10",
                "inputParameter": "השפעת רחוב ראשי טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "500",
                "min": "0",
                "valueType": "m",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "main_street_influence_offset_ring1",
                "defaultValue": "50",
                "increment": "10",
                "inputParameter": "רוחב תוספת לכביש ראשי טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "300",
                "min": "0",
                "valueType": "m",
                "widget": "SLIDER"
            },
            {
                "code": "unit_avg_area_m2_ring1",
                "defaultValue": "100",
                "increment": "5",
                "inputParameter": "גודל יח\"ד ממוצעת כולל שירות, כולל יח\"ד קטנות  טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "300",
                "min": "30",
                "valueType": "m2",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_res_ratio_ring1",
                "defaultValue": "35",
                "increment": "5",
                "inputParameter": "תמהיל אזור יעוד מגורים טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_comm_ratio_ring1",
                "defaultValue": "35",
                "increment": "5",
                "inputParameter": "תמהיל אזור יעוד תעסוקה טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_retial_ratio_ring1",
                "defaultValue": "20",
                "increment": "5",
                "inputParameter": "תמהיל אזור יעוד מסחר טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_other_ratio_ring1",
                "defaultValue": "10",
                "increment": "5",
                "inputParameter": "תמהיל אזור יעוד אחר טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_type_ring1",
                "inputParameter": "תמהיל עירוב שימושים טבעת ראשונה",
                "landUses": [
                    "metro_station"
                ],
                "values": "מוטה מגורים\nמוטה תעסוקה\nמעורב\nעירוני מעורב",
                "widget": "DROPDOWN"
            }
        ],
        "מטרו: מאפייני טבעת שניה": [
            {
                "code": "metro_influence_radius_ring2",
                "defaultValue": "600",
                "increment": "10",
                "inputParameter": "רדיוס טבעת שניה",
                "landUses": [
                    "metro_station"
                ],
                "max": "1000",
                "min": "50",
                "valueType": "m",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "metro_far_ring2",
                "defaultValue": "3",
                "inputParameter": "קיבולת מוצעת (רח\"ק) טבעת שנייה",
                "landUses": [
                    "metro_station"
                ],
                "max": "40",
                "min": "0",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "main_street_influence_ring2",
                "defaultValue": "100",
                "increment": "50",
                "inputParameter": "השפעת רחוב ראשי טבעת שניה",
                "landUses": [
                    "metro_station"
                ],
                "max": "500",
                "min": "0",
                "valueType": "m",
                "widget": "SLIDER"
            },
            {
                "code": "main_street_influence_offset_ring2",
                "defaultValue": "50",
                "increment": "10",
                "inputParameter": "רוחב תוספת לכביש ראשי טבעת שניה",
                "landUses": [
                    "metro_station"
                ],
                "max": "300",
                "min": "30",
                "valueType": "m",
                "widget": "SLIDER"
            },
            {
                "code": "unit_avg_area_m2_ring_2",
                "defaultValue": "100",
                "increment": "5",
                "inputParameter": "גודל יח\"ד ממוצעת כולל שירות, כולל יח\"ד קטנות  טבעת שניה",
                "landUses": [
                    "metro_station"
                ],
                "max": "300",
                "min": "30",
                "valueType": "m2",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_res_ratio_ring2",
                "defaultValue": "35",
                "increment": "5",
                "inputParameter": "תמהיל אזור יעוד מגורים טבעת שנייה",
                "landUses": [
                    "metro_station"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_comm_ratio_ring2",
                "defaultValue": "35",
                "increment": "5",
                "inputParameter": "תמהיל אזור יעוד תעסוקה טבעת שנייה",
                "landUses": [
                    "metro_station"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_retial_ratio_ring2",
                "defaultValue": "20",
                "increment": "5",
                "inputParameter": "תמהיל אזור יעוד מסחר טבעת שנייה",
                "landUses": [
                    "metro_station"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_other_ratio_ring2",
                "defaultValue": "10",
                "increment": "5",
                "inputParameter": "תמהיל אזור יעוד אחר טבעת שנייה",
                "landUses": [
                    "metro_station"
                ],
                "max": "100",
                "min": "0",
                "valueType": "%",
                "widget": "SLIDER"
            },
            {
                "code": "metro_zone_mix_use_center_type_ring2",
                "inputParameter": "תמהיל עירוב שימושים טבעת שנייה",
                "values": "מוטה מגורים\nמוטה תעסוקה\nמעורב\nעירוני מעורב",
                "widget": "DROPDOWN"
            }
        ],
        "תמהיל על פי אזורי יעוד": [],
        "Smart card tree": [],
        "landUse": {
            "3dtest": {
                "color": "#cd2323",
                "displayName": "3dtest",
                "pattern": "1623357409019-scene.gltf",
                "thickness": 5,
                "zIndex": "150",
                "minZoom": 22,
                "maxZoom": 22
            },
            "Train_station": {
                "color": "#ffff",
                "displayName": "תחנת רכבת",
                "maxZoom": 22,
                "minZoom": 16,
                "pattern": "1613465465094-rail-15.svg"
            },
            "comments": {
                "color": "#ffff",
                "displayName": "הערות צוות תכנון",
                "maxZoom": 22,
                "minZoom": 16,
                "pattern": "1611500146631-asd.png"
            },
            "landmark": {
                "color": "#49A8FC",
                "displayName": "מוקד עירוני",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "1658130935265-POI test.png"
            },
            "metro_station": {
                "color": "#ffff",
                "displayName": "תחנת מטרו",
                "maxZoom": 22,
                "minZoom": 12,
                "pattern": "1620024339135-metro_st_new3.png"
            },
            "metro_station_entrance": {
                "color": "#ffff",
                "displayName": "כניסה לתחנת מטרו",
                "maxZoom": 22,
                "minZoom": 16,
                "pattern": "1620024351652-metro_entrance_new2.png"
            },
            "park_entrance": {
                "color": "#ffff",
                "displayName": "מוקד נופי",
                "maxZoom": 10,
                "minZoom": 16,
                "pattern": "1623911636131-train_circle_green.png",
                "thickness": 3
            },
            "intersection": {
                "displayName": "תחנת ממשק",
                "color": "#ffff",
                "maxZoom": 22,
                "thickness": 3,
                "minZoom": 14,
                "opacity": 0.1,
                "pattern": "1629720791287-train_circle_red.png"
            },
            "default": {
                "displayName": "Default POI",
                "color": "#d4d1d1",
                "pattern": "1658238012549-poi.svg",
                "strokeColor": "#c7c3c3"
            },
            "tree1": {
                "displayName": "Tree Type One",
                "color": "#ffff",
                "pattern": "1655578072943-treetypefuck.png",
                "minZoom": 16,
                "maxZoom": 22
            },
            "POI": {
                "displayName": "POI",
                "color": "#ffff",
                "pattern": "1658844503585-POI test.png"
            }
        }
    }
}


export const NEW_MENU_DATA = {
    "default": {
        "landUse": {
            "default_polygon": {
                "color": "#e2e1e6",
                "displayName": "Default Polygon",
                "lazyLoading": false,
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "default_polygon.png",
                "strokeColor": "#090707",
                "opacity": 0.85
            },
            "default_polyline": {
                "color": "#2c2c2c",
                "displayName": "Default Polyline",
                "lazyLoading": false,
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1617648600957-10.png",
                "thickness": 2,
                "strokeColor": "#090707",
                "opacity": 0.85 
            },
            "default_point": {
                "color": "#e2e1e6",
                "displayName": "Default Point",
                "lazyLoading": false,
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "default_point.png",
                "strokeColor": "#090707",
                "opacity": 0.85 
            }
        }
    },
    "parcel": {
        "landUse": {
            "residential": {
                "color": "#FFDF7E",
                "displayName": "Residential",
                "lazyLoading": false,
                "dashArray": 0,
                "mavatCode": "10",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "residential.png",
                "strokeColor": "#090707",
                "opacity": 0.7,
                "subCategory": "private"
            },
            "retail": {
                "color": "#f44df8",
                "displayName": "Retail",
                "dashArray": 0,
                "mavatCode": "200",
                "maxZoom": 22,
                "minZoom": 14,
                "pattern": "retail_landUse.png",
                "thickness": 1,
                "opacity": 0.7,
                "strokeColor": "#171414",
                "subCategory": "private"
            },
            "office": {
                "color": "#b961f8",
                "displayName": "Office",
                "lazyLoading": false,
                "dashArray": 0,
                "mavatCode": "220",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "office.png",
                "strokeColor": "#070505",
                "thickness": 1,
                "opacity": 1,
                "subCategory": "private"
            },
            "hotel": {
                "color": "#ef8354",
                "displayName": "Hotel",
                "mavatCode": "100",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619033453298-100.png",
                "strokeColor": "#090808",
                "subCategory": "private"
            },
            "mix_use": {
                "color": "#FD458F",
                "displayName": "Mix-Use",
                "dashArray": 0,
                "mavatCode": "1050",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "mix_use.png",
                "strokeColor": "#0f0c0c",
                "opacity": 0.7,
                "subCategory": "private"
            },
            "green_area": {
                "displayName": "Green Area",
                "color": "#5baf6b",
                "strokeColor": "#706f6b",
                "maxZoom": 22,
                "minZoom": 14,
                "zIndex": "1",
                "opacity": 0.7,
                "pattern": "green_area.png",
                "subCategory": "private"
            },
            "industiral": {
                "color": "#E3A1E5",
                "displayName": "Industiral",
                "dashArray": 0,
                "mavatCode": "260",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "260_1_102102102.png",
                "strokeColor": "#090809",
                "opacity": 0.7,
                "subCategory": "private"
            },
            "agriculture": {
                "displayName": "Agriculture",
                "color": "#5ea75f",
                "zIndex": "1",
                "opacity": 0.2,
                "strokeColor": "#706f6b",
                "minZoom": 14,
                "maxZoom": 22,
                "subCategory": "private"
            },
            "other_private": {
                "displayName": "Other-Private",
                "color": "#aa5c5c",
                "thickness": 5,
                "dashArray": 1,
                "strokeColor": "#4c0000",
                "minZoom": 17,
                "maxZoom": 22,
                "opacity": 0.7,
                "subCategory": "private"
            },
            "public_buildings": {
                "color": "#ae8060",
                "displayName": "Public Buildings",
                "dashArray": 0,
                "mavatCode": "400",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "public_landUse.png",
                "strokeColor": "#070606",
                "opacity": 0.7,
                "subCategory": "public"
            },
            "park": {
                "color": "#ADDD00",
                "displayName": "Park",
                "mavatCode": "700",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "park.png",
                "strokeColor": "#090808",
                "opacity": 0.7,
                "subCategory": "public"
            },
            "road_new": {
                "color": "#e95d59",
                "displayName": "Road-new",
                "mavatCode": "830",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "road_new.png",
                "strokeColor": "#0b0a0a",
                "opacity": 0.7,
                "subCategory": "public"
            },
            "road_existing": {
                "color": "#ff471c",
                "displayName": "Road-Existing",
                "mavatCode": "830",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1618755188936-830.png",
                "strokeColor": "#0b0a0a",
                "opacity": 0.7,
                "subCategory": "public"
            },
            "pedestrian_path": {
                "color": "#ff471c",
                "displayName": "Pedestrian Path",
                "mavatCode": "830",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1618755188936-830.png",
                "strokeColor": "#0b0a0a",
                "opacity": 0.7,
                "subCategory": "public"
            },
            "infrastructure": {
                "displayName": "infrastructure",
                "color": "#b5838d",
                "thickness": 1,
                "minZoom": 14,
                "maxZoom": 22,
                "opacity": 0.8,
                "strokeColor": "#706f6b",
                "zIndex": "1",
                "subCategory": "public"
            },
            "water": {
                "displayName": "Water",
                "color": "#64becc",
                "pattern": "water.png",
                "maxZoom": 22,
                "minZoom": 15,
                "mavatCode": "740",
                "strokeColor": "#0d0c0c",
                "opacity": 0.7,
                "subCategory": "public"
            },
            "other_public": {
                "color": "#ff471c",
                "displayName": "Other-Public",
                "mavatCode": "830",
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1618755188936-830.png",
                "strokeColor": "#0b0a0a",
                "opacity": 0.7,
                "subCategory": "public"
            },
            "default": {
                "color": "#FFDF7E",
                "displayName": "Default Parcel",
                "lazyLoading": false,
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1617648600957-10.png",
                "strokeColor": "#090707",
                "opacity": 0.7,
                "subCategory": "public"
            }
        }
    },
    "building": {
        "landUse": {
            "building": {
                "color": "#eae1e1",
                "displayName": "Building",
                "lazyLoading": false,
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "building.png",
                "strokeColor": "#090707",
                "opacity": 0.9
            },
            "floor": {
                "color": "#eae1e1",
                "displayName": "Floor",
                "lazyLoading": false,
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1617648600957-10.png",
                "strokeColor": "#090707",
                "opacity": 0.9
            }
        },
        "usage": {
                "residential": {
                    "color": "#FFDF7E",
                    "displayName": "Residential",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "residential.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "main"
                },
                "office": {
                    "color": "#b961f8",
                    "displayName": "Office",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "office.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "main"
                },
                "retail": {
                    "color": "#5ac1e2",
                    "displayName": "Retail",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "retail.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "main"
                },
                "public": {
                    "color": "#c78f69",
                    "displayName": "Public",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "public.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "main"
                },
                "hotel": {
                    "color": "#e85b94",
                    "displayName": "Hotel",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "hotel.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "main"
                },
                "other": {
                    "color": "#8c898a",
                    "displayName": "Other",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "other.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "main"
                },
                "core": {
                    "color": "#FFDF7E",
                    "displayName": "Core",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "1617648600957-10.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "service"
                },
                "lobby": {
                    "color": "#FFDF7E",
                    "displayName": "Lobby",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "1617648600957-10.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "service"
                },
                "technical": {
                    "color": "#FFDF7E",
                    "displayName": "Technical",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "1617648600957-10.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "service"
                },
                "new_unit": {
                    "color": "#FFDF7E",
                    "displayName": "New Unit",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "1617648600957-10.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "unit"
                },
                "renewal_unit": {
                    "color": "#FFDF7E",
                    "displayName": "Renewal Unit",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "1617648600957-10.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "unit"
                },
                "affordable_rent": {
                    "color": "#FFDF7E",
                    "displayName": "Affordable Rent",
                    "lazyLoading": false,
                    "dashArray": 0,
                    "maxZoom": 22,
                    "minZoom": 15,
                    "pattern": "1617648600957-10.png",
                    "strokeColor": "#090707",
                    "opacity": 0.85,
                    "subCategory": "unit"
                }
            }
    },
    "network": {
        "RoadParameters": [ 
            {
                "code": "road.width",
                "inputParameter": "CARDROADPARAM.WIDTH",
                "landUses": [
                    "road",
                    "pedestrian_path",
                    "bicycle_lane"
                ],
                "defaultValue": "18",
                "max": "",
                "min": "0",
                "widget": "INPUT_NUMBER"
            }, 
            {
                "code": "road.lanes.num",
                "inputParameter": "CARDROADPARAM.LANES",
                "landUses": [
                    "road",
                    "pedestrian_path",
                    "bicycle_lane"
                ],
                "defaultValue": "2",
                "max": "",
                "min": "1",
                "widget": "INPUT_NUMBER"
            },
            {
                "code": "road.type",
                "inputParameter": "CARDROADPARAM.ROADTYPE",
                "landUses": [
                    "road",
                    "pedestrian_path",
                    "bicycle_lane"
                ],
                "defaultValue": "basic arterial road",
                "values": "local street - mild, local street - moderate, rearguard street, basic arterial road, multilane arterial road",
                "widget": "DROPDOWN"
            }
        ],
        "landUse": {
            "road": {
                "color": "#db0000",
                "displayName": "Road-New",
                "lazyLoading": true,
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619078767358-roads.png",
                "strokeColor": "#c60000",
                "thickness": 4,
                "opacity": 0.9,
                "subCategory": "road"
            },
            "pedestrian_path": {
                "color": "#00a31b",
                "displayName": "Pedestrian Path-New",
                "lazyLoading": true,
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 15,
                "pattern": "1619078767358-roads.png",
                "strokeColor": "#00a33a",
                "thickness": 4,
                "opacity": 0.9,
                "subCategory": "road"
            },
            "bicycle_lane": {
                "color": "#70b900",
                "displayName": "Bicycle Lane",
                "dashArray": 1,
                "maxZoom": 22,
                "minZoom": 16,
                "thickness": 4,
                "opacity": 0.9,
                "subCategory": "road"
            }
        }
    },
    "junction":{
        "landUse": {
            "junction_roads": {
                "color": "#88531A",
                "displayName": "Junction-Roads",
                "maxZoom": 22,
                "minZoom": 15,
                "strokeColor": "#88531a",
                "thickness": 2,
                "subCategory": "Junction",
                "pattern": "junction_roads.png"
            },
            "junction_road_and_path": {
                "color": "#88531A",
                "displayName": "Junction-Road and Path",
                "maxZoom": 22,
                "minZoom": 15,
                "strokeColor": "#88531a",
                "thickness": 2,
                "subCategory": "Junction",
                "pattern": "junction_roads_and_paths.png"
            },
            "junction_paths": {
                "color": "#88531A",
                "displayName": "Junction-Paths",
                "maxZoom": 22,
                "minZoom": 15,
                "strokeColor": "#88531a",
                "thickness": 2,
                "subCategory": "Junction",
                "pattern": "junction_paths.png"
            }
        }
    },
    "poi": {
        "landUse": {
            "train_station": {
                "color": "#ffff",
                "displayName": "Train Station",
                "maxZoom": 22,
                "minZoom": 16,
                "pattern": "train_station.png"
            },
            "tree": {
                "color": "#ffff",
                "displayName": "Tree",
                "maxZoom": 22,
                "minZoom": 16,
                "pattern": "tree.png"
            },
            "metro_station": {
                "color": "#ffff",
                "displayName": "Metro Station",
                "maxZoom": 22,
                "minZoom": 12,
                "pattern": "metro_station.png"
            },
            "bus_station": {
                "color": "#ffff",
                "displayName": "Bus Station",
                "maxZoom": 22,
                "minZoom": 12,
                "pattern": "bus_station.png"
            },
            "light_train_station": {
                "color": "#ffff",
                "displayName": "Light Train Station",
                "maxZoom": 22,
                "minZoom": 12,
                "pattern": "light_train_station.png"
            },
            "amenity": {
                "color": "#ffff",
                "displayName": "Light Train Station",
                "maxZoom": 22,
                "minZoom": 12,
                "pattern": "amenity.png"
            },
            "comments": {
                "color": "#ffff",
                "displayName": "Comments",
                "maxZoom": 22,
                "minZoom": 16,
                "pattern": "1611500146631-asd.png"
            }
        }
    },
    "guideline_elements": {
        "NormativeAreaParameters": [ 
            {
                "code": "normative_area.topographic_cluster",
                "inputParameter": "CARDNORMATIVEPARAM.TOPOGRAPHICCLUSTER",
                "landUses": [
                    "normative_area"
                ],
                "defaultValue": "3",
                "max": "10",
                "min": "0",
                "widget": "INPUT_NUMBER"
            }
        ],
        "landUse": {
            "lot_line": {
                "color": "#ffffff",
                "displayName": "Lot Line",
                "lazyLoading": false,
                "dashArray": 2,
                "maxZoom": 22,
                "minZoom": 15,
                "strokeColor": "#ff0000",
                "opacity": 0.01,
                "thickness": 1,
                "subCategory": "constrains"
            },
            "plan_boundary": {
                "color": "#ffffff",
                "displayName": "Plan Boundary",
                "dashArray": 0,
                "maxZoom": 22,
                "minZoom": 16,
                "strokeColor": "#3300ff",
                "opacity": 0.01,
                "thickness": 3,
                "subCategory": "constrains"
            },
            "commercial_front": {
                "color": "#ffffff",
                "strokeColor": "#ab00ff",
                "displayName": "Commercial Front",
                "dashArray": 1,
                "maxZoom": 22,
                "minZoom": 16,
                "opacity": 0.01,
                "thickness": 1,
                "subCategory": "guidelines"
            },
            "right_of_way": {
                "color": "#daedc7",
                "strokeColor": "#009729",
                "displayName": "Right of Way",
                "dashArray": 1,
                "maxZoom": 22,
                "minZoom": 16,
                "opacity": 0.2,
                "thickness": 1,
                "subCategory": "guidelines"
            },
            "normative_area": {
                "color": "#ffffff",
                "strokeColor": "#8a00ff",
                "displayName": "Normative Area",
                "dashArray": 1,
                "maxZoom": 22,
                "minZoom": 16,
                "thickness": 3,
                "subCategory": "constrains"
            }
        }
    }
}